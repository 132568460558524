import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { getSubMessageType, getSubMessageTypeList, MESSAGE_TYPES } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons';





class NewMessageModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onSendMessage: PropTypes.func.isRequired,    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      subject: null,
      messageType: null,
      subMessageType: null,
      content: null,
      checkErrors: null,
      sendErrors: null,
      files: null 
    }

    this.sendMessage = this.sendMessage.bind(this)
    this.checkFields = this.checkFields.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {    
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []

    if ( this.state.messageType == null ) {
      errors.push("Il tipo di segnalazione è obbligatorio")
    }

    if ( this.state.subMessageType == null ) {
      errors.push("Il soggetto della segnalazione è obbligatorio")
    }

    if ( this.state.content == null || this.state.content.length <= 10 ) {
      errors.push("Il messaggio da inviare è vuoto")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  sendMessage() {

    console.log("SEND MESSAGE")

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
    
    var msg = {
      subject: this.state.messageType+' '+this.state.subMessageType,
      content: this.state.content,      
    }

    SostaOnlineApiService.sendMessageMe(msg)
    .then( result => {
      console.log("RESULT",result)

      if ( this.props.onSendMessage != null ) {
        this.props.onSendMessage(msg)
      }

      if ( this.props.onClose )
        this.props.onClose()
    })
    .catch( error => {
      console.log("ERROR",error)
      this.setState({ sendErrors: "Errore durante l'invio del messagggio, riprovare pù tardi!" })
    })

    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   */
  stateInit() {
    this.setState({      
      subject: null,
      messageType: null,
      subMessageType: null,
      content: null,
      checkErrors: null,
      sendErrors: null,
      files: null 
    })

    this.props.initialize({ 
      messageType: null,
      subMessageType: null,
      content: null
    });

  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose      
    } = this.props

    const {
      sendErrors,
      checkErrors,
      subMessageType,
      messageType,
      files
    } = this.state

    var selectedSubMessageType = this.getSelectedMessageType()

    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faEnvelope} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>INVIACI UN MESSAGGIO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            <div className="row">
                <Field 
                  name="messageType" 
                  className="form-control" 
                  component="select" 
                  onChange={ e => { this.setState({messageType: e.target.value}) }}
                  required>
                  <option value="">Seleziona tipo di segnalazione...</option>
                  <option value={MESSAGE_TYPES.TECHNICAL.value}  key="1">{MESSAGE_TYPES.TECHNICAL.descr}</option>
                  <option value={MESSAGE_TYPES.ADMIN.value}      key="2">{MESSAGE_TYPES.ADMIN.descr} </option>
                  <option value={MESSAGE_TYPES.GENERAL.value}    key="3">{MESSAGE_TYPES.GENERAL.descr} </option>
                </Field>
                <br/>
            </div>

            <div className="row">
                <Field 
                  name="subMessageType" 
                  className="form-control" 
                  component="select" 
                  onChange={ e => { this.setState({subMessageType: e.target.value}) }}
                  required>
                  { getSubMessageTypeList(this.state.messageType).map( ( smt , index )=> {
                    return ( <option key={index} value={smt.value}>{smt.descr}</option> )
                  })}
                </Field>
                <br/>
            </div> 

            <div className="row" style={{maxWidth: "300px"}}>
            <label className="form-label" htmlFor="customFile">Seleziona File</label>
                <input
                  type="file"
                  id="customFile"
                  accept=".png, .jpeg, .jpg "
                  name="files"
                  className="form-control"
                  component="input"
                  onChange={ e => { this.setState({files: e.target.value}) }}
                />
                <br/>
            </div> 

            <div className="row">
                { selectedSubMessageType != null && selectedSubMessageType.type === "ERROR_REPORT" && 
                  <h5>Descrivi il problema che riscontri:</h5>
                }
                { selectedSubMessageType != null && selectedSubMessageType.type === "INFO_REQUEST" &&
                  <h5>Descrivi i dettagli della tua richiesta informazioni:</h5>
                }              
                <Field 
                  style={{ 
                    height: "250px",                     
                    color: 'grey', 
                    fontWeight: 'bold'  
                  }}
                  name="content" 
                  className="form-control" 
                  component="textarea" 
                  onChange={ e => { this.setState({content: e.target.value}) }}
                  required />                              
                <br/>
            </div>         

            <div className="row">
              { sendErrors && 
                <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h5>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h5 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h5>
              }
            </div>

            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={ () => this.close() }>Annulla</Button>{' '}
                <Button className="btn mainBtn" onClick={() => this.sendMessage()} >Invia</Button>
              </div>
            </div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'NewMessageModal'})(NewMessageModal)
