import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { scroller } from 'react-scroll'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'//
import ResellerUserDetailScreen from '../../screens/Reseller/ResellerUserDetailScreen'//
import queryString from 'query-string'
import { MARK_DURATION, MARK_EXPIRATION_DATE, getSettingByName } from 'libs/settings'
import { reportActions } from 'redux/modules/Report'
import { actions as authActions } from 'redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { MARK_TYPE_FAMILY, getMarkExpirationDate, getSelectedEndDate, getSelectedStartDate } from 'libs/marks'
import { DOCUMENT_TYPE_VEHICLE } from 'libs/documents'
const thisRoute = 'reseller'

const fileDownload = require('js-file-download')

moment.locale('it')

/**
 * --------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    state: state.users,
    auth: state.auth,
    stateReport: state.report,
    downloading: false
  }
}

/**
 * --------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class ResellerUserDetailContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userId: props.match.params.id,
      addresses: [],
      userType: {},
      userTypes: [],
      areas: [],
      user: {},
      markTypes: [],
      markLoading: false,
      markError: null,
      editMarkError: null,
      markSuccess: false,
      receiptLoading: false,
      receiptError: null,
      receiptSuccess: false,
      vehiclesEditsResults: {},
      vehiclesDeleteResults: {},
      marksDeleteResults: {},
      marksCeaseResults: {},
      markActivateErrors: {},
      marksNotesAddedResults: {},
      registry: {},
      reviewingMark: null,
      showReviewDocumentsModal: false,
      submittingMarkEdit: false,
      highlightObjects: [],
      renewPaymentMethodSelected: '',
      markRenewing: {},
      markRenewingErrors: {},
      userWaitingLists: [],
      showChooseMarksVehicleModal: false,
      showMarksVehicleModalNumber: null,
      showActiveNumberPlate: null,
      chooseMarkVehicleError: null,
      scrolling: false,
      newMark: false,
      markData: [], // per la modale di brunone 
      downloading: false,
      marksResellersConfirmed: null // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
    }

    this.onDeleteVehicle = this.onDeleteVehicle.bind(this)
    this.onVehicleSubmit = this.onVehicleSubmit.bind(this)
    this.onCloseVehicleEdit = this.onCloseVehicleEdit.bind(this)
    this.onEditVehicleSubmit = this.onEditVehicleSubmit.bind(this)
    this.onMarkSubmit = this.onMarkSubmit.bind(this)
    this.onResetMarkSubmitForm = this.onResetMarkSubmitForm.bind(this)
    this.onDeleteMark = this.onDeleteMark.bind(this)
    this.onCeaseMark = this.onCeaseMark.bind(this)
    this.onCloseMarkCease = this.onCloseMarkCease.bind(this)
    this.onCreateMarkUserCategorySelected = this.onCreateMarkUserCategorySelected.bind(this)
    this.onDownloadMark = this.onDownloadMark.bind(this)
    this.onDownloadMarkRelease = this.onDownloadMarkRelease.bind(this)
    this.onDownloadUserCredentials = this.onDownloadUserCredentials.bind(this)
    this.onMarkReceiptDownload = this.onMarkReceiptDownload.bind(this)
    this.onEditProfileClick = this.onEditProfileClick.bind(this)
    this.onShowUserDocumentsClick = this.onShowUserDocumentsClick.bind(this)
    this.onMarkActivateClick = this.onMarkActivateClick.bind(this)
    this.onDenyMarkActivationClick = this.onDenyMarkActivationClick.bind(this)
    this.onListAsWaitConfirm = this.onListAsWaitConfirm.bind(this)
    this.onCloseWaitConfirm = this.onCloseWaitConfirm.bind(this)
    this.onOpenReviewDocumentsModal = this.onOpenReviewDocumentsModal.bind(this)
    this.onCloseReviewDocumentsModal = this.onCloseReviewDocumentsModal.bind(this)
    this.onApprovationChanged = this.onApprovationChanged.bind(this)
    this.onMarkEditSubmit = this.onMarkEditSubmit.bind(this)
    this.onMarkShortLinkClick = this.onMarkShortLinkClick.bind(this)
    this.onMarkRenew = this.onMarkRenew.bind(this)
    this.onRenewPaymentMethodChange = this.onRenewPaymentMethodChange.bind(this)
    this.onRenewMarkClick = this.onRenewMarkClick.bind(this)
    this.onCancelRenewMarkClick = this.onCancelRenewMarkClick.bind(this)
    this.onShowChooseMarksVehicleModal = this.onShowChooseMarksVehicleModal.bind(this)
    this.onHideChooseMarksVehicleModal = this.onHideChooseMarksVehicleModal.bind(this)
    this.onVehicleChoosen = this.onVehicleChoosen.bind(this)
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {
    await Promise.all([
      this.fetchUser(),
      this.fetchAreas(),
      this.fetchAddresses()
    ])

    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.scrollTo) {
      setTimeout(() => {
        this.scrollTo(queryParams.scrollTo)
        this.highlightElement(queryParams.scrollTo)
      }, 500)
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  highlightElement(element) {
    setTimeout(() => {
      this.setState({
        highlightObjects: [element]
      }, () => {
        setTimeout(() => {
          this.setState({
            highlightObjects: []
          })
        }, 1000)
      })
    }, 1100)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onVehicleSubmit(data) {
    this.fetchUser()
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
      this.props.reportActions.fetchThingsToDo(data.data)
    })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseVehicleEdit() {
    this.fetchUser()
    this.setState({ vehiclesEditsResults: {} })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseMarkCease() {
    this.setState({ marksCeaseResults: {} })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onDeleteVehicle(data) {
    let vehiclesDeletes = {}
    vehiclesDeletes[data.id] = 'loading'
    this.setState({ vehiclesDeleteResults: vehiclesDeletes })
    SostaOnlineApiService.deleteVehicle(data, thisRoute).then(result => {
      this.setState({ vehiclesDeleteResults: {} })
      this.fetchUser()
    }).catch(err => {
      let vehiclesDeletes = {}
      vehiclesDeletes[data.id] = 'error'
      this.setState({ vehiclesDeleteResults: vehiclesDeletes })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onEditVehicleSubmit(data) {
    let vehiclesEdits = {}
    vehiclesEdits[data.id] = 'loading'
    this.setState({ vehiclesEditsResults: vehiclesEdits })
    let obj = {
      id: data.id,
      plateNumber: data.plate_number,
      type: data.VehicleOwnershipTypeId,
      powerSupply: data.VehiclePowerSupplyId
    }
    SostaOnlineApiService.updateVehicle(obj, thisRoute).then(result => {
      let vehiclesEdits = {}
      vehiclesEdits[data.id] = 'success'
      this.setState({ vehiclesEditsResults: vehiclesEdits })
    })
      .catch(err => {
        let vehiclesEdits = {}
        vehiclesEdits[data.id] = 'error'
        this.setState({ vehiclesEditsResults: vehiclesEdits })
      })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rowMarkData 
   * @returns 
   rowMarkData.markTypeId,
   rowMarkData.selectedStartDate,
   rowMarkData[key].startsWith('vehicle'),
   rowMarkData.method,
   rowMarkData.addressRoute,
   rowMarkData.note,
   rowMarkData.quantity,
   rowMarkData.categoryId,
   rowMarkData.circolazione,
   rowMarkData.periodo,
   rowMarkData.sosta,
   rowMarkData.color,
   rowMarkData.total,
   rowMarkData.virtual
   */
  async onMarkSubmit(rowMarkData) {
    this.setState({ downloading: true, markLoading: true, markSuccess: false, markError: null })
    let nowMoment = moment()
    let settingsResponse = await SostaOnlineApiService.fetchPublicSettings()
    let settings = null

    if (settingsResponse.data) {
      settings = settingsResponse.data.reduce((idx, item) => {
        const itemNameSplitted = item.name.split('_')
        return {
          ...idx,
          [itemNameSplitted[itemNameSplitted.length - 1]]: item
        }
      }, {})
    }

    if (settings == null) {
      this.setState({
        markLoading: false,
        markSuccess: false,
        markError: 'Impostazione di scadenza per il permesso ' + rowMarkData.markTypeId + ' non presente nel sistema'
      })
      return
    }

    var expiration = null
    var markTypes = await SostaOnlineApiService.fetchMarkTypes()
    const markTypeId = parseInt(rowMarkData.markTypeId, 10)
    var markType = null

    if (markTypes.result === 'ok' && markTypes.data.length > 0) {
      markTypes = markTypes.data.filter(markType => markType.id === markTypeId)

      if (markTypes.length > 0) {
        markType = markTypes[0]
        expiration = getMarkExpirationDate(settings, markType, rowMarkData.selectedStartDate)
      }

    }

    if (markType == null || expiration == null) {
      this.setState({
        markLoading: false,
        markSuccess: false,
        markError: 'Errore durante la creazione del permesso'
      })
      return
    }

    this.setState({
      markLoading: false,
      markSuccess: false,
      markError: 'Test'
    })

    let vehicles = []
    let doubleSelectedVehicles = []

    for (let key in rowMarkData) {
      if (key.startsWith('vehicle')) {
        doubleSelectedVehicles = vehicles.filter(item => item === rowMarkData[key])
        if (doubleSelectedVehicles.length <= 0)
          vehicles.push(rowMarkData[key])
      }
    }

    if (doubleSelectedVehicles.length > 0) {
      this.setState({
        markLoading: false,
        markSuccess: false,
        markError: 'Selezionare targhe differenti'
      })
      return
    }

    let vehiclesString = ''
    vehicles.map((item, index) => {
      vehiclesString = vehiclesString + item

      if (index < vehicles.length - 1) {
        vehiclesString = vehiclesString + ','
      }
    })

    var startDate = nowMoment

    if (rowMarkData.selectedStartDate != null && moment(rowMarkData.selectedStartDate).isAfter(nowMoment)) {
      startDate = moment(rowMarkData.selectedStartDate)
    }

    let obj = {
      method: rowMarkData.method,
      issuedDate: nowMoment.format(),
      startDate: startDate.format(),
      endDate: expiration.format(),
      active: 1,
      userId: parseInt(this.state.userId, 10),
      vehicleIds: vehiclesString,
      addressRoute: rowMarkData.addressRoute,
      note: rowMarkData.note,
      quantity: rowMarkData.quantity,
      markTypeId: markTypeId,
      categoryId: parseInt(rowMarkData.categoryId, 10),
      circolazione: rowMarkData.circolazione,
      periodo: rowMarkData.periodo,
      sosta: rowMarkData.sosta,
      color: rowMarkData.color,
      total: rowMarkData.total,
      virtual: rowMarkData.virtual,
      selectedStartDate: rowMarkData.selectedStartDate
    }
    SostaOnlineApiService.createMark(obj, thisRoute).then(result => {

      this.setState({ newMark: result.data })
      //console.log("ResellerUserDetailContainer newMark:", this.state.newMark)
      this.fetchUser()

      const markData = {
        markId: result.data.id,
        userId: parseInt(this.state.userId, 10),
        resellerId: parseInt(this.props.auth.user.id, 10),
        price: rowMarkData.total
      };

      SostaOnlineApiService.createMarkReseller(markData)
        .then(createdMark => {
          this.setState({
            marksResellersConfirmed: true, // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
            markLoading: false,
            downloading: false,
            markData: createdMark.data,
            markError: null,
            markSuccess: true
          })
        }).catch(error => {
          console.error(error)
          this.setState({
            markLoading: false,
            downloading: false,
            markError: error,
            markSuccess: true
          })
        })
    })
      .catch(err => {
        let error = 'Errore nella creazione del permesso'
        if (err.error === 'Mark already exists') {
          error = 'Errore! Permesso già esistente!'
        }
        else if (err.error === 'Vehicle under modify') {
          error = 'Uno dei veicoli è in fase di modifica'
        }
        let anotherError = this.state.markError != null ? this.state.markError + ' \n' + error : this.state.markError
        this.setState({
          markLoading: false,
          markSuccess: false,
          markError: anotherError,
          markConfirmed: false,
          downloading: false
        })
      })

  }

  /**
   * --------------------------------------------------------------
   */
  onResetMarkSubmitForm() {
    this.setState({
      markLoading: false,
      markError: null,
      editMarkError: null,
      markSuccess: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onDeleteMark(data) {
    let marksDeletes = {}
    marksDeletes[data.id] = 'loading'
    this.setState({ marksDeleteResults: marksDeletes })
    SostaOnlineApiService.deleteMark(data, thisRoute).then(result => {
      this.setState({ marksDeleteResults: {} })
      this.fetchUser()
      SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
        this.props.reportActions.fetchThingsToDo(data.data)
      })
    }).catch(err => {
      let marksDeletes = {}
      marksDeletes[data.id] = 'error'
      this.setState({ marksDeleteResults: marksDeletes })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onCeaseMark(data) {
    let marksCease = {}
    marksCease[data.id] = 'loading'
    this.setState({ marksCeaseResults: marksCease })

    SostaOnlineApiService.ceaseMark(data, thisRoute).then(result => {
      let marksCease = {}
      marksCease[data.id] = 'success'
      this.setState({ marksCeaseResults: marksCease })
      this.fetchUser()
    })
      .catch(err => {
        let marksCease = {}
        marksCease[data.id] = 'error'
        this.setState({ marksCeaseResults: marksCease })
      })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} category 
   */
  async onCreateMarkUserCategorySelected(category) {
    let cat = this.state.user.categories.filter(item => item.id === parseInt(category.target.value, 10))

    if (cat.length > 0) {
      let area

      if (cat[0].resident) {
        area = this.state.user.residenceArea.code
      }
      else {
        area = this.state.user.workArea.code
      }

      const markTypes = await SostaOnlineApiService.fetchMarkTypesByCategoryAndArea(cat[0].id, area)
      this.setState({ markTypes: markTypes.data, loading: false })
    }
  }

  /**
   * --------------------------------------------------------------
   */
  onEditProfileClick() {
    this.props.history.push('/reseller/users/' + this.state.userId + '/edit')
  }

  /**
   * --------------------------------------------------------------
   */
  onShowUserDocumentsClick() {
    this.props.history.push('/reseller/users/' + this.state.userId + '/documents')
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onDownloadMark(mark) {
    this.setState({ downloading: true })
    SostaOnlineApiService.downloadMark(mark.id, thisRoute).then(data => {
      fileDownload(data, 'permesso-' + mark.issuedDate + '.pdf', 'application/pdf')
      this.setState({ downloading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onDownloadMarkRelease(mark) {
    this.setState({ downloading: true })
    SostaOnlineApiService.downloadMarkRelease(mark.id, thisRoute).then(data => {
      fileDownload(data, 'modulo-rilascio-permesso-' + mark.id + '.pdf', 'application/pdf')
      this.setState({ downloading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} user 
   */
  onDownloadUserCredentials(user) {
    SostaOnlineApiService.downloadUserCredentials(user.id)
      .then(data => {
        fileDownload(data, 'credenziali-utente-' + user.id + '.pdf', 'application/pdf')
      })
      .catch(error => {
        console.log("ERROR file not found", error)
      })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onMarkReceiptDownload(mark) {
    this.setState({ downloading: true })
    const reseller = this.props.auth.user
    const resellerLastName = reseller.lastName
    const resellerFirstName = reseller.firstName

    SostaOnlineApiService.downloadMarkReceipt(mark.id, thisRoute, resellerLastName, resellerFirstName)
      .then(data => {
        fileDownload(data, 'ricevuta-permesso-' + mark.id + '.pdf', 'application/pdf')
        this.setState({ downloading: false })
      }).catch(error => {
        console.error(error)
        this.setState({ downloading: false })
      })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @returns 
   */
  async onMarkActivateClick(mark) {
    let settings = await SostaOnlineApiService.fetchSettings(thisRoute)

    if (settings != null && settings.data != null) {
      settings = settings.data
    }
    else {
      let error = 'Errore nella conferma del permesso'
      this.setState({ markLoading: false, markSuccess: false, markError: error })
      return
    }

    if (mark.MarkType.type === MARK_TYPE_FAMILY) {
      if (moment(mark.selectedStartDate).add(1, 'year').isBefore(moment(), 'day')) {
        let error = 'Errore nella conferma del permesso'
        this.setState({ markLoading: false, markSuccess: false, markError: error })
        return
      }
    }
    else {
      var filteredSettings = settings.filter(setting => (
        setting.name == MARK_DURATION + mark.MarkType.id ||
        setting.name == MARK_EXPIRATION_DATE + mark.MarkType.id
      ))

      if (filteredSettings.length <= 0) {
        let error = 'Errore nella conferma del permesso'
        this.setState({ markLoading: false, markSuccess: false, markError: error })
        return
      }
    }

    let selectedEndDate = getSelectedEndDate(mark, settings)
    let selectedStartDate = getSelectedStartDate(mark)

    this.setState({ markLoading: true, markSuccess: false, markError: null })

    let obj = {
      id: mark.id,
      startDate: selectedStartDate.format(),
      endDate: selectedEndDate.format(),
      issuedDate: moment().format(),
      active: 1,
      markTypeId: parseInt(mark.markTypeId, 10),
      categoryId: parseInt(mark.categoryId, 10),
      circolazione: mark.circolazione,
      periodo: mark.periodo,
      sosta: mark.sosta,
      price: mark.price
    }

    SostaOnlineApiService.updateMark(obj, thisRoute).then(result => {
      this.setState({ markLoading: false, markSuccess: true, markError: null })
      this.fetchUser()
      SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
        this.props.reportActions.fetchThingsToDo(data.data)
      })
    }).catch(err => {
      let error = 'Errore nella conferma del permesso'
      this.setState({ markLoading: false, markSuccess: false, markError: error })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} reason 
   * @param {*} mark 
   * @returns 
   */
  onDenyMarkActivationClick(reason, mark) {
    if (!reason) {
      return
    }

    this.setState({ markLoading: true, markSuccess: false, markError: null })
    let obj = {
      id: mark.id,
      active: 1,
      ceased: 1,
      note: reason
    }
    SostaOnlineApiService.updateMark(obj, thisRoute).then(result => {
      this.setState({ markLoading: false, markSuccess: true, markError: null })
      this.fetchUser()
      SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
        this.props.reportActions.fetchThingsToDo(data.data)
      })
    }).catch(err => {
      let error = 'Errore nel rifiuto del permesso'
      this.setState({ markLoading: false, markSuccess: false, markError: error })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} instructions 
   * @param {*} mark 
   * @param {*} marksApprovations 
   * @returns 
   */
  async onListAsWaitConfirm(instructions, mark, marksApprovations = null) {
    try {
      if (!instructions) {
        return
      }

      this.setState({ markLoading: true, markSuccess: false, markError: null })
      let obj = {
        markId: mark.id,
        circolazione: marksApprovations != null ? marksApprovations.circolazione : mark.circolazione,
        periodo: marksApprovations != null ? marksApprovations.periodo : mark.periodo,
        sosta: marksApprovations != null ? marksApprovations.sosta : mark.sosta,
        notes: instructions
      }
      const response = await SostaOnlineApiService.addMarkNote(obj, thisRoute)
      if (response.result !== 'ok') {
        this.setState({
          markLoading: false,
          marksNotesAddedResults: { [mark.id]: false },
          markError: 'Errore nell\'invio delle istruzioni'
        })
      }
      else {
        this.setState({
          markLoading: false,
          marksNotesAddedResults: { [mark.id]: true },
          markError: null
        })
      }
      this.fetchUser()
      SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
        this.props.reportActions.fetchThingsToDo(data.data)
      })
    }
    catch (e) {
      console.error('errore nell\'invio delle istruzioni', e)
      this.setState({
        markLoading: false,
        marksNotesAddedResults: { [mark.id]: false },
        markError: 'Errore nell\'invio delle istruzioni'
      })
    }
  }

  /**
   * --------------------------------------------------------------
   */
  fetchUser() {

    this.setState({ loading: true })

    SostaOnlineApiService.fetchUser(this.state.userId, thisRoute)
      .then((data) => {

        const sortedUser = data.data;
        sortedUser.marks = sortedUser.marks.sort((a, b) => (a.id > b.id) ? -1 : 1)
        sortedUser.vehicles = sortedUser.vehicles.sort((a, b) => (a.id > b.id) ? -1 : 1)

        let user = sortedUser
        let newReviewingMark = null

        if (user.marks.length > 0) {
          user = {
            ...user,
            marks: user.marks.map(item => {
              return (
                {
                  ...item,
                  documentsReview: this.checkDocumentsApprovation(item)
                }
              )
            })
          }

          if (this.state.reviewingMark) {
            let foundReviewingMarks = user.marks.filter(item => item.id === this.state.reviewingMark.id)
            if (foundReviewingMarks.length > 0) {
              newReviewingMark = foundReviewingMarks[0]
            }
          }
        }

        this.setState({
          user: user,
          reviewingMark: newReviewingMark
        }, () => {
          if (data.data.fiscalCode) {
            this.fetchRegistry(data.data.fiscalCode)
          }
        })
      })
      .catch((error) => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} fiscalCode 
   */
  fetchRegistry(fiscalCode) {
    this.setState({ loading: true })
    SostaOnlineApiService.fetchRegistry(fiscalCode, thisRoute).then((data) => {
      this.setState({ registry: data.data ? data.data : {}, loading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchAddresses() {
    this.setState({ loading: true })
    SostaOnlineApiService.fetchAddresses().then((data) => {
      this.setState({
        addresses: data.data,
        loading: false
      })
    })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchAreas() {
    this.setState({ loading: true })
    SostaOnlineApiService.fetchAreas().then((data) => {
      this.setState({
        areas: data.data,
        loading: false
      })
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseWaitConfirm() {
    this.setState({
      marksNotesAddedResults: {}
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @returns 
   */
  checkDocumentsApprovation(mark) {
    if (mark.MarkType.DocumentTypes.length === 0) {
      return {
        documents: []
      }
    }

    const documentTypes = []
    let totalDocumentsRequested = 0
    let totalDocumentsUploaded = mark.MarkDocuments.length
    let totalDocumentsApproved = mark.MarkDocuments.filter(item => item.approved).length

    for (let i = 0; i < mark.MarkType.DocumentTypes.length; i++) {
      const documentType = mark.MarkType.DocumentTypes[i]
      const resultObj = {
        ...documentType,
        documents: []
      }
      if (documentType.docType === DOCUMENT_TYPE_VEHICLE) {
        for (let v = 0; v < mark.Vehicles.length; v++) {
          totalDocumentsRequested++
          const vehicle = mark.Vehicles[v]
          if (vehicle.VehicleDocuments.filter(item => item.DocumentTypeId === documentType.id).length === 0) {
            continue
          }

          for (let vd = 0; vd < vehicle.VehicleDocuments.length; vd++) {
            const vehicleDocument = vehicle.VehicleDocuments[vd]
            if (vehicleDocument.DocumentTypeId === documentType.id) {
              resultObj.documents.push({
                vehiclePlateNumber: vehicle.plate_number,
                vehicleId: vehicle.id,
                documentName: vehicleDocument.Document.name,
                documentApproved: vehicleDocument.approved,
                documentId: vehicleDocument.Document.id,
                mimeType: vehicleDocument.Document.mimeType
              })
            }
          }
        }
      } else {
        totalDocumentsRequested++
        for (let d = 0; d < mark.MarkDocuments.length; d++) {
          const document = mark.MarkDocuments[d]
          if (document.DocumentTypeId === documentType.id) {
            resultObj.documents.push({
              documentApproved: document.approved,
              documentId: document.Document.id,
              mimeType: document.Document.mimeType
            })
          }
        }
      }
      documentTypes.push(resultObj)
    }

    return {
      totalDocumentsRequested: totalDocumentsRequested,
      totalDocumentsUploaded: totalDocumentsUploaded,
      totalDocumentsApproved: totalDocumentsApproved,
      documentTypes: documentTypes
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onOpenReviewDocumentsModal(mark) {
    this.setState({
      reviewingMark: mark,
      showReviewDocumentsModal: true
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseReviewDocumentsModal() {
    this.setState({
      reviewingMark: null,
      showReviewDocumentsModal: false
    })
  }

  /**
   * --------------------------------------------------------------
   */
  async onApprovationChanged() {
    await this.fetchUser()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   * @returns 
   */
  async onMarkEditSubmit(mark) {
    const { submittingMarkEdit } = this.state
    if (submittingMarkEdit) {
      return
    }

    this.setState({ submittingMarkEdit: true, editMarkError: null })

    let vehicles = []
    let doubleSelectedVehicles = []

    mark.vehicles.map(item => {
      doubleSelectedVehicles = vehicles.filter(vItem => vItem === item)
      if (doubleSelectedVehicles.length <= 0)
        vehicles.push(item)
    })

    if (doubleSelectedVehicles.length > 0) {
      this.setState({
        editMarkError: 'Selezionare targhe differenti',
        submittingMarkEdit: false
      })
      return
    }

    const editedMark = {
      id: mark.id,
      vehicles: vehicles.map(item => ({ id: item })),
      addressRoute: mark.addressRoute,
      price: mark.total,
      method: mark.method,
      periodo: mark.periodo,
      circolazione: mark.circolazione,
      sosta: mark.sosta
    }

    const response = await SostaOnlineApiService.editMark(editedMark, thisRoute)
    await this.fetchUser()
    this.setState({ submittingMarkEdit: false })
    return response

  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  onVehicleShortLinkClick = (vehicle) => {
    this.scrollTo(`vehicle-${vehicle.id}`)
    this.highlightElement(`vehicle-${vehicle.id}`)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onMarkShortLinkClick(mark) {
    this.scrollTo(`mark-${mark.fatherMark}`)
    this.highlightElement(`mark-${mark.fatherMark}`)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} id 
   * @param {*} objectsArray 
   * @returns 
   */
  findObjectById(id, objectsArray) {
    const searchResult = objectsArray.filter(item => item.id === id)
    if (searchResult.length > 0) {
      return searchResult[0]
    }

    return null
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  async onMarkRenew(mark) {
    this.setState({
      markRenewingErrors: {}
    })

    const {
      renewPaymentMethodSelected
    } = this.state

    if (!renewPaymentMethodSelected) {
      this.setState({
        markRenewingErrors: { [mark.id]: 'Metodo di pagamento obbligatorio' }
      })
      return
    }

    var requestObj = {
      id: mark.id,
      price: mark.price == null ? 0 : parseFloat(mark.price).toFixed(2),
      periodo: mark.periodo,
      sosta: mark.sosta,
      circolazione: mark.circolazione,
      action: 'RENEW',
      method: renewPaymentMethodSelected
    }

    const result = await SostaOnlineApiService.editMark(requestObj, thisRoute)
      .catch(error => {
        console.log("ERROR", error)
        this.setState({
          markRenewingErrors: { [mark.id]: 'Errore nella richiesta di rinnovo' }
        })
      })

    if (result.result === 'ok') {
      this.fetchUser()
      this.setState({
        markRenewingErrors: {},
        markRenewing: {}
      })
    }
    else {
      this.setState({
        markRenewingErrors: { [mark.id]: 'Errore nella richiesta di rinnovo' }
      })
    }

  }

  /**
   * --------------------------------------------------------------
   * @param {*} event 
   */
  onRenewPaymentMethodChange(event) {
    this.setState({
      renewPaymentMethodSelected: event.target.value
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onRenewMarkClick(mark) {
    let markRenewing = {}
    markRenewing[mark.id] = true
    this.setState({ markRenewing: markRenewing })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onCancelRenewMarkClick(mark) {
    this.setState({ markRenewing: {} })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
  onShowChooseMarksVehicleModal(mark) {
    this.setState({
      showMarksVehicleModalNumber: mark,
      showChooseMarksVehicleModal: true
    });
  }

  /**
   * --------------------------------------------------------------
   */
  onHideChooseMarksVehicleModal() {
    this.setState({
      showChooseMarksVehicleModal: false,
      showMarksVehicleModalNumber: null,
      chooseMarkVehicleError: null
    });
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  async onVehicleChoosen(vehicle) {

    try {
      await SostaOnlineApiService.updateMarkActiveVehicle(this.state.showMarksVehicleModalNumber.id, vehicle.id, thisRoute)
      this.fetchUser()
      this.onHideChooseMarksVehicleModal()
    } catch (e) {
      this.setState({
        chooseMarkVehicleError: e.error
      })
      console.log(e)
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {
    
    //console.log( "ResellerUserDetailContainer.props", this.props )
    return (
      <ResellerUserDetailScreen
        onDeleteVehicle={this.onDeleteVehicle}
        onVehicleSubmit={this.onVehicleSubmit}
        onCloseVehicleEdit={this.onCloseVehicleEdit}
        onEditVehicleSubmit={this.onEditVehicleSubmit}
        onMarkSubmit={this.onMarkSubmit}
        onResetMarkSubmitForm={this.onResetMarkSubmitForm}
        onDeleteMark={this.onDeleteMark}
        onCeaseMark={this.onCeaseMark}
        onCloseMarkCease={this.onCloseMarkCease}
        onCreateMarkUserCategorySelected={this.onCreateMarkUserCategorySelected}
        onDownloadMark={this.onDownloadMark}
        onDownloadMarkRelease={this.onDownloadMarkRelease}
        onDownloadUserCredentials={this.onDownloadUserCredentials}
        onMarkReceiptDownload={this.onMarkReceiptDownload}
        onEditProfileClick={this.onEditProfileClick}
        onShowUserDocumentsClick={this.onShowUserDocumentsClick}
        onMarkActivateClick={this.onMarkActivateClick}
        onDenyMarkActivationClick={this.onDenyMarkActivationClick}
        onListAsWaitConfirm={this.onListAsWaitConfirm}
        onCloseWaitConfirm={this.onCloseWaitConfirm}
        onOpenReviewDocumentsModal={this.onOpenReviewDocumentsModal}
        onCloseReviewDocumentsModal={this.onCloseReviewDocumentsModal}
        onMarkShortLinkClick={this.onMarkShortLinkClick}
        onVehicleShortLinkClick={this.onVehicleShortLinkClick}
        findObjectById={this.findObjectById}
        onMarkRenew={this.onMarkRenew}
        onRenewPaymentMethodChange={this.onRenewPaymentMethodChange}
        renewPaymentMethodSelected={this.state.renewPaymentMethodSelected}
        user={this.state.user}
        operator={this.props.auth.user}
        addresses={this.state.addresses}
        areas={this.state.areas}
        userTypes={this.state.userTypes}
        markTypes={this.state.markTypes}
        userType={this.state.userType}
        markLoading={this.state.markLoading}
        markSuccess={this.state.markSuccess}
        markError={this.state.markError}
        receiptLoading={this.state.receiptLoading}
        receiptSuccess={this.state.receiptSuccess}
        receiptError={this.state.receiptError}
        editMarkError={this.state.editMarkError}
        vehiclesEditsResults={this.state.vehiclesEditsResults}
        vehiclesDeleteResults={this.state.vehiclesDeleteResults}
        markDeleteResults={this.state.marksDeleteResults}
        markActivateErrors={this.state.markActivateErrors}
        markCeaseResults={this.state.marksCeaseResults}
        marksNotesAddedResults={this.state.marksNotesAddedResults}
        registry={this.state.registry}
        showReviewDocumentsModal={this.state.showReviewDocumentsModal}
        reviewingMark={this.state.reviewingMark}
        highlightObjects={this.state.highlightObjects}
        onApprovationChanged={this.onApprovationChanged}
        onMarkEditSubmit={this.onMarkEditSubmit}
        onCancelRenewMarkClick={this.onCancelRenewMarkClick}
        onRenewMarkClick={this.onRenewMarkClick}
        markRenewing={this.state.markRenewing}
        markRenewingErrors={this.state.markRenewingErrors}
        userWaitingLists={this.state.userWaitingLists}
        onShowChooseMarksVehicleModal={this.onShowChooseMarksVehicleModal}
        onHideChooseMarksVehicleModal={this.onHideChooseMarksVehicleModal}
        showChooseMarksVehicleModal={this.state.showChooseMarksVehicleModal}
        showMarksVehicleModalNumber={this.state.showMarksVehicleModalNumber}
        onVehicleChoosen={this.onVehicleChoosen}
        chooseMarkVehicleError={this.state.chooseMarkVehicleError}
        newMark={this.state.newMark}
        marksResellersConfirmed={this.state.marksResellersConfirmed} // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
        downloading={this.state.downloading}
        markData={this.state.markData}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerUserDetailContainer)
