import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import PaymentResult from "./PaymentResult";
import queryString from 'query-string'
import Loading from "../../../components/LoadingComponent";
import moment from 'moment';

const mapStateToProps = (state) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        
    };
};

class PaymentResultContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentResult: {},
            loading: false,
            error: null
        };
    }
    async componentDidMount() {
        this.setState({loading: true});
        console.log("containers/UserArea/Checkout/PaymentResultContainer: this.props.location.search",this.props.location.search);
        const parsed  = queryString.parse(this.props.location.search);
        var   payment = await SostaOnlineApiService.getPaymentConfig();

        switch ( payment.module ) {
            
            case "nexi":                                
            SostaOnlineApiService.nexiOperationResult(parsed)
            .then( 
                data => {                        
                    this.setState({paymentResult: data.data, loading: false});   
                    //if (data.data.TransactionResult === 'OK') {
                    //    // Se il pagamento va a buonFine, aggiorna la issuedDate
                    //    this.updateMarkIssuedDate();
                    //}                     
                }
                ).catch(err => {                    
                    this.setState({
                        paymentResult: {
                            TransactionResult: 'KO',
                            ErrorCode: 'B2S',
                            ErrorDescription: 'Errore di comunicazione con il gateway ('+err.error+')',
                            Amount: 0
                        }, 
                        loading: false
                    })
                });
                break;

            //ancora non verificato
            case "xpay":                                
                SostaOnlineApiService.xpayOperationResult(parsed)
                .then( 
                    data => {                        
                        this.setState({
                            paymentResult: data.data,
                            loading: false
                        });
                    }
                    ).catch(err => {                    
                        this.setState({
                            paymentResult: {
                                TransactionResult: 'KO',
                                ErrorCode: 'B2S',
                                ErrorDescription: 'Errore di comunicazione con il gateway ('+err.error+')',
                                Amount: 0
                            }, 
                            loading: false
                        })
                        
                });
                
                break;
                
                
            case "sella":
                SostaOnlineApiService.decryptSellaOperationResult(parsed.a, parsed.b).then(data => {                    
                    this.setState({paymentResult: data.data, loading: false});
                    
                }).catch(err => {
                    this.setState({paymentResult: {
                        TransactionResult: 'KO',
                        ErrorCode: 'B2S',
                        ErrorDescription: 'Errore di comunicazione con il gateway ('+err.error+')',
                        Amount: 0
                    }, loading: false})
                });
                break;
                
            default:
                break; 
        }
        
    }

//    updateMarkIssuedDate() {
//        const { mark } = this.props;
//    
//        if (mark.issuedDate == null) {
//            console.log('Stato Permesso', mark.id ,': ', 'in attesa di conferma della issuedDate');
//            const newIssuedDate = moment().format('YYYY-MM-DD HH:mm:ss');
//            console.log('La nuova issuedDate:', newIssuedDate, 'per il permesso ', mark.id);
//    
//            // Lide com a promessa de SostaOnlineApiService.updateMarkIssuedDate
//            SostaOnlineApiService.updateMarkIssuedDate(mark.id, newIssuedDate)
//                .then(response => {
//                    // Atualizando o estado do componente com a mensagem retornada pelo servidor
//                    this.setState({ msg: response.message });
//                })
//                .catch(error => {
//                    // Verifiche errors
//                    console.error('Error nell aggiornamento della issuedDate:', error);
//                    this.setState({ msg: 'Error nell aggiornamento della issuedDate' });
//                });
//        } else {
//            console.log('Stato Permesso', mark.id ,': ', 'già confermata da backoffice la issuedDate o nel precedente tentativo di pagamento (se mark senza verifica dell\'operatore)');
//        }
//    }


    render() {        
        if(this.state.loading) {
            return(<Loading/>)
        }
        if (this.state.paymentResult ) {
            return (<PaymentResult result={this.state.paymentResult} error={this.state.error} />);
        }
    }
}

PaymentResultContainer.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentResultContainer);

