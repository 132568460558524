import React, { Component } from "react"
import { Link } from 'react-router-dom';
import PropTypes from "prop-types"
import moment from "moment"
import { Table } from "react-bootstrap"
import "./PaymentPage.css"
import { reduxForm, Field } from "redux-form"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as solidIcons from "@fortawesome/free-solid-svg-icons"
// aggiunti getMarkIssuedDate per consentire l'inserimento della issuedDate durante il pagamento dei permessi che non richiedono attivazione da parte dell'operatore
import { getSelectedEndDate, getSelectedStartDate } from "libs/marks"
import SostaOnlineApiService from "services/SostaOnlineApiService"

// ----------------------------------------------------------------
//
//
const mapStateToProps = (state, props) => {
  return { initialValues: props.initialValues };
}

class MarkPayment extends Component {

  /**
   * -------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  static propTypes = {
    mark: PropTypes.object,
    onPayClick: PropTypes.func,
    errorMsg: PropTypes.string
  }

  /**
   * -------------------------------------------------------------------------
   * @returns 
   */
  render() {

    const { mark, errorMsg, payment, onPay, pendingOrders, settings, allOrders, hostedPage } = this.props
    //const { hostedPage } = this.state
    var msg = null
    var wait = false
    var newIssuedDate = null
    console.log("containers/UserArea/Checkout/MarkPayment:render:props:", this.props);
    const markDetails = (
      <>
        <div className="row">
          <div className="text-center">
            <h3 style={{ lineHeight: "3.5vh" }}>Pagamento permesso<br />{mark.MarkType.label.toUpperCase()} n. {mark.id}</h3>
            <p></p>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "2vh" }}>
          <div className="text-center">
            <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faCreditCard} />
            <br />
            <br />
          </div>
        </div>

        <div className="row" style={{ marginTop: "2vh" }}>
          <div className="text-center">
            <h3>Totale: € {Number(mark.price).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
          </div>
        </div>
      </>
    )
    {
      /*console.log("containers/UserArea/Checkout/MarkPayment:render:props:", this.props);
      console.log("containers/UserArea/Checkout/MarkPayment:render:allOrders", allOrders);*/
    }

    if (mark.paid) {
      wait = true
      msg = "Il pagamento risulta già effettuato"
      //console.log("vediamo");
    } else if (pendingOrders != null && pendingOrders.id) {
      //msg = "Una richiesta di pagamento risulta già attiva, attendere la mail di conferma del suo completamento."
      msg = "È stata avviata una richiesta di pagamento. Attendere la conferma via e-mail o riprovare tra 15 minuti."
      wait = true
    }

    if (mark.length > 0 && mark.issuedDate == null) {
      //console.log("Stato Permesso", mark.id, ": ", "in attesa di conferma della issuedDate")
      newIssuedDate = moment().format("YYYY-MM-DD HH:mm:ss");
      //console.log("La nuova issuedDate:", newIssuedDate, "per il permesso ", mark.id)

      // Lavorando con la promisse di SostaOnlineApiService.updateMarkIssuedDate
      SostaOnlineApiService.updateMarkIssuedDate(mark.id, newIssuedDate)
        .then(response => {
          // Aggiornando lo stato dell component d'accordo con il messaggio del server
          this.setState({ msg: response.message });
        })
        .catch(error => {
          // Verifiche errors
          console.error("Error nell aggiornamento della issuedDate:", error);
          this.setState({ msg: "Error nell aggiornamento della issuedDate" });
        });

    } else if (mark.length > 0) {
      //console.log("Stato Permesso", mark.id, ": ", "già confermata da backoffice la issuedDate o nel precedente tentativo di pagamento (se mark senza verifica dell\'operatore)")
      newIssuedDate = mark.issuedDate;
    }

    msg = errorMsg ? errorMsg : msg

    return (
      <div className="col-md-offset-1 col-md-10">
        <div className="panel panel-default panel-border">
          <div className="panel-body">
            <h2 className="title">Riepilogo</h2>

            <div className="row">
              <div className="col-md-12 hidden-md hidden-lg">
                <div className="card-marks" style={{ minHeight: "2vh", paddingTop: "2vh" }}>
                  <div className="capiton cart-caption">
                    <div className="col-md-4">
                      <h5>
                        <strong>Permesso: </strong>
                        <span className="text-left">
                          <strong>{mark.MarkType.label.toUpperCase()}</strong>
                        </span>
                      </h5>

                      <h5>
                        <strong>Categoria: </strong>
                        <span>{mark.Category.label.toUpperCase()}</span>
                      </h5>

                      <h5>
                        <strong>Zon{mark.MarkType.Areas.length > 1 ? "e" : "a"}: </strong>
                        <span>{mark.MarkType.Areas.map(item => item.code + " ")}</span>
                      </h5>

                      <h5>
                        <strong>Targ{mark.Vehicles.length > 0 ? "he" : "a"}: </strong>
                        <span>{mark.Vehicles.map(item => item.plate_number.toUpperCase() + " ")}</span>
                      </h5>
                    </div>

                    <div className="col-md-4">
                      <h5>
                        <strong>Valido </strong>
                        <span className="text-left">
                          <strong>dal </strong> <span>{moment(mark.startDate).format("L")}</span>
                          <strong> al </strong> {moment(mark.endDate).format("L")}
                        </span>
                      </h5>
                    </div>

                    <div className="col-md-4">
                      <h5>
                        <strong>Prezzo </strong>
                        <span className="text-left">
                          € <strong>{Number(mark.price).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <Table responsive className="hidden-xs hidden-sm">
              <thead>
                <tr>
                  <th>Prodotto</th>
                  <th>Categoria</th>
                  <th>Zone</th>
                  <th>Validità</th>
                  <th>Targhe</th>
                  <th>Prezzo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">
                    <span>
                      {mark.MarkType.label.toUpperCase()}
                    </span>
                  </td>
                  <td className="text-left">
                    {mark.Category.label.toUpperCase()}
                  </td>
                  <td className="text-left">
                    {mark.MarkType.Areas.map(item => item.code + " ")}
                  </td>
                  <td className="text-left">
                    {getSelectedStartDate(mark) && getSelectedStartDate(mark).format("L")} - {getSelectedEndDate(mark, settings) && getSelectedEndDate(mark, settings).format("L")}
                  </td>
                  <td className="text-left">
                    {mark.Vehicles.map(item => item.plate_number.toUpperCase() + " ")}
                  </td>
                  <td className="text-left">
                    € {Number(mark.price).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                </tr>
              </tbody>
            </Table>
            {allOrders.length > 0 && (1 + 1 == 0) ? // attivare per vedere gli ordini
              <div className="row hidden-xs hidden-sm" style={{
                display: "flex",
                flex: "auto",
                alignContent: "center",
                alignSelf: "center"
              }}>
                <div className="col-md-5" style={{
                  marginBottom: "1vh",
                  marginLeft: "auto",
                  marginRight: "2%",
                  borderRadius: "15px",
                  backgroundColor: "#eafff061",
                  boxShadow: "3px 3px 12px 12px #e6f5ebad",
                  padding: "1vh 1vw",
                  maxWidth: "45%",
                  alignContent: "center"
                }}>
                  {markDetails}
                </div>
                <div className="col-md-7" style={{
                  marginBottom: "1vh",
                  marginLeft: "2%",
                  marginRight: "auto",
                  borderRadius: "15px",
                  backgroundColor: "#eafff061",
                  boxShadow: "3px 3px 12px 12px #e6f5ebad",
                  padding: "1vh 1vw",
                  maxWidth: "45%"
                }}>
                  <p className="text-justify"><strong>{allOrders.length > 1 ? "Sono presenti " + allOrders.length + " Ordini" : "È presente un Ordine"}</strong></p>
                  <Table responsive className="hidden-xs hidden-sm">
                    <tbody>
                      {
                        allOrders.map((item, index) => {
                          const isLast = index === allOrders.length - 1;
                          return (
                            <React.Fragment key={item.id}>
                              <tr>
                                <td className="text-left">
                                  <strong>MarkOrder:</strong> {item.id} &nbsp; <strong>Order:</strong> {item.Order.id}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  <strong>MarkOrderData:</strong> {moment(item.createdAt).format("DD.MM.YYYY HH:mm")}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  <strong>Gateway:</strong> {item.Order.gateway} &nbsp; <strong>Status:</strong> {item.Order.status}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  <strong>PaymentId:</strong> {item.Order.paymentId}
                                </td>
                              </tr>
                              {
                                /*<tr>
                                  <td className="text-left">
                                    <strong>UUID:</strong> {item.Order.uuid}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-left">
                                    <strong>Updated:</strong> {item.Order.updatedAt}
                                  </td>
                                </tr>*/
                                !isLast &&
                                <tr>
                                  <td className="text-left">
                                    <hr />
                                  </td>
                                </tr>
                              }
                            </React.Fragment>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-12">
                  {markDetails}
                </div>
              </div>
            }
            {msg != null ?
              <div className="row" style={{ marginTop: "3vh", marginBottom: "2vh" }}>
                <div className="text-center">
                  <div className="col-xs-1">&nbsp;</div>
                  <div className="col-xs-10">
                    <h3>{msg}</h3>
                  </div>
                  <div className="col-xs-1">&nbsp;</div>
                </div>
              </div>
              :
              <div className="row"
                style={{
                  marginBottom: "2vh",
                  marginLeft: "2vw",
                  marginRight: "2vw",
                  borderRadius: "15px",
                  backgroundColor: "#eafff061",
                  boxShadow: "3px 3px 12px 12px #e6f5ebad",
                  padding: "2vh 2vw"
                }}>
                <div style={{
                  textAlign: "justify"
                }}>
                  <p>Al click del pulsante <strong>ACQUISTA</strong> ci sarà un reindirizzamento al sistema di pagamento {payment.module.toUpperCase()}.</p>
                  <p style={{ color: "black" }}>Si consiglia di verificare ora di essere in possesso dei dati di abilitazione, esempio carte di credito, app di autorizzazione, e tutte le informazioni necessarie per completare la procedura.</p>
                  <p style={{ color: "black" }}>In caso di imprevisti durante la procedura, evitare il pulsante "torna indietro" del browser, servirsi invece dell'apposito pulsante <span style={{ color: "blue" }}>"Annulla Operazione"</span> posto in basso nell'area di compilazione che seguirà.</p>
                  <p style={{ color: "black" }}>Seguire tutte le indicazioni del gestore dei pagamenti e attendere di essere reindirizzati a questa pagina di esito. Non chiudere la finestra del browser al termine della procedura.</p>
                  <p>Queste raccomandazioni sono espresse per evitarLe spiacevoli ritardi di analisi e gestione del pagamento, che potrebbero impedirLe di ottenere subito l'abilitazione del permesso.</p>
                  <p>Cliccare sul pulsante <strong>ACQUISTA</strong> per procedere...</p>
                </div>
              </div>
            }

            <div className="row" style={{ marginTop: "3vh", marginBottom: "2vh" }}>
              {payment != null && payment.module === "nexi" && !wait &&
                <form id="MarkPaymentForm" action={payment.api} method="POST">
                  <fieldset hidden>
                    <Field component="input" id="alias" name="alias" type="text" />
                    <Field component="input" id="importo" name="importo" type="text" />
                    <Field component="input" id="divisa" name="divisa" type="text" />
                    <Field component="input" id="codTrans" name="codTrans" type="text" />
                    <Field component="input" id="url" name="url" type="text" />
                    <Field component="input" id="url_back" name="url_back" type="text" />
                    <Field component="input" id="mac" name="mac" type="text" />
                    <Field component="input" id="nexiTimeout" name="nexiTimeout" type="text" />
                  </fieldset>
                  <button type="input" onClick={evt => onPay(evt)} className="btn mainBtn">
                    Acquista
                  </button>
                </form>
              }
              {payment != null && payment.module === "xpay" && !wait &&
                <form id="MarkPaymentForm" action={hostedPage} method="GET">
                  <fieldset hidden>
                    <input readOnly style={{ width: "100%" }} key={hostedPage} id="hostedPage" name="hostedPage" type="text" value={hostedPage} />
                    {/* l'input hostedPage viene generato con la funzione onPay(), quindi alla pressione del tasto acquista */}
                    <input readOnly style={{ width: "100%" }} key={payment.timeout} id="xPayTimeout" name="xPayTimeout" type="text" value={payment.timeout} />
                    <input readOnly style={{ width: "100%" }} key="title" id="title" name="title" type="text" value={"Pagamento Permesso " + mark.id} />
                  </fieldset>
                  <button type="input" onClick={evt => onPay(evt)} className="btn mainBtn">
                    Acquista
                  </button>
                </form>
              }

              {payment != null && payment.module === "sella" && !wait &&
                <button onClick={() => onPay()} type="button" className="btn mainBtn">
                  Acquista
                </button>
              }

              {payment != null && payment.module === "pagopa" && !wait &&
                <button onClick={() => onPay()} type="button" className="btn mainBtn">
                  Acquista
                </button>
              }

              {msg != null && wait &&
                <Link to="/user-area/marks">
                  <button type="button" className="btn mainBtn">
                    Annulla
                  </button>
                </Link>
              }
            </div>

          </div>
        </div>
      </div>)
  }
}


export default connect(mapStateToProps)(reduxForm({ form: "MarkPayment", enableReinitialize: true })(MarkPayment));