import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import { Element } from 'react-scroll'
import FileInputField from '../../../components/FileInputField'
import NewVehicleModal from 'components/Vehicles/NewVehicleModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { getVehicleIcon } from 'libs/vehicles'
import { MenuItem, DropdownButton } from 'react-bootstrap'
import { areObligatoryDocsSelected, getDocumentColor, getSelectedEndDate, getSelectedStartDate, MARK_TYPE_ELECTRIC, MARK_TYPE_GREEN, MARK_TYPE_HYBRID } from 'libs/marks'


class EditMark extends Component {
  static propTypes = {
    mark: PropTypes.object.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool,
    markExpirationDate: PropTypes.object,
    errors: PropTypes.string,
    markRules: PropTypes.object,
    onVehicleSelected: PropTypes.func.isRequired,
    onVehicleAdd: PropTypes.func,
    onVehicleRemove: PropTypes.func,
    price: PropTypes.object,
    documents: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    selectedDocuments: PropTypes.arrayOf(PropTypes.object),
    onDocumentAdd: PropTypes.func,
    onDocumentRemove: PropTypes.func,
    onDocumentChange: PropTypes.func,
    onCloseFileManager: PropTypes.func,
    settings: PropTypes.array
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      showNewVehicleModal: false
    }


    this.onCloseNewVehicleModal = this.onCloseNewVehicleModal.bind(this)
    this.showNewVehicleModal = this.showNewVehicleModal.bind(this)
    this.onVehicleSelected = this.onVehicleSelected.bind(this)
    this.onAddVehicle = this.onAddVehicle.bind(this)

  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  componentDidMount() {
    this.setState({ showNewVehicleModal: false })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseNewVehicleModal() {
    this.setState({ showNewVehicleModal: false })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  showNewVehicleModal() {
    this.setState({ showNewVehicleModal: true })
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} vehicleId 
   */
  onVehicleSelected(vehicleId) {

    if (vehicleId != null && this.props.onVehicleSelected) {
      this.props.onVehicleSelected(vehicleId)
    }
    else {
      this.showNewVehicleModal()
    }


  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} vehicle 
   */
  onAddVehicle(vehicle) {
    if (this.props.onVehicleAdd)
      this.props.onVehicleAdd(vehicle)

  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  areSelectedVehicles() {
    var filter = this.props.mark.Vehicles.filter(item => (item.id != null || item.type === 'new'))
    return filter.length > 0
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  getUserVehicles() {
    var result = []
    var vehicles = []

    const {
      user,
      mark
    } = this.props

    if (user && user.vehicles) {

      vehicles = user.vehicles

      if (mark.MarkType) {

        if (mark.MarkType.type === MARK_TYPE_HYBRID) {
          vehicles = vehicles.filter(item => item.VehiclePowerSupply.hybrid)
        }
        else if (mark.MarkType.type === MARK_TYPE_ELECTRIC) {
          vehicles = vehicles.filter(item => item.VehiclePowerSupply.electric)
        }
        else if (mark.MarkType.type === MARK_TYPE_GREEN) {
          vehicles = vehicles.filter(item => (item.VehiclePowerSupply.hybrid || item.VehiclePowerSupply.electric))
        }

      }

      vehicles.map(v => {

        var find = mark.Vehicles.filter(sv => sv.id === v.id)

        if (find.length <= 0)
          result.push(v)
      })
    }

    return result
  }

  /**
  * --------------------------------------------------------------------------
  * @param {*} mark 
  * @returns 
  */
  showThirdStep(mark) {
    return mark.status === 'PENDING_EDITABLE' && this.areSelectedVehicles() && this.props.documents.length > 0
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  showFinalStep(price) {
    return (this.areObligatoryDocsSelected() && price)
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  areObligatoryDocsSelected() {

    var {
      documents,
      selectedDocuments
    } = this.props

    return areObligatoryDocsSelected(documents, selectedDocuments)
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @returns 
   */
  getDocumentColor(document) {
    return getDocumentColor(document, this.props.selectedDocuments)
  }

  render() {
    const {
      mark,
      loading,
      markExpirationDate,
      errors,
      markRules,
      onVehicleRemove,
      price,
      submitting,
      documents,
      selectedDocuments,
      onDocumentChange,
      onDocumentRemove,
      onDocumentAdd,
      onCloseFileManager,
      settings
    } = this.props

    const {
      showNewVehicleModal
    } = this.state

    if (loading) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    return (
      <form onSubmit={this.props.handleSubmit(values => {
        this.props.onSubmit({ ...values })
      })}>
        {mark.id && (
          <div className="col-md-10 col-md-push-1">
            <div className="panel panel-default panel-border">
              <div className="panel-body">
                <NavLink to="/user-area" className="btn mainBtn" style={{ margin: 20 }}>Torna indietro</NavLink>
                <h2 className="title title-special">Modifica Permesso</h2>
                <p>Da qui puoi modificare il tuo permesso<br /></p>

                {/* SEZIONE DATI GENERALI */}
                <div className="box">
                  <fieldset>
                    <div className="titolo-categoria">
                      <legend className="text-left">Tipologia Permesso</legend>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div
                          className={'panel panel-default park-ticket-panel panel-border active'}>
                          <div className="panel-body text-center">
                            <h4>{mark.MarkType.label}</h4>

                            {!mark.MarkType.free ? (
                              <h3 className="title3">
                                A pagamento
                              </h3>
                            ) : (
                              <h3>
                                Gratuito
                              </h3>
                            )}
                            <div className="panel-body flex-grow panel-2">
                              Valido nell{mark.MarkType.Areas.length > 1 ? 'e' : 'a'}
                              {' '}zon{mark.MarkType.Areas.length > 1 ? 'e' : 'a'}:{' '}
                              {mark.MarkType.Areas.map((areaItem, index) => (
                                <span key={index}>
                                  <strong>{areaItem.code}</strong>
                                  {index === mark.MarkType.Areas.length - 1 ? '' : ', '}
                                </span>
                              ))}
                              <br />
                              {mark.MarkType &&
                                <React.Fragment>
                                  Valido
                                  {getSelectedStartDate(mark) && <span> dal     <strong>{getSelectedStartDate(mark).format("L")}</strong></span>}
                                  {getSelectedEndDate(mark, settings) && <span> fino al <strong>{getSelectedEndDate(mark, settings).format("L")}</strong></span>}
                                </React.Fragment>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <Field name="markType" component="input" type="hidden" />
                    </div>
                  </fieldset>
                </div>

                {/* SEZIONE VEICOLI */}
                <Element name="step-two" />
                <div className="box">
                  <fieldset>
                    <div className="titolo-categoria">
                      <legend className="text-left">Veicoli</legend>

                      <h4>
                        Questo permesso è utilizzabile su un massimo di {markRules.vehicleLimit} veicol{markRules.vehicleLimit > 1 ? 'i' : 'o'}
                      </h4>

                      <div className="row">
                        <div className="col-md-12 text-left">

                          <DropdownButton
                            id="AddVehicle"
                            title="SCEGLI VEICOLO"
                            className="mainBtn"
                            dropup
                            onSelect={(vehicleId) => { this.onVehicleSelected(vehicleId) }}
                          >
                            <MenuItem eventKey={null} style={{ minWidth: "400px", padding: "0px", margin: "0" }}>
                              <h4 style={{ margin: "5px", padding: "3px" }}>
                                <span><FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faPlusCircle} />&nbsp;&nbsp;</span>
                                <span><strong>Nuovo Veicolo</strong></span>
                              </h4>
                            </MenuItem>
                            {
                              this.getUserVehicles().map((item) =>
                                <MenuItem key={"select-vehicle-" + item.id} eventKey={item.id} style={{ minWidth: "400px", padding: "0px", margin: "0" }}>
                                  <h4 style={{ margin: "5px", padding: "3px" }}>
                                    <span>{getVehicleIcon(item.VehiclePowerSupply, "1x")}&nbsp;&nbsp;</span>
                                    <span><strong>{item.plate_number}&nbsp;&nbsp;</strong></span>
                                    <span>{item.VehiclePowerSupply.name}</span>
                                  </h4>
                                </MenuItem>
                              )
                            }
                          </DropdownButton>

                          <span className={mark.Vehicles.length >= markRules.vehicleLimit ? 'text-danger' : 'text-primary'}>
                            <strong>{' Veicoli Utilizzati: ' + mark.Vehicles.length} / {markRules.vehicleLimit}</strong>
                          </span>
                        </div>
                      </div>

                    </div>

                    {mark.Vehicles.map((item, index) => {
                      return (
                        <div className="row row-list-body" key={'vehicle-' + index} >

                          <div className="col-sm-1 text-left cell-list-body ">
                            <div style={{ marginTop: 5, paddingLeft: 15 }}>{getVehicleIcon(item.VehiclePowerSupply, "2x")}</div>
                          </div>

                          <div className="col-sm-3 text-left cell-list-body ">
                            <div style={{ marginTop: 5 }}><strong>{item.plate_number}</strong></div>
                          </div>

                          <div className="col-sm-3 text-left cell-list-body">
                            <div style={{ marginTop: 5 }}>{item.VehiclePowerSupply.name}</div>
                          </div>

                          <div className="col-sm-4 text-left cell-list-body">
                            <div style={{ marginTop: 5 }}>{item.VehicleOwnershipType.name}</div>
                          </div>

                          <div className="col-sm-1 cell-list-body">
                            <div onClick={() => onVehicleRemove(index)} className="alertBtn">
                              <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </fieldset>
                </div>

                {/* SEZIONE DOCUMENTI */}
                <Element name="step-three" />
                {this.showThirdStep(mark) && (
                  <div className="box">
                    <fieldset>
                      <div className="titolo-categoria">
                        <legend className="text-left">Documenti</legend>
                        <span className="text-center col-md-12" >
                          {/* <!--h4 className="text-center col-md-12">La richiesta di questo permesso richiede l'upload dei seguenti documenti:</h4--> */}
                          <h4 className="text-center col-md-12">Elenco documentazione per il rilascio ( in ROSSO obbligatoria, in GIALLO facoltativa,):</h4>
                          {documents.map((item, index) => {
                            return (
                              item.typeLabel == "DOCUMENTO CON MARCA DA BOLLO" ? (
                                <p key={"doc-" + index} style={{ color: 'black', fontWeight: '500' }}>
                                  <strong>N.B.: </strong>Per il documento <span style={{ color: 'red' }}>"DOCUMENTO CON MARCA DA BOLLO"</span>, scaricare il fac simile al seguente link, compilarlo e applicare la marca da bollo nell'apposito spazio. Dopodichè scannerizzare e allegare il documento alla richiesta di permesso.&nbsp;&nbsp;<a href="#">Clicca qui!!!</a>
                                </p>
                              ) : item.typeLabel == "CERTIFICATO DI INVALIDITA'" ? (
                                <p key={"doc-" + index} style={{ color: 'black', fontWeight: '500' }}>
                                  <strong>N.B.: </strong>Per il documento <span style={{ color: 'red' }}>"CERTIFICATO DI INVALIDITA'"</span>, scaricare il fac simile al seguente link, compilarlo e allegarlo alla richiesta di permesso.&nbsp;&nbsp;<a href="#">Clicca qui!!!</a>
                                </p>
                              ) : ('')
                            )
                          })}
                          <strong className="text-center col-md-12">
                            <ul className="text-left">
                              {documents.map((item, index) => {

                                var color = this.getDocumentColor(item)

                                return (
                                  <li key={"doc-" + index} style={{ 'color': color }} >
                                    {item.typeLabel} {item.mandatory ? "( obbligatorio )" : "( facoltativo )"}
                                  </li>
                                )
                              })}
                            </ul>
                          </strong>
                        </span>
                        <p>I file caricati devono essere nel formato: png, jpeg, pdf.</p>
                      </div>

                      <div className="row">
                        <div className="col-md-12 text-left">
                          <a type="button" className="mainBtn" onClick={onDocumentAdd} >
                            Allega Documento
                          </a>
                        </div>
                      </div>

                      <br />

                      {selectedDocuments.map((item, index) => {

                        return (
                          <div className="form-group row row-list-body" key={'document-' + index + '-' + item.id}>

                            <div className="col-sm-6 text-left  cell-list-body " >
                              <div style={{ marginLeft: 15, paddingTop: 10 }}>
                                <strong>{item.DocumentType ? item.DocumentType.label : 'Seleziona un documento'}</strong>
                              </div>
                            </div>

                            <div hidden={item.approved} className="col-sm-5 text-left cell-list-body" >
                              <Field
                                openFileManager={item.openFileManager}
                                onChange={(document) => { onDocumentChange(document, index) }}
                                onClose={onCloseFileManager}
                                name={'documentFile-' + index + '-' + item.id}
                                component={FileInputField}
                                required
                              />
                            </div>

                            <div hidden={!item.approved} className="col-sm-5 text-left cell-list-body" >
                              <div className="successLabel" style={{ marginLeft: 5, marginTop: 5 }}>
                                <FontAwesomeIcon className="mainIconsRevNoAction" size="1x" icon={solidIcons.faCheckCircle} />
                                &nbsp;&nbsp;{item.name}
                              </div>
                            </div>

                            <div className="col-sm-1 text-left cell-list-body">
                              {!item.approved &&
                                <div onClick={() => onDocumentRemove(index)} className="alertBtn" >
                                  <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                                </div>
                              }
                            </div>

                          </div>
                        )
                      })}
                    </fieldset>
                  </div>
                )}

                {/* SEZIONE RIEPILOGO */}
                <Element name="step-final" />
                {this.showFinalStep(price) &&
                  <div className="box">
                    <fieldset>
                      <div className="titolo-categoria">
                        <legend className="text-left">Riepilogo</legend>
                      </div>

                      <div className="col-md-6">
                        <div className="card-marks">
                          <div className="caption" style={{ padding: 10 }}>
                            <h5>
                              <strong>{mark.MarkType.label}</strong>
                            </h5>
                            <h5>
                              Valido nell{mark.MarkType.Areas.length > 1 ? 'e' : 'a'}{' '}
                              zon{mark.MarkType.Areas.length > 1 ? 'e' : 'a'}:{' '}
                              {mark.MarkType.Areas.map((areaItem, index) => (
                                <span key={index}>
                                  <strong>{areaItem.code}</strong>
                                  {index === mark.MarkType.Areas.length - 1 ? '' : ', '}
                                </span>
                              ))}
                            </h5>
                            {mark.periodo &&
                              <h5>
                                Periodo: <strong>{mark.periodo}</strong>
                              </h5>
                            }
                            {mark.sosta &&
                              <h5>
                                Sosta: <strong>{mark.sosta}</strong>
                              </h5>
                            }
                            <h5>
                              Targ{mark.Vehicles.length > 1 ? 'he' : 'a'}: {' '}
                              <strong>{mark.Vehicles.map(item => item.plate_number + ' ')}</strong>
                            </h5>
                            <h5>
                              {mark.MarkType &&
                                <React.Fragment>
                                  Valido
                                  {getSelectedStartDate(mark) && <span> dal     <strong>{getSelectedStartDate(mark).format("L")}</strong></span>}
                                  {getSelectedEndDate(mark, settings) && <span> fino al <strong>{getSelectedEndDate(mark, settings).format("L")}</strong></span>}
                                </React.Fragment>
                              }
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h3>Totale: € {Number(price.price).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                        {price.reasons && price.reasons.length > 0 ? price.reasons.map((item, index) => {
                          return (
                            <h5 className="text-info" key={'reasons-' + index}>{item}</h5>
                          )
                        }) : null}
                      </div>
                    </fieldset>

                    {submitting && <div className="box text-info">Richiesta in corso...</div>}

                    <button type="submit" className="btn mainBtn">
                      Modifica Permesso
                    </button>
                  </div>
                }

                <Element name="errors" />
                {errors &&
                  <div>
                    <br /><br />
                    <h4 className="box text-danger">{errors}</h4>
                    <br /><br />
                  </div>}

              </div>
            </div>
          </div>


        )}

        {/* MODALE AGGIUNTA NUOVO VEICOLO */}

        {mark.MarkType &&
          <NewVehicleModal
            show={showNewVehicleModal}
            onAddVehicle={this.onAddVehicle}
            onClose={this.onCloseNewVehicleModal}
            type={mark.MarkType.type}
          />
        }
      </form>
    )
  }
}

export default reduxForm({ form: 'editMark', enableReinitialize: true })(EditMark)
