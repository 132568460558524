import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'

import {actions as authActions} from '../redux/modules/Auth'
import BackofficeSettings from './Backoffice/Settings/BackofficeSettings'
import BackofficeSettingsMarkTypes from './Backoffice/Settings/BackofficeSettingsMarkTypes'
import BackofficeSettingsAreas from './Backoffice/Settings/BackofficeSettingsAreas'

import './Backoffice/Settings/BackofficeSettings.css'
import BackofficeSettingsCategories from './Backoffice/Settings/BackofficeSettingsCategories'
import BackofficeSettingsAdminUsers from './Backoffice/Settings/BackofficeSettingsAdminUsers'
import BackofficeSettingsDocumentTypes from './Backoffice/Settings/BackofficeSettingsDocumentTypes'
import BackofficeSettingsVehicles from './Backoffice/Settings/BackofficeSettingsVehicles'
import BackofficeTests from './Backoffice/Tests/BackofficeTests'

/*
TODO - [ ] Costruire una modale per mostrare i messaggi di errore o di warning
*/

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class BackOfficeSettingsContainer extends Component {
  constructor (props) {
    super(props)    
  }

  render () {
    //console.log('BackOfficeSettingsContainer Props:', this.props)
    return (
      <React.Fragment>
        <div className="panel panel-default panel-border">
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">                    
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faGears} />&nbsp;&nbsp;IMPOSTAZIONI
              </h3>
            </div>
            <Switch>        
              <Route path="/backoffice/settings/adminUsers"    component={BackofficeSettingsAdminUsers} />
              <Route path="/backoffice/settings/markTypes"     component={BackofficeSettingsMarkTypes} />
              <Route path="/backoffice/settings/areas"         component={BackofficeSettingsAreas} />
              <Route path="/backoffice/settings/documentTypes" component={BackofficeSettingsDocumentTypes} />
              <Route path="/backoffice/settings/vehicles"      component={BackofficeSettingsVehicles} />
              <Route path="/backoffice/settings/categories"    component={BackofficeSettingsCategories} />
              <Route path="/backoffice/settings"               component={BackofficeSettings} />
              <Route path="/backoffice/settings/tests"         component={BackofficeTests} />

            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeSettingsContainer)
