import React, { Component } from 'react'
import { Button, Modal, PanelGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import './ReportResellerModal.css'
import moment from 'moment'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import history from '../../../history'
const thisRoute = 'reseller'

const fileDownload = require('js-file-download')



const mapStateToProps = (state) => {
    return {
        downloading: false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

class ReportResellerModal extends Component {
    constructor(props) {
        super(props);

        this.markReceiptDownload = this.markReceiptDownload.bind(this);
        this.onSendReceiptEmail  = this.onSendReceiptEmail.bind(this);
        this.handleCloseAndRedirect = this.handleCloseAndRedirect.bind(this)
    }

    /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
    markReceiptDownload(mark) {
        const { resellerInfo, user } = this.props

        const resellerFirstName = resellerInfo.firstName
        const resellerLastName = resellerInfo.lastName
        const userEmail = user.email


        if (this.props.markSuccess && this.props.newMark.id) {
            SostaOnlineApiService.downloadMarkReceipt(this.props.newMark.id, thisRoute, resellerLastName, resellerFirstName, userEmail)
                .then(data => {
                    fileDownload(data, 'ricevuta-permesso-' + this.props.newMark.id + '.pdf', 'application/pdf')
                }).catch(error => {
                    console.error(error)
                })
        }

    }


    /**
   * --------------------------------------------------------------
   * @param {*} mark 
   */
    onSendReceiptEmail(mark) {
        const { resellerInfo, user } = this.props

        const resellerFirstName = resellerInfo.firstName
        const resellerLastName = resellerInfo.lastName
        const userEmail = user.email


        if (this.props.markSuccess && this.props.newMark.id) {
            SostaOnlineApiService.sendResellerReceipt(this.props.newMark.id, resellerLastName, resellerFirstName, userEmail)
                .then(data => {
                    fileDownload(data, 'ricevuta-permesso-' + this.props.newMark.id + '.pdf', 'application/pdf')
                }).catch(error => {
                    console.error(error)
                })
        }

    }

    /**
* --------------------------------------------------------------
* @param {*} mark
*/
    handleConfirmMark = (mark) => {

        //Prima dobbiamo fare il desconstructioring delle info. che dobbiamo passare alla nostra route di BE che passerà le info al DB
        //price, markId, userId, resellerId
        //console.log("mark:", mark, "this.props", this.props)

        this.setState({ downloading: true })


        //this.props.onSubmit()

        if (this.props.markSuccess && this.props.newMark.id) {
            const { user, resellerInfo, totalPrice } = this.props
            const userId = user.id
            const resellerId = resellerInfo.id
            const price = totalPrice
            const markId = this.props.newMark.id
            const markData = {
                markId: markId,
                userId: userId,
                resellerId: resellerId,
                price: price
            };


        }
    }

    handleCloseAndRedirect = () => {
        const {marksResellersConfirmed} = this.props

        if(marksResellersConfirmed) {
            this.props.onCloseModal(); // Chame a função onCloseModal para fechar a modal
            history.push('/reseller/home');
        } else{
            this.props.onCloseModal()
        }
    }

    render() {

        /**
         * 
         * TODO //
         */

        if (this.props) {
            const { onCloseModal,
                user,
                selectedMarkType,
                showModal,
                category,
                markTypeId,
                selectedStartDate,
                selectedEndDate,
                selectedPlate,
                totalPrice,
                resellerInfo,
                newMark,
                marksResellersConfirmed, // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
                downloading,
                markData,
                markCreateError,
                markSuccess,
                vehicles,
                quantity,
                selectedMarkTypeCategory,
                selectedMarkTypeCategoryCategoryId
            } = this.props

            const selectedStartDateFormatted = moment(selectedStartDate.value).format('DD/MM/YYYY')

            //let vehicles = ""
            //let method = ""
            /*
            // eventualmente la risoluzione di questi dati è nel file "ResellerUserDetailContainer.js" alla riga 500
            //let addressRoute = ""
            //let circolazione = ""
            //let periodo = ""
            //let sosta = ""
            */
            //let note = ""
            //let quantity = ""
            //let categoryId = ""
            //let total = ""
            //let color = ""

            //console.log("ReportResellerModal this.props:", this.props)
            //console.log("ReportResellerModal this.state:", this.state)
            /*
            console.log("ReportResellerModal resellerInfo:", resellerInfo)
            console.log("user modal id", user.id)
            console.log("user modal category", category)
            console.log("user modal markTypeId", markTypeId)
            console.log("user modal selectedEndDate", selectedEndDate)
            console.log("user modal selectedVehicle", selectedPlate)
            console.log("user modal totalPrice", totalPrice)
            console.log("user modal selectedMarkType", selectedMarkType)
            console.log("selectedStartDateFormatted:", selectedStartDateFormatted)*/

            let malloppo = {
                markTypeId: markTypeId,
                selectedStartDate: selectedStartDate,
                vehicles: vehicles,
                method: "RIVENDITORE",
                addressRoute: "",
                note: "",
                quantity: quantity,
                categoryId: selectedMarkTypeCategoryCategoryId,
                circolazione: this.props.circolazione,
                periodo: this.props.periodo,
                sosta: this.props.sosta,
                color: selectedMarkType.color,
                total: this.props.totalPrice,
                virtual: selectedMarkType.virtualizable
            }
            console.log("selectedPLate:", selectedPlate)

            if (showModal) {
                return (
                    <Modal show={true} className="modale-reseller" onHide={this.handleCloseAndRedirect} backdrop="static" scrollable>
                        <Modal.Header closeButton className="header-modale-reseller">
                        </Modal.Header>

                        <Modal.Body className="modale-body-reseller">
                            <div className="container cards-modal-container">
                                    <div className="col-md-4 card-content">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-heading-container">
                                                    <FontAwesomeIcon size="3x" className="sectionIcons icons-report-modal" icon={solidIcons.faUser} />
                                                    <h2 className="card-title">DATI UTENTE</h2>
                                                </div>
                                                <div className="card-text-container">
                                                    <p className="card-text">Nome: <span>{user.firstName}</span></p>
                                                    <p className="card-text">Cognome: <span>{user.lastName}</span></p>
                                                    <p className="card-text">Codice Fiscale: <span>{user.fiscalCode}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 card-content">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-heading-container">
                                                    <FontAwesomeIcon size="3x" className="sectionIcons icons-report-modal" icon={solidIcons.faPassport} />
                                                    <h2 className="card-title">DATI PERMESSO</h2>
                                                </div>
                                                <div className="card-text-container">
                                                    <p className="card-text">Tipo Permesso: <span>{selectedMarkType.name}</span></p>
                                                    <p className="card-text">Data di Inizio: <span>{selectedStartDateFormatted}</span></p>
                                                    <p className="card-text">Data di Scadenza: <span>{selectedEndDate}</span></p>
                                                    <p className="card-text">Prezzo: <span>€{totalPrice}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 card-content">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-heading-container">
                                                    <FontAwesomeIcon size="3x" className="sectionIcons icons-report-modal" icon={solidIcons.faCar} />
                                                    <h2 className="card-title">DATI VEICOLI</h2>
                                                </div>
                                                <div className="card-text-container">
                                                    <p className="card-text">Targa Veicolo:</p>
                                                    {selectedPlate.map((plate, index) => (
                                                        <span key={index}>
                                                            {plate}
                                                            {index < selectedPlate.length - 1 && ', '}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            {/*
                                console.log("ReportResellerModal status messages diagnosis: markSuccess ", markSuccess, "; marksResellersConfirmed ", marksResellersConfirmed, "; markCreateError ", markCreateError, ".")
                            */}
                            { /* resoconto di emissione del permesso */}
                            <div className="text-center mt-4 mx-auto p-5 alert-report-modal">
                                {markSuccess && markCreateError === null ?
                                    <div className="alert alert-success" style={{ margin: '10px 0' }}>
                                        Il permesso n.{newMark.id} è stato creato correttamente.
                                        <br />
                                        Procedere con la stampa o l'invio del PDF su email.
                                    </div>
                                    : markSuccess && markCreateError !== null ?
                                        newMark.id !== null && newMark.id !== undefined ?
                                            <div className="alert alert-success" style={{ margin: '10px 0' }}>
                                                Il permesso n.{newMark.id} è stato creato, ma si sono verificati errori ({markCreateError}).
                                                <br />
                                                Procedere con la stampa o l'invio del PDF su email.
                                            </div>
                                            :
                                            <div className="alert alert-danger" style={{ margin: '10px 0' }}>
                                                Errore durante la creazione del permesso: {markCreateError}
                                            </div>
                                        :
                                        !markSuccess && <div className="alert alert-warning" style={{ margin: '10px 0' }}>
                                            La creazione del permesso non è stata ancora avviata.
                                        </div>
                                }
                                {marksResellersConfirmed === null ? "" :
                                    marksResellersConfirmed === true ?
                                    <div className="alert alert-success" style={{ margin: '10px 0' }}>
                                        La registrazione del permesso è stata inserita correttamente nella rendicontazione del rivenditore {resellerInfo.firstName + " " + resellerInfo.lastName}.
                                    </div>
                                    :
                                    <div className="alert alert-warning" style={{ margin: '10px 0' }}>
                                        Il salvataggio del permesso ha subito un problema e potrebbe aver impedito la registrazione nella rendicontazione del rivenditore {resellerInfo.firstName + " " + resellerInfo.lastName}.
                                    </div>
                                }
                            </div>
                        </Modal.Body>

                        <div className="btn-container">
                            {!markSuccess && !marksResellersConfirmed ? (
                                <>
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        style={{ backgroundColor: "#eb2a2a" }}
                                        onClick={onCloseModal}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faDeleteLeft} className="buttons-icons-modale-reseller" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            ANNULLA
                                        </span>
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                            {markSuccess && marksResellersConfirmed ? (
                                <>
                                    {/* PULSANTE STAMPA RICEVUTA */}
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        onClick={this.markReceiptDownload}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faReceipt} className="buttons-icons-modale-reseller" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            DOWNLOAD
                                        </span>
                                    </Button>
                                    {/* PULSANTE INVIA RICEVUTA MAIL 
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        onClick={this.onSendReceiptEmail}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} className="buttons-icons-modale-reseller" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            INVIA RICEVUTA VIA E-MAIL
                                        </span>
                                    </Button>
                                    */}
                                </>
                            ) : (

                                <Button
                                    className="badge-buttons-modale-reseller button-reseller"
                                    onClick={() => this.props.onCreateMark(malloppo)}
                                    type="submit"
                                >
                                    <span className="buttons-icons-modale-reseller icon-wrapper">
                                        <FontAwesomeIcon size="1x" icon={solidIcons.faPassport} className="buttons-icons-modale-reseller" />
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className="button-reseller">
                                        CONFERMA PERMESSO
                                    </span>
                                </Button>
                            )}
                        </div>

                    </Modal>
                )

            } else {
                return null;
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ReportResellerModal' })(ReportResellerModal))