import React, { Component } from 'react'
import './MainNavigationScreen.css'
import CookieBanner from '../components/CookieLawBanner'
import FooterComponent from '../components/FooterComponent'
import { RESELLER } from 'libs/roles';

export default class MainNavigatorScreen extends Component {
  render() {
    //console.log('thisprops:', this.props)
    //console.log('thispropschildren:', this.props.children)
    if (this.props) {
      const {
        auth
      } = this.props

      return (
        <div>
          <section>
            <div className="main-container container-fluid" style={{ padding: 0 }}>
              <CookieBanner />
              {this.props.children}
            </div>
          </section>
          {auth && auth.user && auth.user.role === RESELLER ? '' : <FooterComponent />}
        </div>
      );
    }
  }
}