import React, { Component }  from 'react'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import moment                from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import './ResellerMessages.css'//
import { getSubjectDescription } from 'libs/messages'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ResellerMessageModal from 'components/Reseller/Messages/ResellerMessageModal'//
import ResellerNewMessageModal from 'components/Reseller/Messages/ResellerNewMessageModal'//
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import VirtualizedSelectFormComponent from 'components/redux-form/VirtualizedSelectFormComponent'
import queryString from 'query-string'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'
import { getGridHeight } from 'libs/utils'
import { RESELLER } from 'libs/roles'
const thisRoute = 'reseller'

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth,
    stateReport : state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {   
    authActions:   bindActionCreators(authActions,   dispatch), 
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class ResellerMessages extends Component {
  static propTypes = {}

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      maxHeight: 0,
      loading: true,
      selectedThreadId: null,
      selectedChildId: null,
      messages: [],
      showNewMessageModal: false,
      showMessageModal: false,
      sendErrors: null,
      selectedMsg: null,
      page: 0,
      totalPages: 0,
      users: [],
      userId: null
    }

    this.getClassRow = this.getClassThreadRow.bind(this)    
    this.onCloseNewMessageModal = this.onCloseNewMessageModal.bind(this)
    this.onSendNewMessage = this.onSendNewMessage.bind(this)
    this.onCloseMessageModal = this.onCloseMessageModal.bind(this)
    this.onSendReplyMessage = this.onSendReplyMessage.bind(this)
    this.selectChild = this.selectChild.bind(this)
    this.expandCollapseTree = this.expandCollapseTree.bind(this)
    this.selectTree = this.selectTree.bind(this)
    this.fetchThingsToDo = this.fetchThingsToDo.bind(this)
    this.handleResize  = this.handleResize.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })

    if ( this.props.settingsMessages.enable  ) {
      this.setState({loading: true})

      const queryParams = queryString.parse(this.props.location.search)
      if (queryParams.userId) {
        this.setState({ userId: queryParams.userId })
        this.fetchMessages(this.state.page,queryParams.userId )
        this.props.initialize({ userId: queryParams.userId });
      }
      else {
        this.fetchMessages(this.state.page,this.state.userId)
      }

      this.fetchUsers()     
      this.fetchThingsToDo()
    }

  }

  /**
     * ----------------------------------------------------------------
     */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }


  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    //console.log("Messages componentDidUpdate")
    
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchMessages (page,userId) {

    if ( page == null || page < 0 )
      page = 0

    SostaOnlineApiService.fetchMessages(page,userId,thisRoute)
      .then( result => {
          if ( result.result === 'ok' ) {                     
              this.setState({
                'messages':result.data, 
                'page': page,
                'totalPages' : result.pagination.totalPages,
                'loading' : false
              })
          }
      } )
      .catch( error => {
        console.log("ERROR",error)
      })

    
  }

  /**
   * --------------------------------------------------------------
   */
  fetchUsers() {
    SostaOnlineApiService.fetchUsers(null,100000000,0,thisRoute)
      .then( data => {
        this.setState({
          users: data.data.map( user => { 
            return { 
              label : user.firstName + ' ' + user.lastName + ' ' + user.fiscalCode + ' ' + user.email ,
              value : user.id            
            } 
          }),        
          loading: false
        })
      })
      .catch( error => {
        console.log("ERROR",error)
      })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedList() {
    var {            
        messages: messages
    } = this.state

    messages = messages.filter( item => item.rootMessage == null  )
    return messages ? messages.sort((a, b) => ( moment(a.threadUpdate).isBefore(b.threadUpdate) ) ? 1 : -1) : []
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   * @returns 
   */
  getChilds(rootMsg) {
    var {            
      messages: messages
    } = this.state

    messages = messages.filter( item => item.rootMessage == rootMsg.id )
    return messages ? messages.sort((a, b) => (a.id > b.id ) ? -1 : 1) : []
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   */
  expandCollapseTree(rootMsg) {  

    if ( this.state.selectedThreadId === rootMsg.id ) {
      this.setState({selectedThreadId: null})
      this.setState({selectedChildId: null})
      //console.log("DESELECT: ",rootMsg.id)
    }
    else {      
      this.setState({selectedThreadId: rootMsg.id})
      //console.log("SELECT: ",rootMsg.id)      
    }

    if (  this.childsCount(rootMsg)  <= 0  ) {
      this.showMessage(rootMsg)
    }

  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   */
  selectTree(rootMsg) {      
      this.setState({selectedThreadId: rootMsg.id})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  selectChild(childMsg) {
    if ( this.state.selectedThreadId === childMsg.id ) {      
      this.setState({selectedChildId: null})
      //console.log("DESELECT: ",childMsg.id)
    }
    else {      
      this.setState({selectedChildId: childMsg.id})
      //console.log("SELECT: ",childMsg.id)      
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getClassThreadRow(msg) {
    var classRow = "resellerMessageRow"
    
    if (  msg.id === this.state.selectedThreadId ) {
      if ( !msg.read && msg.fromId !== this.props.stateAuth.user.id && msg.FromUser.role === RESELLER )      
        classRow = "resellerMessageUnreadSelectedRow"
      else
        classRow = "resellerMessageSelectedRow"
    }
    else if ( this.haveUnreadChilds(msg) || ( !msg.read && msg.fromId !== this.props.stateAuth.user.id && msg.FromUser.role === RESELLER ) ) {
      classRow = "resellerMessageUnreadRow"
    }

    return classRow
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getClassChildRow(msg) {
    var classRow = "resellerMessageRow"

    if (  msg.id === this.state.selectedChildId ) {
      if ( !msg.read && msg.toId == null )
        classRow = "resellerMessageUnreadSelectedRow"
      else
        classRow = "resellerMessageSelectedRow"
    }
    else if ( !msg.read && msg.toId == null ) {
      classRow = "resellerMessageUnreadRow"
    }

    return classRow
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getMailIcon(msg) {
    var icon = solidIcons.faDownload
    var beat = true
    var tooltip = "messaggio in arrivo"

    if( msg.read ) {
      beat = false
    }

    if ( msg.toId != null )  {
      beat = false

      if ( msg.rootMessage == null ) {
        tooltip = "Messaggio inviato"
        icon = solidIcons.faUpload
      }
      else {
        tooltip = "Risposta inviata"
        icon = solidIcons.faReply
      }
    }    
    
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-0-'+msg.id}>{tooltip}</Tooltip>}>
        <FontAwesomeIcon  className="mainIconsNoAction"  beatFade={beat} size="2x" icon={icon} />
      </OverlayTrigger>    
    )
  }  

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getThreadIcon(msg) {
    var icon           = solidIcons.faFolder
    var classIcon      = "mainIcons"
    var tooltipMessage = "Clicca per vedere lo scambio di messaggi"
      
    if ( msg.rootMessage != null || this.getChilds(msg) <= 0  ) {
      icon = regularIcons.faFileLines            
      tooltipMessage = this.getTooltipMessage(msg)
    }    
    else if ( msg.id === this.state.selectedThreadId ) {
      icon = solidIcons.faFolderOpen
    }
    
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-1-'+msg.id}>{tooltipMessage}</Tooltip>}> 
        <FontAwesomeIcon  className={classIcon}  size="2x" icon={icon} />      
      </OverlayTrigger>
    )
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @returns 
   */
  getTooltipMessage(msg) {
    var tooltipMessage = ""

    if ( msg.toId == null )
      tooltipMessage = "Clicca per vedere il Messaggio e rispondere."      
    else
      tooltipMessage = "Clicca per vedere il Messaggio"
      

    return tooltipMessage
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  childsCount(msg) {
    var childs = this.state.messages.filter( m  =>  m.rootMessage === msg.id )
    return childs.length
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  haveUnreadChilds(msg) {
    var childs = this.state.messages.filter( m  =>   m.rootMessage === msg.id && !m.read && m.toId == null  )
    //console.log("ID ", msg.id , " CHILDS ",childs.length)
    return childs.length > 0 ? true : false
  }

  /**
   * --------------------------------------------------------------
   * @param {*} user 
   * @returns 
   */
  getUserDescr(user) {
    return user ? user.firstName + " " + user.lastName : "ASSISTENZA" 
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @returns 
   */
  getUserReadStatus(msg) {
    var icon           = solidIcons.faCheck
    var style          = { color: "grey" }
    var tooltipMessage = "messaggio consegnato all'utente"
      
    if ( msg.toId == null ) {
      return null
    }
    
    if ( msg.read ) {
      icon           = solidIcons.faCheckDouble
      style          = { color: "green" }
      tooltipMessage = "L'utente ha letto il messaggio"
    }
    
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-1-'+msg.id}>{tooltipMessage}</Tooltip>}> 
        <FontAwesomeIcon  style={style} size="2x" icon={icon} />      
      </OverlayTrigger>
    )
                        
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @param {*} index 
   * @returns 
   */
  getRootMsgRow(msg) {

    return (     
      <React.Fragment  key={'main-'+msg.id}>
        
        <tr key={'msg-'+msg.id} className={ this.getClassThreadRow(msg) }>
          <td className="text-left" onClick={ evt => { this.expandCollapseTree(msg) } } >{this.getThreadIcon(msg)}</td>          
          <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{this.getMailIcon(msg)}</td>
          <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{this.getUserDescr(msg.FromUser) }</td>
          <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{this.getUserDescr(msg.ToUser) }</td>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{getSubjectDescription(msg.subject)}</td>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-3-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{msg.content.substring(0,60)} ...</td>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-4-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{moment(msg.threadUpdate).format('L')}</td>
          </OverlayTrigger>
          <td className="text-left" >{this.getUserReadStatus(msg) }</td>
        </tr>
        {  msg.id === this.state.selectedThreadId  && this.getChilds(msg).map( ( msg1 ) => {      
          return(
            <tr key={'msg-'+msg1.id} className={this.getClassChildRow(msg1)} >
              
              <td className="text-right" onClick={ evt => { this.showMessage(msg1) } }><div>{'   '}</div>{this.getThreadIcon(msg1)}</td>
              
              <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{this.getMailIcon(msg1)}</td>
              <td className="text-left" onClick={ evt => { this.showMessage(msg1) } } >{this.getUserDescr(msg1.FromUser) }</td>
              <td className="text-left" onClick={ evt => { this.showMessage(msg1) } } >{this.getUserDescr(msg1.ToUser) }</td>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{getSubjectDescription(msg1.subject)}</td>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{msg1.content.substring(0,60)} ...</td>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{moment(msg1.threadUpdate).format('L')}</td>
              </OverlayTrigger>
              <td className="text-left" >{this.getUserReadStatus(msg1) }</td>
            </tr>
          )
          })
        }               
      </React.Fragment >  
    )
  
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  showMessage(msg) {

    if ( msg.rootMessage == null )
      this.selectTree(msg)
    else
      this.selectChild(msg)

    if ( !msg.read && msg.toId == null ) {

      var updateMsg = {
        id: msg.id,
        read: true
      }

      SostaOnlineApiService.updateMessage(updateMsg,thisRoute)
        .then(
          data => {
            var messages  = this.state.messages.map( m => {  

              if ( m.id === msg.id )   
                m.read = true
        
              return m 
        
            })
        
            this.setState({messages: messages})   
            this.fetchThingsToDo() 
          }
        )
        .catch( error => {
          console.log("ERROR",error)
        })
    }

    this.setState({selectedMsg: msg, showMessageModal: true})    
    
        
  }

  /**
   * --------------------------------------------------------------
   */
  onOpenNewMessageModal () {
    this.setState({      
      showNewMessageModal: true
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseNewMessageModal () {
    this.setState({
      showNewMessageModal: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  onSendNewMessage (msg) {
    //console.log("FETCHING MESSAGES")
    this.fetchMessages(this.state.page,this.state.userId) 
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseMessageModal () {
    this.setState({
      showMessageModal: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  onSendReplyMessage (msg) {    
    this.fetchMessages(this.state.page, this.state.userId) 
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    if ( !this.props.settingsMessages.enable  )
      return(<div></div>)

    const {loading,page,totalPages,userId,maxHeight} = this.state
    const messages = this.getSortedList()

    return (
      <React.Fragment>                
        <div className="panel panel-default panel-border" style={{ minHeight: 100, maxHeight: maxHeight}}>          
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">                    
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faMailBulk} />&nbsp;&nbsp;MESSAGGI
              </h3>
            </div>
            
            <div className="col-md-12">
              {loading ? (
                <React.Fragment>
                <h3><strong>Caricamento in corso...</strong></h3>
                <div className="row" style={{padding: "2em", height: "40em"}}>
                  <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
                </div>
                </React.Fragment>
              ) : messages.length === 0 ? (
                <React.Fragment>
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginTop: 15, marginBottom: 15,}}>
                      <div className="mainBtn" style={{ margin: 0, padding: 10}} onClick={ (evt) => { this.onOpenNewMessageModal() }}>
                        <FontAwesomeIcon size="1x" className="mainIconsRevNoAct" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Invia Messaggio
                      </div>
                      <div style={{ width: "550px", padding: 10}}  >
                        <Field                       
                            name="userId"
                            options={this.state.users}
                            component={VirtualizedSelectFormComponent}
                            onChange={ value => { this.setState({ userId: value, page: 0}) , this.fetchMessages(0,value)  } }
                            placeholder={<div style={{textAlign:'left'}}><FontAwesomeIcon size="1x" icon={solidIcons.faMagnifyingGlass} />&nbsp;&nbsp;Cerca utente...</div>}                            
                            required />                        
                      </div>
                    </div>
                  </div>
                  <div style={{padding: 20}}>
                    <h2>Non sono presenti messaggi</h2>
                    <span>-- Per iniziare una conversazione premere <strong>"INVIA MESSAGGIO"</strong> --</span>
                  </div>
                  </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', marginTop: 15, marginBottom: 15,}}>
                      <div className="mainBtn" style={{ margin: 0, padding: 10}} onClick={ (evt) => { this.onOpenNewMessageModal() }}>
                        <FontAwesomeIcon size="1x" className="mainIconsRevNoAct" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Invia Messaggio
                      </div>
                      <div style={{ width: "550px", padding: 10}}  >
                        <Field                       
                            name="userId"
                            options={this.state.users}
                            component={VirtualizedSelectFormComponent}
                            onChange={ value => { this.setState({ userId: value, page: 0}) , this.fetchMessages(0,value)  } }
                            placeholder={<div style={{textAlign:'left'}}><FontAwesomeIcon size="1x" icon={solidIcons.faMagnifyingGlass} />&nbsp;&nbsp;Cerca utente...</div>}                            
                            required />                        
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    
                    <table className="table ">

                        <thead>
                        <tr className="resellerMessageHeader">
                          <td style={{ width: "8rem" }} ></td>                            
                          <td style={{ width: "1rem" }} ></td>
                          <td className="col-md-1" ><strong>From</strong></td>
                          <td className="col-md-1" ><strong>To</strong></td>
                          <td className="text-left"><strong>Soggetto</strong></td>
                          <td className="text-left"><strong>Anteprima</strong></td>
                          <td className="col-md-1"><strong>Data</strong></td>
                          <td style={{ width: "1rem" }} ></td>
                        </tr>
                        </thead>

                        <tbody>
                        { messages.map( (msg) =>  this.getRootMsgRow(msg) ) }
                        

                        <tr className="resellerMessageFooter">
                          <td ></td>
                          <td ></td>
                          <td ></td>
                          <td ></td>
                          <td ></td>
                          <td ></td>
                          <td ></td>
                          <td ></td>
                        </tr>
                        </tbody>
                                              
                    </table>
                  </div>

                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="col-md-3"></div>
                      <button className={"customBtnPage "+(page === 0 ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === 0}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchMessages(0,userId) } } icon={solidIcons.faAnglesLeft} /></button>
                      <button className={"customBtnPage "+(page === 0 ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === 0}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchMessages(page-1,userId) } } icon={solidIcons.faArrowLeft} /></button>
                      <div><h4> Page:&nbsp;&nbsp;{page+1} / {totalPages}</h4></div>
                      <button className={"customBtnPage "+(page === (totalPages-1) ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === (totalPages-1)}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchMessages(page+1,userId) } } icon={solidIcons.faArrowRight} /></button>
                      <button className={"customBtnPage "+(page === (totalPages-1) ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === (totalPages-1)}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchMessages(totalPages-1,userId) } } icon={solidIcons.faAnglesRight} /></button>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>          
        </div>
              
        <ResellerNewMessageModal
          show={this.state.showNewMessageModal}
          onSendMessage={this.onSendNewMessage}          
          onClose={this.onCloseNewMessageModal}
          />

        <ResellerMessageModal 
          show={this.state.showMessageModal}
          onReplyMessage={this.onSendReplyMessage}          
          onClose={this.onCloseMessageModal}
          msg={this.state.selectedMsg}
          user={this.props.stateAuth.user}
        />

      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerMessages'})(ResellerMessages))

