import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {Component} from 'react'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getActiveChangeRequest, getVehicleIcon, getVehicleStatus, VEHICLE_STATUS_APPROVED, VEHICLE_STATUS_DENIED, VEHICLE_STATUS_REPLY, VEHICLE_STATUS_REQUEST } from 'libs/vehicles'
import { bindActionCreators } from 'redux'
import { connect} from 'react-redux'
import { actions as authActions} from 'redux/modules/Auth'
import { reportActions } from 'redux/modules/Report'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import moment from 'moment'
import NewVehicleModal    from 'components/Vehicles/NewVehicleModal'
import DeleteVehicleModal from 'components/Vehicles/DeleteVehicleModal'
import UpdateVehicleModal from 'components/Vehicles/UpdateVehicleModal'
import { MARK_MODIFY_PRICE } from 'libs/settings'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

moment.locale('it')

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


const TextUser = (props) => <div className={props.bold ? 'text-bold-user' : 'text-regular-user'} style={{textAlign: 'center'}}>{props.children}</div>

class UserAreaVehicles extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      vehicleEditing: {},
      showNewVehicleModal: false,
      showDeleteVehicleModal: false,
      showUpdateVehicleModal: false,
      vehicles: [],
      showEditButtons: [],
      loading: false,     
      selectedVehicle: null ,
      showConfirmRequestCancelButtons: [],
      errors: [],
      markModifyPrice: 0,
      news: []
    }

    this.onCloseNewVehicleModal     = this.onCloseNewVehicleModal.bind(this)
    this.showNewVehicleModal        = this.showNewVehicleModal.bind(this)
    this.showDeleteVehicleModal     = this.showDeleteVehicleModal.bind(this)
    this.showUpdateVehicleModal     = this.showUpdateVehicleModal.bind(this)
    this.onAddVehicle               = this.onAddVehicle.bind(this)
    this.onCloseDeleteVehicleModal  = this.onCloseDeleteVehicleModal.bind(this)
    this.onDeleteVehicle            = this.onDeleteVehicle.bind(this)
    this.onUpdateVehicle            = this.onUpdateVehicle.bind(this)
    this.onCloseUpdateVehicleModal  = this.onCloseUpdateVehicleModal.bind(this)

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount( ){
    this.fetchData()      
    this.setState({ errors: [] })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchData() {

    this.setState({ loading: true })

    SostaOnlineApiService.fetchVehiclesMe()
    .then( vehicles => {
      var showEditButtons                 = []
      var showConfirmRequestCancelButtons = []
      
      vehicles.data.map( item => {  
        showEditButtons[item.id]                 = false 
        showConfirmRequestCancelButtons[item.id] = false 
      })

      this.setState({
        vehicles: vehicles.data, 
        loading: false,
        showEditButtons: showEditButtons,
        showConfirmRequestCancelButtons: showConfirmRequestCancelButtons
      })
                      
    })
    .catch( error => {
      this.setState({loading: false})
      console.log("ERROR",error)
    })

    this.setState({ loading: true })

    SostaOnlineApiService.fetchPublicSettings()
    .then( settings => {

      var found = settings.data.filter( setting => setting.name == MARK_MODIFY_PRICE ) 

      if ( found && found.length > 0 ) {
        this.setState( {
          loading: false,
          markModifyPrice: parseFloat(found[0].value).toFixed(2) 
        })
      }
    })
    .catch( error => {
      this.setState({loading: false})
      console.log("ERROR",error)
    })

    SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERVEHICLES.value)
      .then( news => {
        this.setState({ news: news.data})
      })
      
  }

  /**
   * --------------------------------------------------------------
   */
  showNewVehicleModal() {
    this.setState({showNewVehicleModal: true})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  showDeleteVehicleModal(vehicle) {
    this.setState({showDeleteVehicleModal: true, selectedVehicle: vehicle})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  showUpdateVehicleModal(vehicle) {    
    this.setState({showUpdateVehicleModal: true, selectedVehicle: vehicle})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  onAddVehicle(vehicle) {       
    console.log("GO /user-area/vehicles")
    history.go("/user-area/vehicles")
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  onDeleteVehicle(vehicle) {       
    console.log("GO /user-area/vehicles")
    history.go("/user-area/vehicles")
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
   onUpdateVehicle(vehicle) {       
    console.log("GO /user-area/vehicles")
    history.go("/user-area/vehicles")
  }

  /**
   * --------------------------------------------------------------
   * @param {*} evt 
   */
  onCloseNewVehicleModal(evt) {    
    this.setState({showNewVehicleModal: false})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} evt 
   */
  onCloseDeleteVehicleModal(evt) {    
    this.setState({showDeleteVehicleModal: false, selectedVehicle: null})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} evt 
   */
  onCloseUpdateVehicleModal(evt) {    
    this.setState({showUpdateVehicleModal: false, selectedVehicle: null})
  }


  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  isVehicleDeletable(vehicle) {

    var result = true

    vehicle.Marks.map( m => {      
      result = result && ( !m.ceased && !moment().isBefore(m.endDate) )
    })

    return result
    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedVehicleList() {
    return this.state.vehicles ? this.state.vehicles.sort( (a,b) => ( a.id > b.id) ? -1 : 1 ) : []
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  getVehicleChangeRequest(vehicle) {
    var changeRequest = null

    if ( vehicle && vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var changeRequests = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      changeRequest = changeRequests[0]            
    }

    return changeRequest

  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  getSortedVehiclesChangeRequestsNotes(vehicle) {

    var notes = []
    
    if ( vehicle ) {
      var changeRequest = this.getVehicleChangeRequest(vehicle)

      if ( changeRequest.VehiclesChangeRequestsNotes ) {
        notes = changeRequest.VehiclesChangeRequestsNotes.sort( (a,b) => ( a.id > b.id) ? 1 : -1 )
      }

      if ( changeRequest.status == VEHICLE_STATUS_DENIED ) {

        var slice = (notes.length-1) < 0 ? 0 : notes.length-1

        notes = notes.slice(slice)
      }
    }

    return notes

  }


  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  setShowEditButtons(item) {
    var showEditButton  = !this.state.showEditButtons[item.id]

    var showEditButtons = {}
    
    this.state.vehicles.map( vehicle =>  showEditButtons[vehicle.id] = false )

    showEditButtons[item.id] = showEditButton

    this.setState({ showEditButtons: showEditButtons })
  }


  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   */
  setShowConfirmRequestCancelButtons(vehicle) {
    var showConfirmaRequestCancelButton  = !this.state.showConfirmRequestCancelButtons[vehicle.id]

    var showConfirmRequestCancelButtons = {}
    
    this.state.vehicles.map( vehicle =>  showConfirmRequestCancelButtons[vehicle.id] = false )

    showConfirmRequestCancelButtons[vehicle.id] = showConfirmaRequestCancelButton

    this.setState({ showConfirmRequestCancelButtons: showConfirmRequestCancelButtons })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} item 
   */
  getVehicleRender(item) {
    var result = {
      className: 'card-vehicle',
      showMainEditButtons:     true,
      showPaymentButtons:      false,
      showNote:                false,
      showRequestModifyButton: false,
      showRequestCancelButton: false,
      messageStatus:           null,
      newPlateNumber:          null,
      status:                  null,
      changeRequestId:         null
    }

    if ( item.VehiclesChangeRequests && item.VehiclesChangeRequests.length > 0  ) {

      var changeRequest = this.getVehicleChangeRequest(item)

      if ( changeRequest ) {

        var vehicleStatus      = getVehicleStatus(changeRequest,this.props.stateAuth.user.role)
        result.messageStatus   = vehicleStatus.status        
        result.color           = vehicleStatus.color
        result.changeRequestId = changeRequest.id

        switch ( changeRequest.status ) {
          case VEHICLE_STATUS_DENIED : 
            result.showNote = true
            result.status   = changeRequest.status
            break;

          case VEHICLE_STATUS_REQUEST :
            result.className               = result.className + ' card-vehicle-r'
            result.showMainEditButtons     = false          
            result.showNote                = true
            result.showRequestModifyButton = false
            result.showRequestCancelButton = true
            result.status                  = changeRequest.status
            result.newPlateNumber          = changeRequest.plate_number
            break;
          
          case VEHICLE_STATUS_APPROVED :
            result.className = result.className + ' card-vehicle-wp'            
            result.showMainEditButtons     = false            
            result.showPaymentButtons      = this.state.markModifyPrice != null && this.state.markModifyPrice > 0
            result.showNote                = false 
            result.showRequestModifyButton = false
            result.showRequestCancelButton = true
            result.status                  = changeRequest.status
            result.newPlateNumber          = changeRequest.plate_number
            break;

          case VEHICLE_STATUS_REPLY :
            result.className               = result.className + ' card-vehicle-r'
            result.showMainEditButtons     = false
            result.showNote                = true
            result.showRequestModifyButton = true
            result.showRequestCancelButton = true
            result.status                  = changeRequest.status
            result.newPlateNumber          = changeRequest.plate_number
            break;
          
        }
      }

    }    

    return result;
  }

  /**
   * --------------------------------------------------------------
   * RITIRA LA RICHIESTA DI MODIFICA DEL VEICOLO
   * @param {*} vehicle 
   */
  async cancelRequest(vehicle) {

    var errors = []

    if ( vehicle ) {
      var changeRequest = getActiveChangeRequest(vehicle)

      if ( changeRequest && changeRequest.id ) {

        SostaOnlineApiService.cancelVehicleChangeRequestMe(changeRequest.id)
          .then( result =>{

            if ( result.result === 'ok' ) {    
                
                if ( this.onUpdateVehicle ) 
                  this.onUpdateVehicle(result.data)
                
                //this.setShowConfirmRequestCancelButtons(vehicle)
            }
            else {            
              errors[vehicle.id] = result.error ? result.error : "Errore durante la richiesta di cancellazione."
              this.setState({ errors: errors })                                 
            }

          })
          .catch( error => {
            errors[vehicle.id] = error.message
            this.setState({ errors: errors })   
          }) 

      }
      else {
        errors[vehicle.id] = "Richiesta di annullo non valida"
        this.setState({ errors: errors })        
      }      
    }
    else {
      errors[vehicle.id] = "Errore durante la richiesta di cancellazione."
      this.setState({ errors: errors })        
    }
    
  }
  
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      user 
    } = this.props

    const {      
      showDeleteVehicleModal,
      showUpdateVehicleModal,
      loading,
      showEditButtons,
      selectedVehicle,
      showNewVehicleModal,
      showConfirmRequestCancelButtons,
      errors,
      markModifyPrice,
      news
    } = this.state

    return (
    <div className="row">
      <div className="col-md-8">
        <div className="panel panel-default panel-border-login">
          <h2 className="login-title">
            VEICOLI
          </h2>
          {loading 
          ? ( <div>Caricamento in corso...</div> ) 
          : (
          <div className="panel-body">
            <div className="col-md-3">              
              <FontAwesomeIcon  size="6x" className="sectionIcons"   icon={solidIcons.faCar} />
            </div>

            {/* PULSANTI LISTA VEICOLI */}
            { user && user.userConfirmed && 
            <div className="col-md-9">
              
                <div className="bnt user-card-btn1" onClick={()=>{ this.showNewVehicleModal( ) }}>
                  <strong>NUOVO VEICOLO</strong>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="2x" icon={solidIcons.faCirclePlus} />
                </div>
              
            </div>
            }
            {/* LISTA VEICOLI */}
            { this.getSortedVehicleList().map((vehicle, index) => {

              var render = this.getVehicleRender(vehicle)
              
              return(
              <div key={vehicle.id + '-' + vehicle.index} className={'col-md-9' + (index > 0 ? ' col-md-push-3' : '')}>
                <div className={ render.className } > 
                    
                    { render.messageStatus && 
                    <div className="row">
                      <h4 style={{ margin: "1em" , color: render.color }}><strong>{render.messageStatus.toUpperCase()}</strong></h4>
                    </div>
                    }

                    <div className="row">
                      <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}>
                        <h5 style={{ margin: "1em" }}>{vehicle.VehicleOwnershipType && vehicle.VehicleOwnershipType.name}</h5>
                      </div>
                      <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}>
                        <h3>
                          {vehicle.plate_number}
                          {render.newPlateNumber && <React.Fragment>&nbsp;<FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faArrowRight} />&nbsp;</React.Fragment>  }
                          {render.newPlateNumber}
                        </h3>
                      </div>
                      <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}>                    
                        <div className="col-md-5 col-xs-5 text-right" >
                          {getVehicleIcon(vehicle.VehiclePowerSupply)} 
                        </div>
                        <div className="col-md-7 col-xs-7  text-left" >
                          <h4>{vehicle.VehiclePowerSupply && vehicle.VehiclePowerSupply.name}</h4>
                        </div>
                      </div>  

                      {/* NOTE VEICOLO */}
                      { render.showNote &&
                        <React.Fragment>
                          <div className="row" style={{ marginRight: "2em", marginLeft:"2em"}}>                             
                            <div className="col-md-12 col-xs-12">
                              
                              { this.getSortedVehiclesChangeRequestsNotes(vehicle).map( ( note , index ) =>                                 
                                <React.Fragment key={"note-"+note.id}>
                                  { index === 0  &&
                                    <h4><strong>Note di questa richiesta:</strong></h4>
                                  }

                                  { index === 0  &&
                                    <div key="note-header" className="row noteRow" >
                                      <div className="col-md-3 col-sm-3 col-xs-12 text-left " ><strong>Data</strong></div>
                                      <div className="col-md-9 col-sm-9 col-xs-12 text-left " ><strong>Nota</strong></div>
                                    </div>
                                  }

                                  <div className="row noteRow" >
                                    <div className="col-md-3 col-sm-3 col-xs-12 text-left "><strong>{moment(note.createdAt).fromNow() }</strong></div>
                                    <div className="col-md-9 col-sm-9 col-xs-12 text-left "><strong>{note.note}</strong></div>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>                            
                          </div>                                                  
                        </React.Fragment>
                      }

                      {/* ERRORI */}
                      { errors[vehicle.id] && 
                        <div className="row" style={{ margin: "1em"}}> 
                          <div className="col-md-2 col-xs-2"></div>
                          <div className="col-md-8 col-xs-8 text-danger" >
                            <strong>{errors[vehicle.id]}</strong>                              
                          </div>
                          <div className="col-md-2 col-xs-2"></div>
                        </div>
                      }

                      {/* PULSANTI */}
                      { render.showRequestModifyButton && 
                        <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}> 
                          <div className="col-md-2 col-xs-2"></div>
                          <div className="mainBtn col-md-8 col-xs-8" onClick={ () => { this.showUpdateVehicleModal(vehicle) }} >
                            <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faEdit} />&nbsp;&nbsp;
                            MODIFICA RICHIESTA
                          </div>
                          <div className="col-md-2 col-xs-2"></div>
                        </div>
                      }                     

                      { render.showPaymentButtons &&
                      <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}> 
                        <div className="col-md-2 col-xs-2"></div>
                        <div className="mainBtn col-md-8 col-xs-8" onClick={ () => { this.props.history.push("/user-area/pay/vehicleChangeRequest/" + render.changeRequestId)} }>
                            <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faEuro} />&nbsp;&nbsp;
                            PAGA RICHIESTA                          
                        </div>
                        <div className="col-md-2 col-xs-2"></div>
                      </div>
                      }

                      { render.showRequestCancelButton && 
                        <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}> 
                          <div className="col-md-2 col-xs-2"></div>
                          { !showConfirmRequestCancelButtons[vehicle.id] && 
                          <div className="alertBtn col-md-8 col-xs-8" onClick={ () => { this.setShowConfirmRequestCancelButtons(vehicle) }} >
                            <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;
                            ANNULLA RICHIESTA
                          </div>
                          }
                          { showConfirmRequestCancelButtons[vehicle.id] && 
                            <React.Fragment>
                              <div className="alertBtn col-md-8 col-xs-8" onClick={ () => {  this.cancelRequest(vehicle) }} >
                                <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;
                                CONFERMA DI ANNULLO RICHIESTA?
                              </div>

                              <div className="col-md-2 col-xs-2"></div>

                              <div className="mainBtn col-md-8 col-xs-8" onClick={ () => { this.setShowConfirmRequestCancelButtons(vehicle) }} >
                                <FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faDeleteLeft} />&nbsp;&nbsp;
                                INDIETRO
                              </div>

                              <div className="col-md-2 col-xs-2"></div>
                            </React.Fragment>
                            
                          }
                                                  
                        </div>
                      }

                      { !showEditButtons[vehicle.id] && render.showMainEditButtons &&
                      <div className="row" style={{ marginRight: "1em", marginLeft:"1em"}}> 
                        <div className="col-md-2 col-xs-2"></div>
                        <div className="mainBtn col-md-8 col-xs-8" onClick={  ( evt ) => { this.setShowEditButtons(vehicle) } } >HAI VENDUTO O CAMBIATO IL VEICOLO?</div>
                        <div className="col-md-2 col-xs-2"></div>
                      </div>
                      }
                      
                      { showEditButtons[vehicle.id] && render.showMainEditButtons &&
                      <div className="row " style={{ marginRight: "1em", marginLeft:"1em"}}>                                                     
                          <div className="col-md-2 col-xs-2"></div>
                          <div className="row col-md-8 col-xs-8" style={{ margin: "0em", padding:"0em"}}> 
                            <div  className= "btn alertBtn col-md-12 col-xs-12" onClick={  ( evt ) => { this.setShowEditButtons(vehicle) } } >
                              <FontAwesomeIcon  size="1x" icon={solidIcons.faCircleXmark} />&nbsp;&nbsp;ANNULLA
                            </div>
                            {/* <div className="btn alertBtn col-md-12 col-xs-12" onClick={ () => { this.showDeleteVehicleModal(vehicle) }}>
                              <FontAwesomeIcon  size="1x" icon={solidIcons.faTrash}  />&nbsp;&nbsp;VENDUTO
                            </div>                                      */}
                            <div className="btn mainBtn  col-md-12 col-xs-12" onClick={ () => { this.showUpdateVehicleModal(vehicle) }}>
                              <FontAwesomeIcon  size="1x" icon={solidIcons.faEdit} />&nbsp;&nbsp;CAMBIATO
                            </div>                            
                          </div>
                          <div className="col-md-2 col-xs-2"></div>                          
                      </div>
                      }

                    </div>                       
                </div>
              </div>
              )
            })}
          </div>
          )}
        </div>
      </div>

      {/* BLOCCO INFO */}
      <div className="col-md-4">
        <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
          <div style={{ width:'85%' }}>
            <div style={{marginTop: 15, marginBottom: 10,  display:'flex', alignItems: 'center', justifyContent:'center' }}>
              <span className="threesom-span">
                <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
              </span>
              <div>
              <TextUser bold>VEICOLI</TextUser>
              <TextUser>
                Questi sono i tuoi veicoli registrati
              </TextUser>
              </div>
            </div>
          </div>
        </div>

        {news != null && news.length > 0 && 
        <div className="information-container">
          <div style={{marginLeft: 10}}>
            <div style={{marginTop: "5em", marginBottom: 10}}>
              <TextUser bold>COMUNICAZIONI</TextUser>
              <div style={{ margin: 0 }}>
                
                <Carousel
                  autoPlay
                  interval={8000}
                  infiniteLoop
                  stopOnHover
                  emulateTouch
                  showThumbs={false}
                >
                { news.map(  n => 
                  <div className={"boxLabel " + (getNewsStyle(n))}>
                    {n.thumbImage == null ? (
                    <div>
                      {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                      { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                    </div>
                    ):(
                      <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={n.thumbImage}></img>
                    )}
                  </div>     
                )} 
                </Carousel>
                
              </div>
            </div>
          </div>
        </div>
        }
        
      </div>

      {/* MODALE AGGIUNTA NUOVO VEICOLO */}
      <NewVehicleModal
        show={showNewVehicleModal}
        onAddVehicle={this.onAddVehicle}
        onClose={this.onCloseNewVehicleModal}
        />      

      {/* MODALE CANCELLAZIONE VEICOLO */}
      <DeleteVehicleModal
        show={showDeleteVehicleModal}
        onDeleteVehicle={this.onDeleteVehicle}
        onClose={this.onCloseDeleteVehicleModal}
        vehicle={selectedVehicle}
        />   

      {/* MODALE MODIFICA DATI VEICOLO */}
      <UpdateVehicleModal
        show={showUpdateVehicleModal}
        onUpdateVehicle={this.onUpdateVehicle}
        onClose={this.onCloseUpdateVehicleModal}
        vehicle={selectedVehicle}
        />
        
    </div>
    
    )
  }
}

UserAreaVehicles.defaultProps = {}
UserAreaVehicles.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserAreaVehicles)
