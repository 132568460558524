import React, { Component } from 'react'
import './ResellerNavMenuComponent.css'
import { Nav, NavItem, Navbar, Button, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import LogoInput from '../../../assets/LogoInput60x60.png'

var LogoApp = "/cities/" + process.env.REACT_APP_CITY.toLowerCase() + '/logo-app.png'
var LogoCity = "/cities/" + process.env.REACT_APP_CITY.toLowerCase() + '/logo-city.png'

const mapStateToProps = (state) => {
    return {
        stateReport: state.report
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // test?
    }
}

class ResellerNavMenuComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minHeight: 0/*
            isModalOpen: this.props.isModalOpen // Definisce lo stato iniziale per la modale
            */
        }
    }

    getBadgeClass(playBadge) {
        if (playBadge)
            return "badge badge-list badgeActive"
        else
            return "badge badge-list badgeInactive"
    }

    modal(state = null, action) {
        switch (action.type) {
            case 'OPEN_MODAL':
                return action.data
            case 'CLOSE_MODAL':
                return null
            default:
                return state
        }
    }

    render() {
        if (this.props) {
            const {
                stateReport,
                stateAuth,
                reportActions,
                user,
                onNewMessagesClick,
                onLogoutClick,
                onPasswordChangeClick,
                settingsMessages,
                badgeData,
                isModalOpen,
                onHandleButtonClick
            } = this.props

            if (user, badgeData) {
                const { MarksResellers,
                    marksResellersTotal,
                    marksTotalValue,
                    marksBudgetValue,
                    buttonColor,
                    iconType,
                    theBudget,
                    thePercentage,
                    theTotal,
                    theResidual
                } = badgeData;
                //const { marksBudgetValue, marksTotalValue, marksResellersTotal, buttonColor, iconType, theBudget, thePercentage, theTotal, theResidual } = badgeData;

                const tipData = "Situazione Reseller;\n" + "- Permessi generati per un valore di: " + theTotal + ";\n" + "- Fido massimo permessi consentito: " + theBudget + ";\n" + "- Percentuale usata: " + thePercentage + ";\n" + "- Totale permessi generati: " + marksResellersTotal + ";\n" + "- Residuo spendibile: " + theResidual + "."
                // 
                var playMessagesNew = (stateReport.newMessages && stateReport.newMessages > 0)               

                return (
                    <Navbar className="navbar-reseller">
                        <Nav className="nav-logos">
                                <NavItem href="https://inputitalia.com/" target="_blank" className="logo-item logo-link">
                                    <img
                                        src={LogoInput}
                                        className="logo-input d-inline-block align-top img-fluid"
                                        alt="Logo Input"
                                    />
                                </NavItem>
                                <NavItem href={"http://" + process.env.REACT_APP_MANAGER_URL} target="_blank" className="logo-item logo-link">
                                    <img
                                        src={LogoApp}
                                        className="logoCity d-inline-block align-top img-fluid"
                                        alt="Logo T"
                                    />
                                </NavItem>
                                <NavItem href={"http://" + process.env.REACT_APP_CITY_URL} target="_blank" className="logo-item logo-link">
                                    <img
                                        src={LogoCity}
                                        className="logoThree d-inline-block align-top img-fluid"
                                        alt="Logo City"
                                    />
                                </NavItem>
                        </Nav>
                        <Nav className="nav-buttons">
                            <Button
                                id="Situazione-Reseller"
                                className={"Reseller-Status Reseller-Status-" + buttonColor}
                                onClick={onHandleButtonClick}
                                title={tipData}
                            >
                                <FontAwesomeIcon
                                    color={buttonColor}
                                    size="1x"
                                    icon={iconType} className="badgeMain" />
                                &nbsp;&nbsp;
                                <span key={1} id="Reseller-Expense">
                                    {theTotal}
                                </span>
                                {marksBudgetValue !== "∞" ?
                                    [
                                        <span key={2} id="Reseller-Budget">
                                            <strong> / </strong>
                                            <span>
                                                {theBudget}
                                            </span>
                                        </span>
                                    ]
                                    : ""}
                                {stateReport.newMessages ? <>
                                    &nbsp;&nbsp;<Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={stateReport.newMessages ? stateReport.newMessages : 0} color="secondary"><FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} color="action" /></Badge></>
                                    : ""}
                            </Button>
                            {
                                // Tab 1 (predefinito): Situazione reseller, budget attuale (fido massimo permessi) vs totale importi permessi generati, numero totale permessi generati vs totale permessi consentiti
                                // Tab 2 : Nuove notifiche (messaggi, permessi, veicoli)
                                // Tab 3 : Numero utenti Creati, con ultimi 5 utenti, ultimi 5 veicoli generati, ultimi permessi generati
                            }
                        </Nav>
                    </Navbar>
                );
            }

            return null;
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerNavMenuComponent' })(ResellerNavMenuComponent))