import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import ResellerEditTimeSlot from './ResellerEditTimeSlot';//

moment.locale('it')



class ResellerEditTimeSlotModal extends Component {

  static propTypes = {
    show:         PropTypes.bool.isRequired,
    timeSlot:     PropTypes.object,
    onSave:       PropTypes.func.isRequired,
    onDelete:     PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
    onClose:      PropTypes.func.isRequired,
  }
  
  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }

    this.close    = this.close.bind(this)
    this.onSave   = this.onSave.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onCancelEdit = this.onCancelEdit.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
     
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
        
  }

 
  /**
   * --------------------------------------------------------------
   * @param {*} timeSlot 
   */
  onSave(timeSlot) {
    if ( this.props.onSave != null )
      this.props.onSave(timeSlot)

    this.close()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onDelete() {    
    if ( this.props.onDelete != null )
      this.props.onDelete()
    
    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEdit() {
    if ( this.props.onCancelEdit != null )
      this.props.onCancelEdit()
    
  }

  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      timeSlot 
    } = this.props
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong> GESTISCI SLOT TEMPORALI </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => { this.close() } } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        <div className="col-md-12">
          <div className="app-modal-body">                                  
            <ResellerEditTimeSlot
              timeSlot={timeSlot}
              onSave={this.onSave}      
              onDelete={this.onDelete}    
              onCancelEdit={this.onCancelEdit}
              />
          </div>
        </div>    

       
      </Modal>
    )
  }
}

export default reduxForm({ form: 'ResellerEditTimeSlotModal'})(ResellerEditTimeSlotModal)
