import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'

export default class RenewModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mark: PropTypes.object,
    onRenewMark: PropTypes.func.isRequired,
    renewErrors: PropTypes.string
  }

  state = {
    acceptPolicy: false,
    renewErrors1: null
  }

  onAcceptPolicyChange = (event) => {
    this.setState({acceptPolicy: event.target.checked})
  }

  onRenewMark(mark) {
    if ( this.state.acceptPolicy  ) {
      this.props.onRenewMark(mark)
    }
    else {
      this.setState({
        renewErrors1: "Attenzione! È necessario accettare la dichiarazione di non variazione"
      })
    }
  }

  
  render () {

    const {
      show,
      onClose,
      mark,
      renewErrors
    } = this.props

    const {
      renewErrors1
    } = this.state

    var errors = null

    if ( renewErrors )
      errors = renewErrors
    else if ( renewErrors1 )
      errors = renewErrors1

    return (
      <Modal
        open={show}
        onClose={onClose}
        showCloseIcon={false}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: 'app-modal-container' }}        
        focusTrapped={true}
        animationDuration={500}
        center                                                
      >        
        {mark ? (
          <React.Fragment>
            <h4 className="app-modal-title">
              <div style={{ flex: 10, textAlign : 'left'}}>
                <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faWallet} />
              </div>          
              <div style={{ flex: 80, textAlign : 'center' }}><strong>RINNOVA PERMESSO</strong></div>          
              <div style={{ flex: 10, textAlign : 'right'}}>
                <FontAwesomeIcon  onClick={ evt => onClose() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
              </div>          
            </h4> 
            <div className="panel-body"> 
              <div className="row">                     
                <div className="col-md-12 col-xs-12">
                  <div className="user-card">
                    <div className="user-card-head-a">
                      <h5 className="user-card-title">
                        PERMESSO
                      </h5>
                    </div>
                    <div className="user-card-body">
                      <div className="row">
                        <div style={{padding: 0, marginBottom: 5}} className="col-md-12">
                          <strong>{mark.MarkType.label + ' ' + mark.Category.label.toUpperCase()}</strong>
                        </div>
                        <div style={{padding: 0}} className="col-md-12">
                          ZON{mark.MarkType.Areas.length > 1 ? 'E' : 'A'} <strong>{mark.MarkType.Areas.map(item => item.code + ' ')}</strong>
                        </div>
                        <div style={{padding: 0, marginBottom: 5}} className="col-md-12">
                          {mark.fatherMark && <span>DERIVATO DA PERMESSO N.: <strong>{mark.id}</strong></span>}
                        </div>
                        <div style={{padding: 0, marginBottom: 5}} className="col-md-12">
                          <span>TARG{mark.Vehicles.length > 1 ? 'HE' : 'A'}: <strong>{mark.Vehicles.filter( item => !item.archived ).map(item => item.plate_number + ' ')}</strong></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12" style={{display:'flex', alignItems:'center'}}>
                  <div className="col-xs-3">
                    <input value={this.state.acceptPolicy} className="form-control" onChange={this.onAcceptPolicyChange} type="checkbox" /> 
                  </div>
                  <div className="col-xs-9" style={{ paddingTop: "0.7em" }}>
                    <label>
                      Con l’invio della richiesta di rinnovo, dichiaro che nulla è variato rispetto alla situazione dell’anno precedente
                    </label>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12" style={{ marginTop: "1em" }}>
                  <p>
                    Da qui puoi richiedere il rinnovo del tuo permesso <strong>N. {mark.id}</strong> attivo {mark.Vehicles.length > 1 ? 'sui veicoli' : 'sul veicolo'}: <strong>{mark.Vehicles.filter( item => !item.archived ).map(item => item.plate_number + ' ')}</strong>.<br />
                    Per confermare la richiesta clicca: <strong>"Conferma Richiesta"</strong>.
                  </p>
                </div>
              </div>
              <div className="row">
                {errors && (
                  <div className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {errors}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <a className="alertBtn"    onClick={onClose}                >Annulla</a>{' '}
                  <a className="btn mainBtn" onClick={() => this.onRenewMark(mark)}>Conferma Richiesta</a>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div>Dati non disponibili</div>
        )}
      </Modal>
    )
  }
}
