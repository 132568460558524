import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import ReactTable from 'react-table';
import { Oval } from 'react-loader-spinner';
import Parser from 'html-react-parser';
const thisRoute = 'reseller'


class BackofficeAddressesModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onUpdateAddresses: PropTypes.func.isRequired,
    areaId: PropTypes.number.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = { 
      loading: true,   
      area: null,  
      addresses: [],
      sortedAddresses: [],
      addressesFile: null,    
      newAddressName: '',             
      checkErrors: null,
      sendErrors: null,
      sendMsg: null,      
      selectedRow: null,    
      showAddNewAddress: false,  

      columns: [
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Id</strong></div>,
          accessor: 'id',
          filterable: false,
          sortable: true,
          width: 60,
          Cell: (row) => {
            return (
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
            )
          }
        },
        {
          Header: () => <div className="pull-left" style={{ marginLeft: "1em" }}><strong>Nome</strong></div>,
          accessor: 'name',
          filterable: false,
          sortable: true,
          width: 300,
          Cell: (row) => {
            return (
              <div className="pull-left" style={{ marginLeft: "1em" }}>
                {row.value}                
              </div>
            )
          }
        },
      ]      
    }

    this.uploadAddresses             = this.uploadAddresses.bind(this)
    this.checkFieldsForFileUpload    = this.checkFieldsForFileUpload.bind(this)
    this.checkFieldsForSaveAddress   = this.checkFieldsForSaveAddress.bind(this)
    this.checkFieldsForCreateAddress = this.checkFieldsForCreateAddress.bind(this)
    this.checkFieldsForDeleteAddress = this.checkFieldsForDeleteAddress.bind(this)
    this.deleteAddress               = this.deleteAddress.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {    
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if ( this.props.areaId != prevProps.areaId ) {
      this.stateInit()
      this.fetchData()
    }
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null  )
      this.props.onClose()
    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      addressesFile: null,
      newAddressName: '',            
      checkErrors: null,
      sendErrors: null, 
      sendMsg: null,
      loading: true,
      area: null,  
      addresses: [],   
      sortedAddresses: [],               
      selectedAddressId: null,
      selectedRow: null, 
      showAddNewAddress: false,
    })    

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {    

    this.setState({loading: true})

    if ( this.props.areaId != null ) {
      var area = await SostaOnlineApiService.fetchArea(this.props.areaId)

      if ( area != null ) {
        this.setState({area: area.data})
      }

      var addresses = await SostaOnlineApiService.fetchAddresses(this.props.areaId)

      if ( addresses != null ) {

        var sortedAddresses = addresses.data.sort( ( a, b ) => ( a.name.toUpperCase() > b.name.toUpperCase()  ? 1 : -1 )  )

        this.setState({
          addresses: addresses.data,
          sortedAddresses: sortedAddresses
        })
      }
      else {
        this.setState({
          addresses: [],
          sortedAddresses: []
        })
      }

    }    

    this.setState({loading: false})

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFieldsForFileUpload() {

    var errors = []

    if ( this.state.addressesFile == null ) {
      errors.push("Nessun file selezionato")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   */
  checkFieldsForSaveAddress(addressId) {
    var errors = []

    var addresses = this.state.sortedAddresses

    if ( addresses == null || addresses.length <= 0 ) {
      errors.push("Nome indirizzo obbligatorio")
    }
    else {

      var address = addresses.filter( addr => addr.id == addressId)  
      
      if ( address == null || address.length <= 0 ) {
        errors.push("Nome indirizzo obbligatorio")
      }
      else if ( address[0].name.trim().length <= 0 ) {
        errors.push("Nome indirizzo obbligatorio")
      } 
    }

    return errors;
  }

  /**
   * --------------------------------------------------------------
   */
  checkFieldsForCreateAddress() {
    var errors = []

    if ( this.state.newAddressName == null || this.state.newAddressName.trim().length <= 0 ) {
      errors.push("Nome indirizzo obbligatorio")
    }

    return errors;
  }  

  /**
   * --------------------------------------------------------------
   * @param {*} addressId 
   * @returns 
   */
  checkFieldsForDeleteAddress(addressId) {
    var errors = []

    var addresses = this.state.addresses

    if ( addresses == null || addresses.length <= 0 ) {
      errors.push("Nome indirizzo obbligatorio")
    }
    else {

      var address = addresses.filter( addr => addr.id == addressId)  
      
      if ( address == null || address.length <= 0 ) {
        errors.push("Nome indirizzo obbligatorio")
      }
      
    }

    return errors;
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  uploadAddresses() {    

    var errors = this.checkFieldsForFileUpload()
    this.setState({loading: true, checkErrors: errors , sendErrors: null, sendMsg: null })

    if ( errors != null && errors.length > 0 ) {      
      this.setState({loading: false})
      return
    } 
    
    SostaOnlineApiService.uploadAddresses(    
      this.props.areaId,        
      this.state.addressesFile,
      thisRoute
    )
    .then( result => {      

      if ( this.props.onUploadDocument != null ) {
        this.props.onUploadDocument(result)        
      }
      
      this.stateInit()
      this.fetchData()

      var messages = ""
      
      if ( result.data && result.data.messages != null && result.data.messages.length > 0  ) {        
        for ( let i in result.data.messages ) {
          messages = messages + result.data.messages[i] +"<br/>"
        }
      }
      
      if ( this.props.onUpdateAddresses != null )
        this.props.onUpdateAddresses()

      this.setState({sendMsg: messages+"Importazione dati avvenuta con successo!"})
      //console.log("DATA",result)
      
    })
    .catch( error => {
      console.log("ERROR",error)
      if ( error.error !=  null || error.error.length > 0 )
        this.setState({ sendErrors:  error.error, loading: false })
      else 
        this.setState({ sendErrors: "Errore durante l'invio del documento, riprovare pù tardi!" })
    })
    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  cancelUploadAddresses() {
    this.setState({ addressesFile: null})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  onFileAddressesChange(value) {  
    if ( value.length > 0 ) {      
      this.setState({
        addressesFile: value[0],
        showAddNewAddress: false,
        selectedRow: null,
        newAddressName: ''
      })
    }
  }
  
  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  onAddresSelected(rowIndex, rowInfo){
    //console.log("ROW SELECTED",rowIndex,rowInfo)    
    this.setState({ addressesFile: null, selectedRow: rowIndex, showAddNewAddress: false, newAddressName: '', sendErrors: null, checkErrors: null , sendMsg: null  })
  }

  /**
   * --------------------------------------------------------------
   */
  showAddNewAddress() {
    //console.log("SHOW ADD NEW ADDRESS")
    this.setState({ addressesFile: null, showAddNewAddress: true , selectedRow: null, newAddressName: '', sendErrors: null, checkErrors: null, sendMsg: null  })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} addressId 
   */
  saveAddress(addressId) {

    var errors = []

    // AGGIORNO
    if ( addressId != null ) {
      errors = this.checkFieldsForSaveAddress(addressId)
      this.setState({ checkErrors: errors,  sendErrors: null, loading: true, sendMsg: null})
      
      if ( errors != null && errors.length > 0 ) {      
        this.setState({loading: false})
        return
      }

      var address = this.state.addresses.filter( address => address.id == addressId )

      if ( address.length > 0 ) {    

        address[0].name = address[0].name.trim()
        
        SostaOnlineApiService.updateAddress(address[0])
        .then( data => {
          this.fetchData()

          if ( this.props.onUpdateAddresses != null )
            this.props.onUpdateAddresses()

          this.setState({ checkErrors: null,  sendErrors: null, loading: false , sendMsg: "Indirizzo salvato correttamente"})
        })
        .catch( error => {          
          if ( error.error != null ) {
            this.setState({ sendErrors: error.error , loading: false, sendMsg: null })
          }
          else {
            this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" , loading: false, sendMsg: null })
          }          
        })
      }
    }

    // CREO
    else {

      errors = this.checkFieldsForCreateAddress()
      this.setState({ checkErrors: errors,  sendErrors: null, loading: true, sendMsg: null})
      
      if ( errors != null && errors.length > 0 ) {      
        this.setState({loading: false})
        return
      }

      SostaOnlineApiService.createAddress( { name: this.state.newAddressName.trim(), areaId: this.props.areaId},thisRoute )
        .then( async ( data ) => {          
          await this.fetchData()

          if ( data != null && data.data != null ) {      
            var createdAddresIndex = null

            await this.state.addresses.map( ( a, index ) => {             
              if ( a.id == data.data.id )  {              
                createdAddresIndex = index
              }
            })            

            if ( createdAddresIndex != null ) {
              this.setState({ selectedRow: createdAddresIndex})
            }
          }
          else {
            this.setState({ selectedRow: null })
          }

          if ( this.props.onUpdateAddresses != null )
            this.props.onUpdateAddresses()

          this.setState({ checkErrors: null,  sendErrors: null, loading: false , sendMsg: "Indirizzo salvato correttamente"})          
        })
        .catch( err => {          
          var sendErrors = "Errore durante Il salvataggio, riprovare più tardi!"

          if ( err.error != null )
            sendErrors = err.error

          this.setState({ sendErrors: sendErrors, loading: false })
        })
      
    }

    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} addressId 
   */
  deleteAddress(addressId) {

    if ( addressId != null ) {
      var errors = this.checkFieldsForDeleteAddress(addressId)
      this.setState({ checkErrors: errors,  sendErrors: null, loading: true, sendMsg: null})
      
      if ( errors != null && errors.length > 0 ) {      
        this.setState({loading: false})
        return
      }

      var address = this.state.addresses.filter( address => address.id == addressId )

      if ( address.length > 0 ) {    

        address[0].name = address[0].name.trim()

        //console.log("SAVE ADDRESS", address[0]  )
        SostaOnlineApiService.deleteAddress(address[0].id,thisRoute)
        .then( data => {
          this.fetchData()

          if ( this.props.onUpdateAddresses != null )
            this.props.onUpdateAddresses()
            
          this.setState({ checkErrors: null,  sendErrors: null, loading: false , sendMsg: "Indirizzo cancellato correttamente", selectedRow: null})
        })
        .catch( err => {
          var sendErrors = "Errore durante la cancellazione, riprovare più tardi!"

          if ( err.error != null )
            sendErrors = err.error

          this.setState({ sendErrors: sendErrors, loading: false })                    
        })
      }
    }

  }

  /**
   * --------------------------------------------------------------
   * @param {*} addressName 
   */
  setAddressName(addressName) {

    var { 
      addresses,
      selectedRow 
    } = this.state

    if ( selectedRow != null && addressName != null) {
      addresses[selectedRow].name = addressName
      this.setState({addresses: addresses, newAddressName: ''})
    }
    else if ( selectedRow == null ) {
      this.setState({newAddressName: addressName})
    }

  }
 
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,
      areaId      
    } = this.props

    const {
      sendMsg,
      sendErrors,
      checkErrors,
      addressesFile,    
      area,
      addresses,
      sortedAddresses,
      columns,
      loading,      
      selectedRow,
      showAddNewAddress,
      newAddressName
    } = this.state


    

    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faFileUpload} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>INDIRIZZI ZONA {areaId} { area!= null ? area.name : '' }</strong></div>
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>   

        { area  == null &&
        <div className="col-md-12">
          <div className="app-modal-body">
            <div className="row">          
              Loading ...
            </div>
          </div>
        </div>
        }     

        { area != null && 
        <div className="col-md-12" >
          <div className="app-modal-body">

            <div className="row" >       
                <div className="col-md-12 pull-left " >                  
                  <h5>Modifica la lista degli indirizzi o carica un 
                  <OverlayTrigger placement="top" overlay={
                    <Tooltip id={'tooltip-address-1'}>
                    Il file richiesto deve essere in <strong>formato testuale (txt)</strong> e gli indirizzi al suo interno devono essere suddivisi da newline.<br/>
                    Gli indirizzi verranno aggiunti a quelli già esistenti nella zona.<br/>
                    Indirizzi doppi già presenti in questa o altre zone non verranno importati.
                    </Tooltip>}>
                  <strong> FILE <span className="glyphicon glyphicon-info-sign">{}</span> </strong>
                  </OverlayTrigger>
                  contentente gli indirizzi che vuoi associare alla zona <strong>{area.name}</strong>.                  
                  </h5>
                </div>
                <br/><br/>
            </div>
            
            <div className="row" style={{ paddingLeft: "10px" , paddingRight: "10px" }}>

                { addressesFile == null && 
                <label className="mainBtn" onClick={ (evt) => { this.showAddNewAddress() }}>                  
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle}  />&nbsp;&nbsp;Aggiungi nuovo Indirizzo
                </label>
                }

                <label className="mainBtn">
                  <input         
                    style={{ display: "none"}}                               
                    type="file" 
                    name="addressesFile"                                       
                    onChange={ (evt) => { this.onFileAddressesChange(evt.target.files) } }
                  />
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faFile} />&nbsp;&nbsp;Seleziona File Indirizzi ...
                </label>
                  
                
                { addressesFile != null &&                   
                  <span><strong> {addressesFile.name} </strong></span>
                }
                
            </div>                           

            <div className="row">
              { sendMsg &&<h4 className="col-md-12 text-success text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {Parser(sendMsg)}
                </h4> }
              { sendErrors && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h4>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            { addressesFile && 
            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn" onClick={ () => this.cancelUploadAddresses() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;Annulla
                </Button>{' '}
                <Button className="btn mainBtn"  onClick={ () => this.uploadAddresses()}>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faUpload} />&nbsp;&nbsp;Carica
                </Button>
              </div>
            </div>
            }

            <div className="row" style={{marginBottom: '1.5em', marginTop: '1em'}}>
            
              <div className= "col-md-6 text-center"  style={{marginBottom: '1em' }}>
                <ReactTable       
                  getTdProps={(state, rowInfo, column, instance) => {

                    const isActive = rowInfo && selectedRow == rowInfo.index
                    const activeStyle = {}

                    if (isActive) {
                      activeStyle.background = '#f5f5f5'
                      activeStyle.color = 'black'                      
                    }

                    return {
                      style: { ...activeStyle },
                      onClick: (e, handleOriginal) => {                
                        if (rowInfo != null ) {                                                
                          this.onAddresSelected(rowInfo.index, rowInfo.original)
                        }
                      }
                    }
                  }}              
                  manual
                  filterable
                  loading={loading}
                  noDataText={'Nessun Indirizzo Presente'}
                  //onFetchData={null}  
                  className="-highlight"
                  data={ sortedAddresses ? sortedAddresses : []}
                  style={{ maxHeight: "20em" }}                                    
                  loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
                  showPaginationBottom={false}                  
                  columns={columns} />
              </div>

              { ( selectedRow != null  || showAddNewAddress ) &&
              <div className="col-md-6" >
                <div className="backofficeSettingsBox col-sx-12" style={{ padding: "1em" }}>
                  <br/>

                  { selectedRow != null  && 
                  <div className="row">
                    <div className="col-xs-2" ><strong>Id:</strong></div>                    
                    <div className="col-xs-10" style={{ paddingLeft: "30px" } }>{ addresses[selectedRow].id }</div>
                  </div>
                  }
                  
                  <br/>

                  <div className="row">
                    <div className="col-xs-2" >
                      <strong className="text-left">Nome:</strong>
                    </div>
                    
                    <div className="col-xs-10" >
                      <input
                        name="addressName" 
                        className="form-control" 
                        type="text" 
                        onChange={ e => {  this.setAddressName(e.target.value) }}
                        value={ selectedRow != null ? addresses[selectedRow].name : newAddressName }
                        required />
                    </div>
                  </div>

                  <br/><br/>

                  <div className="row text-center">                      
                    <Button className="btn mainBtn" onClick={ () => this.saveAddress( selectedRow != null ? addresses[selectedRow].id : null ) }>
                      <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faSave} />&nbsp;&nbsp;Salva&nbsp;Indirizzo&nbsp;{ selectedRow != null ? addresses[selectedRow].id : '' }
                    </Button>

                    { selectedRow != null  && 
                    <Button className="btn alertBtn" onClick={ () => this.deleteAddress(addresses[selectedRow].id) }>
                      <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Cancella&nbsp;Indirizzo&nbsp;{ selectedRow != null ? addresses[selectedRow].id : '' }
                    </Button>
                    }
                  </div>
                </div>
              </div>                                
              }

            </div>            
          </div>
        </div>
        }
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'BackofficeAddressesModal'})(BackofficeAddressesModal)
