import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackOfficeNavigatorScreen from '../screens/BackOfficeNavigatorScreen'
import { Route, Switch, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../redux/modules/Auth'
import { reportActions } from 'redux/modules/Report'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import BackOfficeMarksTableContainer from './BackOfficeMarksTableContainer'
import BackOfficeReceiptsTableContainer from './BackOfficeReceiptsTableContainer'
import BackOfficeUserContainer from './BackOfficeUserContainer'
import BackofficeReports from './Backoffice/Reports/BackofficeReports'
import { ADMIN, OPERATOR, RESELLER } from 'libs/roles'
import BackofficeMessages from './Backoffice/Messages/BackofficeMessages'
import BackOfficeVehiclesContainer from './BackOfficeVehiclesContainer'
import BackOfficeSettingsContainer from './BackOfficeSettingsContainer'
import PasswordChange, { STATE_SEND_OTP } from './UserArea/Password/PasswordChange'
import history from '../history'
import BackofficeNews from './Backoffice/News/BackofficeNews'
import BackOfficeOperatorProfile from '../screens/BackOfficeOperatorProfile'

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class BackOfficeContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {

    super(props)

    this.state = {
      settings: {
        messages: {
          enable: false
        }
      }
    }

    this.fetchThingsToDo = this.fetchThingsToDo.bind(this)
    this.onLogoutClick = this.onLogoutClick.bind(this)
    this.onPasswordChangeClick = this.onPasswordChangeClick.bind(this)

    /*
    this.tasks = [
      {
        id: '1',
        name: 'fetchThingsToDo',
        fn: this.fetchThingsToDo,        
        //config: '0,5,10,15,20,25,30,35,40,45,50,55 * * * *'
        config: '* * * * *'
      }    
    ]    
    */

  }


  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    //console.log("BackOfficeContainer componentDidMount")
    this.fetchSettings()
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
  fetchSettings() {

    var settings = this.state.settings

    SostaOnlineApiService.fetchMessagesSettings()
      .then(data => {
        settings.messages = data.data
        this.setState({ settings: settings })
      })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo() {
    SostaOnlineApiService.fetchThingsToDo().then(data => {
      this.props.reportActions.fetchThingsToDo(data.data)
    })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------
   */
  onLogoutClick() {
    this.props.authActions.logout()
  }

  /**
   * --------------------------------------------------------------
   */
  onPasswordChangeClick() {
    history.push("/backoffice/password-change")
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkRedirectTo() {

    //console.log('BackOfficeContainer Props:', this.props);

    if (this.props.stateAuth == null || this.props.stateAuth.user == null) {
      return (<Redirect to="/splash" />)
    }

    if (this.props.stateAuth.user.role !== ADMIN && this.props.stateAuth.user.role !== OPERATOR) {
      return (<Redirect to="/user-area" />)
    }

    if (this.props.stateAuth.user.role === RESELLER) {
      //if (this.props.stateAuth.user.budget == null) {
      //return (<Redirect to="/user-area" />)
      //} else {
      return (<Redirect to="/reseller" />)
      //}
    }

    return null
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    var redirect = this.checkRedirectTo()

    if (redirect != null)
      return (redirect)


    return (

      <React.Fragment>
        <BackOfficeNavigatorScreen
          onMarksWaitingClick={() => this.props.history.push('/backoffice/users/waiting-list')}
          onNewMessagesClick={() => this.props.history.push('/backoffice/messages')}
          onVehiclesWaitingClick={() => this.props.history.push('/backoffice/vehicles/waiting-list')}
          onResellerWaitingClick={() => this.props.history.push('/backoffice/settings/adminUsers')}
          user={this.props.stateAuth.user}
          settingsMessages={this.state.settings.messages}
          onLogoutClick={this.onLogoutClick}
          onPasswordChangeClick={this.onPasswordChangeClick}
        >
          <Switch>
            <Route path="/backoffice/password-change" component={(props) => <PasswordChange user={this.props.stateAuth.user} action={STATE_SEND_OTP} />} />
            <Route path="/backoffice/marks" component={BackOfficeMarksTableContainer} />
            <Route path="/backoffice/users" component={BackOfficeUserContainer} />
            <Route path="/backoffice/vehicles" component={BackOfficeVehiclesContainer} />
            <Route path="/backoffice/receipts" component={BackOfficeReceiptsTableContainer} />
            <Route path="/backoffice/settings" component={BackOfficeSettingsContainer} />
            <Route path="/backoffice/reports" component={BackofficeReports} />
            <Route path="/backoffice/news" component={BackofficeNews} />
            <Route path="/backoffice/operator-profile" component={BackOfficeOperatorProfile} />
            <Route path="/backoffice/messages" component={
              (props) => <BackofficeMessages settingsMessages={this.state.settings.messages} {...props} />
            } />

            <Redirect to="/backoffice/users" />

          </Switch>
        </BackOfficeNavigatorScreen>
      </React.Fragment>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeContainer)
