import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'//
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import ResellerSettingsMenuComponent from 'components/Reseller/Navmenu/ResellerSettingsMenuComponent'//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { ADMIN, OPERATOR, RESELLER } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ResellerEditAdminUser from 'components/Reseller/AdminUsers/ResellerEditAdminUser'//
import ResellerEditAdminUserModal from 'components/Reseller/AdminUsers/ResellerEditAdminUserModal'//
import { scroller , Element } from 'react-scroll'
const thisRoute = 'reseller'


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class ResellerSettingsAdminUsers extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,    
      users: [],
      selectedUserId: null,
      showEditAreaModal: false,      
      showMaximizedList: true,
    }
    
    this.onSaveAdminUser           = this.onSaveAdminUser.bind(this)    
    this.editUserAdmin             = this.editUserAdmin.bind(this)
    this.onCloseEditAdminUserModal = this.onCloseEditAdminUserModal.bind(this)    
    this.onCancelEditAdminUser     = this.onCancelEditAdminUser.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {
    this.fetchData()    
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({loading: true})

    const users = await SostaOnlineApiService.fetchPureUsersList(thisRoute)
        
    if ( users != null && users.data != null  )
      this.setState({ users: users.data })

    this.fetchThingsToDo()

    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSaveAdminUser (userId) {        
    this.setState({submitting: true, error: null, success: false, selectedUserId: userId })
    await this.fetchData()
    this.setState({submitting: false, error: false, success: true, selectedUserId: userId})
  }


  /**
   * --------------------------------------------------------------------------
   * @param {*} evt 
   */
  editUserAdmin(id) {    
    this.setState({
      selectedUserId: id,      
    })

    this.scrollToDetail()
  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditAdminUserModal() {
    this.setState({
      selectedUserId: null,
      showEditAreaModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   */
  async onCancelEditAdminUser() {

  }


  /**
   * --------------------------------------------------------------------------
   */
  windowMaximize() {
    this.setState({ showEditAreaModal: true})
  }

  /**
   * --------------------------------------------------------------------------
   */
  getSortedAdminUsers() {

    var users = []

    if ( this.state.users != null ) {
      users = this.state.users.filter( u => u.role == ADMIN || u.role == OPERATOR || u.role == RESELLER  ).sort((a, b) => (a.id > b.id ) ? 1 : -1)
    }

    return users
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }


  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {    

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,
      users,
      showEditAreaModal,
      selectedUserId,      
      showMaximizedList
    } = this.state


    var usersSorted = this.getSortedAdminUsers()


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <ResellerSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em" , marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={ e => { this.editUserAdmin(null) } } ><FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle} />
            <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Assegna Ruolo</span></button>
          </div>
        </div>
        <div className="row" style={{  padding: 0,  margin: 0 }}>

          { showMaximizedList ?           
          <div className={ showEditAreaModal ? "box col-md-12" :  "box col-md-4" } style={{ padding: "0.3em", margin: 0 , marginBottom: "0.3em" }}>
            
            <div className="row resellerSettingsHeader" >
              <div className="col-xs-2"><label>Id</label></div>
              <div className="col-xs-5"><label>Nome</label> </div>       
              <div className="col-xs-4" style={{ textAlign: "center"}}><label>Ruolo</label> </div>       
              <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>
                <FontAwesomeIcon  size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={ () => { this.setState({showMaximizedList: false}) } } />
              </div>                  
            </div>
                      
            {usersSorted.map(user =>              
              <OverlayTrigger key={`user-${user.id}`} placement="top" overlay={<Tooltip id={'tooltip-user-1-'+user.id}>Clicca per modificare i dati dell'operatore</Tooltip>}>                
                <div  className={ selectedUserId == user.id ? "resellerSettingsRowSelected row" :  "resellerSettingsRow row"} >
                  <div className="col-xs-12 row" style={{padding: 7}}>
                    <div className="col-xs-2" onClick={ evt => { this.editUserAdmin(user.id)} } ><label>{user.id}</label></div>                
                    <div className="col-xs-5" onClick={ evt => { this.editUserAdmin(user.id)} } ><label>{user.firstName} {user.lastName}</label></div>                
                    <div className="col-xs-4" onClick={ evt => { this.editUserAdmin(user.id)} } style={{ textAlign:"center", verticalAlign: "middle", borderRadius: "0.3em" , color: "white", backgroundColor:  user.role == ADMIN ? "#cc0000" : user.role === RESELLER ? "#f5009b" : "orange" }} ><label style={{marginBottom: 0}}>{user.role.toUpperCase()}</label></div>                
                    <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>{ selectedUserId == user.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }</div>                                                                               
                  </div>
                </div>
              </OverlayTrigger>         
            )}

            <div className="form-group row resellerSettingsFooter" >
              <div className="col-md-12 col-form-label" ></div>            
            </div>
          
            {submitting && <div className="text-primary row">
              Salvataggio in corso
            </div>}

            {error && <div className="text-danger row">
              Si sono verificati degli errori
            </div>}

            {success && <div className="text-success row">
            
            </div>}
            
            
          </div>
          :
          <div className="col-md-1" style={{ padding: 0, margin: 0 , marginBottom: "0.3em" }}>
            <div className="mainBtn" onClick={ () => { this.setState({showMaximizedList: true}) } } >
              <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretDown} />
            </div>
          </div>
          }
        
          { !showEditAreaModal && 
          <div className={ showMaximizedList ? "col-md-8": "col-md-11"} style={{  padding: "0.3em" , margin: 0 }}>
            <Element name="detail"/>
            <div className="resellerSettingsBox" >
              <div className="resellerSettingsBoxHeader row text-center">
                <div className="col-xs-1 pull-left">
                { selectedUserId != null 
                    ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                    : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
                  }                
                </div>          
                <div className="col-xs-10 pull-left" >
                  <strong>{ selectedUserId != null ? "MODIFICA" : "ASSEGNA NUOVO" } RUOLO </strong>
                </div>
                <div className="col-xs-1 pull-right" onClick={ evt => this.windowMaximize() }>
                  <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                    <FontAwesomeIcon  size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row" >
                
                <ResellerEditAdminUser                    
                    userId={selectedUserId}
                    users={users}
                    onSaveAdminUser={this.onSaveAdminUser}      
                    onDeleteAdminUser={this.onSaveAdminUser}    
                    onCancelEditAdminUser={this.onCancelEditAdminUser}
                    />
                
              </div>
            </div>
          </div>        
          }
        </div>
        <ResellerEditAdminUserModal
          show={showEditAreaModal}
          userId={selectedUserId}
          users={users}
          onSaveAdminUser={this.onSaveAdminUser}      
          onDeleteAdminUser={this.onSaveAdminUser}    
          onCancelEditAdminUser={this.onCancelEditAdminUser}
          onClose={this.onCloseEditAdminUserModal}
          />        
      </React.Fragment>

      
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSettingsAdminUsers)
