import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'//
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' //
import ResellerSettingsMenuComponent from 'components/Reseller/Navmenu/ResellerSettingsMenuComponent'//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { RESELLER } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { scroller , Element } from 'react-scroll'

import ResellerEditDocumentTypeModal from 'components/Reseller/DocumentTypes/ResellerEditDocumentTypeModal'//
import ResellerEditDocumentType from 'components/Reseller/DocumentTypes/ResellerEditDocumentType'//
const thisRoute = 'reseller'


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class ResellerSettingsDocumentTypes extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,    
      documentTypes: [],
      selectedDocumentTypeId: null,
      showEditDocumentTypeModal: false,      
      showMaximizedList: true,
    }
    
    this.onSaveDocumentType       = this.onSaveDocumentType.bind(this)    
    this.editDocumentType         = this.editDocumentType.bind(this)
    this.onCloseEditDocumentType  = this.onCloseEditDocumentType.bind(this)    
    this.onCancelEditDocumentType = this.onCancelEditDocumentType.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {
    this.fetchData()    
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({loading: true})

    const documentTypes = await SostaOnlineApiService.fetchDocumentTypes()    
        
    if ( documentTypes != null && documentTypes.data != null  )
      this.setState({ documentTypes: documentTypes.data })

    this.fetchThingsToDo()

    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSaveDocumentType (documentTypeId) {        
    this.setState({submitting: true, error: null, success: false })
    await this.fetchData()
    this.setState({submitting: false, error: false, success: true, selectedDocumentTypeId: documentTypeId})           
  }


  /**
   * --------------------------------------------------------------------------
   * @param {*} evt 
   */
  editDocumentType(id) {    
    this.setState({
      selectedDocumentTypeId: id,      
    })

    this.scrollToDetail()
  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditDocumentType() {
    this.setState({
      selectedDocumentTypeId: null,
      showEditDocumentTypeModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   */
  async onCancelEditDocumentType() {

  }


  /**
   * --------------------------------------------------------------------------
   */
  windowMaximize() {
    this.setState({ showEditDocumentTypeModal: true})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
   scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }



  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {    

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,
      documentTypes,
      showEditDocumentTypeModal,
      selectedDocumentTypeId,      
      showMaximizedList
    } = this.state


    var documentTypesSorted = documentTypes.sort((a, b) => (a.id > b.id ) ? 1 : -1)


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <ResellerSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em" , marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={ e => { this.editDocumentType(null) } } ><FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle} />
            <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Crea Tipo Documento</span></button>
          </div>
        </div>
        <div className="row" style={{  padding: 0,  margin: 0 }}>

          { showMaximizedList ?           
          <div className={ showEditDocumentTypeModal ? "box col-md-12" :  "box col-md-5" } style={{ padding: "0.3em", margin: 0 , marginBottom: "0.3em" }}>
            
            <div className="row resellerSettingsHeader" >
              <div className="col-xs-2"><label>Id</label></div>
              <div className="col-xs-8"><label>Nome</label> </div>       
              <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>
                 <FontAwesomeIcon  size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={ () => { this.setState({showMaximizedList: false}) } } />
              </div>                  
            </div>
                      
            {documentTypesSorted.map(documentType =>              
              <OverlayTrigger key={`documentType-${documentType.id}`}  placement="top" overlay={<Tooltip id={'tooltip-documentType-'+documentType.id}>Clicca per modificare i dati del Tipo Documento</Tooltip>}>
                <div className={ selectedDocumentTypeId == documentType.id ? "resellerSettingsRowSelected row" :  "resellerSettingsRow row"}  >
                  <div className="col-xs-12 row">
                    <div className="col-xs-2" onClick={ evt => { this.editDocumentType(documentType.id)} } ><label >{documentType.id}</label></div>                  
                    <div className="col-xs-8" onClick={ evt => { this.editDocumentType(documentType.id)} } ><label >{documentType.name}</label></div>                  
                    <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>{ selectedDocumentTypeId == documentType.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }</div>
                  </div>
                </div>
              </OverlayTrigger>
            )}

            <div className="row resellerSettingsFooter" >
              <div></div>
            </div>
          
            {submitting && <div className="text-primary col-xs-12 ">
              Salvataggio in corso
            </div>}

            {error && <div className="text-danger col-xs-12">
              Si sono verificati degli errori
            </div>}

            {success && <div className="text-success col-xs-12">
            
            </div>}
            
            
          </div>
          :
          <div className="col-md-1" style={{ padding: 0, margin: 0 , marginBottom: "0.3em" }}>
            <div className="mainBtn" onClick={ () => { this.setState({showMaximizedList: true}) } } >
              <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretDown} />
            </div>
          </div>
          }
        
          { !showEditDocumentTypeModal && 
          <div className={ showMaximizedList ? "col-md-7": "col-md-11"} style={{  padding: "0.3em" , margin: 0 }}>
            <Element name="detail"/>
            <div className="resellerSettingsBox" >
              <div className="resellerSettingsBoxHeader row text-center">
                <div className="col-xs-1 pull-left">
                { selectedDocumentTypeId != null 
                    ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                    : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
                  }                
                </div>          
                <div className="col-xs-10 pull-left" >
                  <strong>{ selectedDocumentTypeId != null ? "MODIFICA" : "CREA NUOVO" } TIPO DOCUMENTO </strong>
                </div>
                <div className="col-xs-1 pull-right" onClick={ evt => this.windowMaximize() }>
                  <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                    <FontAwesomeIcon  size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row" >
                <ResellerEditDocumentType
                    documentTypeId={selectedDocumentTypeId}
                    onSaveDocumentType={this.onSaveDocumentType}      
                    onDeleteDocumentType={this.onSaveDocumentType}    
                    onCancelEditDocumentType={this.onCancelEditDocumentType}
                    />
              </div>
            </div>
          </div>        
          }
        </div>
        
        <ResellerEditDocumentTypeModal
          show={showEditDocumentTypeModal}
          documentTypeId={selectedDocumentTypeId}
          onSaveDocumentType={this.onSaveDocumentType}      
          onDeleteDocumentType={this.onSaveDocumentType}              
          onCancelEditDocumentType={this.onCancelEditDocumentType}
          onClose={this.onCloseEditDocumentType}
          />

      </React.Fragment>

      
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSettingsDocumentTypes)
