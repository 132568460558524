import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ResellerMarkDocumentsReview from './ResellerMarkDocumentsReview'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
const thisRoute = 'reseller'

const fileDownload = require('js-file-download')

export default class ResellerMarkDocumentsReviewContainer extends Component {
  static propTypes = {
    onApprovationChanged: PropTypes.func,
    mark: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userName: PropTypes.string
  }

  constructor (props) {
    super(props)

    this.state = {
      selectedRows: [],
      loading: false,
      documents: [],
      error: null
    }
    this.onRowSelected = this.onRowSelected.bind(this)
    this.onDocumentDownloadClick = this.onDocumentDownloadClick.bind(this)
    this.onDocumentPreviewClick = this.onDocumentPreviewClick.bind(this)
    this.onDocumentApprovationChange = this.onDocumentApprovationChange.bind(this)
  }

  onRowSelected (index, row) {
    const newSelectedRows = []
    newSelectedRows.push(index)
    this.setState({selectedRows: newSelectedRows})
  }

  async onDocumentDownloadClick (document) {
    const {userName} = this.props
    //console.log(document)
    const documentResponse = await SostaOnlineApiService.downloadDocument(document.DocumentId,thisRoute)
    fileDownload(documentResponse, userName + '_' + document.DocumentType.name + '.' + document.Document.mimeType.split('/')[1])
  }

  onDocumentPreviewClick (document) {
    //console.log(document)
  }

  async onDocumentApprovationChange (document) {
    //console.log(document, !document.approved)
    try {
      this.setState({loading: true})
      await SostaOnlineApiService.updateMarkDocumentApprovation(document.DocumentId, this.props.mark.id, !document.approved,thisRoute)
      
      if (this.props.onApprovationChanged) {
        await this.props.onApprovationChanged()
      }
      this.setState({loading: false})
    } catch (e) {
      this.setState({error: 'Errore nella modifica dell\'approvazione.', loading: false})
    }
  }

  render () {
    const {
      open,
      onClose,
      mark
    } = this.props
    const {
      selectedRows,
      loading,
      documents,
      error
    } = this.state

    return (
      <ResellerMarkDocumentsReview
        mark={mark}
        onClose={onClose}
        open={open}
        loading={loading}
        onRowSelected={this.onRowSelected}
        selectedRows={selectedRows}
        documents={documents}
        error={error}
        onDocumentDownloadClick={this.onDocumentDownloadClick}
        onDocumentPreviewClick={this.onDocumentPreviewClick}
        onDocumentApprovationChange={this.onDocumentApprovationChange}
      />
    )
  }
}
