import React, { Component } from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import { Oval } from 'react-loader-spinner'
import { actions as authActions } from '../../../redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report'
import BackOfficeSettingsMenuComponent from 'components/BackOfficeSettingsMenuComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import * as regularIcons from '@fortawesome/free-regular-svg-icons'
import { ADMIN, OPERATOR, RESELLER, USER } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BackofficeEditAdminUser from 'components/AdminUsers/BackofficeEditAdminUser'
import BackofficeEditAdminUserModal from 'components/AdminUsers/BackofficeEditAdminUserModal'
import { scroller, Element } from 'react-scroll'


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport: state.report
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class BackofficeSettingsAdminUsers extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,
      users: [],
      selectedUserId: null,
      showEditAreaModal: false,
      showMaximizedList: true,
      resellers: []
    }

    this.onSaveAdminUser = this.onSaveAdminUser.bind(this)
    this.editUserAdmin = this.editUserAdmin.bind(this)
    this.onCloseEditAdminUserModal = this.onCloseEditAdminUserModal.bind(this)
    this.onCancelEditAdminUser = this.onCancelEditAdminUser.bind(this)
    this.onWaitingResellers = this.fetchWaitingResellers.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount() {
    this.fetchData()
    this.fetchWaitingResellers()
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({ loading: true })

    const users = await SostaOnlineApiService.fetchPureUsersList()

    if (users != null && users.data != null)
      this.setState({ users: users.data })

    this.fetchThingsToDo()

    this.setState({ loading: false })
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo() {
    SostaOnlineApiService.fetchThingsToDo().then(data => {
      this.props.reportActions.fetchThingsToDo(data.data)
    })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  // ----------------------------------------------------------------
  //
  //
  fetchWaitingResellers() {
    var newResellers = this.state.resellers
    SostaOnlineApiService.fetchResellerWaitingList()
      .then(data => {
        //console.log("fetchWaitingResellers: ", data)
        newResellers = data.data
        // Store the result in state
        this.setState({ resellers: newResellers })
        //console.log ( newResellers)
      })
      .catch(error => {
        console.log("ERROR", error)
      })
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSaveAdminUser(userId) {
    this.setState({ submitting: true, error: null, success: false, selectedUserId: userId })
    await this.fetchData()
    this.setState({ submitting: false, error: false, success: true, selectedUserId: userId })
  }


  /**
   * --------------------------------------------------------------------------
   * @param {*} evt 
   */
  editUserAdmin(id) {
    this.setState({
      selectedUserId: id,
    })

    this.scrollToDetail()
  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditAdminUserModal() {
    this.setState({
      selectedUserId: null,
      showEditAreaModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   */
  async onCancelEditAdminUser() {

  }


  /**
   * --------------------------------------------------------------------------
   */
  windowMaximize() {
    this.setState({ showEditAreaModal: true })
  }

  /**
   * --------------------------------------------------------------------------
   */
  getSortedAdminUsers() {

    var users = []

    if (this.state.users != null) {
      users = this.state.users.filter(u => u.role == ADMIN || u.role == OPERATOR || u.role == RESELLER).sort((a, b) => (a.id > b.id) ? 1 : -1)
    }

    return users
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }


  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render() {

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,
      users,
      showEditAreaModal,
      selectedUserId,
      showMaximizedList,
      resellers
    } = this.state


    var usersSorted = this.getSortedAdminUsers()


    if (loading) {
      return (
        <React.Fragment>
          <h3><strong>Caricamento in corso...</strong></h3>
          <div className="row" style={{ padding: "2em", height: "40em" }}>
            <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
          </div>
        </React.Fragment>
      )
    }
    if (error) {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if (this.props.auth.user.role != ADMIN) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    var waitingResellers = (resellers ? Object.keys(resellers).length : 0)

    return (
      <React.Fragment>
        <BackOfficeSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em", marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={e => { this.editUserAdmin(null) }} ><FontAwesomeIcon size="1x" icon={solidIcons.faPlusCircle} />
              <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Assegna Ruolo</span></button>
          </div>

            {
            /* piccolo box di testo non editabile che contiene il numero di reseller in attesa e il loro nome e cognome */
            waitingResellers > 0 &&
              <div className="pull-right small bottom" style={{ margin: "1em 1.5em 0.5em 1em", position: "relative", top: "2vh", bottom: 0 }}>
              <strong>Reseller in attesa</strong>
              {resellers && Object.values(resellers).map(reseller => (
                <span key={reseller.id}>, {reseller.firstName} {reseller.lastName} </span>
              ))}
              </div>
            }

        </div>
        <div className="row" style={{ padding: 0, margin: 0 }}>

          {showMaximizedList ?
            <div className={showEditAreaModal ? "box col-md-12" : "box col-md-4"} style={{ padding: "0.3em", margin: 0, marginBottom: "0.3em" }}>

              <div className="row backofficeSettingsHeader" >
                <div className="col-xs-2"><label>Id</label></div>
                <div className="col-xs-5"><label>Nome</label> </div>
                <div className="col-xs-4" style={{ textAlign: "center" }}><label>Ruolo</label> </div>
                <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle" }}>
                  <FontAwesomeIcon size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={() => { this.setState({ showMaximizedList: false }) }} />
                </div>
              </div>

              {usersSorted.map(user =>
                <OverlayTrigger key={`user-${user.id}`} placement="top" overlay={<Tooltip id={'tooltip-user-1-' + user.id}>Clicca per modificare i dati dell'operatore</Tooltip>}>
                  <div className={selectedUserId == user.id ? "backofficeSettingsRowSelected row" : "backofficeSettingsRow row"} >
                    <div className="col-xs-12 row" style={{ padding: 7 }}>
                      <div className="col-xs-2" onClick={evt => { this.editUserAdmin(user.id) }} ><label>{user.id}</label></div>
                      <div className="col-xs-5" onClick={evt => { this.editUserAdmin(user.id) }} ><label>{user.firstName} {user.lastName}</label></div>
                      <div className="col-xs-4" onClick={evt => { this.editUserAdmin(user.id) }} style={{ textAlign: "center", verticalAlign: "middle", borderRadius: "0.3em", color: "white", backgroundColor: user.role == ADMIN ? "#cc0000" : user.role === RESELLER ? "#f5009b" : "orange" }} ><label style={{ marginBottom: 0 }}>{user.role.toUpperCase()}</label></div>
                      <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle" }}>{selectedUserId == user.id ? <FontAwesomeIcon size="lg" icon={solidIcons.faCaretRight} /> : ""}</div>
                    </div>
                  </div>
                </OverlayTrigger>
              )}

              <div className="form-group row backofficeSettingsFooter" >
                <div className="col-md-12 col-form-label" ></div>
              </div>

              {submitting && <div className="text-primary row">
                Salvataggio in corso
              </div>}

              {error && <div className="text-danger row">
                Si sono verificati degli errori
              </div>}

              {success && <div className="text-success row">

              </div>}


            </div>
            :
            <div className="col-md-1" style={{ padding: 0, margin: 0, marginBottom: "0.3em" }}>
              <div className="mainBtn" onClick={() => { this.setState({ showMaximizedList: true }) }} >
                <FontAwesomeIcon size="lg" icon={solidIcons.faCaretDown} />
              </div>
            </div>
          }

          {!showEditAreaModal &&
            <div className={showMaximizedList ? "col-md-8" : "col-md-11"} style={{ padding: "0.3em", margin: 0 }}>
              <Element name="detail" />
              <div className="backofficeSettingsBox" >
                <div className="backofficeSettingsBoxHeader row text-center">
                  <div className="col-xs-1 pull-left">
                    {selectedUserId != null
                      ? <FontAwesomeIcon className="IconsRevNoAction" size="1x" icon={solidIcons.faEdit} />
                      : <FontAwesomeIcon className="IconsRevNoAction" size="1x" icon={solidIcons.faPlusCircle} />
                    }
                  </div>
                  <div className="col-xs-10 pull-left" >
                    <strong>{selectedUserId != null ? "MODIFICA" : "ASSEGNA NUOVO"} RUOLO </strong>
                  </div>
                  <div className="col-xs-1 pull-right" onClick={evt => this.windowMaximize()}>
                    <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                      <FontAwesomeIcon size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="row" >

                  <BackofficeEditAdminUser
                    userId={selectedUserId}
                    users={users}
                    onSaveAdminUser={this.onSaveAdminUser}
                    onDeleteAdminUser={this.onSaveAdminUser}
                    onCancelEditAdminUser={this.onCancelEditAdminUser}
                  />

                </div>
              </div>
            </div>
          }
        </div>
        <BackofficeEditAdminUserModal
          show={showEditAreaModal}
          userId={selectedUserId}
          users={users}
          onSaveAdminUser={this.onSaveAdminUser}
          onDeleteAdminUser={this.onSaveAdminUser}
          onCancelEditAdminUser={this.onCancelEditAdminUser}
          onClose={this.onCloseEditAdminUserModal}
        />
      </React.Fragment>


    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeSettingsAdminUsers)
