import React, { Component }     from 'react'
import SostaOnlineApiService    from '../../../services/SostaOnlineApiService'
import moment                   from 'moment'
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import  * as solidIcons         from '@fortawesome/free-solid-svg-icons'
import {actions as authActions} from '../../../redux/modules/Auth'
import { Oval }                 from 'react-loader-spinner'
import { bindActionCreators }   from 'redux'
import {connect}                from 'react-redux'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";


const fileDownload = require('js-file-download')

const TextUser = (props) => <div className={props.bold ? 'text-bold-user' : 'text-regular-user'} style={{textAlign: 'center'}}>{props.children}</div>


const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch)    
  }
}


class OrdersHistory extends Component {
  static propTypes = {}

  /**
   *  --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      downloading: null,
      orders: [],
      news: []
    }
  }

  /**
   * --------------------------------------------------------------
   *
   */
  async componentDidMount () {
    this.setState({loading: true})
    await this.fetchOrdersHistory()
    await this.fetchSettings()
    await this.fetchNews()   
    this.setState({loading: false})
  }

  /**
   *  --------------------------------------------------------------
   * 
   */
  async fetchNews() {
    const response = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERORDERS.value)       
    this.setState({
      news: response.data
    })      
  }

  /**
   *  --------------------------------------------------------------
   * 
   */
  async fetchSettings() {
    const response = await  SostaOnlineApiService.fetchCitySettings()
    this.setState({
      city: response.data
    })      

  }

  /**
   *  --------------------------------------------------------------
   * 
   */  
  async fetchOrdersHistory () {
    const response = await SostaOnlineApiService.fetchThisMarkOrdersHistory()
    this.setState({
      orders: response.data
    })
  }

  /**
   *  --------------------------------------------------------------
   * @param {*} item 
   */
  onReceiptDownload (item) {
    if (item.MarkOrders.length > 0) {
      
      const markId = item.MarkOrders[0].markId
      this.setState({ downloading: item.id})

      SostaOnlineApiService.downloadMarkReceiptMe(markId).then(data => {
        fileDownload(data, 'ricevuta-permesso-' + markId + '.pdf', 'application/pdf')
        this.setState({ downloading: null})
      })
      .catch( error => {
        this.setState({ downloading: null})
      })
    } 
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markId 
   */
  getUserMark(markId) {

    var mark = null 
    
    if ( this.props.user.marks )  {
      var filter = this.props.user.marks.filter( item => item.id === markId )
    
      if ( filter && filter.length > 0 )
        mark = filter[0]

    }

    return mark 

  }

  /**
   * --------------------------------------------------------------
   * @param {*} markId 
   * @returns 
   */
  getMarkType(markId) {
    var marktype = { name : '', label: '' } 
    
    var mark =  this.getUserMark(markId)
    
    if ( mark && mark.MarkType )
      marktype = mark.MarkType

    return marktype
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markId 
   * @returns 
   */
  getMarkArea(markId) {
    var markArea = { name : '' } 
    
    var mark =  this.getUserMark(markId)
    
    if ( mark && mark.MarkType && mark.MarkType.Areas && mark.MarkType.Areas.length > 0 ) {
      markArea = mark.MarkType.Areas[0]
    }

    return markArea
  }
  

  /**
   *  --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {loading, orders, downloading, news } = this.state
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-8">
            
            <div className="panel panel-default panel-border">
              
              <h2 className="login-title">
                STORICO ACQUISTI
              </h2>
              
              <div className="panel-body">
                <div className="col-md-3">
                  <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faCreditCard} />
                </div>
                                  
                { loading 
                  ? ( <div>
                    <div className="row"><h3></h3></div>
                    <div className="row" style={{padding: "2em", height: "40em"}}>
                      <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
                    </div>
                  </div> ) 
                  : orders.map((item, index) => (
                    
                    <div key={"order-history-"+index} className={'col-md-9' + (index > 0 ? ' col-md-push-3' : '')} >
                      { downloading === item.id && 
                        <div className="card-order-history col-md-12">
                          <div className="row" style={{padding: "2em", height: "15em"}}>
                            <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
                          </div>                    
                        </div>
                      }
                      { downloading !== item.id &&
                      <div className="card-order-history col-md-12">

                        <div>
                          <h5>
                            <div className="text-center"><strong>Ordine:&nbsp; &nbsp;</strong>
                            {'SOSTA_'+this.state.city.name.toUpperCase()+"-"+item.id}
                            </div>
                          </h5>
                        </div>

                        {item.MarkOrders.length > 0 && 
                        <div className="row"   >
                            <h4>
                              <strong>PERMESSO N°&nbsp;{item.MarkOrders[0].markId}</strong>
                            </h4>
                            <h5>
                              <strong>TIPO: &nbsp;</strong>{ this.getMarkType(item.MarkOrders[0].markId).label }                              
                            </h5>
                            <h5>
                              <strong>ZONA: &nbsp;</strong>{ this.getMarkArea(item.MarkOrders[0].markId).name }                              
                            </h5>
                        </div>

                        }
                      
                        <div className="row" >

                          { item.Receipt &&
                            <div className= "col-sm-4 col-md-6 col-lg-4 col-xl-4" >
                              <h5>
                                <strong>Costo: €&nbsp;</strong>{ Number(item.Receipt.total).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                              </h5>
                            </div>
                          }
                                                    
                          <div className={ item.Receipt ? "col-sm-4 col-md-6 col-lg-4 col-xl-4" : "col-sm-6 col-md-6 col-lg-6 col-xl-6" }>
                            <h5><strong> Data:&nbsp;</strong>{moment(item.updatedAt).format('L')}</h5>
                          </div>

                          <div className={ item.Receipt ? "col-sm-4 col-md-12 col-lg-4 col-xl-4" : "col-sm-6 col-md-6 col-lg-6 col-xl-6" } >
                            <h5>
                            <strong>Stato:&nbsp;</strong>
                            {item.status ===  0 && (<span className="label label-primary">In Attesa</span>)}
                            {item.status ===  1 && (<span className="label label-success">Completato</span>)}
                            {item.status === -1 && (<span className="label label-danger">Rifiutato</span>)}
                            </h5>
                          </div>
                        </div>

                        { item.Receipt &&                         
                          <div className="row"  >
                            <div className= "col-md-12" >
                                  <h5>
                                    <a onClick={() => this.onReceiptDownload(item)} className="btn mainBtn">
                                      <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;Ricevuta
                                    </a>
                                  </h5>
                            </div>                        
                          </div>
                        }
                      </div>                  
                      }                            
                    </div>    
                  ))                   
                }                               
              </div>
            </div>
          </div>

          <div className="col-md-4">

            <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
              <div style={{width:'85%'}}>
                <div style={{marginTop: 15, marginBottom: 10, display:'flex', alignItems: 'center', justifyContent:'center'}}>
                  <span className="threesom-span">
                    <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
                  </span>
                  <div>
                    <TextUser bold>ORDINI</TextUser>
                    <TextUser>
                      Questi sono i tuoi ordini di acquisto. Per quelli andati a buon fine trovi allegate e scaricabili le relative ricevute di pagamento
                    </TextUser>
                  </div>
                </div>
              </div>
            </div>

            {news != null && news.length > 0 && 
            <div className="information-container">
              <div style={{marginLeft: 10}}>
                <div style={{marginTop: "5em", marginBottom: 10}}>
                  <TextUser bold>COMUNICAZIONI</TextUser>
                  <div style={{ margin: 0 }}>
                    
                    <Carousel
                      autoPlay
                      interval={8000}
                      infiniteLoop
                      stopOnHover
                      emulateTouch
                      showThumbs={false}
                    >
                    { news.map(  n => 
                        <div className={"boxLabel " + (getNewsStyle(n))}>
                          {n.thumbImage == null ? (
                          <div>
                            {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                            { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                          </div>
                          ):(
                            <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={n.thumbImage}></img>
                          )}
                        </div>     
                    )} 
                    </Carousel>
                    
                  </div>
                </div>
              </div>
            </div>
            }

          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory)