import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import RequestConfirmModal from 'components/General/RequestConfirmModal';//

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { RESELLER } from 'libs/roles';
const thisRoute = 'reseller'


moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class ResellerEditCategory extends Component {

  static propTypes = {    
    categoryId:           PropTypes.number,    
    onSaveCategory:       PropTypes.func.isRequired,
    onDeleteCategory:     PropTypes.func.isRequired,
    onCancelEditCategory: PropTypes.func.isRequired,
  }

  

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      category: this.getNullCategory(),
      categories: [],
      checkErrors: null,
      sendErrors: null,
      sendMsg: null,
      showConfirmDeleteCategory: false
    }

    this.saveCategory = this.saveCategory.bind(this)
    this.checkFields  = this.checkFields.bind(this)  
    this.cancelEditCategory = this.cancelEditCategory.bind(this)
    this.setCategoryResident = this.setCategoryResident.bind(this)
    this.deleteCategory = this.deleteCategory.bind(this)
    this.getNullCategory = this.getNullCategory.bind(this)
    this.getParentIdSelectOptions = this.getParentIdSelectOptions.bind(this)
   
  }

  /**
   * --------------------------------------------------------------
   */
  getNullCategory() {

    var nullCategory = {
      id: null, 
      name: "", 
      label: "" ,
      description: "",    
      imageUrl: null,
      resident: false,
      worker: false,
      workerPerson: false,      
      standard: true,        
      ParentId: null,
      domiciled: false,
      enableRegistration: false
    }

    return nullCategory

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      category: this.getNullCategory(),
      sendMsg: null,
      checkErrors: null,
      sendErrors: null,
      showConfirmDeleteCategory: false, 
      categories: []
    })    
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {
    this.stateInit()
    await this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    if ( prevProps.categoryId != this.props.categoryId ) {
      this.stateInit()
      await this.fetchData()
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {

    if ( this.props.categoryId != null ) {
      var category = await SostaOnlineApiService.fetchUserCategoryById(this.props.categoryId)

      if ( category != null ) {
        this.setState({category: category.data})
      }
      else {
        this.setState({category: this.getNullCategory()})
      }      
    } 
    else {
      this.setState({ category: this.getNullCategory()})
    }   

    var categories = await SostaOnlineApiService.fetchFlatCategories()

    if ( categories != null ) {
      this.setState({categories: categories.data})
    }
    else {
      this.setState({categories: []})
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors   = []   
    var category = this.state.category    

    if ( category.name == null || category.name.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }

    category.name = category.name.trim()

    if ( category.label == null || category.label.trim().length <= 0 ) {
      errors.push("Label non specificata")
    }

    category.label = category.label.trim()

    if ( category.description == null || category.description.trim().length <= 0 ) {
      errors.push("Descrizione non specificata")
    }

    if ( category.ParentId != null && category.id != null ) {
      //console.log("PARENT CATEGORY",category.ParentId)

      var childs = this.state.categories.filter( checkCategory => checkCategory.ParentId == category.id )

      if ( childs.length > 0  ) {
        errors.push("La categoria ha già delle sotto categorie, non può avere una categoria padre")
      }

    }

    category.description = category.description.trim()

    this.setState({ category: category })

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  saveCategory() {
        
    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    if ( this.state.category.id == null  ) {
            
      SostaOnlineApiService.createCategory(this.state.category,thisRoute)
      .then( result => {            
        
        var categoryId = null

        if ( result.data != null && result.data.id != null )
          categoryId =  result.data.id
        
        if ( this.props.onSaveCategory != null ) {
          this.props.onSaveCategory(categoryId)
        }

        //console.log("set Ok message") 

        this.setState({ sendMsg: "Categoria creata con successo"})
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: error.error != null ? error.error : "Errore durante Il salvataggio, riprovare più tardi!" })
      })
            

    }
    else {
      
      SostaOnlineApiService.updateCategory(this.state.category,thisRoute)
      .then( result => {      
        
        if ( this.props.onSaveCategory != null ) {
          this.props.onSaveCategory(this.props.categoryId)
        } 

        //console.log("set Ok message") 

        this.setState({ sendMsg: "Categoria salvata con successo"})
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: error.error != null ? error.error : "Errore durante Il salvataggio, riprovare più tardi!" })
      })      

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  deleteCategory() {    

    if ( this.props.categoryId != null  ) {

      SostaOnlineApiService.deleteCategory(this.props.categoryId,thisRoute)
      .then( result => {      
        
        if ( this.props.onDeleteCategory != null ) {
          this.props.onDeleteCategory()
        }        

      })
      .catch( error => {
        console.log("ERROR",error)

        if ( error.error != null ) {
          this.setState({ sendErrors: error.error })
        }
        else {
          this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
        }
        
      })
            
    }

    this.setState({ showConfirmDeleteCategory : false })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setCategoryName(name) {
    var category = this.state.category
    category.name=name
    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} description 
   */
  setCategoryDescription(description) {
    var category = this.state.category
    category.description=description
    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} label 
   */
  setCategoryLabel(label) {
    var category = this.state.category
    category.label=label
    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} resident 
   */
  setCategoryResident(resident) {
    var category = this.state.category
    category.resident = resident

    if (  resident ) 
      category.standard = false

    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} domiciled 
   */
  setCategoryDomiciled(domiciled) {
    var category = this.state.category
    category.domiciled = domiciled

    if (  domiciled ) 
      category.standard = false

    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} worker 
   */
  setCategoryWorker(worker) {
    var category = this.state.category
    category.worker = worker

    if (  worker ) 
      category.standard = false

    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} workerPerson 
   */
  setCategoryWorkerPerson(workerPerson) {
    var category = this.state.category    
    category.workerPerson = workerPerson

    if (  workerPerson ) 
      category.standard = false

    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} standard 
   */
  setCategoryStandard(standard) {
    var category = this.state.category
    category.standard = standard

    if ( standard ) {
      category.worker = false
      category.workerPerson = false
      category.resident = false
      category.domiciled = false
    }

    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} enableRegistration 
   */
  setCategoryEnableRegistration(enableRegistration) {
    var category = this.state.category
    category.enableRegistration = enableRegistration
    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} parentId 
   */
  setCategoryParentId(parentId) {
    var category = this.state.category
    category.ParentId = parentId == "null" ? null : parentId
    this.setState({category: category})
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async cancelEditCategory() {
    this.stateInit()
    await this.fetchData()

    if ( this.props.onCancelEditCategory != null )
      this.props.onCancelEditCategory()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} categoryId 
   * @returns 
   */
  getParentIdSelectOptions(categoryId) {
    var categories = this.state.categories.filter( cat => {      
      return (cat.ParentId == null && ( categoryId == null || cat.id != parseInt(categoryId) ))
    })

    return categories.map( cat => {
      return (<option key={"ParentIdSelectOptions"+cat.id} value={cat.id}>{cat.name}</option>)
    })
  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {            
      categoryId 
    } = this.props

    const {
      sendErrors,
      checkErrors,
      category,      
      showConfirmDeleteCategory,
      sendMsg,      
    } = this.state


    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    /* 
    Da qualche parte REACT mi azzera la categoria e non c'è verso di capire dove ... 
    ho dovuto inizializzarla qui per correggere il problema
    */
    if ( category == null ) {
      this.setState({ category: this.getNullCategory()})
    }
      
    return (
      <React.Fragment>
        
        { category == null &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { category != null &&
        <div className="col-xs-12" style={{ padding: "0em" , paddingBottom: "2em" , paddingLeft: "2em", paddingRight: "2em" }}>                      
            { category.id != null &&
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}><strong>Id:</strong></h5>
              </div>
              
              <div className="col-xs-9">
                <h5 style={{ marginTop: 5 , marginBottom: 5, marginLeft: 15, verticalAlign: "top" }}><strong>{category.id}</strong></h5>
              </div>
            </div>
            }
            
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome:</strong></h5>
              </div>
              
              <div className="col-xs-9" >
                <input
                  name="name" 
                  className="form-control" 
                  type="text" 
                  onChange={ e => {  this.setCategoryName(e.target.value) }}
                  value={category.name}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Label:</strong></h5>
              </div>
              
              <div className="col-xs-9" >
                <input
                  name="label" 
                  className="form-control" 
                  type="text" 
                  onChange={ e => {  this.setCategoryLabel(e.target.value) }}
                  value={category.label}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Descrizione:</strong></h5>
              </div>
              
              <div className="col-xs-9" >
                <input
                  name="description" 
                  className="form-control" 
                  type="text" 
                  onChange={ e => {  this.setCategoryDescription(e.target.value) }}
                  value={category.description}
                  required />
              </div>
            </div>
            
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Categoria padre:</strong></h5>
              </div>
              
              <div className="col-xs-8" >
                <select
                  name="parentId" 
                  className="form-control" 
                  onChange={ e => {  this.setCategoryParentId(e.target.value) }}
                  value={category.ParentId == null ? "null" :  category.ParentId }
                  required >
                    <option value="null">-</option>
                    { this.getParentIdSelectOptions(categoryId) }
                </select>
              </div>                                
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>                                
              <div className="col-xs-1" >
              <input
                  style={{ width: "2em"}}
                  name="resident" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryResident(e.target.checked) }}
                  checked={category.resident}
                  />
              </div>

              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Residente</strong></h5>
              </div>
              
              <div className="col-xs-1" style={{ marginLeft: "1em" }}>
                <input
                  style={{ width: "2em"}}
                  name="domiciled" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryDomiciled(e.target.checked) }}
                  checked={category.domiciled}
                  />
              </div>

              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Domiciliato</strong></h5>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-1" >
              <input
                  style={{ width: "2em"}}
                  name="worker" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryWorker(e.target.checked) }}
                  checked={category.worker}
                  />
              </div>

              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Azienda</strong></h5>
              </div>
                              
              <div className="col-xs-1" style={{ marginLeft: "1em" }}>
              <input
                  style={{ width: "2em"}}
                  name="workerPerson" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryWorkerPerson(e.target.checked) }}
                  checked={category.workerPerson}
                  />
              </div>

              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Lavoratore</strong></h5>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-1" >
              <input
                  style={{ width: "2em"}}
                  name="standard" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryStandard(e.target.checked) }}
                  checked={category.standard}
                  />
              </div>

              <div className="col-xs-3">
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Standard</strong></h5>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-1" >
              <input
                  style={{ width: "2em"}}
                  name="enableRegistration" 
                  className="form-control" 
                  type="checkbox" 
                  onChange={ e => {  this.setCategoryEnableRegistration(e.target.checked) }}
                  checked={category.enableRegistration}
                  />
              </div>

              <div className="col-xs-7">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-user-registration-show"}>
                  Se spuntata serve a far comparire la categoria tra quelle selezionabili dall’utente che sta procedendo alla sua prima registrazione sul portale.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Mostra in fase di registrazione utente</strong> <span className="glyphicon glyphicon-info-sign">{}</span></h5>
                </OverlayTrigger>
              </div>
            </div>
                                              
            <div className="row" style={{ marginBottom: "1em" , marginTop: "1em"}}>
              { sendMsg != null && 
                <h4 className="col-md-12 text-confirm text-center" style={{ marginBottom: "1em" }}>
                  {sendMsg} 
                </h4>
              }
              { sendErrors != null && 
                <h4 className="col-md-12 text-danger text-center" style={{ marginBottom: "1em" }}>
                  {sendErrors}
                </h4>
              }
              { checkErrors != null && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{ marginBottom: "1em" }}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-md-12 text-center">
                <Button className="btn alertBtn" onClick={() => { this.cancelEditCategory() }}>Annulla</Button>{' '}
                <Button className="btn mainBtn"  onClick={() => { this.saveCategory() }} >Salva</Button>
                { categoryId &&
                <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDeleteCategory: true })} }><FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                }
              </div>
            </div>          
            
            
          
        </div>           
        }  

        { category != null && 
        <RequestConfirmModal
            requestStyle={2}
            requestTitle="Conferma Eliminazione Categoria"
            requestSubTitle="ATTENZIONE"
            requestMsg={"Proseguendo con l'operazione si eliminerà la categoria <strong>"+ category.name +"</strong>.<br>L'operazione è irreversibile, si vuole procedere?"}
            show={showConfirmDeleteCategory}
            onConfirm={this.deleteCategory}
            onRefuse={ () => {this.setState({ showConfirmDeleteCategory: false }) }}
        />
        }
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerEditCategory'})(ResellerEditCategory))
