import React, { Component } from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import Loading from '../../../components/LoadingComponent'
import { getActiveMarks, VEHICLE_STATUS_APPROVED, VEHICLE_STATUS_DONE } from 'libs/vehicles'
import moment from 'moment'
import { reduxForm, Field } from 'redux-form'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { MARK_MODIFY_PRICE } from 'libs/settings'


/**
 * -------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {

  }
}

/**
 * -------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {

  }
}


/**
 * -------------------------------------------------------------------------
 * 
 */
class VehicleChangeRequestPayment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paymentData: {},
      vehicleChangeRequest: {},
      loading: true,
      error: null,
      total: 0,
      markModifyPrice: 0
    }

    if (this.state.payment == null) {
      SostaOnlineApiService.getPaymentConfig().then(data => {
        this.state.payment = data
      })
    }

    this.fetchData = this.fetchData.bind(this)
    this.onPayNexi = this.onPayNexi.bind(this)
    this.onPayXpay = this.onPayXpay.bind(this)
    this.onPaySella = this.onPaySella.bind(this)
    this.onPay = this.onPay.bind(this)

  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  fetchData() {

    this.setState({ loading: true })

    SostaOnlineApiService.fetchMyVehiclesChangePendingOrders(this.props.match.params.id)
      .then((data) => {
        this.setState({
          vehicleChangeRequest: data.data,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false
        })
        console.log("ERROR", error)
      })

    this.setState({ loading: true })

    SostaOnlineApiService.fetchPublicSettings()
      .then(settings => {
        var found = settings.data.filter(setting => setting.name == MARK_MODIFY_PRICE)
        if (found && found.length > 0) {
          this.setState({
            markModifyPrice: parseFloat(found[0].value).toFixed(2)
          })
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log("ERROR", error)
      })
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  componentDidMount() {
    this.fetchData()
  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  async onPay(e) {

    switch (this.state.payment.module) {

      case "nexi":
        this.onPayNexi(e)
        break;

      case "xpay":
        this.onPayXpay(e)
        break;

      case "sella":
        this.onPaySella(e)
        break;

      default:
        break;
    }
  }


  /**
   * -------------------------------------------------------------------------
   * 
   */
  onPayNexi(e) {

    e.preventDefault();

    this.setState({ error: null })

    SostaOnlineApiService.buyVehicleChangeRequest(this.props.match.params.id).then(data => {

      if (data.result == "ok") {

        console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: DATA", data.data)

        this.setState({
          paymentData: data.data
        })

        this.props.initialize(data.data);

        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: alias", document.getElementById('alias').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: importo", document.getElementById('importo').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: divisa", document.getElementById('divisa').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: codTrans", document.getElementById('codTrans').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: url", document.getElementById('url').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: url_back", document.getElementById('url_back').value);
        
        // che c'entra xpayTimeout con Nexi?
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: xpayTimeout", document.getElementById('xpayTimeout').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: mac", document.getElementById('mac').value);

        document.getElementById('VehicleChangeRequestPaymentForm').submit()

      }

    })
      .catch(err => {
        console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayNexi: ERROR NEXI", err)
        //this.setState({ 'error' : err.error})
        this.setState({ error: err.error })
      })


  }

  /**
   * -------------------------------------------------------------------------
   * 
   */

  onPayXpay(e) {

    e.preventDefault();

    this.setState({ error: null })

    SostaOnlineApiService.buyVehicleChangeRequest(this.props.match.params.id).then(data => {

      if (data.result == "ok") {
        this.setState({
          paymentData: data.data,
          hostedPage: data.data.hostedPage
        })
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayXpay: paymentData", document.getElementById('paymentData').value);
        // console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayXpay: hostedPage", document.getElementById('hostedPage').value);

        window.location.href = data.data.hostedPage
      }

    })
      .catch(err => {
        console.log("containers/UserArea/Checkout/VehicleChangeRequestPayment: onPayXpay: ERROR XPAY", err)
        //this.setState({ 'error' : err.error})
        this.setState({ error: err.error })
      })


  }

  /**
   * -------------------------------------------------------------------------
   * 
   */
  onPaySella(e) {

    this.setState({ error: null })

    SostaOnlineApiService.buyVehicleChangeRequest(this.props.match.params.id).then(data => {
      this.setState({
        paymentData: data.data
      })

      // TODO compleatare sviluppo e test per Banca Sella

      //window.location.href = 'https://ecomm.sella.it/pagam/pagam.aspx?a=' + this.state.paymentData.shopLogin + '&b=' + this.state.paymentData.cryptedString

    })
      .catch(err => {
        console.log("ERROR SELLA", err.error)
        this.setState({ error: err.error })
      })

  }


  /**
   * -------------------------------------------------------------------------
   * 
   */
  render() {
    
    if (this.state.loading) {
      return (<Loading />)
    }

    const {
      vehicleChangeRequest,
      error,
      payment,
      markModifyPrice,
      hostedPage
    } = this.state

    var msg = null
    var wait = false
    var activeMarks = []
    var oldPlateNumber = ""
    var newPlateNumber = ""
    var total = 0

    if (!vehicleChangeRequest) {      
      wait = true
      msg = 'Richiesta di modifica non valida'
    }
    else if (vehicleChangeRequest.status == VEHICLE_STATUS_DONE) {      
      wait = true
      msg = 'Il pagamento risulta già effettuato'
    }
    else if (vehicleChangeRequest.status != VEHICLE_STATUS_APPROVED) {      
      wait = true
      msg = 'Il pagamento non è processabile. ritentare più tardi'
    }
    else if (     
      vehicleChangeRequest.VehiclesChangeOrders && vehicleChangeRequest.VehiclesChangeOrders.length > 0) {
      //msg = 'Una richiesta di pagamento risulta già attiva, attendere la mail di conferma del suo completamento.'
      msg = 'È stata avviata una richiesta di pagamento. Attendere la conferma via e-mail o riprovare tra 15 minuti.'
      wait = true
    }

    msg = error ? error : msg


    if (vehicleChangeRequest && vehicleChangeRequest.Vehicle) {      
      if (vehicleChangeRequest.Vehicle.Marks) {        
        activeMarks = getActiveMarks(vehicleChangeRequest.Vehicle.Marks)
      }
      activeMarks.map(mark => {        
        total = parseFloat(total) + parseFloat(markModifyPrice)
      })
      oldPlateNumber = vehicleChangeRequest.Vehicle.plate_number.toUpperCase()
      newPlateNumber = vehicleChangeRequest.plate_number.toUpperCase()
    }


    return (<div className="col-md-offset-1 col-md-10">
      <div className="panel panel-default panel-border">
        <div className="panel-body">
          <h2 className="title">Riepilogo Richiesta di Cambio Veicolo</h2>
          <h2 className="title">
            {oldPlateNumber}&nbsp;<FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faArrowRight} />&nbsp;{newPlateNumber}
          </h2>

          <div className="row">
            {activeMarks.map(mark =>
              <div key={"mark-list-" + mark.id} className="col-md-12 hidden-md hidden-lg">
                <div className="card-marks">
                  <div className="capiton cart-caption">
                    <div className="col-md-4">
                      <h4>
                        <strong>Permesso: </strong>
                        <span className="text-left">
                          {mark.MarkType.label.toUpperCase()}
                        </span>
                      </h4>

                      <h5>
                        <strong>Categoria: </strong>
                        <span>{mark.Category.label.toUpperCase()}</span>
                      </h5>

                      <h5>
                        <strong>Zon{mark.MarkType.Areas.length > 1 ? 'e' : 'a'}: </strong>
                        <span>{mark.MarkType.Areas.map(item => item.code + ' ')}</span>
                      </h5>

                      <h5>
                        <strong>Targa:  </strong>
                        <span><strong>{oldPlateNumber}&nbsp;<FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faArrowRight} />&nbsp;{newPlateNumber}</strong></span>
                      </h5>
                    </div>

                    <div className="col-md-4">
                      <h5>
                        <strong>Valido </strong>
                        <span className="text-left">
                          <strong>dal </strong> <span>{moment(mark.startDate).format('L')}</span>
                          <strong> al </strong> {moment(mark.endDate).format('L')}
                        </span>
                      </h5>
                    </div>

                    <div className="col-md-4">
                      <h4>
                        <strong>Prezzo </strong>
                        <span className="text-left">
                          € {Number(markModifyPrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Table responsive className="hidden-xs hidden-sm">
            <thead>
              <tr>
                <th>Prodotto</th>
                <th>Categoria</th>
                <th>Zone</th>
                <th>Validità</th>
                <th>Targhe</th>
                <th>Prezzo</th>
              </tr>
            </thead>
            <tbody>
              {activeMarks.map(mark =>
                <tr key={"mark-table-" + mark.id} >
                  <td className="text-left">
                    <span>
                      {mark.MarkType.label.toUpperCase()}
                    </span>
                  </td>
                  <td className="text-left">
                    {mark.Category.label.toUpperCase()}
                  </td>
                  <td className="text-left">
                    {mark.MarkType.Areas.map(item => item.code + ' ')}
                  </td>
                  <td className="text-left">
                    {moment(mark.startDate).format('L') + ' - '}
                    {moment(mark.endDate).format('L')}
                  </td>
                  <td className="text-left">
                    <strong>{oldPlateNumber}&nbsp;<FontAwesomeIcon size="1x" className="sectionIconsRev" icon={solidIcons.faArrowRight} />&nbsp;{newPlateNumber}</strong>
                  </td>
                  <td className="text-left">
                    € {Number(markModifyPrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="row">
            <div className="text-center">
              <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faCreditCard} />
              <br />
              <br />
            </div>
          </div>

          <div className="row">
            <div className="text-center">
              <h3>Totale: € {Number(total).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
            </div>
          </div>

          {msg != null &&
            <div className="row">
              <div className="text-center">
                <h3>{msg}</h3>
              </div>
            </div>
          }

          {payment.module === "nexi" && !wait &&
            <form id="VehicleChangeRequestPaymentForm" action={payment.api} method="POST">
              <fieldset hidden>
                <Field component="input" id="alias" name="alias" type="text" />
                <Field component="input" id="importo" name="importo" type="text" />
                <Field component="input" id="divisa" name="divisa" type="text" />
                <Field component="input" id="codTrans" name="codTrans" type="text" />
                <Field component="input" id="url" name="url" type="text" />
                <Field component="input" id="url_back" name="url_back" type="text" />
                <Field component="input" id="mac" name="mac" type="text" />
                <Field component="input" id="xpayTimeout" name="xpayTimeout" type="text" />
              </fieldset>
              <button type="input" onClick={evt => this.onPay(evt)} className="btn mainBtn">
                Acquista
              </button>
            </form>
          }

          {payment != null && payment.module === "xpay" && !wait &&
            <form id="VehicleChangeRequestPaymentForm" action={hostedPage} method="GET">
              <fieldset hidden>
                <Field component="input" id="hostedPage" name="hostedPage" type="text" />
              </fieldset>
              <button type="input" onClick={evt => this.onPay(evt)} className="btn mainBtn">
                Acquista
              </button>
            </form>
          }

          {payment.module === "sella" && !wait &&
            <button onClick={() => this.onPay()} type="button" className="btn mainBtn">
              Acquista
            </button>
          }

        </div>
      </div>
    </div>)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'VehicleChangeRequestPayment', enableReinitialize: true })(VehicleChangeRequestPayment));
