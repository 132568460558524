import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { getSubMessageType } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import DatePickerFormComponent from 'components/redux-form/DatePickerFormComponent';
import { NEWS_SECTIONS, NEWS_TYPES } from 'libs/news';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import Select from 'react-select';
import moment from 'moment';


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

var initState = {      
  checkErrors: null,
  sendErrors: null,
  loading: true,
  showDeleteConfirm: false,
  image:null,

  type: "INFO",
  title: "",
  content: "",
  thumbImage: null,
  startDate: null,
  endDate: null,
  enable: true,
  sections: []
}

class BackofficeEditNewsModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onSaveNews: PropTypes.func.isRequired,
    news: PropTypes.object
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      initState,
      image:null
    }

    this.saveNews          = this.saveNews.bind(this)
    this.checkFields       = this.checkFields.bind(this)
    this.close             = this.close.bind(this)
    this.onEndDateChange   = this.onEndDateChange.bind(this)
    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onSectionsChange  = this.onSectionsChange.bind(this)
    this.removeImage       = this.removeImage.bind(this)
    this.getSelectSectionsSourceData = this.getSelectSectionsSourceData.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {  
    
    //console.log("componentDidUpdate")

    if ( prevProps.show != this.props.show ) {
    
      this.setState({      
        checkErrors: null,
        sendErrors: null,
        loading: true,
        showDeleteConfirm: false,
      })

      if ( this.props.news != null ) {
        //console.log("LOAD NEWS",this.props.news)
  
        this.setState({              
          type: this.props.news.type,
          title: this.props.news.title,
          content: this.props.news.content,
          thumbImage: this.props.news.thumbImage,
          startDate: this.props.news.startDate,
          endDate: this.props.news.endDate,
          enable: this.props.news.enable,
          sections: this.props.news.NewsSections
        })

        var activeSections = []    

        if ( this.props.news != null && this.props.news.NewsSections != null ) {
          for ( let key in this.props.news.NewsSections ) {
            activeSections.push({ section: this.props.news.NewsSections[key].section  })             
          }
        }

        this.setState({ sections: activeSections})
      
        this.props.initialize({ 
          startDate: this.props.news.startDate,
          endDate:   this.props.news.endDate
        })

      }
      else {
        this.setState({  
          type: "INFO",
          title: "",
          content: "",
          thumbImage: null,
          startDate: null,
          endDate: null,
          enable: true,
          sections: null
        })
      }

      

    }

    
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    //console.log("componentDidMount")
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []

    //console.log("checkFields",this.state)

    if ( this.state.type == null ||  this.state.type.length <= 0 ) {
      errors.push("Tipo news obbligatorio")
    }

    if ( this.state.title == null || this.state.title.length <= 0 ) {
      errors.push("Titolo news obbligatorio")
    }

    if ( this.state.content == null || this.state.content.length <= 10 ) {
      errors.push("Il contenuto della news è vuoto")
    }

    if ( this.state.sections == null || this.state.sections.length <= 0 ) {
      errors.push("Nessuna sezione selezionata")
    }

    if ( this.state.startDate != null &&  this.state.endDate != null && moment(this.state.endDate).isBefore(moment(this.state.startDate)) ) {
      errors.push("Range date non valido")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  saveNews() {

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
    this.state.thumbImage == this.state.image
    
    var news = {
      id : null,
      type: this.state.type,
      title: this.state.title,
      content: this.state.content,
      thumbImage:this.state.image,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      enable: this.state.enable,
      sections: this.state.sections       
    }

    if ( this.props.news == null  ) {

      //console.log("CREATE NEWS",news)

      SostaOnlineApiService.createNews(news)
      
      .then( result => {
        console.log("RESULT",result)
        
        if ( this.props.onSaveNews != null ) {
          this.props.onSaveNews(news)
        }

        this.close()

      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante la creazione della news, riprovare pù tardi!" })
      })
    }
    else {

      if(this.props.news.thumbImage != null){
        this.props.news.thumbImage = news.thumbImage
        
      }

      news.id = this.props.news.id

      //console.log("UPDATE NEWS",news)
      
      SostaOnlineApiService.updateNews(news)
      .then( result => {
        console.log("RESULT",result)

        if ( this.props.onSaveNews != null) {
          this.props.onSaveNews(news)
        }
        this.close()

      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante il salvataggio della news, riprovare pù tardi!" })
      })

    }

  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  deleteNews() {

    if ( this.props.news != null && this.props.news.id != null ) {            
      //console.log("DELETE NEWS",this.props.news.id)
      
      SostaOnlineApiService.deleteNews(this.props.news.id)
      .then( result => {
        console.log("RESULT",result)

        if ( this.props.onSaveNews != null ) {
          this.props.onSaveNews(null)
        }

        this.close()

      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante la cancellazione della news, riprovare pù tardi!" })
      })

    }
    else {
      this.setState({ sendErrors: "Impossibile procedere con la cancellazione" })
    }

    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }

  /**
   * --------------------------------------------------------------
   */
  close() {    

    //console.log("CLOSE")

    this.setState(initState)

    this.props.initialize({ 
      startDate: null,
      endDate:   null
    })


    if ( this.props.onClose )
        this.props.onClose()    
  }

  /**
   * --------------------------------------------------------------
   */
  setNewsEnable(checked) {
    this.setState({ enable: checked })
  }

  /**
   * --------------------------------------------------------------
   */
  setNewsType(value) {
    this.setState({ type: value})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onStartDateChange(data){    
    this.setState({ startDate: data })    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  onEndDateChange(data){    
    this.setState({ endDate: data })    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectSectionsSourceData() {
    var data = []

    for ( let key in NEWS_SECTIONS ) {
      data.push( { value:  NEWS_SECTIONS[key].value, label: NEWS_SECTIONS[key].descr } )
      //console.log( "SECTION",  NEWS_SECTIONS[key] )
    }
      
    return data
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getDefaultSectionsSourceData() {
    var data           = []    

    if ( this.props.news != null && this.props.news.NewsSections != null ) {
      for ( let key in this.props.news.NewsSections ) {
        data.push( { 
          value: NEWS_SECTIONS[this.props.news.NewsSections[key].section].value, 
          label: NEWS_SECTIONS[this.props.news.NewsSections[key].section].descr 
        } )                  
      }
    }
      
    return data
  }

  /**
   * --------------------------------------------------------------
   * @param {*} values 
   */
  onSectionsChange ( values  ) {
    const activeSections = values.map( section => {
      return { section: section.value }
    })
    
    //console.log("activeSections",activeSections)
    
    this.setState({ sections: activeSections})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} values 
   */
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      console.log(img);
      
      this.setState({
        image: URL.createObjectURL(img),
        thumbImage: URL.createObjectURL(img)
      });
    }
  };

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   */
  removeImage (news){
    if(news){
      this.setState({ thumbImage: null })   
      this.setState({ image : null})  
    }else if(!news && this.state.image != null){
      console.log('qui');
      this.setState({image : null})  
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      news
    } = this.props

    const {
      sendErrors,
      checkErrors,
      type,
      title,
      content,
      thumbImage,
      enable,
      showDeleteConfirm,
    } = this.state

    var selectedSubMessageType = this.getSelectedMessageType()

    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        focusTrapped={true}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faNewspaper} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong> { news && "MODIFICA NEWS" } { !news && "NUOVA NEWS" } </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            <div className="row">    

              <div className="col-xs-2" style={{marginLeft: 0, paddingLeft: 0, marginTop: "0em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Tipo:</strong></h5>
              </div>

              <div className="col-xs-4" style={{marginLeft: 0, paddingLeft: 0, marginTop: "0em" }}>
                <select
                  name="type" 
                  className="form-control" 
                  onChange={ e => {  this.setNewsType(e.target.value) }}
                  value={type}
                  required >
                    <option value={NEWS_TYPES.INFO.value}   >{NEWS_TYPES.INFO.descr}</option>
                    <option value={NEWS_TYPES.WARNING.value}>{NEWS_TYPES.WARNING.descr}</option>
                    <option value={NEWS_TYPES.ALERT.value}  >{NEWS_TYPES.ALERT.descr}</option>
                    <option value={NEWS_TYPES.ADV.value}    >{NEWS_TYPES.ADV.descr}</option>
                </select>
              </div>

              <div className="col-xs-2" style={{ marginTop: "0em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Abilitata:</strong></h5>
              </div>

              <div className="col-xs-2" style={{marginLeft: 0, paddingLeft: 0, marginTop: "0em" }}>
                <input
                    style={{ width: "2em"}}
                    name="enable" 
                    className="form-control" 
                    type="checkbox" 
                    onChange={ e => {  this.setNewsEnable(e.target.checked) }}
                    checked={enable}
                    />
              </div>
              <br/>
                      
            </div>

            <div className="row">
              <div className="col-xs-2" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Titolo:</strong></h5>
              </div>

              <div className="col-xs-10" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <input    
                  style={{ width : "100%"}}
                  className="form-control" 
                  name="title"                  
                  component="input"
                  onChange={ e => { this.setState({ title: e.target.value })  } }
                  placeholder="Titolo della news ..."
                  value={title}
                  required />
              </div>
              <br/>
            </div>  

            <div className="row">
              <div className="col-xs-2" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Validità da:</strong></h5>
              </div>

              <div className="col-xs-4" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="startDate"
                  className="from-control"
                  onDateChange={this.onStartDateChange}
                  required
                />
              </div>

              <div className="col-xs-2" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>a:</strong></h5>
              </div>

              <div className="col-xs-4" style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="endDate"
                  className="from-control"
                  onDateChange={this.onEndDateChange}
                  required
                />
              </div>

            </div>

            <div className="row" >
              <div className="col-xs-2"  style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Sezioni: </strong></h5>
              </div>
              
              <div className="col-xs-10"  style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <Select 
                  defaultValue={this.getDefaultSectionsSourceData()}
                  isMulti={true}
                  name="sections"
                  options={this.getSelectSectionsSourceData()}
                  className="basic-multi-select"
                  classNamePrefix="select" 
                  onChange={ this.onSectionsChange }/>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ marginTop: 10, marginBottom: 10 }}><strong>Testo News:</strong></h5 >
                
                <textarea 
                  style={{ 
                    height: "230px",                     
                    color: 'grey', 
                    fontWeight: 'bold'  
                  }}
                  name="content" 
                  className="form-control"                   
                  onChange={ e => { this.setState({content: e.target.value}) }}
                  value={content}
                  required />                              
                <br/>
              </div>

              {/* 
              //Seleziona immagine per ADV
              //fixare display image perchè viene salvata in formato blob. Si visualizza bene al caricamento ma al refresh della pagina non viene più renderizzata
              <div className="col-md-3">
                <div style={{marginLeft: 0, paddingLeft: 0, marginTop: "1em" }}>
                <h5 style={{ marginTop: 10, marginBottom: 10 }}>
                  <strong>
                    { this.state.image == null && !news || thumbImage == null ? (
                      'Carica immagine'
                    ):(
                      'Modifica immagine'
                    )}
                  </strong>
                </h5>
                <div>                  
                  {news && this.state.thumbImage == null  ? (
                    <div>
                      <input 
                        type="file" 
                        name="thumbImage" 
                        onChange={this.onImageChange}
                      />
                    </div>

                  ): news && this.state.thumbImage != null ? (
                    <div>
                      <img 
                        style={{width:'100%', height:'100%',marginBottom:10, objectFit:'contain'}} 
                        src={thumbImage}
                      />
                      <button onClick={() => {this.removeImage(news)}} className="btn alertBtn" style={{marginLeft:0}}>Rimuovi</button>
                      <input 
                        type="file" 
                        name="thumbImage" 
                        onChange={this.onImageChange}
                        
                      />
                    </div>              

                  ): !news && this.state.image != null ? (
                    <div>
                      <img 
                        style={{width:'100%', height:'100%',marginBottom:10, objectFit:'contain'}} 
                        src={this.state.image}
                      />
                      <button onClick={() => {this.removeImage(news)}} className="btn alertBtn" style={{marginLeft:0}}>Rimuovi</button>
                      <input 
                        type="file" 
                        name="thumbImage" 
                        onChange={this.onImageChange}
                      />
                    </div>
                  ):(           
                    <input 
                      type="file" 
                      name="thumbImage" 
                      onChange={this.onImageChange}
                    />
                  )}
                </div>
                </div>
              </div> */}
            </div>         

            <div className="row">
              { sendErrors && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h4>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            <div className="row">
              { !showDeleteConfirm && 
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                { }
                <Button className="btn alertBtn" onClick={this.close}>Annulla</Button>{' '}                 
                <Button className="btn mainBtn"  onClick={() => this.saveNews()} >{ news == null ? "Invia" : "Aggiorna" }</Button>

                { news != null && 
                <Button className="btn alertBtn"  onClick={() => { this.setState({ showDeleteConfirm: true })}} >
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina
                </Button>
                }
              </div>
              }
              { showDeleteConfirm && 
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <h4 className="col-md-12 col-xs-12 text-center">
                  Si sta richiedendo l'eliminazione della notizia.<br/>                  
                  L'operazione è irreversibile, si vuole procedere?
                </h4>
                <Button className="btn alertBtn" onClick={() => { this.setState({ showDeleteConfirm: false })}}>Annulla</Button>{' '}                                                 
                <Button className="btn alertBtn" onClick={() => { this.deleteNews() } } >
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina
                </Button>                
              </div>
              }
            </div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditNewsModal'})(BackofficeEditNewsModal))

