
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { ADMIN, USER, RESELLER } from './roles'
import moment from 'moment'

export const VEHICLE_STATUS_REQUEST  = 'REQUEST'
export const VEHICLE_STATUS_REPLY    = 'REPLY'
export const VEHICLE_STATUS_APPROVED = 'APPROVED'
export const VEHICLE_STATUS_DENIED   = 'DENIED'
export const VEHICLE_STATUS_DONE     = 'DONE'
export const VEHICLE_STATUS_CANCELED = 'CANCELED'

export const STATUS_VEHICLE_DESCR_REQUEST  = 'RICHIESTA DI MODIFICA IN ATTESA DI APPROVAZIONE'
export const STATUS_VEHICLE_DESCR_REPLY    = 'RICHIESTA DI MODIFICA IN ATTESA DI RISPOSTA'
export const STATUS_VEHICLE_DESCR_APPROVED = 'RICHIESTA DI MODIFICA IN ATTESA DI PAGAMENTO'
export const STATUS_VEHICLE_DESCR_DENIED   = 'RICHIESTA DI MODIFICA NEGATA'
export const STATUS_VEHICLE_DESCR_DONE     = ''
export const STATUS_VEHICLE_DESCR_CANCELED = ''

export const VEHICLE_STATUS_LIST = [
  { id: 1 , value: VEHICLE_STATUS_REQUEST },
  { id: 2 , value: VEHICLE_STATUS_REPLY },
  { id: 3 , value: VEHICLE_STATUS_APPROVED },
  { id: 4 , value: VEHICLE_STATUS_DENIED },
  { id: 5 , value: VEHICLE_STATUS_DONE },  
  { id: 6 , value: STATUS_VEHICLE_DESCR_CANCELED },  
]

/**
   * --------------------------------------------------------------
   * FILTRA LA LISTA DEI PERMESSI ATTUALMENTE ATTIVI E RICHIESTI IN 
   * ATTESA DI APPROVAZIONE TRA QUELLI PASSATI ALLA FUNZIONE
   * @param {*} marks 
   * @returns 
   */
 export function getActiveMarks( marks ) {
  return marks.filter ( mark => {
    
    if (
      /*  mark.active && Considero anche le richieste non ancora approvate */ 
      !mark.archived && !mark.ceased && moment().isBefore(moment(mark.endDate)) ){
      return true
    }

    return false
  })
}


/**
   * --------------------------------------------------------------
   * Restituisce la lista dei documenti necessari per il cambio veicolo
   * in base ai permessi attivi in cui è allocato il veicolo
   * @param {*} marks          lista permessi utente di un determinato veicolo 
   * @param {*} markTypes      lista tipi permessi
   * @param {*} documentTypes  lista tipi documenti
   * @returns 
   */
 export function getRequestedDocumentTypes(marks, markTypes, documentTypes) {
  var activeMarks          = getActiveMarks(marks)
  var docTypes             = []
  var requestedDocTypes    = []    
  var activeMarkTypeIdList = []

  if ( activeMarks && activeMarks.length > 0 && markTypes ) {            
    activeMarks.map( mark => { activeMarkTypeIdList.push(mark.markTypeId) })

    // rimuovo gli elementi doppi
    activeMarkTypeIdList = activeMarkTypeIdList.filter(function(elem, pos) {
      return activeMarkTypeIdList.indexOf(elem) == pos;
    });       
    
    activeMarkTypeIdList.map( markTypeId => {                
      markTypes.map( markType => {
        if ( markType.id == markTypeId && markType.DocumentTypes ) {
          markType.DocumentTypes
            .filter( documentType => documentType.docType == 'VEHICLE' )
            .map( documentType => {
              docTypes.push(documentType.id)
            })                          
        }            
      })
    })

    // rimuovo gli elementi doppi
    docTypes = docTypes.filter(function(elem, pos) {
      return docTypes.indexOf(elem) == pos;
    });

    requestedDocTypes = documentTypes.filter( docType => docTypes.indexOf(docType.id) >= 0  )  
    
    requestedDocTypes = requestedDocTypes.map( docType => {        
      var mandatory = false;

      if ( docType.MarkTypes && docType.MarkTypes.length > 0 ) {
        docType.MarkTypes.map( markType => {
          if( markType.MarkTypesDocuments &&  markType.MarkTypesDocuments.mandatory )
            mandatory = true
        })              
      }
      
      docType.mandatory = mandatory

      return docType
    })

  }
  
  return requestedDocTypes

}

/**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
 export function getBackofficeCardVehicleHeaderClass(vehicle,role) {

  var result = ["card-vehicles"]    
  
  if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
    var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
    var status        = getVehicleStatus(vehicleStatus[0],role)

    //console.log("VEHICLE",status)
    
    switch ( status.code ) {

        case VEHICLE_STATUS_REQUEST:
          result += ' card-vehicles-request '
          break;
        case VEHICLE_STATUS_REPLY:
          result += ' card-vehicles-reply '
          break;
        case VEHICLE_STATUS_APPROVED:
          result += ' card-vehicles-approved '
          break;
        case VEHICLE_STATUS_DENIED:
          if ( role != USER ) {
            result += ' card-vehicles-done '
          }
          else {
            result += ' card-vehicles-denied '            
          }
          break;
        case VEHICLE_STATUS_DONE:
        case VEHICLE_STATUS_CANCELED:
          result += ' card-vehicles-done '
          break;
        
    }

  }
    
  return result
  
}

/**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
 export function getResellerCardVehicleHeaderClass(vehicle,role) {

  var result = ["card-vehicles"]    
  
  if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
    var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
    var status        = getVehicleStatus(vehicleStatus[0],role)

    //console.log("VEHICLE",status)
    
    switch ( status.code ) {

        case VEHICLE_STATUS_REQUEST:
          result += ' card-vehicles-request '
          break;
        case VEHICLE_STATUS_REPLY:
          result += ' card-vehicles-reply '
          break;
        case VEHICLE_STATUS_APPROVED:
          result += ' card-vehicles-approved '
          break;
        case VEHICLE_STATUS_DENIED:
          if ( role != USER ) {
            result += ' card-vehicles-done '
          }
          else {
            result += ' card-vehicles-denied '            
          }
          break;
        case VEHICLE_STATUS_DONE:
        case VEHICLE_STATUS_CANCELED:
          result += ' card-vehicles-done '
          break;
        
    }

  }
    
  return result
  
}


/**
 * --------------------------------------------------------------
 * @param {*} vehiclePowerSupply 
 * @param {*} size 
 * @returns 
 */
export function getVehicleIcon(vehiclePowerSupply,size="2x") {
    
    var icon = null
    var color = "black"

    if ( vehiclePowerSupply != null  ) {
      switch(vehiclePowerSupply.name) {
          case  'Alimentato a gas metano/gpl':
            icon = solidIcons.faGasPump
            color = 'darkblue'
            break;
          case  'Bi-fuel':
            icon = solidIcons.faGasPump
            color = 'darkblue'
            break;
          case  'Ibrido/bimodale':
            icon = solidIcons.faGasPump
            color = 'darkblue'
            break;
          case  'A trazione elettrica':
            icon = solidIcons.faCarBattery
            color = 'darkgreen'
            break;
          case  'Ad emissione zero':
            icon = solidIcons.faRecycle
            color = 'darkgreen'
            break;
          case  'Benzina':
            color = '#d9534f'
            icon = solidIcons.faGasPump
            break;
          case  'Diesel':
            icon = solidIcons.faGasPump
            break;
        
      }

      return <FontAwesomeIcon  size={size} className="sectionIcons"  style={{ color: color }} icon={icon} />
    }

}
/**
 * --------------------------------------------------------------
 * @param {{ status:[STRING] }} vehicle 
 * @param {STRING} userRole 
 * @returns 
 */
 export function getVehicleStatus(vehicle,userRole) {
  
  var   status = { 
    code: vehicle.status,
    status : null , 
    color : '#174c88' 
  }
    
  switch ( vehicle.status ) {
    case VEHICLE_STATUS_REQUEST :       
      status.status = STATUS_VEHICLE_DESCR_REQUEST
      if ( userRole != USER )
        status.color = 'orange'
      else
        status.color = '#4f6b8a'        
      break;

    case VEHICLE_STATUS_APPROVED :
      status.status = STATUS_VEHICLE_DESCR_APPROVED
      status.color = '#df8500'
      break;
    
    case VEHICLE_STATUS_DENIED:
      if ( userRole == USER ) {
        status.status = STATUS_VEHICLE_DESCR_DENIED
        status.color = 'red'
      }
      break;

    case VEHICLE_STATUS_DONE:
      status.status = STATUS_VEHICLE_DESCR_DONE
      status.color = '#174c88'
      break;
    
    case VEHICLE_STATUS_CANCELED:
      status.status = STATUS_VEHICLE_DESCR_CANCELED
      status.color = '#174c88'
      break;
  
    case VEHICLE_STATUS_REPLY:
      status.status = STATUS_VEHICLE_DESCR_REPLY
      if ( userRole != USER )
        status.color = 'orange'
      else 
        status.color = '#4f6b8a'
      break;
  }
  
  
  return status
}

/**
  * --------------------------------------------------------------
  * @param {*} plateNumber 
  * @returns 
  */
export function getValidPlateNumberContent(plateNumber) {
  return plateNumber.toUpperCase().replace(/[^A-Z,0-9]/gi, '')
}

/**
 * --------------------------------------------------------------
 * @param {*} vehicle 
 * @returns 
 */
export function getActiveChangeRequest(vehicle) {
  var changeRequest = null

  if ( vehicle && vehicle.VehiclesChangeRequests) {
    var changeRequests = vehicle.VehiclesChangeRequests.filter( vcr =>  
      vcr.status != VEHICLE_STATUS_DONE && 
      vcr.status != VEHICLE_STATUS_DENIED && 
      vcr.status != VEHICLE_STATUS_CANCELED 
    )
    
    if ( changeRequests && changeRequests.length > 0 && changeRequests[0] ) {      
      changeRequest = changeRequests[0]
    }
  }

  return changeRequest
}
