import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SostaOnlineApiService from '../../../../services/SostaOnlineApiService'
import { NavLink, withRouter } from 'react-router-dom'
import Loading from '../../../../components/LoadingComponent'
import './RegistrationCategoriesChooser.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'


// ================================================================
//
//
class RegistrationCategoriesChooser extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  // ----------------------------------------------------------------
  //
  //
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      categories: [],
      id: null
    }

    this.renderCategory = this.renderCategory.bind(this)
    this.fetchCategories = this.fetchCategories.bind(this)
  }

  // ----------------------------------------------------------------
  //
  //
  async fetchCategories(id) {
    this.setState({ loading: true });
  
    if (id) {
      const category = await SostaOnlineApiService.fetchUserCategoryById(id);
      this.setState({ categories: [category.data], loading: false, id: id });
    } else {
      const categoriesResponse = await SostaOnlineApiService.fetchUserCategories();
      const allCategories = categoriesResponse.data;
  
      // Filtra apenas a categoria "Reseller"
      const resellerCategory = allCategories.find(category => category.name === "Reseller");
  
      this.setState({ categories: resellerCategory ? [resellerCategory] : [], loading: false, id: null });
    }
  }

  // ----------------------------------------------------------------
  //
  //
  componentDidMount () {
    this.fetchCategories(this.props.match.params.id)
  }

  // ----------------------------------------------------------------
  //
  //
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchCategories(this.props.match.params.id)
    }
  }

  // ----------------------------------------------------------------
  //
  //
  renderCategory (category, index, parentMode = true) {
    
    if (parentMode) {
      console.log('categoryId:',category.id)
      console.log('categoryReseller:', category.reseller)
      console.log('categoryResident:', category.resident)
      if ('reseller' in category) {
        console.log('categoryReseller:', category.reseller);
      } else {
        console.log('categoryReseller não está definida');
      }
      return (<div key={index} className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div className="panel registration-box panel-default panel-border-login">
          <div className="registration-box-content">
            <div className="registration-title registration-box-title"></div>
            <div className="panel-body registration-box-body">
              <a href="#" className="btn-social btn-outline">
                {/* category.worker         && <FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faBuilding} />  */}
                {/* category.workerPerson   && <FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faBriefcase} />  */} 
                {/* category.resident       && <FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faHouseUser} />  */}
                {/* category.standard       && <FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faCar} />  */}
                {<FontAwesomeIcon  className="mainIconsNoAction" style={{ margin: "2rem" }} size="6x" icon={solidIcons.faShop} />}
              </a>
              
              <div className="panel-body flex-grow panel-2">
                <div className="title-registration-box">
                  {category.name}
                </div>
                <div className="subtitle-registration-box">
                  
                </div>
              </div> 
              {category.Children.length === 0 && <NavLink
                style={{display: 'block'}}
                className="resident-btn-navlink mainBtnLarge"
                to={'/register-reseller/user-info/' + category.id}>
                <strong>REGISTRATI</strong> <span className="glyphicon glyphicon-arrow-right">{}</span>
              </NavLink>}
              {category.Children.length > 0 && <NavLink
                style={{display: 'block'}}
                className="resident-btn-navlink mainBtnLarge"
                to={'/register-reseller/choose-category/' + category.id}>
                <strong>AVANTI</strong> <span className="glyphicon glyphicon-arrow-right">{}</span>
              </NavLink>}
            </div>
          </div>
        </div>
      </div>)
    } else {
      return (<div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div className="panel panel-default panel-border">
          <div className="panel-body text-left">
            <h3 className="title3">{category.name}</h3>
            <div className="panel-body flex-grow panel-2">
              {category.description}
            </div>
            <NavLink 
                style={{display: 'block'}}
                className="resident-btn-navlink mainBtnLarge" 
                to={'/register/worker/employees'}>
              VAI <span className="glyphicon glyphicon-arrow-right">{}</span>
            </NavLink>
          </div>
        </div>
      </div>)
    }
  }

  // ----------------------------------------------------------------
  //
  //
  render () {

    const { categories, loading, id } = this.state

    if (loading) {
      return (<Loading />)
    }

    return (<div>
      {id && categories[0].Children.map((item, index) => {
        if (item.enableRegistration) {
          return this.renderCategory(item, index)
        }
      })}

      {!id && categories.map((item, index) => {
        if (item.enableRegistration) {
          return this.renderCategory(item, index, true)
        }
      })}
    </div>)
  }
}

export default withRouter(RegistrationCategoriesChooser)
