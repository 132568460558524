import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import BackofficeEditAdminUser from './BackofficeEditAdminUser';

moment.locale('it')



class BackofficeEditAdminUserModal extends Component {

  static propTypes = {
    show:                   PropTypes.bool.isRequired,
    userId:                 PropTypes.number,    
    onSaveAdminUser:        PropTypes.func.isRequired,
    onDeleteAdminUser:      PropTypes.func.isRequired,
    onCancelEditAdminUser:  PropTypes.func.isRequired,
    users:                  PropTypes.array.isRequired,
    onClose:                PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }

    this.close            = this.close.bind(this)
    this.onSaveAdminUser       = this.onSaveAdminUser.bind(this)
    this.onDeleteAdminUser     = this.onDeleteAdminUser.bind(this)
    this.onCancelEditAdminUser = this.onCancelEditAdminUser.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
     
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
        
  }

 
  /**
   * --------------------------------------------------------------
   * @param {*} userId 
   */
  onSaveAdminUser(userId) {
    if ( this.props.onSaveAdminUser != null )
      this.props.onSaveAdminUser(userId)

    this.close()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onDeleteAdminUser() {    
    if ( this.props.onDeleteAdminUser != null )
      this.props.onDeleteAdminUser()
    
    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEditAdminUser() {
    if ( this.props.onCancelEditAdminUser != null )
      this.props.onCancelEditAdminUser()
    
      this.close()
  }

  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      userId,
      users
    } = this.props
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>  {userId != null ? "MODIFICA" : "ASSEGNA NUOVO" } RUOLO </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => { this.close() } } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>

        <div className="col-md-12">
          <div className="app-modal-body" style={{ minHeight: "30em"}}>                                  
            <BackofficeEditAdminUser
              userId={userId}
              users={users}
              onSaveAdminUser={this.onSaveAdminUser}      
              onDeleteAdminUser={this.onDeleteAdminUser}    
              onCancelEditAdminUser={this.onCancelEditAdminUser}
              />
          </div>
        </div>    

       
      </Modal>
    )
  }
}

export default reduxForm({ form: 'BackofficeEditAdminUserModal'})(BackofficeEditAdminUserModal)
