import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UserMainNavigatorScreen from '../screens/UserMainNavigatorScreen'
import { Route, Switch, Redirect } from 'react-router-dom'
import { actions as authActions } from '../redux/modules/Auth'
import UserAreaContainer from './UserArea/UserAreaContainer'
import PaymentResultContainer from './UserArea/Checkout/PaymentResultContainer'
import MarkRequest from './UserArea/MarkRequest'
import MarkPaymentContainer from './UserArea/Checkout/MarkPaymentContainer'
import EditMark from './UserArea/EditMark'
import { ADMIN, OPERATOR, RESELLER } from 'libs/roles'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import VehicleChangeRequestPayment from './UserArea/Checkout/VehicleChangeRequestPayment'

const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

class UserMainContainer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      settings: {
        messages: {
          enable: false
        }
      }
    }

    this.onLogoutClick = this.onLogoutClick.bind(this)

  }

  componentDidMount() {
    this.fetchSettings()
  }

  fetchSettings() {

    var settings = this.state.settings

    SostaOnlineApiService.fetchMessagesSettings()
      .then(data => {
        settings.messages = data.data
        this.setState({ settings: settings })
      })
      .catch((err) => {
        console.log("ERROR", err)
      });
  }

  onLogoutClick() {
    this.props.authActions.logout()
  }

  checkRedirectTo() {
    //console.log('UserMainContainer Props:', this.props);
    if (this.props.state == null || this.props.state.user == null) {
      return (<Redirect to="/splash" />)
    }

    if (this.props.state.user.role === RESELLER) {
      return (<Redirect to="/reseller" />)
    }

    if (this.props.state.user.role === ADMIN || this.props.state.user.role === OPERATOR) {
      return (<Redirect to="/backoffice" />)
    }

    return null
  }

  render() {

    var redirect = this.checkRedirectTo()

    if (redirect != null)
      return (redirect)

    return (
      <UserMainNavigatorScreen
        user={this.props.state.user}
        settingsMessages={this.state.settings.messages}
        onLogoutClick={this.onLogoutClick}>
        <Switch>
          <Route path="/user-area/marks/edit/:id" component={EditMark} />
          <Route path="/user-area/marks/create" component={MarkRequest} />
          <Route path="/user-area/pay/result" component={PaymentResultContainer} />
          <Route path="/user-area/pay/mark/:id" component={MarkPaymentContainer} />
          <Route path="/user-area/pay/vehicleChangeRequest/:id" component={VehicleChangeRequestPayment} />
          <Route path="/user-area" component={
            (props) => <UserAreaContainer {...props} settingsMessages={this.state.settings.messages} />
          } />

          <Redirect to="/user-area" />
        </Switch>
      </UserMainNavigatorScreen>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMainContainer)
