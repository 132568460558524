import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'//
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import ResellerSettingsMenuComponent from 'components/Reseller/Navmenu/ResellerSettingsMenuComponent'//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { RESELLER } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ResellerEditCategory from 'components/Reseller/Categories/ResellerEditCategory'//
import ResellerEditCategoryModal from 'components/Reseller/Categories/ResellerEditCategoryModal'//
import { scroller , Element } from 'react-scroll'
const thisRoute = 'reseller'

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class ResellerSettingsCategories extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,    
      categories: [],
      selectedCategoryChildId: null,      
      selectedCategoryParentId: null,
      showEditCategoryModal: false,
      showMaximizedList: true
    }
    
    this.onSaveCategory           = this.onSaveCategory.bind(this)    
    this.editCategory             = this.editCategory.bind(this)
    this.onCloseEditCategoryModal = this.onCloseEditCategoryModal.bind(this)    
    this.onCancelEditCategory     = this.onCancelEditCategory.bind(this)
    this.onDeleteCategory         = this.onDeleteCategory.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {
    this.fetchData()    
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({loading: true})

    const categories = await SostaOnlineApiService.fetchFlatCategories()    
        
    if ( categories != null && categories.data != null  )
      this.setState({ categories: categories.data })

    this.fetchThingsToDo()

    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSaveCategory (categoryId) {    
    this.setState({submitting: true, error: null, success: false})
    await this.fetchData()
    this.editCategory(categoryId)
    this.setState({submitting: false, error: false, success: true})
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onDeleteCategory () {    
    this.setState({submitting: true, error: null, success: false})
    await this.fetchData()
    this.editCategory(null)
    this.setState({submitting: false, error: false, success: true})
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} evt 
   */
  editCategory(id) {    
    var category = this.state.categories.find( cat => cat.id == id )    

    var selectedCategoryParentId = null

    if ( category != null ) {
      if ( category.ParentId != null )
        selectedCategoryParentId = category.ParentId 
      else
        selectedCategoryParentId = category.id
    }

    this.setState({
      selectedCategoryChildId: id,      
      selectedCategoryParentId:  selectedCategoryParentId
    })

    this.scrollToDetail()
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditCategoryModal() {
    this.setState({      
      showEditCategoryModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   */
  windowMaximize() {
    this.setState({ showEditCategoryModal: true })
  }

  /**
   * --------------------------------------------------------------------------
   */
  onCancelEditCategory() {
    //this.setState({ selectedCategoryChildId: null})
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  getSortedMainCategoryList() {
    var categories = this.state.categories
    categories = categories.filter( cat => cat.ParentId == null  ).sort((a, b) => (a.name > b.name ) ? 1 : -1)
    return categories
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} parentId 
   * @returns 
   */
  getSortedChildsCategoryList(parentId) {    
    var categories = this.state.categories
    categories = categories.filter( cat => cat.ParentId == parentId  ).sort((a, b) => (a.name > b.name ) ? 1 : -1)  
    return categories
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }


  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {    

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,      
      showEditCategoryModal,
      selectedCategoryChildId, 
      selectedCategoryParentId,
      showMaximizedList
    } = this.state

    var categoriesSorted = this.getSortedMainCategoryList()

    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <ResellerSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em" , marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={ e => { this.editCategory(null) } } ><FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle} />
            <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Crea Categoria</span></button>
          </div>
        </div>
        <div className="row" style={{  padding: 0,  margin: 0 }}>

          { showMaximizedList ? 
          <div className={ showEditCategoryModal ? "box col-md-12" :  "box col-md-5" } style={{ padding: "0.3em" , margin: 0 , marginBottom: "0.3em" }}>
            
            <div className="row resellerSettingsHeader" >
              <div className="col-xs-2 "><label className="">Id</label></div>
              <div className="col-xs-8 "><label className="">Nome</label></div>   
              <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>
                <FontAwesomeIcon  size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={ () => { this.setState({showMaximizedList: false}) } } />
              </div>                     
            </div>
            
            {categoriesSorted.map(category => {      
              
              var childCategories = this.getSortedChildsCategoryList(category.id)

              return(
              <React.Fragment key={`cat-${category.id}`} > 
                <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-area-1-'+category.id}>Clicca per modificare i dati della categoria</Tooltip>}>
                  <div className={ selectedCategoryChildId == category.id || selectedCategoryParentId == category.id ? "row resellerSettingsRowSelected" :  "row resellerSettingsRow"} onClick={ evt => { this.editCategory(category.id)} } >                                       
                    <div className="col-xs-12 row">
                      <div className="col-xs-2" ><label className="">{category.id}</label></div>
                      { childCategories != null && childCategories.length > 0  &&
                        <div className="col-xs-1" ><label className="">{ category.id == selectedCategoryParentId 
                          ? <FontAwesomeIcon  size="1x" icon={solidIcons.faMinusSquare} />
                          : <FontAwesomeIcon  size="1x" icon={solidIcons.faPlusSquare} />
                        }</label></div>
                      }
                      <div className="col-xs-8" ><label className="">{category.name}</label></div>
                      <div className="col-xs-1 pull-right" >
                        { selectedCategoryChildId == category.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
                { category.id == selectedCategoryParentId && childCategories.map( childCategory => 
                  <OverlayTrigger key={`cat-${childCategory.id}`} placement="top" overlay={<Tooltip id={'tooltip-area-1-'+childCategory.id}>Clicca per modificare i dati della categoria</Tooltip>}>
                  <div className={ selectedCategoryChildId == childCategory.id ? "form-group row resellerSettingsRowSelected" :  "form-group row resellerSettingsRow"} onClick={ evt => { this.editCategory(childCategory.id)} } >
                    <div className="col-xs-12 row">
                      <div className="col-xs-2" ><label className="">{childCategory.id}</label></div>
                      <div className="col-xs-1" ></div>
                      <div className="col-xs-8" ><label className="">{childCategory.name}</label></div>
                      <div className="col-xs-1 pull-right" >
                          { selectedCategoryChildId == childCategory.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }
                      </div>
                    </div>
                  </div>
                  </OverlayTrigger>
                )}
              </React.Fragment>
              )              
              }
            )}

            <div className="form-group row resellerSettingsFooter" >
              <div className="col-md-12 " ></div>            
            </div>
          
            {submitting && <div className="text-primary">
              Salvataggio in corso
            </div>}

            {error && <div className="text-danger">
              Si sono verificati degli errori
            </div>}

            {success && <div className="text-success">
            
            </div>}
            
          </div>
          : 
          <div className="col-md-1" style={{ padding: 0, margin: 0 , marginBottom: "0.3em" }}>
            <div className="mainBtn" onClick={ () => { this.setState({showMaximizedList: true}) } } >
              <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretDown} />
            </div>
          </div>
          }
          { !showEditCategoryModal && 
          <div className={ showMaximizedList ? "col-md-7": "col-md-11"}  style={{  padding: "0.3em" , margin: 0 }}>
            <Element name="detail"/>
            <div className="resellerSettingsBox" >
              <div className="resellerSettingsBoxHeader row text-center">
                <div className="col-xs-1 pull-left">
                  { selectedCategoryChildId != null
                    ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                    : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
                  }                  
                </div>          
                <div className="col-xs-10 pull-left" >
                  <strong>{ selectedCategoryChildId != null ? "MODIFICA" : "CREA NUOVA" } CATEGORIA </strong>
                </div>
                <div className="col-xs-1 pull-right" onClick={ evt => this.windowMaximize() }>
                  <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                    <FontAwesomeIcon  size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row" >
                <ResellerEditCategory                
                  categoryId={selectedCategoryChildId}
                  onSaveCategory={this.onSaveCategory}      
                  onDeleteCategory={this.onDeleteCategory}            
                  onCancelEditCategory={this.onCancelEditCategory}
                  />
              </div>
            </div>
          </div>
          }
        </div>

        <ResellerEditCategoryModal
          show={showEditCategoryModal}
          categoryId={selectedCategoryChildId}
          onSaveCategory={this.onSaveCategory}      
          onDeleteCategory={this.onDeleteCategory}
          onClose={this.onCloseEditCategoryModal} 
          onCancelEditCategory={this.onCancelEditCategory}         
          />
            
      </React.Fragment>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSettingsCategories)
