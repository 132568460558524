import React, {Component, useRef }   from 'react'
import {reduxForm, Field }  from 'redux-form'
import {connect }           from 'react-redux'
import                           './LoginFormReseller.css'
import {bindActionCreators} from 'redux'
import SpidIcon             from '../../../assets/spid-ico-circle-bb.png'
import SplashLoginBtn       from '../../../components/SplahsLoginBtn'
import { OverlayTrigger, Popover }   from 'react-bootstrap'
import SpidAgidLogo           from  '../../../assets/spid-agid-logo-lb.png'
//import SpidAgidLogo         from  '../assets/spid-agid-logo-lb.png'

import {actions as authActions} from '../../../redux/modules/Auth'
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import  * as solidIcons         from '@fortawesome/free-solid-svg-icons'


const mapStateToProps = (state) => {
  return {
    state: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}


class LoginFormReseller extends Component {

  constructor(props) {
    super(props)    
  }

  spidPopOver() {
    
      return (
        <OverlayTrigger 
          placement="top" 
          trigger="click"
          overlay={            
              <Popover id="popover-positioned-top" title="SPID" >                
                <strong>SPIIID</strong> è il sistema di accesso che consente di utilizzare, 
                con un'identità digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati.<br/>                
                Se sei già in possesso di un'identità digitale, accedi con le credenziali del tuo gestore.<br/>
                Se non hai ancora una'identità digitale, richiedila ad uno dei gestori.<br/><br/>
                <a href="https://www.spid.gov.it/" target="_blank">Maggiori informazioni su SPID</a><br/>
                <a href="https://www.spid.gov.it/cos-e-spid/come-attivare-spid/" target="_blank">Non hai SPID?</a>
                <br/><br/>                
                <img className="spid-agid-icon" src={SpidAgidLogo}></img>                                
              </Popover>                        
          }>
          <span className="question-icon glyphicon glyphicon-question-sign icon-question-spid ">{}</span>
        </OverlayTrigger>
      )
    
  }

  render () {
    const {error, onPasswordForgot, handleSubmit, onSpidClick } = this.props

    //console.log("spidEnable",  this.props.state.spidEnable )

    var errorMsg = ""

    switch( error) {
      case "LOGIN":
        errorMsg = "USERNAME O PASSWORD ERRATI"
        break;

      case "SPID":
        errorMsg = "AUTENTICAZIONE SPID ERRATA"
        break;

      default:
        break;
    }

    return (
      <React.Fragment>
      

        <div className="panel panel-default panel-border-login-reseller" style={{minHeight: "40em"}}>
          <h2 className="login-title login-title-reseller" >LOG-IN</h2>
          <div className="panel-body panel-body-reseller-login" >
            <form onSubmit={handleSubmit}>
              <a href="#" className="btn-social btn-outline">
                <FontAwesomeIcon size="10x" className="sectionIcons" icon={solidIcons.faShop} style={{ margin: "3rem"  }}/>
              </a>
              <div className="login-input-box">
                <Field name="username" component="input" type="text" placeholder="E-MAIL o CODICE FISCALE" className="inputLogin" />
                <Field name="password" component="input" type="password" placeholder="PASSWORD" className="inputLogin" />
                { (error != null && errorMsg != "") && <h5 className="error">{errorMsg}</h5>}
              </div>
              <div><SplashLoginBtn type="submit"><div></div>ACCEDI<div></div></SplashLoginBtn></div>
              { this.props.state.spidEnable && 
                <div>
                  <div >&nbsp;</div>
                  <div style={{display: "flex", width:"100%", justifyContent : "space-between"}}>
                      <SplashLoginBtn onClick={onSpidClick} starticon={SpidIcon} classstarticon="spid-button-icon">                         
                        ENTRA CON SPID       
                        <div></div>                                 
                      </SplashLoginBtn>
                      {this.spidPopOver()}
                  </div>
                </div>
              }
            </form>
          </div>
        </div>

        {onPasswordForgot &&
        <div className="row text-center align-top" style={{margin: 0, padding: 5}}>
          <span className="pas">
            <a onClick={() => onPasswordForgot()}>
              <i className="glyphicon glyphicon-lock">{}</i> Hai necessità di cambiare la password oppure l'hai dimenticata? Clicca qui
            </a>
          </span>
        </div>}
      

      <a hidden id="linkSPIDSTART" href="#" referrerPolicy="no-referrer-when-downgrade" > </a>
      
    </React.Fragment> 
    )
  }
}

export default reduxForm({
  form: 'login'
})(connect(mapStateToProps,mapDispatchToProps)(LoginFormReseller))
