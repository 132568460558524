import React, {Component}       from 'react'
import {connect}                from 'react-redux'
import ResellerVehiclesScreen   from '../../screens/Reseller/ResellerVehiclesScreen'//
import SostaOnlineApiService    from '../../services/SostaOnlineApiService'//
import {actions as authActions} from '../../redux/modules/Auth'//
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import  * as solidIcons         from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators }   from 'redux'
import { reportActions }        from 'redux/modules/Report' 
import { NavLink }              from 'react-router-dom'
const thisRoute = 'reseller'

/**
 * --------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
 const mapStateToProps = (state) => {
  return {
    state: state.users,
    stateAuth: state.auth,
    stateReport : state.report
  }
}

/**
 * --------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * ====================================================================
 * 
 */
class ResellerVehiclesWaitingListTableContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      vehicles: [],
      count: 0,
      filters: {}
    }

    this.onRowClick = this.onRowClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.fetchVehicles = this.fetchVehicles.bind(this)
  }

  /**
   * --------------------------------------------------------------------
   * 
   */
  componentDidMount () {
    this.setState({loading: true})    
    this.fetchVehicles()
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
   fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------
   * 
   */
  onCreateVehicleClick () {
    this.props.history.push('/reseller/vehicles/create')
  }

  /**
   * --------------------------------------------------------------------
   * @param {*} filterObj 
   * @param {*} limit 
   * @param {*} offset 
   */
  fetchVehicles (filterObj, limit, offset) {
    this.setState({loading: true})
    let string = ''
    for (let key in filterObj) {
      string = string + key + '=' + filterObj[key] + '&'
    }

    SostaOnlineApiService.fetchVehiclesWaitingList(string, limit || 100, offset || 0,thisRoute).then((data) => {
      this.setState({
        vehicles: data.data,
        pagination: data.pagination,
        loading: false
      })
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------------
   * @param {*} filter 
   */
  handleFilterChange (filter) {
    let newFilters = Object.assign({}, this.state.filters)
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter
    } else {
      delete newFilters[filter.column.key]
    }
    this.setState({ filters: newFilters })
  }

  /**
   * --------------------------------------------------------------------
   * @param {*} rowIdx 
   * @param {*} row 
   * @returns 
   */
  onRowClick (rowIdx, row) {
    if (rowIdx <= -1 || !row.userId) {
      return
    }

    this.props.history.push('/reseller/users/' + row.userId + '?scrollTo=vehicle-' + row.id)
  }

  /**
   * --------------------------------------------------------------------
   */
  onClearFilters () {
    // all filters removed
    this.setState({filters: {}})
  }

  /**
   * --------------------------------------------------------------------
   * @returns 
   */
  render () {
    return (

      <React.Fragment>
        <div className="btn-group">
          <div className="btn-group" role="group">            
            <NavLink exact className="tableBtn" activeClassName="active" to={'/reseller/vehicles'}>
              <FontAwesomeIcon size="1x" icon={solidIcons.faCar} />&nbsp;&nbsp;Tutti
            </NavLink>
            <NavLink className="tableBtn" activeClassName="active" to={'/reseller/vehicles/waiting-list'}>
              <FontAwesomeIcon size="1x" icon={solidIcons.faClock} />&nbsp;&nbsp;In Attesa ({this.props.stateReport.waitingVehicles ? this.props.stateReport.waitingVehicles : 0})
            </NavLink>
          </div>
        </div>

        <ResellerVehiclesScreen
        loading={this.state.loading}
        fetchVehicles={this.fetchVehicles}
        vehicles={this.state.vehicles}
        rows={this.state.vehicles}
        pagination={this.state.pagination}
        onRowClick={this.onRowClick.bind(this)}
        handleFilterChange={this.handleFilterChange.bind(this)}
        onClearFilters={this.onClearFilters.bind(this)} />

      </React.Fragment>
      
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerVehiclesWaitingListTableContainer)