import React, { Component } from 'react'
import './FooterComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import LogoInput from '../assets/LogoInput60x60.png'



var LogoApp  = "/cities/"+process.env.REACT_APP_CITY.toLowerCase()+'/logo-app.png'
var LogoCity = "/cities/"+process.env.REACT_APP_CITY.toLowerCase()+'/logo-city.png'


import MessaggioLogo from '../assets/Oggetto-message.png'

export default class FooterComponent extends Component {
  
  render() {

    const handleScrollToTop = () => {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
      <footer className="text-center">
        <div className="footer-above">
          <div className="container">            
            <div className="row">
              
              {/*LARGE SCREEN*/}
              <div className="footer-logo-container">
                <div className="row footer-logo-content">
                  <div className="col-12 col-sm-4 text-center">
                    <a href={"http://"+process.env.REACT_APP_MANAGER_URL} target="_new" className=""><img alt="Logo" className="logoT" src={LogoApp} /></a>
                  </div>
                  <div className="col-6 col-sm-4 text-center">
                    <a href={"http://"+process.env.REACT_APP_CITY_URL}    target="_new" className=""><img alt="" className="logoC" src={LogoCity} style={{margin: '20px 0'}} /></a> 
                    <br/>                    
                    <span className="comune-text">Comune di {process.env.REACT_APP_CITY}</span>
                  </div>
                  <div className="col-6 col-sm-4 text-center logos-fa-footer">
                    <div className="col-12 col-sm-4 try" >                    
                      <a href={"mailto: "+process.env.REACT_APP_EMAIL} style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <FontAwesomeIcon size="4x" className="sectionIcons" icon={regularIcons.faEnvelope} style={{margin: '20px 0'}} />
                      </a>                  
                    </div>
                    <div className="col-12 col-sm-4 try">
                      <a className="footer-anchor" style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <FontAwesomeIcon size="4x" className="sectionIcons" icon={solidIcons.faArrowUp} onClick={handleScrollToTop} />  
                        <span className="footer-popper">Top</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>              

            </div>

            <div style={{padding:'10px' }}>
              <div style={{ margin: "10px 0", padding:'10px 0', borderTop:'1px solid #337ab7' }}>
                <a href="https://inputitalia.com/" target="_blank">Powered by Input s.r.l. P.IVA 01175100997</a>
              </div>
              <a href="https://inputitalia.com/" target="_blank">
                <img className="logo-input-footer" src={LogoInput} style={{maxWidth: "35px"}}></img>
              </a>
            </div>

          </div>
        </div>
      </footer>

    );
  }
}
