import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import { Element } from 'react-scroll'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import queryString from 'query-string'
import { scroller } from 'react-scroll'
import classNames from 'classnames'
import { MARK_DURATION, MARK_ENABLE_START_DATE, MARK_EXPIRATION_DATE, MARK_RENEW_NOTIFY_DAYS } from 'libs/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../../../redux/modules/Auth'
import { Oval } from 'react-loader-spinner'
import { MARK_TYPE_FAMILY, STATUS_ACTIVE, STATUS_CEASED, STATUS_EXPIRED, STATUS_RENEWED, STATUS_WAIT_APPROVE, STATUS_WAIT_PAYMENT, getMarkZone, getSelectedEndDate, getSelectedStartDate } from 'libs/marks'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ResellerHomeContainer.css'

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  }
}

const TextUser = (props) => <div className={props.bold ? "text-bold-user" : "text-regular-user"} style={{ textAlign: 'center' }}>{props.children}</div>

class ResellerHomeContainer extends Component {
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    var render = {};

    return (
      <div className="row container-reseller-home-container">
        <div className="col-md-10">
          <div className="panel panel-default panel-border-login panel-reseller-home" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
            <h1 className="login-title">OPERAZIONI UTENTI E PERMESSI</h1>
            <div className="panel-body">
              <div className="col-md-4">
                <FontAwesomeIcon size="7x" className="sectionIcons" icon={solidIcons.faUser} style={{padding: "2vh 2vw"}} />
                <FontAwesomeIcon size="7x" className="sectionIcons" icon={solidIcons.faPassport} style={{padding: "2vh 2vw"}} />
              </div>
              <div className="col-md-7">
                <div className="centratore">
                  <NavLink to="/reseller/user/">
                    <div className="bnt start-button">
                      INIZIA
                      &nbsp;
                      <FontAwesomeIcon className="mainIconsRevNoAction" size="1x" icon={solidIcons.faCirclePlus} />
                    </div>
                    
                  </NavLink>
                </div>
              </div>
              <div className="col-md-1">&nbsp;</div>
            </div>
          </div>
        </div>
        {/*<div className="col-md-4">
          <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
            <div style={{ width:'85%' }}>
              <div style={{ marginTop: 15, marginBottom: 10, display:'flex', alignItems: 'center' }}>
                <span className="threesom-span">
                  <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
                </span>
                <div>
                <TextUser bold>OPERAZIONI UTENTE / OPERAZIONI PERMESSI</TextUser>
                <TextUser>
                    In questa sezione puoi creare i nuovi utenti, cercare utenti già censiti per veicolo o dati utente e creare o rinnovare i permessi agli utenti.<br /><br />
                </TextUser>
                </div>
              </div>              
            </div>            
          </div>
          
        </div>
        */}
        {/*
        <div className="col-md-8">
          <div className="panel panel-default panel-border-login">
            <h2 className="login-title">NUOVO PERMESSO / RINNOVO</h2>
            <div className="panel-body">
              <div className="col-md-3">                
                <FontAwesomeIcon  size="6x" className="sectionIcons"   icon={solidIcons.faPassport} />
              </div>
              <div className="col-md-9">
                
                <NavLink to="/reseller/marks/" style={{ textDecoration: "none" }}>
                  <div className="bnt user-card-btn1" style={{ marginBottom: "2.5rem"}}>                                    
                    <strong>RICHIEDI PERMESSO</strong>
                    <FontAwesomeIcon  className="mainIconsRevNoAction"  size="2x" icon={solidIcons.faCirclePlus} />
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
            <div style={{ width:'85%' }}>
              <div style={{ marginTop: 15, marginBottom: 10, display:'flex', alignItems: 'center' }}>
                <span className="threesom-span">
                  <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
                </span>
                <div>
                <TextUser bold>NUOVO PERMESSO / RINNOVO</TextUser>
                <TextUser>
                In questa sezione puoi creare i nuovi permessi disponibili per i tuoi utenti e fare il rinnovo per glit utenti esistenti.<br /><br />
                </TextUser>
                </div>
              </div>              
            </div>            
          </div>

        </div>
        */}
      </div>

    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerHomeContainer)
