import React, {Component} from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

export default class DatePickerFormComponent extends Component {

  constructor(props) {
    super(props)
    var userLang = "it"; 
    const getLocale = locale => require(`date-fns/locale/${userLang}/index.js`)
    this.locale = getLocale(userLang);
  }


  /**
   * -------------------------------------------------
   * @param {*} value 
   */
  onChange (value) {
    if (value) {
      this.props.input.onChange(moment(value).format())
      if (this.props.onDateChange) {
        this.props.onDateChange(moment(value).format())
      }
    }
  }

  /**
   * -------------------------------------------------
   * @returns 
   */
  render () {

    //console.log("props",this.props)
    var selected = null 
    
    if ( this.props.selected != null ) {
      selected = moment(this.props.selected).toDate() 
    }
    else if ( this.props.input.value ) {
      selected = moment(this.props.input.value).toDate() 
    }    

    return (<div>
      <DatePicker  
        locale={this.locale}
        popperPlacement={this.props.popperPlacement}
        placeholderText={this.props.placeholderText}    
        name={this.props.name}  
        dateFormat="dd/MM/yyyy"
        required={this.props.required}
        className={this.props.className}
        selected={selected}
        onChange={this.onChange.bind(this)}
        disabled={this.props.disabled} //Ho aggiunto questa riga | B.V
        />
    </div>)
  }
}
