import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import SplashReseller from './SplashReseller'
import {actions as authActions} from '../../../redux/modules/Auth'

//import {actions as authActions} from '../../redux/modules/Auth'

const mapStateToProps = (state) => {
  return {
    state: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

class SplashContainerReseller extends Component {
  static propTypes = {
    history: PropTypes.object,
    actions: PropTypes.object
  }

  // ----------------------------------------------------------------
  //
  //
  constructor (props) {
    super(props)
    this.onRegisterClick    = this.onRegisterClick.bind(this)
    this.onPasswordForgot   = this.onPasswordForgot.bind(this)
    this.onSpidClick        = this.onSpidClick.bind(this)
    this.onSubmit           = this.onSubmit.bind(this)
    this.onSendConfirmEmail = this.onSendConfirmEmail.bind(this)

    this.props.actions.setSpidEnable()
    this.handleSpidToken()
  }

  // ----------------------------------------------------------------
  //
  //
  handleSpidToken( ) {
    const queryParams = new URLSearchParams(window.location.search);
    const spidToken   = queryParams.get('SpidToken');

    if ( spidToken == null ) {
      this.props.actions.clearSpidToken()
      this.props.actions.clearLoginError()
    }
    else {
      this.props.actions.spidLogin(spidToken)
    }
  }

  // ----------------------------------------------------------------
  //
  //
  updateErrorMessage(message,callback) {
    if ( callback )
      callback(message)
  }

  // ----------------------------------------------------------------
  //
  //
  onPasswordForgot () {
    this.props.history.push('/forgot-password-reseller')
  }

  /**
   * ----------------------------------------------------------------
   */
   onSendConfirmEmail() {
    this.props.history.push('/register/sendConfirmMail-reseller')
   }

  // ----------------------------------------------------------------
  //
  //
  onRegisterClick () {
    this.props.history.push('/register-reseller')
  }

  // ----------------------------------------------------------------
  //
  //
  getState(key) {
    return this.props.state[key]
  }
  
  // ----------------------------------------------------------------
  //
  //
  setState(key,value) {
    this.props.state[key] = value
  }

  // ----------------------------------------------------------------
  //
  //
  onSpidClick(e) {
    if ( e != null )
      e.preventDefault();
  
    this.props.actions.getSpidToken()

  }

  // ----------------------------------------------------------------
  //
  //
  onSubmit (credentials) {
    this.props.actions.loginRequest(credentials)
  }

  // ----------------------------------------------------------------
  //
  //
  render () {
    return (
      <SplashReseller
        onRegisterClick={this.onRegisterClick}
        onPasswordForgot={this.onPasswordForgot}
        onSpidClick={this.onSpidClick}
        onSubmit={this.onSubmit} 
        onSendConfirmEmail={this.onSendConfirmEmail}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashContainerReseller)
