import singleton from 'singleton'
import EventEmitter from 'eventemitter3'

const HOST = ''
const API_ENDPOINT = HOST + '/api/v1'

class SostaOnlineApiService extends singleton {
  HOST = HOST;
  token;
  spidToken;
  deviceId;
  eventEmitter = new ApiEvents();
  logout = null

  /**
   * ==============================================================================
   * listener di logout automatico
   * @param {*} logout 
   */
  setListenerLogout(logout) {
    if (logout != null) {
      this.logout = logout

      var listenerLogout = () => {
        this.logout()
      }

      this.eventEmitter.addListener('auth-error', listenerLogout);

    }
  }

  /**
   * ==============================================================================
   * FUNZIONI GENERICHE   
   */
  baseFetch(url, method, body = null, headers = null) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: headers ? this.getHeaders(headers) : this.getHeaders(),
        body: body
      })
        .then((response) => {
          switch (response.status) {
            case 401:
              this.eventEmitter.emit('auth-error')
              if (!this.logout)
                reject({ status: 401, message: 'Auth Error', ...response })
              else
                resolve(response)
              return null
            default:
              return response.json()
          }
        })
        .then((response) => {
          if (response.result === 'ko') {
            reject(response)
          } else {
            resolve(response)
          }
        })
        .catch((error) => {
          reject({ message: 'Error on baseFetch at url: ' + url, ...error })
        })
    })
  }

  getHeaders(options = { contentType: 'application/json' }) {
    let opts = {
      contentType: options.contentType || 'application/json'
    }

    let headers = {}


    if (opts.contentType) {
      headers['Content-Type'] = opts.contentType
    }

    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token
    }

    return headers
  }

  /**
   * ==============================================================================
   * GESTIONE REPORTS
   */
  fetchPassReleasedChart(from, to, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/reports/charts/passReleased' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/reports/charts/passReleased' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    }
  }

  fetchGeneralCounters(from, to, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/reports/counters/general' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/reports/counters/general' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    }
  }

  fetchOnlineTransactionsCounters(from, to, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/reports/counters/onlineTransactions' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/reports/counters/onlineTransactions' + (from && to ? '?startDate=' + from + '&endDate=' + to : ''), 'GET')
    }
  }

  /**
   * ==============================================================================
   * GESTIONE NEWS
   */
  createNews(news, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/news/', 'POST', JSON.stringify({ news: news }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/news/', 'POST', JSON.stringify({ news: news }))
    }
  }

  updateNews(news, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/news/' + news.id, 'PUT', JSON.stringify({ news: news }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/news/' + news.id, 'PUT', JSON.stringify({ news: news }))
    }
  }

  deleteNews(newsId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/news/' + newsId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/news/' + newsId, 'DELETE')
    }
  }

  fetchNews(page, route = 'backoffice') {

    var parameters = ""

    if (page == null) {
      page = 0
    }

    parameters = parameters + 'page=' + page

    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/news?' + parameters, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/news?' + parameters, 'GET')
    }
  }

  fetchNewsMe(section) {
    var parameters = 'section=' + section
    return this.baseFetch(API_ENDPOINT + '/news?' + parameters, 'GET')
  }


  /**
   * ==============================================================================
   * GESTIONE MESSAGGI
   */
  sendMessageMe(msg) {
    return this.baseFetch(API_ENDPOINT + '/messages/', 'POST', JSON.stringify({ msg: msg }))
  }

  sendMessage(msg, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/messages/', 'POST', JSON.stringify({ msg: msg }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/messages/', 'POST', JSON.stringify({ msg: msg }))
    }
  }

  replyMessageMe(msg, replyToMsgId) {
    return this.baseFetch(API_ENDPOINT + '/messages/reply/' + replyToMsgId, 'POST', JSON.stringify({ msg: msg }))
  }

  replyMessage(msg, replyToMsgId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/messages/reply/' + replyToMsgId, 'POST', JSON.stringify({ msg: msg }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/messages/reply/' + replyToMsgId, 'POST', JSON.stringify({ msg: msg }))
    }
  }

  fetchMyMessages() {
    return this.baseFetch(API_ENDPOINT + '/messages', 'GET')
  }

  fetchMessages(page, userId, route = 'backoffice') {

    var parameters = ""

    if (page == null) {
      page = 0
    }

    parameters = parameters + 'page=' + page

    if (userId != null) {
      parameters = parameters + '&userId=' + userId
    }

    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/messages?' + parameters, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/messages?' + parameters, 'GET')
    }
  }

  updateMessageMe(msg) {
    return this.baseFetch(API_ENDPOINT + '/messages/' + msg.id, 'PUT', JSON.stringify({
      msg: {
        read: msg.read
      }
    }))
  }

  updateMessage(msg, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/messages/' + msg.id, 'PUT', JSON.stringify({
        msg: {
          read: msg.read
        }
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/messages/' + msg.id, 'PUT', JSON.stringify({
        msg: {
          read: msg.read
        }
      }))
    }
  }


  /**
   * ==============================================================================
   * GESTIONE CATEGORIE
   */
  fetchFlatCategories() {
    return this.baseFetch(API_ENDPOINT + '/categories?flat=true', 'GET')
  }

  fetchUserCategoryById(id) {
    return this.baseFetch(API_ENDPOINT + '/categories/' + id, 'GET')
  }

  createCategory(category, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/categories', 'POST', JSON.stringify({
        category: category
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/categories', 'POST', JSON.stringify({
        category: category
      }))
    }
  }

  updateCategory(category, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/categories/' + category.id, 'PUT', JSON.stringify({
        category: category
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/categories/' + category.id, 'PUT', JSON.stringify({
        category: category
      }))
    }
  }

  deleteCategory(categoryId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/categories/' + categoryId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/categories/' + categoryId, 'DELETE')
    }
  }

  /**
   * ==============================================================================
   * GESTIONE INDIRIZZI
   */

  fetchAddresses(areaId = null) {
    var parameters = ""

    if (areaId != null)
      parameters = "?areaId=" + areaId

    return this.baseFetch(API_ENDPOINT + '/addresses' + parameters, 'GET')
  }

  createAddress(address, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses', 'POST', JSON.stringify({
        address: address
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses', 'POST', JSON.stringify({
        address: address
      }))
    }
  }

  updateAddress(address, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses/' + address.id, 'PUT', JSON.stringify({
        address: address
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses/' + address.id, 'PUT', JSON.stringify({
        address: address
      }))
    }
  }

  uploadAddresses(areaId, file, route = 'backoffice') {
    const rotta = route == '' ? '/' : '/' + route + '/' + '/addresses/upload'
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('file', file)
      data.append('areaId', areaId)
      fetch(API_ENDPOINT + rotta, {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + this.token },
        body: data
      })
        .then((response) => {
          switch (response.status) {
            case 401:
              this.eventEmitter.emit('auth-error')
              if (!this.logout)
                reject({ status: 401, message: 'Auth Error', ...response })
              else
                resolve(response)
              return null
            default:
              return response.json()
          }
        })
        .then((response) => {
          if (response.result === 'ko') {
            reject(response)
          } else {
            resolve(response)
          }
        })
        .catch((error) => {
          reject({ message: 'Error uploading file', ...error })
        })
    })
  }


  deleteAddress(addressId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses/' + addressId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses/' + addressId, 'DELETE')
    }
  }


  fetchPassages(areaId = null) {
    var parameters = ""

    if (areaId != null)
      parameters = "?areaId=" + areaId

    return this.baseFetch(API_ENDPOINT + '/addresses/passages' + parameters, 'GET')
  }

  createPassage(passage, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses/passage/', 'POST', JSON.stringify({
        passage: passage
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses/passage/', 'POST', JSON.stringify({
        passage: passage
      }))
    }
  }

  updatePassage(passage, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses/passage/' + passage.id, 'PUT', JSON.stringify({
        passage: passage
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses/passage/' + passage.id, 'PUT', JSON.stringify({
        passage: passage
      }))
    }
  }

  deletePassage(passageId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/addresses/passage/' + passageId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/addresses/passage/' + passageId, 'DELETE')
    }
  }


  /**
   * ==============================================================================
   * GESTIONE AREE
   */


  fetchAreas() {
    return this.baseFetch(API_ENDPOINT + '/areas', 'GET')
  }

  fetchArea(areaId) {
    return this.baseFetch(API_ENDPOINT + '/areas/' + areaId, 'GET')
  }

  fetchCategoryAreas(categoryId) {
    return this.baseFetch(API_ENDPOINT + '/areas/category?categoryId=' + categoryId, 'GET')
  }

  createArea(area, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/areas', 'POST', JSON.stringify({
        area: area
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/areas', 'POST', JSON.stringify({
        area: area
      }))
    }
  }

  updateArea(area, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/areas/' + area.id, 'PUT', JSON.stringify({
        area: area
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/areas/' + area.id, 'PUT', JSON.stringify({
        area: area
      }))
    }
  }

  deleteArea(areaId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/areas/' + areaId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/areas/' + areaId, 'DELETE')
    }
  }


  /**
   * ==============================================================================
   * GESTIONE RICEVUTE
   */

  fetchReceipts(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/receipts?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/receipts?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }

  //Invia Ricevuta al utente dell reseller tramite email
  sendResellerReceipt(id, resellerLastName, resellerFirstName, userEmail) {
    const rotta = '/reseller/sendReceipt/' + id + '/receipt/' + encodeURIComponent(resellerFirstName) + '/' + encodeURIComponent(resellerLastName);

    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'POST',
        headers: this.getHeaders(),
        body: JSON.stringify({ userEmail })
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark receipt: ', ...error })
      })
    })
  }

  /**
   * ==============================================================================
   * GESTIONE NOTIFICHE
   */

  fetchThingsToDo(route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/report', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/report', 'GET')
    }
  }

  fetchThingsToDoMe() {
    return this.baseFetch(API_ENDPOINT + '/report', 'GET')
  }

  fetchUsersWaitingList(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/waiting-list?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/waiting-list?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }

  fetchVehiclesWaitingList(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles/waiting-list?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles/waiting-list?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }

  fetchResellerWaitingList() {
    return this.baseFetch(API_ENDPOINT + '/backoffice/users/waitingResellers', 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE UTENTI
   */

  createUser(user, route = 'backoffice') {
    user.workAreaId = parseInt(user.workAreaId, 10)
    user.residenceAreaId = parseInt(user.residenceAreaId, 10)
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users', 'POST', JSON.stringify(user))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users', 'POST', JSON.stringify(user))
    }
  }

  updateUser(user, route = 'backoffice') {
    user.workAreaId = parseInt(user.workAreaId, 10)
    user.residenceAreaId = parseInt(user.residenceAreaId, 10)
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/' + user.id, 'PUT', JSON.stringify(user))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/' + user.id, 'PUT', JSON.stringify(user))
    }
  }

  updateUserRole(userId, role, plafond = null, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/changeRole/' + userId, 'PUT', JSON.stringify({
        role: role, budget: plafond
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/changeRole/' + userId, 'PUT', JSON.stringify({
        role: role, budget: plafond
      }))
    }
  }

  generateUserCredentials(userId, password, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/' + userId + '/generateCredentials', 'PUT', JSON.stringify({
        password: password
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/' + userId + '/generateCredentials', 'PUT', JSON.stringify({
        password: password
      }))
    }
  }

  findUser(column, value) {
    return this.baseFetch(API_ENDPOINT + '/users?column=' + column + '&offset=' + value, 'GET')
  }

  countUser() {
    return this.baseFetch(API_ENDPOINT + '/users/all/all', 'GET')
  }

  deleteUser(id, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/' + id, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/' + id, 'DELETE')
    }
  }

  fetchUsers(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }

  fetchPureUsersList(route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/pureUsersList', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/pureUsersList', 'GET')
    }
  }

  fetchActiveUsers() {
    return this.baseFetch(API_ENDPOINT + '/backoffice/users/activeUsers', 'GET')
  }

  fetchPureUsersListReseller(route = 'reseller') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/pureUsersList', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/pureUsersList', 'GET')
    }
  }

  fetchUserType(id) {
    return this.baseFetch(API_ENDPOINT + '/userTypes/' + id, 'GET')
  }

  fetchUserMe() {
    return this.baseFetch(API_ENDPOINT + '/users/me', 'GET')
  }

  fetchUserTypes() {
    return this.baseFetch(API_ENDPOINT + '/userTypes', 'GET')
  }

  fetchUser(userId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/' + userId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/' + userId, 'GET')
    }
  }

  fetchUserReseller(userId, route = 'reseller') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/users/' + userId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/users/' + userId, 'GET')
    }
  }

  fetchRegistry(fiscalCode, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/registries/' + fiscalCode, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/registries/' + fiscalCode, 'GET')
    }
  }

  fetchUserCategories() {
    return this.baseFetch(API_ENDPOINT + '/categories', 'GET')
  }

  fetchUserLogs(userId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/logs/user/' + userId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/logs/user/' + userId, 'GET')
    }
  }

  /**
   * ==============================================================================
   * GESTIONE FILES E DOCUMENTI   
   */
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  downloadMark(id, route = 'backoffice') {
    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/downloads/marks/' + id : '/reseller/downloads/marks/' + id;

    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark: ', ...error })
      })
    })
  }

  downloadMarkMe(id) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/downloads/marks/' + id, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark: ', ...error })
      })
    })
  }

  downloadMyMark(id) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/downloads/printMyMark/' + id, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark: ', ...error })
      })
    })
  }

  downloadMarkRelease(id, route = 'backoffice') {
    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/downloads/marks/' + id + '/release' : '/reseller/downloads/marks/' + id + '/release';

    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark release: ', ...error })
      })
    })
  }

  downloadMarkReleaseMe(id) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/downloads/marks/' + id + '/release', {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark release: ', ...error })
      })
    })
  }

  downloadDocumentMe(id) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/downloads/documents/' + id, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download document: ', ...error })
      })
    })
  }

  downloadDocument(id, route = 'backoffice') {
    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/downloads/documents/' + id : '/reseller/downloads/documents/' + id;

    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download document: ', ...error })
      })
    })
  }

  downloadUserCredentials(id, route = 'backoffice') {
    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/downloads/user-credentials-' + id : '/reseller/downloads/user-credentials-' + id;

    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'GET',
        headers: this.getHeaders()
      })
        .then((response) => {

          if (response.status === 200)
            return response.blob()

          else
            reject(response)

        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject({ message: 'Error on download user-credentials: ', ...error })
        })
    })
  }

  downloadMarkReceiptMe(id) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/downloads/marks/' + id + '/receipt', {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark receipt: ', ...error })
      })
    })
  }

  downloadMarkReceipt(id, route = 'backoffice', resellerLastName, resellerFirstName) {
    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/downloads/marks/' + id + '/receipt' : '/reseller/downloads/marks/' + id + '/receipt/' + encodeURIComponent(resellerFirstName) + '/' + encodeURIComponent(resellerLastName);


    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + rotta, {
        method: 'GET',
        headers: this.getHeaders()
      }).then((response) => {
        return response.blob()
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject({ message: 'Error on download mark receipt: ', ...error })
      })
    })
  }

  downloadReport(startDate, endDate, route = 'backoffice') {
    if (route == 'reseller') {
      return this.baseFetch(API_ENDPOINT + '/reseller/downloads/reports', 'POST', JSON.stringify({
        startDate: startDate,
        endDate: endDate
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/downloads/reports', 'POST', JSON.stringify({
        startDate: startDate,
        endDate: endDate
      }))
    }
  }

  downloadReportReseller(startDate, endDate, route = 'reseller', resellerId) {
    if (route == 'reseller') {
      return this.baseFetch(API_ENDPOINT + '/reseller/downloads/reports', 'POST', JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        resellerId: resellerId
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/downloads/reports', 'POST', JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        resellerId: resellerId
      }))
    }
  }

  async createDocuments(data) {
    const req = []
    for (let doc of data.documents) {
      if (doc.document) {
        let encDoc = await this.getBase64(doc.document)
        req.push({
          document: encDoc,
          documentTypeId: doc.documentTypeId,
          vehiclePlateNumber: doc.vehiclePlateNumber,
          type: doc.document.type,
          markId: data.markId
        })
      }
    }
    // return this.baseFetch(API_ENDPOINT + '/documents', 'POST', JSON.stringify(req))
  }

  uploadDocument(name, typeId, file) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('file', file)
      data.append('name', name)
      data.append('typeId', typeId)
      fetch(API_ENDPOINT + '/documents', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + this.token },
        body: data
      }).then((response) => {
        switch (response.status) {
          case 401:
            this.eventEmitter.emit('auth-error')
            reject({ status: 401, message: 'Auth Error', ...response })
            return null
          default:
            return response.json()
        }
      }).then((response) => {
        if (response.result === 'ko') {
          reject(response)
        } else {
          resolve(response)
        }
      }).catch((error) => {
        reject({ message: 'Error uploading document', ...error })
      })
    })
  }

  updateMarkDocumentApprovation(documentId, markId, approved, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documents/' + documentId + '/marks/' + markId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documents/' + documentId + '/marks/' + markId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    }
  }

  // funzione non usata
  updateVehicleDocumentApprovation(documentId, vehicleId, approved, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documents/' + documentId + '/vehicles/' + vehicleId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documents/' + documentId + '/vehicles/' + vehicleId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    }
  }

  updateVehicleChangeRequestDocumentApprovation(documentId, vehiclesChangeRequestId, approved, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documents/' + documentId + '/vehiclesChangeRequests/' + vehiclesChangeRequestId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documents/' + documentId + '/vehiclesChangeRequests/' + vehiclesChangeRequestId, 'PUT', JSON.stringify({
        approved: !!approved
      }))
    }
  }

  fetchDocuments(userId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documents/user/' + userId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documents/user/' + userId, 'GET')
    }
  }

  fetchMyDocuments() {
    return this.baseFetch(API_ENDPOINT + '/documents', 'GET')
  }

  updateDocumentMe(doc) {
    return this.baseFetch(API_ENDPOINT + '/documents/' + doc.id, 'PUT', JSON.stringify({
      name: doc.name,
    }))
  }

  /**
   * ==============================================================================
   * GESTIONE TIPI DOCUMENTO
   */

  fetchDocumentTypes() {
    return this.baseFetch(API_ENDPOINT + '/markDocumentTypes', 'GET')
  }

  fetchDocumentType(documentTypeId) {
    return this.baseFetch(API_ENDPOINT + '/markDocumentTypes?documentTypeId=' + documentTypeId, 'GET')
  }

  fetchDocumentTypesByMarkTypeId(markTypeId) {
    return this.baseFetch(API_ENDPOINT + '/markDocumentTypes?markTypeId=' + markTypeId, 'GET')
  }

  createDocumentType(documentType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documentTypes', 'POST', JSON.stringify({
        documentType: documentType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documentTypes', 'POST', JSON.stringify({
        documentType: documentType
      }))
    }
  }

  updateDocumentType(documentType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documentTypes/' + documentType.id, 'PUT', JSON.stringify({
        documentType: documentType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documentTypes/' + documentType.id, 'PUT', JSON.stringify({
        documentType: documentType
      }))
    }
  }

  deleteDocumentType(documentTypeId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/documentTypes/' + documentTypeId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/documentTypes/' + documentTypeId, 'DELETE')
    }
  }

  /**
   * ==============================================================================
   * GESTIONE SETTINGS   
   */

  // funzione non usata
  fetchSetting(name, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/settings/' + name, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/settings/' + name, 'GET')
    }
  }

  fetchSettings(route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/settings/', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/settings', 'GET')
    }
  }

  // funzione non usata
  updateSetting(name, value, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/settings/' + name, 'POST', JSON.stringify({
        value: value
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/settings/' + name, 'POST', JSON.stringify({
        value: value
      }))
    }
  }

  updateSettings(settings, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/settings/', 'POST', JSON.stringify({
        settings: settings
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/settings', 'POST', JSON.stringify({
        settings: settings
      }))
    }
  }

  fetchPublicSettings() {
    return this.baseFetch(API_ENDPOINT + '/settings', 'GET')
  }

  fetchPublicConfigs() {
    return this.baseFetch(API_ENDPOINT + '/settings/config', 'GET')
  }

  fetchCitySettings() {
    return this.baseFetch(API_ENDPOINT + '/settings/city', 'GET')
  }

  fetchPaymentSettings() {
    return this.baseFetch(API_ENDPOINT + '/settings/payment', 'GET')
  }

  fetchMessagesSettings() {
    return this.baseFetch(API_ENDPOINT + '/settings/messages', 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE VEICOLI   
   */

  createVehicle(vehicle, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles', 'POST', JSON.stringify({
        userId: vehicle.userId,
        plateNumber: vehicle.plateNumber,
        type: vehicle.type,
        powerSupply: vehicle.powerSupply
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles', 'POST', JSON.stringify({
        userId: vehicle.userId,
        plateNumber: vehicle.plateNumber,
        type: vehicle.type,
        powerSupply: vehicle.powerSupply
      }))
    }
  }

  createVehicleChangeRequestMe(vehicleId, plateNumber, ownershipTypeId, powerSupplyId, documents) {
    return this.baseFetch(API_ENDPOINT + '/vehicles/changeRequest', 'POST', JSON.stringify({
      vehicleId: vehicleId,
      plateNumber: plateNumber,
      ownershipTypeId: ownershipTypeId,
      powerSupplyId: powerSupplyId,
      documents: documents
    }))
  }

  updateVehicleChangeRequestMe(vehicleChangeRequestId, plateNumber, ownershipTypeId, powerSupplyId, documents) {
    return this.baseFetch(API_ENDPOINT + '/vehicles/changeRequest/' + vehicleChangeRequestId, 'PUT', JSON.stringify({
      plateNumber: plateNumber,
      ownershipTypeId: ownershipTypeId,
      powerSupplyId: powerSupplyId,
      documents: documents
    }))
  }

  cancelVehicleChangeRequestMe(vehicleChangeRequestId) {
    return this.baseFetch(API_ENDPOINT + '/vehicles/changeRequest/' + vehicleChangeRequestId, 'DELETE')
  }

  updateVehicleChangeRequest(vehicleChangeRequestId, status, note, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles/changeRequest/' + vehicleChangeRequestId, 'PUT', JSON.stringify({
        status: status,
        note: note
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles/changeRequest/' + vehicleChangeRequestId, 'PUT', JSON.stringify({
        status: status,
        note: note
      }))
    }
  }

  createVehicleMe(vehicle) {
    return this.baseFetch(API_ENDPOINT + '/vehicles', 'POST', JSON.stringify({
      plateNumber: vehicle.plateNumber,
      type: vehicle.type,
      powerSupply: vehicle.powerSupply
    }))
  }
  // la trappola si trova in UserAreaContainer
  updateVehicle(vehicle, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles/' + vehicle.id, 'PUT', JSON.stringify({
        plateNumber: vehicle.plateNumber,
        type: vehicle.type,
        powerSupply: vehicle.powerSupply
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles/' + vehicle.id, 'PUT', JSON.stringify({
        plateNumber: vehicle.plateNumber,
        type: vehicle.type,
        powerSupply: vehicle.powerSupply
      }))
    }
  }

  // la trappola si trova in UserAreaContainer
  deleteVehicle(vehicle, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles/' + vehicle.id, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles/' + vehicle.id, 'DELETE')
    }
  }

  deleteVehicleMe(vehicle) {
    return this.baseFetch(API_ENDPOINT + '/vehicles/' + vehicle.id, 'DELETE')
  }

  fetchVehicles(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }

  fetchVehiclesMe() {
    return this.baseFetch(API_ENDPOINT + '/vehicles', 'GET')
  }
  // funzione non usata
  fetchVehiclesByUserId(userId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicles', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicles?userId=' + userId, 'GET')
    }
  }

  fetchVehicleOwnershipTypes() {
    return this.baseFetch(API_ENDPOINT + '/vehicleOwnershipTypes', 'GET')
  }

  fetchVehicleOwnershipType(ownershipTypeId) {
    return this.baseFetch(API_ENDPOINT + '/vehicleOwnershipTypes/' + ownershipTypeId, 'GET')
  }

  createVehicleOwnershipType(vehicleOwnershipType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicleOwnershipTypes', 'POST', JSON.stringify({
        vehicleOwnershipType: vehicleOwnershipType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicleOwnershipTypes', 'POST', JSON.stringify({
        vehicleOwnershipType: vehicleOwnershipType
      }))
    }
  }

  updateVehicleOwnershipType(vehicleOwnershipType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicleOwnershipTypes/' + vehicleOwnershipType.id, 'PUT', JSON.stringify({
        vehicleOwnershipType: vehicleOwnershipType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicleOwnershipTypes/' + vehicleOwnershipType.id, 'PUT', JSON.stringify({
        vehicleOwnershipType: vehicleOwnershipType
      }))
    }
  }

  deleteVehicleOwnershipType(vehicleOwnershipTypeId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehicleOwnershipTypes/' + vehicleOwnershipTypeId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehicleOwnershipTypes/' + vehicleOwnershipTypeId, 'DELETE')
    }
  }

  fetchVehiclePowerSupplies() {
    return this.baseFetch(API_ENDPOINT + '/vehiclePowerSupplies', 'GET')
  }

  fetchVehiclePowerSupply(vehiclePowerSupplyId) {
    return this.baseFetch(API_ENDPOINT + '/vehiclePowerSupplies/' + vehiclePowerSupplyId, 'GET')
  }

  createVehiclePowerSupply(vehiclePowerSupply, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehiclePowerSupplies', 'POST', JSON.stringify({
        vehiclePowerSupply: vehiclePowerSupply
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehiclePowerSupplies', 'POST', JSON.stringify({
        vehiclePowerSupply: vehiclePowerSupply
      }))
    }
  }

  updateVehiclePowerSupply(vehiclePowerSupply, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehiclePowerSupplies/' + vehiclePowerSupply.id, 'PUT', JSON.stringify({
        vehiclePowerSupply: vehiclePowerSupply
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehiclePowerSupplies/' + vehiclePowerSupply.id, 'PUT', JSON.stringify({
        vehiclePowerSupply: vehiclePowerSupply
      }))
    }
  }

  deleteVehiclePowerSupply(vehiclePowerSupplyId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/vehiclePowerSupplies/' + vehiclePowerSupplyId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/vehiclePowerSupplies/' + vehiclePowerSupplyId, 'DELETE')
    }
  }

  updateUserMarkActiveVehicle(markId, newVehicleId) {
    return this.baseFetch(API_ENDPOINT + '/marks/' + markId + '/changeActiveVehicle', 'PUT', JSON.stringify({
      vehicleId: newVehicleId
    }))
  }

  updateMarkActiveVehicle(markId, newVehicleId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks/' + markId + '/changeActiveVehicle', 'PUT', JSON.stringify({
        vehicleId: newVehicleId
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks/' + markId + '/changeActiveVehicle', 'PUT', JSON.stringify({
        vehicleId: newVehicleId
      }))
    }
  }

  fetchVehicleLogs(vehicleId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/logs/vehicle/' + vehicleId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/logs/vehicle/' + vehicleId, 'GET')
    }
  }

  /**
   * ==============================================================================
   * GESTIONE PERMESSI   
   */
  // la trappola si trova in UserAreaContainer
  createMark(mark, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks', 'POST', JSON.stringify(mark))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks', 'POST', JSON.stringify(mark))
    }
  }

  //Questa funzione crea il mark nella tabela MarksResellers
  createMarkReseller(markData) {    
    return this.baseFetch(API_ENDPOINT + '/reseller/marksresellers', 'POST', JSON.stringify(markData))
  }

  addMarkNote(note, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/markNotes', 'POST', JSON.stringify({
        ...note
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/markNotes', 'POST', JSON.stringify({
        ...note
      }))
    }
  }

  updateMarkIssuedDate(markId, thisDate) {
    // issuedDateForUserWithoutOperatorCheckPaymentConfirmationForTrafficStudio
    // 
    return this.baseFetch(API_ENDPOINT + '/marks/issuedDate/' + markId, 'PUT', JSON.stringify({
      // issuedDate: mark.issuedDate == null ? mark.selectedStartDate : mark.issuedDate, // mark.issuedDate,//
      issuedDate: thisDate
    }))
  }

  updateMark(mark, route = 'backoffice') {    

    const validRoutes = ['backoffice', 'reseller'];
    const selectedRoute = validRoutes.includes(route) ? route : 'backoffice';
    const rotta = selectedRoute === 'backoffice' ? '/backoffice/marks/' + mark.id : '/reseller/marks/' + mark.id;

    return this.baseFetch(API_ENDPOINT + rotta, 'PUT', JSON.stringify({
      selectedStartDate: mark.selectedStartDate,
      issuedDate: mark.issuedDate,
      startDate: mark.startDate,
      endDate: mark.endDate,
      active: mark.active,
      userId: mark.userId,
      note: mark.note,
      areaId: mark.areaId,
      firstVehicleId: mark.firstVehicleId,
      secondVehicleId: mark.secondVehicleId,
      markTypeId: mark.markTypeId,
      categoryId: mark.categoryId,
      ceased: mark.ceased,
      action: mark.action,
      circolazione: mark.circolazione,
      periodo: mark.periodo,
      sosta: mark.sosta,      
      price: mark.price,
      fatherMark: mark.fatherMark,
    }))
  }

  updateMarkMe(mark) {
    return this.baseFetch(API_ENDPOINT + '/marks/' + mark.id, 'PUT', JSON.stringify({
      vehicles: mark.vehicles,
      documents: mark.documents
    }))
  }

  renewMark(mark) {
    return this.baseFetch(API_ENDPOINT + '/marks/' + mark.id, 'PUT', JSON.stringify({
      action: 'RENEW',
      vehicles: mark.Vehicles.filter(item => !item.archived),
      markTypeId: mark.MarkTypeId,
      categoryId: mark.categoryId,
      documents: mark.documents
    }))
  }

  createMarkMe(mark) {
    return this.baseFetch(API_ENDPOINT + '/marks', 'POST', JSON.stringify({
      vehicles: mark.vehicles,
      markTypeId: mark.markTypeId,
      categoryId: mark.categoryId,
      documents: mark.documents,
      selectedStartDate: mark.selectedStartDate
    }))
  }

  fetchMarkRulesByCategoryAndAreaAndMark(category, areaId, markType) {
    return this.baseFetch(API_ENDPOINT + '/markRules?areaId=' + areaId + '&category=' + category + '&markType=' + markType, 'GET')
  }

  fetchMarkRulesByMarkType(markType) {
    return this.baseFetch(API_ENDPOINT + '/markRules?markType=' + markType, 'GET')
  }

  fetchMarkPriceRecalculate(markId) {
    return this.baseFetch(API_ENDPOINT + '/markPrices?markId=' + markId, 'GET')
  }

  fetchMarkPrice(userId, markTypeId, categoryId, vehicleIds, vehiclePowerSupplyIds, startDate) {

    var queryParams = '/markPrices?userId=' + userId

    if (categoryId != null) {
      queryParams += '&categoryId=' + categoryId
    }

    if (markTypeId != null) {
      queryParams += '&markTypeId=' + markTypeId
    }

    if (vehicleIds != null) {
      queryParams += '&vehicleIds=' + vehicleIds
    }

    if (vehiclePowerSupplyIds != null) {
      queryParams += '&vehiclePowerSupplyIds=' + vehiclePowerSupplyIds
    }

    if (startDate != null) {
      queryParams += '&startDate=' + startDate
    }

    return this.baseFetch(API_ENDPOINT + queryParams, 'GET')
  }
  // la trappola si trova in client/src/components/Marks/MarksPersonaPerHouseUnits
  fetchMarksPersonsPerHouseUnits(userId, markTypeId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks/marksPersonsPerHouseUnits?userId=' + userId + '&markTypeId=' + markTypeId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks/marksPersonsPerHouseUnits?userId=' + userId + '&markTypeId=' + markTypeId, 'GET')
    }
  }

  fetchMarkMe(markId) {
    return this.baseFetch(API_ENDPOINT + '/marks/' + markId, 'GET')
  }
  // la trappola si trova in UserAreaContainer
  deleteMark(mark, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks/' + mark.id, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks/' + mark.id, 'DELETE')
    }

  }

  editMark(mark, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(
        API_ENDPOINT + '/marks/' + mark.id + '?ceaseAndCreate=1',
        'PUT',
        JSON.stringify(mark)
      )
    } else {      
      return this.baseFetch(        
        API_ENDPOINT + '/' + route + '/marks/' + mark.id + '?ceaseAndCreate=1',
        'PUT',
        JSON.stringify(mark)
      )
    }
  }

  // la trappola si trova in UserAreaContainer
  ceaseMark(mark, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks/' + mark.id, 'PUT', JSON.stringify({
        ceased: true
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks/' + mark.id, 'PUT', JSON.stringify({
        ceased: true
      }))
    }
  }

  fetchMarks(filterObj, limit, offset, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks?limit=' + limit + '&offset=' + offset + '&' + filterObj, 'GET')
    }
  }
  // funzione non usata
  fetchMarksByUserId(id, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/marks?column=userId&value=' + id, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/marks?column=userId&value=' + id, 'GET')
    }
  }

  fetchMarkLogs(markId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/logs/mark/' + markId, 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/logs/mark/' + markId, 'GET')
    }
  }

  fetchMarkRulesByCategoryAndAreaIdAndMark(category, areaId, mark) {
    return this.baseFetch(API_ENDPOINT + '/markRules?areaId=' + areaId + '&category=' + category + '&mark=' + mark, 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE TIPI PERMESSO
   */

  createMarkType(markType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/markTypes/', 'POST', JSON.stringify({
        markType: markType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/markTypes/', 'POST', JSON.stringify({
        markType: markType
      }))
    }
  }

  updateMarkType(markType, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/markTypes/' + markType.id, 'PUT', JSON.stringify({
        markType: markType
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/markTypes/' + markType.id, 'PUT', JSON.stringify({
        markType: markType
      }))
    }
  }

  updateMarkTypes(markTypes, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/markTypes', 'POST', JSON.stringify({
        markTypes: markTypes
      }))
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/markTypes', 'POST', JSON.stringify({
        markTypes: markTypes
      }))
    }
  }

  deleteMarkType(markTypeId, route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/markTypes/' + markTypeId, 'DELETE')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/markTypes/' + markTypeId, 'DELETE')
    }
  }

  fetchMarkTypes() {
    return this.baseFetch(API_ENDPOINT + '/markTypes', 'GET')
  }

  fetchMarkType(markTypeId) {
    return this.baseFetch(API_ENDPOINT + '/markTypes/' + markTypeId, 'GET')
  }

  fetchMarkTypesByCategory(category) {
    return this.baseFetch(API_ENDPOINT + '/markTypes?category=' + category, 'GET')
  }

  fetchMarkTypesByCategoryAndResalable(category, filtra = 'no') {
    return this.baseFetch(API_ENDPOINT + '/markTypes/' + category + '/resalable/' + filtra, 'GET')
  }


  fetchMarkTypesByCategoryAndAreaId(category, areaId) {
    return this.baseFetch(API_ENDPOINT + '/markTypes?areaId=' + areaId + '&category=' + category, 'GET')
  }

  fetchMarkTypesByCategoryAndArea(category, area) {
    return this.baseFetch(API_ENDPOINT + '/markTypes?area=' + area + '&categories=' + category, 'GET')
  }

  fetchMarkTypesByCategoryAndAreaIds(categoryIds, areaIds) {
    return this.baseFetch(API_ENDPOINT + '/markTypes?areaIds=' + areaIds + '&categories=' + categoryIds, 'GET')
  }

  fetchMarkTypesByCategoriesAndArea(categories, area) {
    let categoriesString = ''
    for (let index in categories) {
      categoriesString += categories[index].name
      if (index < categories.length - 1) {
        categoriesString += ','
      }
    }
    return this.baseFetch(API_ENDPOINT + '/markTypes?area=' + area + '&categories=' + categoriesString, 'GET')
  }


  /**
   * ==============================================================================
   * GESTIONE REGISTRAZIONE UTENTE   
   */

  register(user) {
    return this.baseFetch(API_ENDPOINT + '/register', 'POST', JSON.stringify(user))
  }

  sendConfirmEmail(email) {
    return this.baseFetch(API_ENDPOINT + '/register/sendConfirmEmail', 'POST', JSON.stringify({
      email: email
    }))
  }

  changePasswordMe(obj) {
    return this.baseFetch(API_ENDPOINT + '/passwordRecovery/changePassword', 'POST', JSON.stringify({
      key: obj.key,
      userId: obj.userId,
      password: obj.password,
      repeatedPassword: obj.repeatedPassword
    }))
  }

  sendMailChangePasswordMe(obj) {
    return this.baseFetch(API_ENDPOINT + '/passwordRecovery/sendEmail', 'POST', JSON.stringify({
      email: obj.email
    }))
  }

  sendOtp() {
    return this.baseFetch(API_ENDPOINT + '/users/sendOtpByEmail', 'GET')
  }

  sendOtpReseller() {
    return this.baseFetch(API_ENDPOINT + '/reseller/resellers/sendOtpByEmail', 'GET')
  }

  changePasswordMe1(key, password, repeatedPassword) {
    return this.baseFetch(API_ENDPOINT + '/users/changePassword', 'POST', JSON.stringify({
      key: key,
      password: password,
      repeatedPassword: repeatedPassword
    }))
  }

  changePasswordMe1Reseller(key, password, repeatedPassword) {
    return this.baseFetch(API_ENDPOINT + '/reseller/changePassword', 'POST', JSON.stringify({
      key: key,
      password: password,
      repeatedPassword: repeatedPassword
    }))
  }

  confirmMail(userId, key) {
    return this.baseFetch(API_ENDPOINT + '/confirmEmail?key=' + key + '&userId=' + userId, 'POST', JSON.stringify({
      key: key,
      userId: userId
    }))
  }

  /**
   * ==============================================================================
   * GESTIONE AUTENTICAZIONE   
   */

  login(username, password) {
    return new Promise((resolve, reject) => {
      fetch(API_ENDPOINT + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.data.token) {
            resolve(response.data)
          } else {
            reject(response)
            this.eventEmitter.emit('auth-error')
          }
        })
        .catch((error) => {
          this.eventEmitter.emit('auth-error')
          reject({ message: 'Error on LoginForm', ...error })
        })
    })
  }

  setToken(token) {
    this.token = token
  }

  clearToken() {
    this.token = null
  }

  setSpidToken(token) {
    this.spidToken = token
  }

  isSpidEnabled() {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/isEnabled', 'GET').then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  getSpidToken() {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/getToken', 'GET').then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  getSpidIdp() {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/getIdp', 'GET').then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  spidLogin(spidToken) {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/spidLogin', 'POST', JSON.stringify({
        spidToken: spidToken
      })).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  requestEnableSpidProfile(userId) {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/requestEnableProfile', 'POST', JSON.stringify({
        userId: userId
      })).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  enableSpidProfile(userId, key) {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/auth/spid/enableProfile', 'POST', JSON.stringify({
        userId: userId,
        key: key
      })).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  /**
   * ==============================================================================
   * GESTIONE PAGAMENTI   
   */

  fetchMarkPaymentStatus(markId) {
    return this.baseFetch(API_ENDPOINT + '/backoffice/payment/check/' + markId, 'GET')
  }

  buyParkingTickets(parkingTickets) {
    return this.baseFetch(API_ENDPOINT + '/payment/buy', 'POST', JSON.stringify({}))
  }

  buyMark(markId) {
    return this.baseFetch(API_ENDPOINT + '/payment/buyMark/' + markId, 'POST', JSON.stringify({}))
  }

  buyVehicleChangeRequest(VehiclesChangeRequestsId) {
    return this.baseFetch(API_ENDPOINT + '/payment/buyVehicleChangeRequest/' + VehiclesChangeRequestsId, 'POST', JSON.stringify({}))
  }

  decryptSellaOperationResult(a, b) {
    return this.baseFetch(API_ENDPOINT + '/payment/result?a=' + a + '&b=' + b, 'GET')
  }

  nexiOperationResult(req) {

    var parameters = 'esito=' + req.esito

    if (req.importo != null)
      parameters += '&importo=' + req.importo
    if (req.divisa != null)
      parameters += '&divisa=' + req.divisa
    if (req.data != null)
      parameters += '&data=' + req.data
    if (req.orario != null)
      parameters += '&orario=' + req.orario
    if (req.codAut != null)
      parameters += '&codAut=' + req.codAut
    if (req.codTrans != null)
      parameters += '&codTrans=' + req.codTrans
    if (req.messaggio != null)
      parameters += '&messaggio=' + req.messaggio
    if (req.codiceEsito != null)
      parameters += '&codiceEsito=' + req.codiceEsito
    if (req.mac != null)
      parameters += '&mac=' + req.mac


    return this.baseFetch(API_ENDPOINT + '/gestpay/result?' + parameters, 'GET')
  }

  /* alla ricerca di parametri predeterminati in una funzione che non fornisce esiti diretti
  xpayOperationResult(req) {

    //var parameters = ''
    var parameters = 'esito=' + req.esito // il risultato ottenuto contiene un ?&importo

    if (req.importo != null)
      parameters += '&importo=' + req.importo
    if (req.codTrans != null)
      parameters += '&codTrans=' + req.codTrans
    if (req.paymentid != null)
      parameters += '&paymentid=' + req.paymentid
    if (req.uuid != null)
      parameters += '&uuid=' + req.uuid


    return this.baseFetch(API_ENDPOINT + '/gestpay/result?' + parameters, 'GET')
  }
  */
  /* req oggetto apparentemente non compatibile
  xpayOperationResult(req) {
    const url = new URL(API_ENDPOINT + '/gestpay/result');
    const params = new URLSearchParams(req);
  
    url.search = params.toString();
  
    return this.baseFetch(url, 'GET');
  }*/
  /*
  xpayOperationResult(req) { //Uncaught (in promise) TypeError: req.hasOwnProperty is not a function
    let url = API_ENDPOINT + '/gestpay/result';
    let parameters = '';
  
    for (let key in req) {
      if (req.hasOwnProperty(key) && req[key] !== null) {
        parameters += `${key}=${req[key]}&`;
      }
    }
  
    if (parameters.length > 0) {
      parameters = parameters.slice(0, -1); // Remove the trailing '&'
      url += '?' + parameters;
    }
  
    return this.baseFetch(url, 'GET');
  }*/
  xpayOperationResult(req) {
    let url = API_ENDPOINT + '/gestpay/result';
    let parameters = '';
  
    for (let key in req) {
      if (Object.prototype.hasOwnProperty.call(req, key) && req[key] !== null) {
        parameters += `${key}=${req[key]}&`;
      }
    }
  
    if (parameters.length > 0) {
      parameters = parameters.slice(0, -1); // Remove the trailing '&'
      url += '?' + parameters;
    }
  
    return this.baseFetch(url, 'GET');
  }

  getPaymentConfig() {
    return new Promise((resolve, reject) => {
      this.baseFetch(API_ENDPOINT + '/payment/getconfig', 'GET').then(data => {
        if (data.result === "ok")
          resolve(data.data)
        else
          reject(data.error)

      }).catch(err => {
        reject(err)
      })

    })
  }

  fetchPaymentMethods() {
    return this.baseFetch(API_ENDPOINT + '/paymentMethods', 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE ORDINI
   */
  fetchThisMarkOrdersHistory(markId) {
    return this.baseFetch(API_ENDPOINT + '/orders/ordersHistoryForThis/' + markId, 'GET')
  }

  fetchMyMarksPendingOrders(markId) {
    return this.baseFetch(API_ENDPOINT + '/orders/marksPendingOrders/' + markId, 'GET')
  }

  fetchMyVehiclesChangePendingOrders(vehiclesChangeRequestsId) {
    return this.baseFetch(API_ENDPOINT + '/orders/vehiclesChangePendingOrders/' + vehiclesChangeRequestsId, 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE GEOGRAFICA
   */
  fetchGeoProvince() {
    return this.baseFetch(API_ENDPOINT + '/geo/province', 'GET')
  }

  fetchGeoCities() {
    return this.baseFetch(API_ENDPOINT + '/geo/cities', 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE MARK RESELLER
   */

  // salva questo MarkID,UserID,ResellerID,Importo anche nella tabella MarkResellers
  saveThisMarkReseller(MarkID, UserID, ResellerID, Importo) {    
    return this.baseFetch(API_ENDPOINT + '/reseller/marksresellers/', 'POST', JSON.stringify({
      markId: MarkID,
      userId: UserID,
      resellerId: ResellerID,
      price: Importo
    }))
  }

  exportResellerMonth(reseller, date) {
    return this.baseFetch(API_ENDPOINT + '/reseller/resellers/exportsrange/?d=' + date + '&r=' + reseller, 'GET')
  }

  // ottiene la lista dei marks resellers per il reseller specificato
  // viene usata per mostrare il badge nell'header e tutti i resoconti della modale
  getMarksResellersList(ResellerID, ResellerName, date = null) { 
    if (date == null) {             
      return this.baseFetch(API_ENDPOINT + '/reseller/resellers/marksresellers/' + ResellerID + '/' + ResellerName, 'GET')
    } else {
      
      return this.baseFetch(API_ENDPOINT + '/reseller/resellers/marksresellers/' + ResellerID + '/' + ResellerName + '?d=' + date, 'GET')
    }
  }

  // ottiene il nome del reseller, viene utilizzato per le liste abbonamenti fatti dai reseller
  async getMarksResellersName(ResellerID) {    
    return this.baseFetch(API_ENDPOINT + '/reseller/resellers/resellerName/' + ResellerID, 'GET');    
  }

  // ottiene la lista dei mark reseller per il reseller specificato, con allegati tutti i marks, users, vehicles
  getMarksResellersFullList(ResellerID) {    
    return this.baseFetch(API_ENDPOINT + '/reseller/resellers/marksresellers/fulllist/' + ResellerID, 'GET')
  }

  // ottiene l'importo del budget inserito nell'utente rivenditore
  getResellerBudget(ResellerID) {
    return this.baseFetch(API_ENDPOINT + '/reseller/budget/' + ResellerID, 'GET')
  }

  // salva l'importo del budget inserito nell'utente rivenditore da backoffice
  saveResellerBudget(ResellerID, Importo) {
    return this.baseFetch(API_ENDPOINT + '/backoffice/users/budget/' + ResellerID, 'PUT', JSON.stringify({
      budget: Importo
    }))
  }
  // ottiene l'importo del budget inserito nell'utente rivenditore e tutti i suoi dati
  getReseller(ResellerID) {
    return this.baseFetch(API_ENDPOINT + '/reseller/resellers/' + ResellerID, 'GET')
  }


  fetchMarkResellers(markId) {
    return this.baseFetch(API_ENDPOINT + '/reseller/marks/' + markId, 'GET')
  }
  /**
   * ==============================================================================
   * GESTIONE VEICOLI
   */
  getVehicle(VehicleId) {
    return this.baseFetch(API_ENDPOINT + '/reseller/vehicles/' + VehicleId, 'GET')
  }

  /**
   * ==============================================================================
   * GESTIONE TESTS
   */

  runTest1(route = 'backoffice') {
    if (route == '') {
      return this.baseFetch(API_ENDPOINT + '/tests/one', 'GET')
    } else {
      return this.baseFetch(API_ENDPOINT + '/' + route + '/tests/one', 'GET')
    }
  }

}

class ApiEvents extends EventEmitter { }

export default new SostaOnlineApiService()
