import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { actions as authActions} from 'redux/modules/Auth'
import { getValidPlateNumberContent } from 'libs/vehicles';
import { MARK_TYPE_HYBRID } from 'libs/marks';
import { MARK_TYPE_ELECTRIC } from 'libs/marks';
import { MARK_TYPE_GREEN } from 'libs/marks';


const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch)    
  }
}


class NewVehicleModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onAddVehicle: PropTypes.func.isRequired,    
    type: PropTypes.string,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {            
      checkErrors: null,
      sendErrors: null ,
      ownershipTypeId: null,
      powerSupplyId: null,
      vehicleOwnershipTypes: [],
      vehiclePowerSupplies:  [] ,
      plateNumber: null
    }

    this.addVehicle       = this.addVehicle.bind(this)
    this.checkFields      = this.checkFields.bind(this)
    this.fetchInitialData = this.fetchInitialData.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  async fetchInitialData () {
                        
    this.setState({loading: true})
  
    try {
      const vehicleOwnershipTypes = await SostaOnlineApiService.fetchVehicleOwnershipTypes()
      const vehiclePowerSupplies  = await SostaOnlineApiService.fetchVehiclePowerSupplies()    

      if ( vehiclePowerSupplies && vehiclePowerSupplies.result === 'ok' && vehicleOwnershipTypes && vehicleOwnershipTypes.result === 'ok' ) {
        this.setState({                    
            vehicleOwnershipTypes: vehicleOwnershipTypes.data,
            vehiclePowerSupplies:  vehiclePowerSupplies.data           
        })
      
      } 
      this.setState({loading: false})
    }
    catch (error){               
      console.log("ERROR",error)      
      this.setState({loading: false})
    }
 
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchInitialData ()
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []    

    const {
      plateNumber,
      powerSupplyId,
      ownershipTypeId
    } = this.state

    if ( plateNumber == null || plateNumber.length <= 0 )
      errors.push("Non hai specificato una targa")

    if ( powerSupplyId == null ) 
      errors.push("Non hai specificato il tipo di alimentazione")
    
    if( ownershipTypeId == null )
      errors.push("Non hai specificato la proprietà del veicolo")

    return errors;

  }

  /**
   * --------------------------------------------------------------
   */
  async addVehicle() {       
    var vehicle = null


    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
          
    vehicle = {
        plateNumber: this.state.plateNumber,
        type: this.state.ownershipTypeId,
        powerSupply: this.state.powerSupplyId
    }

    //console.log("VEHICLE",vehicle)

    SostaOnlineApiService.createVehicleMe(vehicle)
      .then( addVehicle =>{
        if ( addVehicle.result === 'ok' ) {
            if ( this.props.onAddVehicle ) 
                this.props.onAddVehicle(addVehicle.data)


            this.close()
        }
        else {
            this.setState({sendErrors: addVehicle.error})
        }
      } )
      .catch( error => {
        this.setState({sendErrors: error.error})
      })
            
  }  

  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   */
  stateInit() {
    this.setState({                  
      checkErrors: null,
      sendErrors: null,
      plateNumber: null,
      ownershipTypeId: null,
      powerSupplyId: null,
    })

    this.props.initialize({ 
      plateNumber: null,
      ownershipTypeId: null,
      powerSupplyId: null,
    })

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getPowerSupplyes() {
    
    if( this.props.type ) {

      if ( this.props.type == MARK_TYPE_HYBRID )
        return this.state.vehiclePowerSupplies.filter( item =>  item.hybrid  )
      else if ( this.props.type == MARK_TYPE_ELECTRIC )
        return this.state.vehiclePowerSupplies.filter( item =>  item.electric  )
      else if ( this.props.type == MARK_TYPE_GREEN )
        return this.state.vehiclePowerSupplies.filter( item =>  item.hybrid || item.electric )
        
    }
    
    return this.state.vehiclePowerSupplies

  }

 

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,      
    } = this.props

    const {
      sendErrors,
      checkErrors, 
      vehiclePowerSupplies,
      vehicleOwnershipTypes         
    } = this.state    

    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faCar} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>NUOVO VEICOLO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            
            <div className="row">
                <Field 
                  name="powerSupplyId" 
                  className="form-control" 
                  component="select" 
                  onChange={ e => { this.setState({powerSupplyId: e.target.value}) }}
                  required
                  placeholder="Seleziona il tipo di alimentazione del nuovo veicolo..."
                  >
                  <option>Seleziona il tipo di alimentazione del nuovo veicolo...</option>
                  { this.getPowerSupplyes().map( ( ps , index )=> {
                    return ( <option key={index} value={ps.id}>{ps.name}</option> )
                  })}
                  
                </Field>
                <br/>
            </div>   

            <div className="row">
                <Field 
                  name="ownershipTypeId" 
                  className="form-control" 
                  component="select" 
                  onChange={ e => { this.setState({ownershipTypeId: e.target.value}) }}
                  required
                  placeholder="Seleziona la proprietà del veicolo..."
                  >
                  <option>Seleziona la proprietà del veicolo...</option>
                  { vehicleOwnershipTypes.map( ( ot , index )=> {
                    return ( <option key={index} value={ot.id}>{ot.name}</option> )
                  })}
                  
                </Field>
                <br/>
            </div>   

            <div className="row">
                <Field 
                  name="plateNumber" 
                  className="form-control" 
                  component="input"
                  onChange={ e => {                     
                    var plateNumber = getValidPlateNumberContent(e.target.value)
                    this.setState({plateNumber: plateNumber})
                  }}                                   
                  required                  
                  placeholder="Inserisci la targa del nuovo veicolo..."
                  normalize={ value => getValidPlateNumberContent(value) }
                  />                                  
                <br/>
            </div>   
                                        
            <div className="row">
              { sendErrors && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h4>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn"   onClick={ () => this.close() }>Annulla</Button>{' '}
                <Button className="btn mainBtn" onClick={() => this.addVehicle()} >Invia</Button>
              </div>
            </div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(reduxForm({ 
  form: 'NewVehicleModal',
  enableReinitialize: true
})(NewVehicleModal))
