import Parser from 'html-react-parser';

export const NEWS_SECTIONS = {
    USERLOGIN     : { value: "USERLOGIN",     descr: "Sezione Login"     },
    USERMARKS     : { value: "USERMARKS",     descr: "Sezione Permessi"  },
    USERVEHICLES  : { value: "USERVEHICLES",  descr: "Sezione Veicoli"   },
    USERPROFILE   : { value: "USERPROFILE",   descr: "Sezione Profilo"   },
    USERORDERS    : { value: "USERORDERS",    descr: "Sezione Orders"    },
    USERDOCUMENTS : { value: "USERDOCUMENTS", descr: "Sezione Documenti" },
    USERMESSAGES  : { value: "USERMESSAGES",  descr: "Sexione Messaggi"  }
}

export const NEWS_TYPES = {
    INFO    : { value: "INFO",    descr: "Info" },
    WARNING : { value: "WARNING", descr: "Warning" },
    ALERT   : { value: "ALERT",   descr: "Alert" },
    ADV     : { value: "ADV",     descr: "Adv" },
}


/**
 * ----------------------------------------------------------------
 * 
 */
export function getNewsStyle(news) {

  var style = 'successLabel'

  if ( news ) {
    if ( news.type == NEWS_TYPES.INFO.value ) {
      style = 'successLabel'
    }
    else if ( news.type == NEWS_TYPES.WARNING.value ) {
      style = 'warningLabel'
    }
    else if ( news.type == NEWS_TYPES.ALERT.value ) {
      style = 'alertLabel'
    }
    else if ( news.type == NEWS_TYPES.ADV.value ) {
      style = ''
    }
  }

  return style
}

/**
 * ----------------------------------------------------------------
 * 
 */
export function getHtmlMsgFromString(msg) {    
  var htmlMessage = msg.replace(/\n/g,"<br/>")    
  return  Parser("<span>"+htmlMessage+"</span>","text/html")
}