import React, { Component } from 'react'
import { reduxForm, Field, change } from 'redux-form'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'//
import { connect } from 'react-redux'
import DatePickerFormComponent from '../redux-form/DatePickerFormComponent'
import moment, { duration } from 'moment'
import { Oval } from 'react-loader-spinner'
import { MARK_TYPE_ELECTRIC, MARK_TYPE_FAMILY, MARK_TYPE_GREEN, MARK_TYPE_HYBRID, PRICE_TYPE_PERSON_PER_HOUSING_UNIT, getMarkExpirationDate } from 'libs/marks'
import { MARK_DURATION, MARK_EXPIRATION_DATE, MARK_ENABLE_START_DATE, MARK_ENABLE_END_DATE, getSettingByName } from 'libs/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import * as regularIcons from '@fortawesome/free-regular-svg-icons'
import { OverlayTrigger, Tooltip, DropdownButton, MenuItem } from 'react-bootstrap'
import MarksPersonsPerHouseUnitsModal from './Marks/ResellerMarksPersonsPerHouseUnitsModal'
import { isNull } from 'lodash'
import './ResellerUserDetailMarkForm.css'
import ReportResellerModal from './Modals/ReportResellerModal'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

// Funzione per fare il calcolo della data di scadenza con base nella data di inizio e nell tipo di abbonamento
//function calculateEndDate(startDate, markType) {
//const durationInMonths = markType.durationInMonths   // Ottiene la durata in mesi del tipo di abbonamento dall'oggetto markType
//const endDate = new Date(startDate);   // Crea una nuova istanza di Date basata sulla data di inizio fornita
//endDate.setMonth(endDate.getMonth() + durationInMonths); // Aggiunge la durata in mesi al mese della data di termine
//console.log('funzione calculateEndDate chiamata con successo')
//return endDate; // Restituisce la data di termine calcolata
//}

class ResellerUserDetailMarkForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fetchingMarkTypes: false,
      category: null,
      allMarkTypes: [],
      filteredMarkTypes: [],
      notSealableFilteredMarkTypes: [],
      markTypeId: null,
      markRules: {},
      errorFetching: null,
      vehicles: [],
      price: {},
      quantity: 1,
      error: false,
      markTypeDocuments: [],
      selectedMarkType: null,
      selectedMarkTypeCategory: null,
      selectedTotalPrice: 0,
      priceDescr: null,
      enableCash: false,
      enablePagoPa: true,
      enableReclaim: false,
      selectedStartDate: null,
      selectedEndDate: null,  //Nuovo campo nello state per fare lo STORAGE della data di SCADENZA
      settings: [],
      showMarkPerPersonUnits: false,
      showModal: false,
      selectedPlateNumber: [],
      isButtonDisabled: true
    }

    this.onUserCategorySelected = this.onUserCategorySelected.bind(this)
    this.onMarkTypeSelected = this.onMarkTypeSelected.bind(this)
    this.onVehicleChange = this.onVehicleChange.bind(this)
    this.onAddQuantity = this.onAddQuantity.bind(this)
    this.onRemoveQuantity = this.onRemoveQuantity.bind(this)
    this.countMarks = this.countMarks.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.showMarksPersonsPerHouseUnits = this.showMarksPersonsPerHouseUnits.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async componentDidMount() {
    try {
      this.updateSelectedEndDate();
      this.formReset()
      this.setState({ loading: true, error: false })
      const markTypes = await SostaOnlineApiService.fetchMarkTypes()

      this.setState({
        allMarkTypes: markTypes.data,
        filteredMarkTypes: markTypes.data,
        loading: false
      })

      const payments = await SostaOnlineApiService.fetchPaymentSettings()

      if (payments.data.length === 0) {
        this.setState({ enableCash: false })
        this.setState({ enablePagoPa: false })
        this.setState({ enableReclaim: false })
      }
      else {
        this.setState({ enableCash: payments.data.enableCash })
        this.setState({ enablePagoPa: payments.data.enablePagoPa })
        this.setState({ enableReclaim: payments.data.enableReclaim })
      }

      const settings = await SostaOnlineApiService.fetchPublicSettings()

      if (settings.data != null && settings.data.length > 0) {
        this.setState({ settings: settings.data })
      }

    }
    catch (e) {
      console.error(e)
      this.setState({ error: true, loading: false })
    }
  }

  /**
 * --------------------------------------------------------------
 * 
 */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedStartDate !== prevState.selectedStartDate ||
      this.state.selectedMarkType !== prevState.selectedMarkType ||
      this.state.settings !== prevState.settings
    ) {
      this.updateSelectedEndDate();
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchFilteredMarkTypes() {
    let cat = this.props.user.categories.filter(item => item.id === parseInt(this.state.category, 10))
    if (cat.length > 0) {
      this.setState({ fetchingMarkTypes: true })

      //const markTypes = await SostaOnlineApiService.fetchMarkTypesByCategory(cat[0].id)
      const markTypes = await SostaOnlineApiService.fetchMarkTypesByCategoryAndResalable(cat[0].id, 'si')

      const markTypesNonVendibili = await SostaOnlineApiService.fetchMarkTypesByCategoryAndResalable(cat[0].id, 'no')

      //console.log(markTypes)
      this.setState({
        filteredMarkTypes: markTypes.data,
        fetchingMarkTypes: false,
        notSealableFilteredMarkTypes: markTypesNonVendibili.data,
        rules: {},
        price: {}
      })
    }
  }

  /**
   * --------------------------------------------------------------
   */
  formReset() {

    this.setState({
      priceDescr: null,
      errorFetching: null,
      error: false
    })

    if (this.props.onResetMarkSubmitForm != null)
      this.props.onResetMarkSubmitForm()


  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   */
  onUserCategorySelected(e) {
    this.formReset()

    var selectedMarkTypeCategory = this.props.user.categories.find((category) => category.id == e.target.value)
    //console.log("category",e.target.value)
    //console.log("selectedMarkTypeCategory",selectedMarkTypeCategory)

    this.setState(
      {
        category: e.target.value,
        selectedMarkTypeCategory: selectedMarkTypeCategory
      }, () => { this.fetchFilteredMarkTypes() }
    )
  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   * @returns 
   */
  async onMarkTypeSelected(e) {

    this.formReset()

    try {
      const rules = await SostaOnlineApiService.fetchMarkRulesByMarkType(e.target.value)
      if (rules.data.length === 0) {
        this.setState({
          errorFetching: "Errore nella ricezione delle regole del Permesso",
          markRules: {},
          markTypeId: null,
          selectedMarkType: null,
          markTypeDocuments: []
        }, () => {
          this.updatePrice()
        })

        return
      }

      const today = moment()

      let activeMarks = this.props.user.marks.filter(item => item.active === true && item.ceased === false && item.archived === false && today.diff(item.endDate, 'days') <= 0)
      let newArray = []

      activeMarks.map(element => {
        if (element.MarkType.id == e.target.value) {
          newArray.push(element)
        }
      });
      //Verifico se l'utente ha già raggiunto il limite massimo di permessi attivi di quel tipo
      if (newArray.length >= rules.data[0].marksLimit) {
        this.setState({
          markLimitReached: true,
          errors: 'L\'utente ha già superato il numero massimo di permessi attivi per il suo tipo di categoria utente'
        })
        return
      }

      if (rules.data[0].vehicleLimit > 0 && this.state.vehicles.length === 0) {
        this.setState({ vehicles: [''] })
      }

      let cat = this.props.user.categories.filter(item => item.id === parseInt(this.state.category, 10))

      if (cat.length <= 0) {

        this.setState({
          markRules: {},
          markTypeId: null,
          selectedMarkType: null,
          markTypeDocuments: [],
          markLimitReached: false,
        }, () => {
          this.updatePrice()
        })

        return
      }

      const markType = this.state.allMarkTypes.filter(item => item.id + '' === e.target.value + '')[0]

      if (markType.virtualizable) {
        this.props.change('virtual', true)
      }

      if (this.state.category && markType && markType.Categories.length > 0) {
        const foundedMarkTypeCategory = markType.Categories.filter(item => {
          return item.id + '' === this.state.category + ''
        })

        if (foundedMarkTypeCategory.length > 0 && foundedMarkTypeCategory[0].MarkTypeCategories) {
          this.props.change('circolazione', foundedMarkTypeCategory[0].MarkTypeCategories.circolazione)
          this.props.change('periodo', foundedMarkTypeCategory[0].MarkTypeCategories.periodo)
          this.props.change('sosta', foundedMarkTypeCategory[0].MarkTypeCategories.sosta)
          this.setState({
            selectedMarkTypeCategory: foundedMarkTypeCategory[0].MarkTypeCategories,
          })
        }
      }

      this.setState({
        markRules: rules.data[0],
        markTypeId: e.target.value,
        selectedMarkType: markType,
        markTypeDocuments: markType.DocumentTypes,
        markLimitReached: false,
      }, () => {
        // Imposta la data di scadenza con base nell tipo di abbonamento
        //Questo blocco di codice imposta essenzialmente lo stato del componente con informazioni sul tipo di abbonamento selezionato, imposta automaticamente la data di inizio dell'abbonamento in base alla data di scadenza e per ultimo aggiorna il prezzo
        //const expirationDate = getMarkExpirationDate(markType);
        //console.log("Expiration Date:", expirationDate);//Console per vedere se expiration date e valido


        //this.setState({ selectedStartDate: expirationDate });
        //this.props.change('selectedStartDate', expirationDate);

        //Calcolo e impostazione della data di scadenza nello state
        //const endDate = calculateEndDate(expirationDate, markType);// Calcola la data di termine in base alla data di scadenza e al tipo di abbonamento 
        //console.log("End Date:", endDate);//Console per vedere se End date e valido


        //this.setState({ selectedEndDate: endDate });// Imposta la data di termine nello stato del componente
        //this.props.change('selectedEndDate', endDate);// Aggiorna il valore del campo 'selectedEndDate' nel componente del genitore utilizzando Redux Form

        this.updatePrice()
      })

    }
    catch (ex) {
      this.setState({
        markRules: {},
        markTypeId: null,
        selectedMarkType: null,
        markTypeDocuments: [],
        errorFetching: "Errore nella ricezione dati del tipo permesso"
      }, () => {
        this.updatePrice()
      })
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} e 
   * @param {*} index 
   */
  onVehicleChange(e, index) {
    this.formReset();
    const plateNumberValue = e.target.options[e.target.selectedIndex].text.split(' - ')[0];
  
    if (this.state.selectedPlateNumber[index]) {
      this.setState(prevState => ({
        selectedPlateNumber: prevState.selectedPlateNumber.map((plate, i) => i === index ? plateNumberValue : plate)
      }));
    } else {
      this.setState(prevState => ({
        selectedPlateNumber: [...prevState.selectedPlateNumber, plateNumberValue]
      }));
    }
  
    let newVehicles = [...this.state.vehicles];
    newVehicles[index] = e.target.value;
    this.setState({ vehicles: newVehicles }, () => this.updatePrice());

    this.setState({ selectedVehicle: e.target.value }, this.updateButtonState);

  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async updatePrice() {
    try {
      if (this.state.selectedMarkType == null) {
        return
      }

      let price = 0

      let calculatedPrice = await SostaOnlineApiService.fetchMarkPrice(
        this.props.user.id,
        this.state.selectedMarkType.id,
        this.state.selectedMarkTypeCategory.CategoryId,
        this.printVehicles(this.state.vehicles.filter(item => item !== '')),
        null,
        this.state.selectedStartDate
      )
        .catch(error => {
          console.log("ERROR", error)

          var priceDescr = ''

          if (error.result == "ko" && error.error != null) {
            priceDescr = error.error
          }

          this.setState({
            errorFetching: "Errore durante il calcolo del prezzo",
            priceDescr: priceDescr
          })
        })

      if (calculatedPrice == null || calculatedPrice.data == null) {
        this.setState({
          errorFetching: "Errore durante il calcolo del prezzo",
        })
      }
      else {
        price = parseFloat(calculatedPrice.data.price).toFixed(2)
        this.setState({
          price: price,
          priceDescr: calculatedPrice.data.reasons[0],
          selectedTotalPrice: price,
          errorFetching: null
        })
        this.props.change('total', price)
      }

    }
    catch (ex) {
      console.log("ERROR", ex)

      this.setState({
        errorFetching: "Errore durante il calcolo del prezzo "
      })

    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  onAddQuantity() {
    this.formReset()
    if (this.state.quantity >= 99) {
      return
    }

    this.props.dispatch(change('resellerUserDetailMark', 'quantity', this.state.quantity + 1))
    this.setState({ quantity: this.state.quantity + 1 }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  onRemoveQuantity() {
    this.formReset()
    if (this.state.quantity <= 1) {
      return
    }

    this.props.dispatch(change('resellerUserDetailMark', 'quantity', this.state.quantity - 1))
    this.setState({ quantity: this.state.quantity - 1 }, () => this.updatePrice())
  }

  /**
   * --------------------------------------------------------------
   * @param {*} areas 
   * @returns 
   */
  printAreas(areas) {
    let string = ''
    areas.map((item, index) => {
      string = string + item.code

      if (index < areas.length - 1) {
        string = string + ', '
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicles 
   * @returns 
   */
  printVehicles(vehicles) {
    let string = ''
    vehicles.map((item, index) => {
      string = string + item

      if (index < vehicles.length - 1) {
        string = string + ','
      }
    })
    return string
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markTypeId 
   * @returns 
   */
  countMarks(markTypeId) {
    return this.props.user.marks.filter(item => item.id === markTypeId).length
  }

  /**
   * --------------------------------------------------------------
   * @param {*} data 
   */
  async onDateChange(data) {
    this.formReset()
    await this.setState({ selectedStartDate: data })
    this.updatePrice()

  }

  /**
   * --------------------------------------------------------------
   * @param {*} date 
   * @returns 
   */
  checkSelectedStartDate() {

    var {
      selectedMarkType,
      settings,
      selectedStartDate,
    } = this.state

    var disable = false

    if (selectedMarkType == null || selectedStartDate == null || settings == null)
      return disable

    var markDuration = getSettingByName(settings, MARK_DURATION + selectedMarkType.id)
    var enableStartDate = getSettingByName(settings, MARK_ENABLE_START_DATE)

    if (enableStartDate == null) {
      enableStartDate = new Object()
      enableStartDate.name = MARK_ENABLE_START_DATE
      enableStartDate.value = "0"
    }


    if (selectedMarkType.type == MARK_TYPE_FAMILY) {
      
      var duration = parseInt(markDuration.value, 10)
      if (moment(selectedStartDate).add(duration, 'days').isBefore(moment(), 'days')) {
        disable = true
      }
    }

    // Ottiene la data di oggi
    const currentDate = moment();

    //ID marktype FERROVIARIO MENSILE:19
    //ID marktype FERROVIARIO ANNUALE:20
    //Verifica se la data scelta è un giorno prima dell momento attuale
    const { name } = selectedMarkType
    const nameHasFerrovi = name.toUpperCase().includes('FERROVI')

    if (moment(selectedStartDate).isBefore(currentDate, 'day') && selectedMarkType.type != MARK_TYPE_FAMILY && nameHasFerrovi === false) {
      disable = true;
    }

    //Verifica se la data scelta e lo current date o posteriore al momento attuale
    if (moment(selectedStartDate).isSameOrAfter(currentDate, 'day')) {
      disable = false;
    }

    return disable
  }

  calculateEndDate = () => {
    const { selectedStartDate, selectedMarkType, settings } = this.state;
    //console.log('this.state:', this.state)

    if (selectedStartDate && selectedMarkType && settings) {
      const permessoId = selectedMarkType.id
      //console.log('selectedMarktypeNAME:',selectedMarkType.name )
      const markDurationByDays = getSettingByName(settings, MARK_DURATION + permessoId);
      const markDurationByDate = getSettingByName(settings, MARK_EXPIRATION_DATE + permessoId);

      if (markDurationByDate !== null) {
        //E una DATA
        const endDate = moment(markDurationByDate.value)
        return endDate.format('DD/MM/YYYY')
      } else {
        //E un numero
        const endDate = moment(selectedStartDate).add(parseInt(markDurationByDays.value, 10), 'days');
        return endDate.format('DD/MM/YYYY')
        //const endDate = moment(selectedStartDate).add(markDuration, 'days');
      }

    }
    return '';
  };

  /**
   * --------------------------------------------------------------
   * 
   */
  updateSelectedEndDate = () => {
    const { selectedStartDate, selectedMarkType, settings } = this.state;
    if (selectedStartDate && selectedMarkType && settings) {
      const permessoId = selectedMarkType.id;
      const markDurationByDays = getSettingByName(settings, MARK_DURATION + permessoId);
      const markDurationByDate = getSettingByName(settings, MARK_EXPIRATION_DATE + permessoId);

      let endDate = '';
      if (markDurationByDate !== null) {
        endDate = moment(markDurationByDate.value).format('DD/MM/YYYY');
      } else {
        endDate = moment(selectedStartDate).add(parseInt(markDurationByDays.value, 10), 'days').format('DD/MM/YYYY');
      }

      this.setState({ selectedEndDate: endDate });
    } else {
      this.setState({ selectedEndDate: null });
    }
  };



  /**
   * --------------------------------------------------------------
   * @param {*} markTypeId 
   */
  showMarksPersonsPerHouseUnits(show) {
    this.setState({ showMarkPerPersonUnits: show })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  isMarksPersonsPerHoursActive() {
    var markType = this.state.selectedMarkType
    var isActive = false

    if (markType != null && markType.Prices != null && markType.Prices.length > 0) {

      var filteredPrices = markType.Prices.filter((price) => price.type == PRICE_TYPE_PERSON_PER_HOUSING_UNIT)

      if (filteredPrices != null && filteredPrices.length > 0)
        isActive = true
    }

    return isActive
  }

  /**
 * --------------------------------------------------------------
 * @returns 
 */
  handleButtonClick = () => {
    this.setState({ showModal: true });
  }

  /**
* --------------------------------------------------------------
* @returns 
*/
  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleDateChange = (date) => {
    this.setState({ selectedStartDate: date }, this.updateButtonState);
  }

  updateButtonState = () => { //Ho utilizzato questa funzione per fare in modo che il btn sia attivo solo se l'utente ha scelto una data e vehicle
    const { selectedStartDate, selectedVehicle } = this.state;
    const isButtonDisabled = !selectedStartDate || !selectedVehicle;
    this.setState({ isButtonDisabled });
  }

  removeVehicle(index) {    //Cosi l'user puo eliminare il vehicle solo se > 1
    const { vehicles } = this.state;
    if (vehicles.length > 1) {
      this.setState({ vehicles: vehicles.filter((_, i) => i !== index) }, () => this.updatePrice());
    }
  }
  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      //handleSubmit,
      settings,
      markCreateError,
      user,
      operator,
      newMark,
      marksResellersConfirmed, // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
      downloading,
      markData,
      // markError, esiste già con altro nome markCreateError
      markSuccess,
      onCreateMark
    } = this.props

    const {
      selectedMarkType,
      selectedMarkTypeCategory,
      notSealableFilteredMarkTypes,
      errorFetching,
      priceDescr,
      showMarkPerPersonUnits,
      error,
      vehicles,
      selectedPlateNumber,
      showModal,
      category,
      markTypeId,
      selectedStartDate,
      selectedEndDate,
      price,
      quantity,
      selectedTotalPrice,
      isButtonDisabled
    } = this.state

    var markEnableStartDate = getSettingByName(settings, MARK_ENABLE_START_DATE)

    if (this.state.loading) {
      return (
        <div>
          <div className="row"><h3>Caricamento in corso...</h3></div>
          <div className="row" style={{ padding: "2em", height: "10em" }}>
            <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }

    if (error) {
      return (<div className="text-danger">Errore di ricezione dati</div>)
    }

    var userVehicles = user.vehicles

    if (selectedMarkType && selectedMarkType.type === MARK_TYPE_HYBRID) {
      userVehicles = user.vehicles.filter(item => item.VehiclePowerSupply.hybrid)
    }
    else if (selectedMarkType && selectedMarkType.type === MARK_TYPE_ELECTRIC) {
      userVehicles = user.vehicles.filter(item => item.VehiclePowerSupply.electric)
    }
    else if (selectedMarkType && selectedMarkType.type === MARK_TYPE_GREEN) {
      userVehicles = user.vehicles.filter(item => (item.VehiclePowerSupply.hybrid || item.VehiclePowerSupply.electric))
    }

    //const { selectedStartDate, selectedEndDate } = this.props;


    //const markType = this.state.allMarkTypes.filter(item => item.id + '' === e.target.value + '')[0]
    //var markDuration    = getSettingByName(settings,MARK_DURATION+selectedMarkType.id )
    //var duration   =parseInt(markDuration.value,10)
    const endDate = this.calculateEndDate();

    return (
      <React.Fragment>
        { /* <form onSubmit={handleSubmit} style={{ padding: "15px" }}></form> */ }
        <form style={{ padding: "15px" }}>
          <div className="box">

            <div className="row form-group">
              <div className="col-md-6 text-left">
                <label>Seleziona Categoria</label>
                <Field onChange={this.onUserCategorySelected} name="categoryId" className="form-control" component="select" required>
                  <option value={''}>Seleziona Categoria Utente</option>
                  {this.props.user.categories.map((item, index) =>
                    <option key={item.id + '-' + index} value={item.id}>{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</option>
                  )}
                </Field>
                {/*notSealableFilteredMarkTypes &&
                  <div className="container-notresalable">
                    <DropdownButton bsStyle="danger" bsSize="small" title={<span style={{ fontWeight: 'bold' }}>Permessi non vendibili:</span>} onChange={this.onMarkTypeSelected} name="markTypeId">
                      {this.state.notSealableFilteredMarkTypes.length > 0 ? this.state.notSealableFilteredMarkTypes.map(item =>
                        <MenuItem key={item.id} value={item.id}>{item.label + ' - aree: ' + this.printAreas(item.Areas)}</MenuItem>) : <MenuItem value={0}>Nessuna Categoria Filtrata</MenuItem>}
                    </DropdownButton>
                  </div>
                */}

              </div>

              {selectedMarkTypeCategory &&
                <div className="col-md-6 text-left">
                  <label>Seleziona Tipo Permesso</label>
                  <Field onChange={this.onMarkTypeSelected} name="markTypeId" className="form-control" component="select" required>
                    {this.state.filteredMarkTypes.length > 0 && <option value={''}>Seleziona Permesso</option>}
                    {this.state.filteredMarkTypes.length > 0 ? this.state.filteredMarkTypes.map(item =>
                      <option key={item.id} value={item.id}>{item.label + ' - aree: ' + this.printAreas(item.Areas)}</option>) : <option value={0}>Nessun Permesso disponbile</option>}
                  </Field>
                </div>
              }



            </div>
            {this.state.markLimitReached == true &&
              <span className="text-danger">{this.state.errors}</span>
            }

            {this.state.markRules.id && this.state.markLimitReached == false &&
              <div className="row form-group">

                {/* SEZIONE VIRTUALE 
              { selectedMarkType.virtualizable &&
                <div className="col-md-12 checkbox text-left">
                  <label>
                    <Field
                      name="virtual"
                      component="input"
                      type="checkbox"
                    />
                    Contrassegno Virtuale
                  </label>
                </div>
              }
              */}

                {/* SEZIONE PARAMETRI DI CIRCOLAZIONE E SOSTA 
              { (selectedMarkTypeCategory) && 
                <React.Fragment>
                  <div className="col-md-4 text-left" style={{ marginBottom: "1em" }}>
                    <label>Circolazione</label>
                    <Field
                      name="circolazione"
                      className="form-control"
                      component="input"
                    />
                  </div>

                  <div className="col-md-4 text-left">
                    <label>Periodo</label>
                    <Field
                      name="periodo"
                      className="form-control"
                      component="input"
                    />
                  </div>
                  <div className="col-md-4 text-left">
                    <label>Sosta</label>
                    <Field
                      name="sosta"
                      className="form-control"
                      component="input"
                    />
                  </div>
                  <Field
                    name="color"
                    component="input"
                    type="hidden"
                  />                  
                </React.Fragment>                            
              }
              */}

                {/* SEZIONE DOCUMENTI 
              { this.state.markTypeDocuments.length > 0 && 
                <div className="col-md-12 text-left">
                  <label>Documenti necessari:</label>
                  <ul>
                    {this.state.markTypeDocuments.map((item, index) => {
                      return (
                        <li key={`markTypeDocument-${index}`}>
                          {item.name}
                        </li>
                      )
                    })}
                  </ul>
                </div>                                  
              }
              */}
              </div>
            }

            {/* SEZIONE RICHIESTA CON SELEZIONE DATA DI INIZIO VALIDITA' */}
            {selectedMarkType && (selectedMarkType.type === MARK_TYPE_FAMILY || (markEnableStartDate && markEnableStartDate.value == "1")) && this.state.markLimitReached == false &&
              <div className="row form-group">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    {selectedMarkType.type === MARK_TYPE_FAMILY ?
                      <React.Fragment>
                        <legend className="text-left">Data di nascita del figlio</legend>
                        <p>Il contrassegno Family è valido fino al compimento di <strong>1 anno</strong>.</p>
                      </React.Fragment> :
                      <React.Fragment>
                        <legend className="text-left">Data</legend>
                      </React.Fragment>
                    }
                  </div>
                </div>
                <div className="col-md-6 text-left">
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <label>Data di Nascita del figlio</label> :
                    <label>Data di inizio validità permesso</label>
                  }
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <Field
                      placeholderText="GG/MM/AAAA"
                      component={DatePickerFormComponent}
                      name="selectedStartDate"
                      className="mainField"
                      onDateChange={this.handleDateChange}
                      //onDateChange={this.onDateChange}
                      popperPlacement="top-end"
                      required
                    />
                    :
                    <Field
                      placeholderText="GG/MM/AAAA"
                      component={DatePickerFormComponent}
                      name="selectedStartDate"
                      className="mainField"
                      onDateChange={this.handleDateChange}
                      //onDateChange={this.onDateChange}
                      popperPlacement="top-end"
                      required
                    />
                  }

                </div>

                <div className="col-md-6 text-left">
                  {selectedMarkType.type === MARK_TYPE_FAMILY ?
                    <label>Data di scadenza permesso</label> :
                    <label>Data di scadenza permesso</label>
                  }
                  <Field
                    placeholderText={this.state.selectedEndDate}
                    component={DatePickerFormComponent}
                    name="selectedEndDate"
                    className="mainField DateInput__disabled"
                    onDateChange={this.onDateChange}
                    initialValue={this.state.selectedEndDate}
                    popperPlacement="top-end"
                    disabled={true}
                  />
                </div>
                <div className="col-md-6">
                  <label> </label>
                  {this.checkSelectedStartDate() && (
                    selectedMarkType.type == MARK_TYPE_FAMILY ?
                      <div className="text-danger">
                        Errore! Il figlio ha già raggiunto un anno di età.
                      </div>
                      :
                      <div className="text-danger">
                        La data di validità deve essere successiva alla mezzanotte della data odierna
                      </div>
                  )
                  }
                </div>
              </div>
            }

            {/* SEZIONE SELEZIONE VEICOLI */}
            {this.state.markRules.id && this.state.markLimitReached == false && this.state.markRules.vehicleLimit > 1 &&
              <div className="row form-group">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    <legend className="text-left">Veicolo</legend>
                  </div>
                  <span
                    className={vehicles.length > this.state.markRules.vehicleLimit
                      ? 'text-danger'
                      : 'text-primary'}>
                    {vehicles.length} / {this.state.markRules.vehicleLimit}
                  </span>
                  {' '}
                  <button
                    type="button"
                    className="btn mainBtn"
                    onClick={() => {
                      this.setState(prevState => ({
                        vehicles: [...prevState.vehicles, '']
                      }), () => {
                        this.updatePrice()
                        const newIndex = this.state.vehicles.length;
                        this.props.dispatch(change('resellerUserDetailMark', `vehicle${newIndex}`, this.state.vehicles[newIndex - 1]))
                      })
                    }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Aggiungi Veicolo</Tooltip>}>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faPlusCircle} />
                    </OverlayTrigger>
                  </button>
                </div>
              </div>
            }

            {/* SEZIONE LISTA VEICOLI */}
            {this.state.markRules.id && this.state.markLimitReached == false && vehicles.map((item, index) => (
              <div key={index} className="row form-group">
                {/* TODO  verificare la funzione di aggiunta e rimozione veicoli che non pulisce bene la lista e invia veicoli non desiderati alla creazione  */}
                <div className="col-md-4 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <label>Seleziona {index + 1}° Veicolo</label>
                  </div>
                </div>

                <div className="col-md-6 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <Field onChange={(e) => this.onVehicleChange(e, index)} name={'vehicle' + index} className="form-control" component="select" required>
                      {(!userVehicles) && <option value={''}>Questo Utente non ha Veicoli</option>}
                      {(userVehicles && userVehicles.length === 0) && <option value={''}>
                        Questo Utente non ha Veicoli
                      </option>}
                      {(userVehicles && userVehicles.length > 0) &&
                        <option value={''}>Seleziona un veicolo</option>}
                      {(userVehicles && userVehicles.length > 0) &&
                        userVehicles.map(item =>
                          <option key={item.id} value={item.id}>
                            {item.plate_number + ' - ' + item.VehiclePowerSupply.name}
                          </option>)}

                    </Field>
                    { /* console.log("resellerUserDetailMarkForm.state:", this.state) */ }
                  </div>

                </div>
                {this.state.markRules.vehicleLimit > 1 && 
                  <div className="col-md-2 text-left">
                    <div className="input-group" style={{ marginTop: 5, marginBottom: 12 }}>
                      <button
                        onClick={() => this.removeVehicle(index)}
                        type="button"
                        className="btn alertBtn">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Rimuovi Veicolo</Tooltip>}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />
                        </OverlayTrigger>
                      </button>
                    </div>
                  </div>
                }

              </div>)
            )}
            {/* SEZIONE SELEZIONE DATA INIZIO ABBONAMENTO */}
            {/* this.state.markRules.id && selectedMarkType.type != MARK_TYPE_FAMILY && this.state.markLimitReached == false && (
          <div className="row">
            <div className="col-md-6 text-left">
                  <label>Inizio validità</label> 
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="selectedStartDate" //Questo è il nome del campo che memorizzerà la data di inizio dell'abbonamento. Quando il valore di questo campo cambia, lo utilizzi per aggiornare lo stato ed eseguire i calcoli correlati.(REDUX FORM)
                  className="mainField"
                  onDateChange={this.onDateChange}
                  popperPlacement="top-end"
                  selected={startDateIsValid ? selectedStartDate : null}
                  required                  
                />
            </div>
            <div className="col-md-6 text-left">
                  <label>Scadenza Permesso</label> 
                <Field
                  placeholderText="GG/MM/AAAA"
                  component={DatePickerFormComponent}
                  name="selectedEndDate" //Questo è il nome del campo che memorizzerà la data di fine dell'abbonamento(STATO REDUX FORM).
                  className="mainField"
                  onDateChange={this.onDateChange}
                  selected={endDateIsValid ? selectedEndDate : null}
                  popperPlacement="top-end"
                  required                  
                />
            </div>
          </div>
          )*/}

            {/* SEZIONE PREZZO */}
            {(this.state.markTypeId) && this.state.markLimitReached == false && (
              <div className="row">
                <div className="col-md-12 text-left">
                  <div className="titolo-categoria">
                    <legend className="text-left">Prezzo</legend>
                  </div>
                </div>
                <div className="col-md-4 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    <div className="input-group-addon">€</div>
                    { }
                    <Field
                      name="total"
                      component="input"
                      type="number"
                      className="form-control"
                      disabled
                      onChange={e => {
                        if (!e.target.value) {
                          this.setState({ selectedTotalPrice: 0 })
                          this.props.change('total', 0)
                        } else {
                          this.setState({ selectedTotalPrice: e.target.value })
                          this.props.change('total', e.target.value)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-left">
                  <div className="input-group" style={{ marginTop: 12, marginBottom: 12 }}>
                    {priceDescr}
                  </div>
                </div>
                {this.isMarksPersonsPerHoursActive() &&
                  <div className="col-md-2 text-left">
                    <div className="input-group" style={{ marginTop: 5, marginBottom: 12 }}>
                      <button type="button" className="btn mainBtn" onClick={() => this.showMarksPersonsPerHouseUnits(true)}>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Mostra i permessi attivi dei componenti del nucleo abitativo</Tooltip>}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faUsers} />
                        </OverlayTrigger>
                      </button>
                    </div>
                  </div>
                }
              </div>
            )}

            {this.state.markRules.id && this.state.selectedTotalPrice > 0 && this.state.markLimitReached == false && <div className="row">
              <div className="col-md-6 text-left">
                <label>Totale</label>
                <h4>€ {Number(this.state.selectedTotalPrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
              </div>
            </div>}

            {/* SEZIONE METODI DI PAGAMENTO */}
            {this.state.markRules.id && this.state.selectedTotalPrice > 0 && this.state.markLimitReached == false && <div className="row">
              <div className="col-md-6 text-left">
                <label>Metodo di Pagamento</label>
                <div className="radio">
                  {/* todo: per Bruno: Rivedere il metodo di pagamento gestito da rivenditore, quindi il metodo di pagamento inserito nella contabile. Mi pareva che fosse RIVENDITORE e non SPORTELLO_RIVENDITORE, in caso contrario va cambiato in tutti gli altri UserDetailEditMarkForm e UserDetailMarkForm */}
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_RIVENDITORE" required checked disabled/>Pagamento Gestito da Rivenditore</label>
                </div>
              </div>
            </div>}
            {/*
            {this.state.markRules.id && this.state.selectedTotalPrice > 0 && this.state.markLimitReached == false && <div className="row">
              <div className="col-md-6 text-left">
                <label>Metodo di Pagamento</label>
                <div className="radio">
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_POS" required /> POS</label>
                </div>
                { this.state.enableCash && 
                <div className="radio">
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_CONTANTI" required /> CONTANTI</label>
                </div>
                }
                { this.state.enablePagoPa && 
                <div className="radio">
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_PAGOPA" required /> PAGOPA</label>
                </div>
                }
                { this.state.enableReclaim && 
                <div className="radio">
                  <label><Field component="input" type="radio" name="method" value="SPORTELLO_BONIFICO" required /> BONIFICO</label>
                </div>
                }
              </div>
            </div>}
            */}

            {this.state.markRules.id && this.state.markLimitReached == false && ((this.state.price && this.state.price.price == 0) || !this.state.price) && <div className="text-primary">
              Permesso gratuito
            </div>}

            {/* SEZIONE MESSAGGGI */}
            {this.props.loading && this.state.markLimitReached == false &&
              <div className="well">
                <div className="row"><h4>Creazione Permesso in Corso...</h4></div>
                <div className="row" style={{ padding: "2em", height: "8em" }}>
                  <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
                </div>
              </div>
            }

            {errorFetching && this.state.markLimitReached == false && <div className="text-danger">
              ERRORE: {errorFetching}
            </div>}

            {markCreateError && this.state.markLimitReached == false && <div className="text-danger">
              ERRORE: {markCreateError}
            </div>}

            {this.props.markSuccess && this.state.markLimitReached == false && <div>
              <div className="row">
                <div className="col-md-4 col-md-offset-4 ">
                  <div className="contrassegno">
                    <h4 className="contrassegno-h">Permesso creato correttamente!</h4>
                  </div>
                </div>
              </div>
            </div>}

            {/* SEZIONE PULSANTI */}
            {errorFetching == null && this.state.markLimitReached == false &&
              <p>
                <button type="button" disabled={isButtonDisabled || this.checkSelectedStartDate() || (selectedPlateNumber == null || selectedPlateNumber == "Seleziona un veicolo")} className="btn mainBtn" onClick={this.handleButtonClick}>
                  Crea Permesso
                </button>
              </p>
            }
            { /* console.log("handleSubmit value:", handleSubmit) */ }
            {this.state.showModal &&
              <ReportResellerModal
                resellerInfo={operator}
                user={user}
                showModal={showModal} //
                onCloseModal={this.handleCloseModal}
                category={category} //
                markTypeId={markTypeId} //
                selectedMarkType={selectedMarkType} //
                selectedMarkTypeCategory={selectedMarkTypeCategory} //
                selectedMarkTypeCategoryCategoryId={selectedMarkTypeCategory.CategoryId}
                selectedStartDate={selectedStartDate} //
                selectedEndDate={selectedEndDate} //
                selectedPlate={selectedPlateNumber} //
                totalPrice={price} //
                onCreateMark={onCreateMark}
                newMark={newMark}
                marksResellersConfirmed={marksResellersConfirmed} // Aggiorna lo state e inserisce il pulsante "Stampa Ricevuta"
                downloading={downloading}
                markData={markData}
                markCreateError={markCreateError}
                markSuccess={markSuccess}
                vehicles={vehicles} //
                quantity={quantity} //
                price={selectedTotalPrice} //
                // mancano
                // this.state.showMarkPerPersonUnits
                // this.state.priceDescr
                // this.state.errorFetching
                // this.state.markRules
                // this.state.markTypeDocuments
                // this.state.settings // sia props che state?
                // this.props.settings // sia props che state?
                // this.props.handleSubmit
              />
            }
          </div>
        </form>

        <MarksPersonsPerHouseUnitsModal
          show={showMarkPerPersonUnits}
          userId={user.id}
          markTypeId={selectedMarkType != null ? selectedMarkType.id : null}
          onClose={() => { this.showMarksPersonsPerHouseUnits(false) }}
        />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'resellerUserDetailMark'
})(ResellerUserDetailMarkForm))
