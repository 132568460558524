import React, { Component } from 'react'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'//
import moment from 'moment'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import { Area, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as authActions } from '../../../redux/modules/Auth'//
import { reportActions } from 'redux/modules/Report'
const thisRoute = 'reseller'
import './ResellerReport.css'
import fileDownload from 'js-file-download'
import history from '../../../history'
//const fileDownload = require('js-file-download')

moment.locale('it')

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth,
    stateReport: state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class ResellerReports extends Component {
  static propTypes = {}

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {

    super(props)

    this.state = {
      loading: false,
      passReleasedLoading: false,
      passReleased: [],
      passReleasedStartDate: moment().subtract(1, 'months'),
      passReleasedEndDate: moment(),
      countersLoading: false,
      counters: {
        activeMarks: 0,
        onlineRegisteredUsers: 0,
        offlineCreatedUsers: 0,
        markTypes: null,
        marksCreated: 0,
        marksSoldOffline: 0,
        marksSoldOnline: 0
      },
      countersStartDate: moment().subtract(1, 'months'),
      countersEndDate: moment(),
      countersTransactionLoading: false,
      countersTransaction: {
        totalOrdersOnline: 0,
        totalOrdersOnlineCompleted: 0,
        totalOrdersOnlineRejected: 0,
        totalOperations: 0,
        totalOnlineOperations: 0
      },
      countersTransactionStartDate: moment().subtract(1, 'months'),
      countersTransactionEndDate: moment()
    }

    this.onPassReleasedDateRangeChange = this.onPassReleasedDateRangeChange.bind(this)
    this.onCountersDateRangeChange = this.onCountersDateRangeChange.bind(this)
    this.onCountersTransactionsDateRangeChange = this.onCountersTransactionsDateRangeChange.bind(this)
    this.onDownloadReport = this.onDownloadReport.bind(this)
    this.onRedirectHome = this.onRedirectHome.bind(this)

  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {
    this.setState({ loading: true })
    await this.fetchPassReleasedChart()
    await this.fetchGeneralCounters()
    await this.fetchTransactionCounters()
    //await this.fetchThingsToDo()
    this.setState({ loading: false })
  }

  /**
   * --------------------------------------------------------------
   */
  /*fetchThingsToDo() {
    SostaOnlineApiService.fetchThingsToDo(thisRoute).then(data => {
      this.props.reportActions.fetchThingsToDo(data.data)
    })
      .catch(error => {
        console.log("ERROR", error)
      })
  }*/

  /**
   * --------------------------------------------------------------
   */
  async fetchPassReleasedChart() {
    const { passReleasedStartDate, passReleasedEndDate } = this.state
    var result = null

    try {
      result = await SostaOnlineApiService.fetchPassReleasedChart(
        passReleasedStartDate.format('YYYY-MM-DD'),
        passReleasedEndDate.format('YYYY-MM-DD'),
        thisRoute
      )
      this.setState({ passReleased: result.data })
    }
    catch (error) {
      console.log("ERROR", error)
    }


  }

  /**
   * --------------------------------------------------------------
   */
  async fetchGeneralCounters() {
    const { countersStartDate, countersEndDate } = this.state
    var result = null

    try {
      result = await SostaOnlineApiService.fetchGeneralCounters(
        countersStartDate.format('YYYY-MM-DD'),
        countersEndDate.format('YYYY-MM-DD'),
        thisRoute
      )

      this.setState({ counters: result.data })
    }
    catch (error) {
      console.log("ERROR", error)
    }


  }

  /**
   * --------------------------------------------------------------
   */
  async fetchTransactionCounters() {
    const { countersTransactionStartDate, countersTransactionEndDate } = this.state
    try {
      const result = await SostaOnlineApiService.fetchOnlineTransactionsCounters(
        countersTransactionStartDate.format('YYYY-MM-DD'),
        countersTransactionEndDate.format('YYYY-MM-DD'),
        thisRoute
      )
      this.setState({ countersTransaction: result.data })
    } catch (error) {
      console.log("Erro", error)
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} param0 
   */
  onPassReleasedDateRangeChange({ startDate, endDate }) {
    this.setState({
      passReleasedStartDate: startDate,
      passReleasedEndDate: endDate
    }, async () => {
      if (!this.state.passReleasedStartDate || !this.state.passReleasedEndDate) {
        return
      }

      this.setState({ passReleasedLoading: true })
      await this.fetchPassReleasedChart()
      this.setState({ passReleasedLoading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} param0 
   */
  onCountersDateRangeChange({ startDate, endDate }) {
    this.setState({
      countersStartDate: startDate,
      countersEndDate: endDate
    }, async () => {
      if (!this.state.countersStartDate || !this.state.countersEndDate) {
        return
      }

      this.setState({ countersLoading: true })
      await this.fetchGeneralCounters()
      this.setState({ countersLoading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} param0 
   */
  onCountersTransactionsDateRangeChange({ startDate, endDate }) {
    this.setState({
      countersTransactionStartDate: startDate,
      countersTransactionEndDate: endDate
    }, async () => {
      if (!this.state.countersTransactionStartDate || !this.state.countersTransactionEndDate) {
        return
      }

      this.setState({ countersTransactionLoading: true })
      await this.fetchTransactionCounters()
      this.setState({ countersTransactionLoading: false })
    })
  }

  /**
   * --------------------------------------------------------------
   */
  async onDownloadReport() {
    const { reseller } = this.props
    const resellerId = reseller.id

    try {

      const result = await SostaOnlineApiService.downloadReportReseller(this.state.passReleasedStartDate, this.state.passReleasedEndDate, thisRoute, resellerId);

      if (result && result.data && result.data.csv) {

        // Crea un blob com i dati CSV ricevuti dal server
        const blob = new Blob([result.data.csv], { type: 'text/csv' });

        // Crea una URL temporary con il BLOB
        const url = window.URL.createObjectURL(blob);

        // Crea un link per iniziare il download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();

        // Clean l'url temporario
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Errore nel download del report: risposta invalida del server');
      }
    } catch (error) {
      console.error('Errore nel download del report:', error);
    }

  }

  onRedirectHome() {
    history.push('/reseller/home')
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {
    const {
      passReleased,
      loading,
      passReleasedLoading,
      passReleasedStartDate,
      passReleasedEndDate,
      passReleasedFocusedDate,
      counters,
      countersLoading
    } = this.state

    if (loading && countersLoading) {
      return (
        <div className="panel panel-default panel-border panel-reseller-report">
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faChartLine} />&nbsp;&nbsp;REPORTS
              </h3>
            </div>
            <br /><br /><br />
            <h3>Caricamento in corso...</h3>
            <div className="row" style={{ padding: "2em", height: "40em" }}>
              <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
          </div>
        </div>
      )
    }

    if (countersLoading == false) {
      return (
        <div className="panel panel-default panel-border panel-reseller-report">
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faChartLine} />&nbsp;&nbsp;REPORTS
              </h3>
            </div>
            <div className="titolo-categoria">
              <legend className="text-left">Permessi rilasciati nel periodo:</legend>
            </div>
            <div className="row col-12 btn-download-report-reseller-container" style={{ marginTop: '20px' }}>
              <div className="col-md-6 ">
                <DateRangePicker
                  isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                  startDate={passReleasedStartDate}
                  startDateId="your_unique_start_date_id"
                  endDate={passReleasedEndDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={this.onPassReleasedDateRangeChange}
                  focusedInput={passReleasedFocusedDate}
                  onFocusChange={focusedInput => this.setState({ passReleasedFocusedDate: focusedInput })}
                />
              </div>
              <div className="col-md-2">{ }</div>
              <div className="col-md-1">{ }</div>
              <div className="col-md-2 btn-download-report-reseller" onClick={this.onDownloadReport}>
                <span className="span-btn-download-report-reseller"><strong>SCARICA REPORT</strong></span>
              </div>
              <div className="col-md-1">{ }</div>
            </div>
            {passReleasedLoading ? (
              <div style={{ height: '400px', backgroundColor: '#ffffff' }} />
            ) : (
              <div style={{ height: '400px', width: '100%', padding: '10px 10px 10px 0px' }}   >

                {passReleased && passReleased.firstGraph &&
                  <ResponsiveContainer width="98%" height={400}>
                    <ComposedChart
                      data={passReleased.firstGraph}
                      margin={{ top: 5, right: 5, left: 0, bottom: 0 }}>
                      <CartesianGrid horizontal={true} vertical={true} strokeDasharray="4 1" />
                      <XAxis dataKey="dateFormat" tick={{ stroke: '#9B9B9B', strokeWidth: 1 }} padding={{ left: 0, right: 0 }} />
                      <YAxis type="number" tick={{ stroke: '#9B9B9B', strokeWidth: 1 }} padding={{ top: 0, bottom: 10 }} />
                      <Tooltip />
                      <Legend align="center" />
                      <Area type="monotone" dataKey="total" stroke="lightgrey" fill="lightgrey" />
                      <Line type="monotone" dataKey="online" stroke="blue" />
                      <Line type="monotone" dataKey="offline" stroke="red" />
                    </ComposedChart>
                  </ResponsiveContainer>
                }
              </div>
            )}
            <div className="row" style={{ marginTop: '80px' }}>
              <div className="col-md-4">
                <ul className="list-group">
                  <li className="list-group-item list-group-item-info">Totale Permessi per tipologia:</li>
                  {(!countersLoading && counters.markTypes) && counters.markTypes.map((item, index) => (
                    <li key={'mark-type-counter-' + index} className="list-group-item">
                      <span className="badge" style={{ fontSize: '20px' }}>{item.markCounts}</span>
                      {item.name.toLowerCase()}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center">
                      <div className="thumbnail">
                        <div className="caption">
                          <div style={{ fontSize: '35px' }} className="text-info">{counters.marksCreated}</div>
                          <span>Totale Permessi Richiesti</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="text-center">
                      <div className="thumbnail">
                        <div className="caption">
                          <div className="text-success" style={{ fontSize: '35px' }}>{counters.marlsSoldTot}</div>
                          <span>Contr. Rilasciati</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="text-center">
                      <div className="thumbnail">
                        <div className="caption">
                          <div className="text-success" style={{ fontSize: '35px' }}>{counters.marksSoldOnline}</div>
                          <span>Contr. Rilasciati Online</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="text-center">
                      <div className="thumbnail">
                        <div className="caption">
                          <div style={{ fontSize: '35px' }}>
                            {counters.marksSoldOffline}
                          </div>
                          <span>Contr. Rilasciati Offline</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col-md-12" style={{ marginTop: "20px" }}>
              <div className="col-md-4"></div>

              <div className="col-md-4 text-center">
                <div className="mainBtn" onClick={this.onRedirectHome}>Torna a Home</div>
              </div>

              <div className="col-md-4"></div>
            </div>



          </div>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerReports)
