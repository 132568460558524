const ADMIN       = 'admin'
const OPERATOR    = 'operator'
const USER        = 'user'
const RESELLER    = 'reseller'

module.exports = {
    ADMIN,
    OPERATOR,
    USER,
    RESELLER
}