import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import ResellerEditDocumentType from './ResellerEditDocumentType';//

moment.locale('it')



class ResellerEditDocumentTypeModal extends Component {

  static propTypes = {
    show:                     PropTypes.bool.isRequired,
    documentTypeId:           PropTypes.number,
    onSaveDocumentType:       PropTypes.func.isRequired,
    onDeleteDocumentType:     PropTypes.func.isRequired,
    onCancelEditDocumentType: PropTypes.func.isRequired,
    onClose:                  PropTypes.func.isRequired
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }

    this.close            = this.close.bind(this)
    this.onSaveDocumentType       = this.onSaveDocumentType.bind(this)
    this.onDeleteDocumentType     = this.onDeleteDocumentType.bind(this)
    this.onCancelEditDocumentType = this.onCancelEditDocumentType.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
     
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
        
  }

 
  /**
   * --------------------------------------------------------------
   * @param {*} documentTypeId 
   */
  onSaveDocumentType(documentTypeId) {
    if ( this.props.onSaveDocumentType != null )
      this.props.onSaveDocumentType(documentTypeId)

    this.close()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onDeleteDocumentType() {    
    if ( this.props.onDeleteDocumentType != null )
      this.props.onDeleteDocumentType()
    
    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEditDocumentType() {
    if ( this.props.onCancelEditDocumentType != null )
      this.props.onCancelEditDocumentType()
    
  }

  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      documentTypeId 
    } = this.props
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>  {documentTypeId != null ? "MODIFICA" : "CREA" } TIPO DOCUMENTO </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => { this.close() } } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        <div className="col-md-12">
          <div className="app-modal-body">                                  
            <ResellerEditDocumentType
              documentTypeId={documentTypeId}
              onSaveDocumentType={this.onSaveDocumentType}      
              onDeleteDocumentType={this.onDeleteDocumentType}    
              onCancelEditDocumentType={this.onCancelEditDocumentType}
              />
          </div>
        </div>    

       
      </Modal>
    )
  }
}

export default reduxForm({ form: 'ResellerEditDocumentTypeModal'})(ResellerEditDocumentTypeModal)
