import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import RequestConfirmModal from 'components/General/RequestConfirmModal';//
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { RESELLER } from 'libs/roles';
import { DOCUMENT_TYPE_DRIVER, DOCUMENT_TYPE_GENERIC, DOCUMENT_TYPE_IDCARD, DOCUMENT_TYPE_VEHICLE } from 'libs/documents';
const thisRoute = 'reseller'

moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    resellerValues: state.form.resellerSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class ResellerEditDocumentType extends Component {

  static propTypes = {    
    documentTypeId:            PropTypes.number,    
    onSaveDocumentType:        PropTypes.func.isRequired,
    onDeleteDocumentType:      PropTypes.func.isRequired,
    onCancelEditDocumentType : PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      documentType: this.getNullDocumentType(),
      checkErrors: null,
      sendErrors: null,
      displayColorPicker: false,
      showConfirmDeleteDocumentType: false,      
         
    }

    this.saveDocumentType          = this.saveDocumentType.bind(this)
    this.checkFields               = this.checkFields.bind(this)        
    this.getNullDocumentType       = this.getNullDocumentType.bind(this)
    this.deleteDocumentType        = this.deleteDocumentType.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getNullDocumentType() {
    var nullDocumentType = { id: null, name: '', label: '', docType : DOCUMENT_TYPE_GENERIC }

    return nullDocumentType    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      documentType: this.getNullDocumentType(),
      checkErrors: null,
      sendErrors: null,      
      displayColorPicker: false,
      showConfirmDeleteDocumentType: false
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {       

    if ( prevProps.documentTypeId != this.props.documentTypeId  ) {
      this.stateInit()
      this.fetchData()
    }
        
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {
    
    if ( this.props.documentTypeId != null ) {
      var documentTypes = await SostaOnlineApiService.fetchDocumentType(this.props.documentTypeId)

      if ( documentTypes != null && documentTypes.data != null && documentTypes.data.length > 0 ) {        
        this.setState({documentType: documentTypes.data[0]})
      }
      else {
        this.setState({documentType: this.getNullDocumentType()})
      }
    }
    else {
      this.setState({documentType: this.getNullDocumentType()})
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.documentType.name == null || this.state.documentType.name.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }

    if ( this.state.documentType.label == null || this.state.documentType.label.trim().length <= 0) {
      errors.push("Label non specificata")
    }

    if ( this.state.documentType.docType == null || this.state.documentType.docType.length <= 0) {
      errors.push("Tipo documento non specificato")
    }

    
    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  saveDocumentType() {
    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    if ( this.state.documentType.id == null  ) {

      SostaOnlineApiService.createDocumentType(this.state.documentType,thisRoute)
      .then( result => {    
        
        var documentTypeId  = null

        if ( result.data != null && result.data.id != null )
          documentTypeId =  result.data.id
        
        if ( this.props.onSaveDocumentType != null ) {
          this.props.onSaveDocumentType(documentTypeId)
        }           
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }
    else {
      SostaOnlineApiService.updateDocumentType(this.state.documentType,thisRoute)
      .then( result => {      
        
        if ( this.props.onSaveDocumentType != null ) {
          this.props.onSaveDocumentType(this.props.documentTypeId)
        }        
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async deleteDocumentType() {    

    if ( this.props.documentTypeId != null  ) {

      await SostaOnlineApiService.deleteDocumentType(this.props.documentTypeId,thisRoute)
      .then( result => {      
        
        if ( this.props.onDeleteDocumentType != null ) {
          this.props.onDeleteDocumentType()
        }                    

      })
      .catch( error => {
        console.log("ERROR",error)

        if ( error.error != null ) {
          this.setState({ sendErrors: error.error })
        }
        else {
          this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
        }
        
      })

      this.setState({ showConfirmDeleteDocumentType: false})

    }

  }

  
  /**
   * --------------------------------------------------------------
   * 
   */
  cancelEditDocumentType() {

    this.stateInit()
    this.fetchData()

    if ( this.props.onCancelEditDocumentType != null )
      this.props.onCancelEditDocumentType()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setDocumentTypeName(name) {
    var documentType = this.state.documentType
    documentType.name=name
    this.setState({documentType: documentType})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} label 
   */
  setDocumentTypeLabel(label) {
    var documentType = this.state.documentType
    documentType.label=label
    this.setState({documentType: documentType})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} docType 
   */
  setDocumentTypeDocType(docType) {
    var documentType = this.state.documentType
    documentType.docType=docType
    this.setState({documentType: documentType})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {        
      documentTypeId 
    } = this.props

    const {
      sendErrors,
      checkErrors,
      documentType,
      showConfirmDeleteDocumentType
    } = this.state

    if ( this.props.auth.user.role != RESELLER ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }
      
    return (
      <React.Fragment>
              
        { documentType  == null &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { documentType != null &&
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            
            <React.Fragment>

              { documentType.id != null &&
              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}><strong>Id:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, marginLeft: 15, verticalAlign: "top" }}><strong>{documentType.id}</strong></h5>
                  </div>                                
              </div>
              }
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome:</strong></h5>
                </div>
                
                <div className="col-xs-9">
                  <input
                    name="name" 
                    className="form-control" 
                    type="text" 
                    onChange={ e => {  this.setDocumentTypeName(e.target.value) }}
                    value={documentType.name}
                    required />
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Label:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <input
                      name="label" 
                      className="form-control" 
                      type="text" 
                      onChange={ e => {  this.setDocumentTypeLabel(e.target.value) }}
                      value={documentType.label}
                      required />
                  </div>                                   
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Tipo:</strong></h5>
                </div>
                
                <div className="col-xs-9" >
                  <select
                    name="docType" 
                    className="form-control" 
                    onChange={ e => {  this.setDocumentTypeDocType(e.target.value) }}
                    value={documentType.docType == null ? DOCUMENT_TYPE_GENERIC :  documentType.docType }
                    required >
                      <option value={DOCUMENT_TYPE_GENERIC}>Doc. Generico</option>
                      <option value={DOCUMENT_TYPE_DRIVER} >Doc. Conducente</option>
                      <option value={DOCUMENT_TYPE_VEHICLE}>Doc. Veicolo</option>
                      <option value={DOCUMENT_TYPE_IDCARD} >Doc. Identità</option>
                  </select>
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                { sendErrors && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>              
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => { this.cancelEditDocumentType() } }>Annulla</Button>{' '}
                  <Button className="btn mainBtn"  onClick={() => { this.saveDocumentType() }} >Salva</Button>
                  { documentTypeId &&
                  <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDeleteDocumentType: true })} }><FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                  }
                </div>
              </div>          

            </React.Fragment>            
          </div>
        </div>           
        }  

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione Tipo Documento"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà il tipo documento "+ documentType.name +".<br/><br/>L'operazione è irreversibile, si vuole procedere?"}
          show={showConfirmDeleteDocumentType}
          onConfirm={this.deleteDocumentType}
          onRefuse={ () => {this.setState({ showConfirmDeleteDocumentType: false }) }}
        />

       
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerEditDocumentType'})(ResellerEditDocumentType))
