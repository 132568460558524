import React, { useState, useEffect } from 'react';
import SostaOnlineApiService from 'services/SostaOnlineApiService'


function MyTable(data) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Chiave</th>
                    <th>Valore</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(data).map(([key, value]) => (
                    <tr key={key}>
                        <td title={value == null ? key+": "+"null" : key+": "+value}>{key}</td>
                        <td title={value == null ? key+": "+"null" : key+": "+value}>{value == null ? "null" : value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

//console.log("components/Payments/MarksPayments: PaymentCheckResult: start")
const PaymentCheckResult = ({ markId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await SostaOnlineApiService.fetchMarkPaymentStatus(markId);
                //console.log("components/Payments/MarksPayments: PaymentCheckResult: useEffect: fetchData: response", response)
                this.props.updateCheckPaymentStatus("result",response.data)
                setResult(response.data);
            } catch (error) {
                this.props.updateCheckPaymentStatus("error",error.message)
                setError(error.message);
            } finally {
                this.props.updateCheckPaymentStatus("loading",false)
                setLoading(false);
            }
        };

        fetchData();
    }, [markId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!result) {
        return <div>No data available</div>;
    }

    //console.log("components/Payments/MarksPayments: PaymentCheckResult: result", result)
    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="table-responsive bg-warning" style={{ overflow: "hidden" }}>
            {MyTable(result)}
        </div>
    );
};

export default PaymentCheckResult;