import React, {Component} from 'react'
import {reduxForm, Field} from 'redux-form'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'//
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getResellerCardVehicleHeaderClass, getValidPlateNumberContent } from 'libs/vehicles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ResellerUserDetailVehicleLogsContainer from 'containers/Reseller/ResellerUserDetailVehicleLogsContainer'//
import PropTypes from 'prop-types'

class ResellerUserDetailVehicleEditForm extends Component {

  static propTypes = {    
    vehicle: PropTypes.object.isRequired,
    onCloseVehicleEdit: PropTypes.func.isRequired,
    status: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    operator: PropTypes.object.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      errors: false,
      ownershipTypes: [],
      powerSupplies: []
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  async componentDidMount () {
    try {
      this.setState({loading: true})
      let promises = [
        SostaOnlineApiService.fetchVehicleOwnershipTypes(),
        SostaOnlineApiService.fetchVehiclePowerSupplies()
      ]

      const result = await Promise.all(promises)

      if (result.filter(item => item.result !== 'ok').length > 0) {
        this.setState({errors: true, loading: false})
        return
      }

      this.setState({
        ownershipTypes: result[0].data,
        powerSupplies: result[1].data,
        loading: false
      })
    } catch (e) {
      this.setState({loading: false, errors: true})
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {handleSubmit, status, vehicle, operator} = this.props

    if (this.state.loading) {
      return (
      <div className="box">
        <div className="row"><h4>Caricamento in corso...</h4></div>
        <div className="row" style={{padding: "2em", height: "14em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
        </div>
      </div>)
    }

    if (this.state.errors) {
      return (<div className="box text-danger">
        Errore nel caricamento dei dati...
      </div>)
    }

    //console.log("STATUS",status)

    return (
      <form onSubmit={handleSubmit}>

        <div className={getResellerCardVehicleHeaderClass(vehicle,operator.role)}>

          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-sx-2"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <strong>MODIFICA VEICOLO</strong>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-sx-2">
              <OverlayTrigger
                placement="left"
                shouldUpdatePosition={true}
                overlay={<Tooltip id="tooltip5"><ResellerUserDetailVehicleLogsContainer vehicleId={vehicle.id}  /></Tooltip>}
                >
                <div className="icon-tooltip-div"><i className="glyphicon glyphicon-info-sign">{}</i></div>
              </OverlayTrigger>
            </div>
          </div>

          <div className="row">
            <div className="caption" >   
              
              <div className="row" style={{marginTop: "1em"}}>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-sx-10">
                  <Field name="VehicleOwnershipTypeId" className="form-control" component="select" required>
                    <option value="">Seleziona tipo di proprietà...</option>
                    {this.state.ownershipTypes.map((item, index) => {
                      return (<option value={item.id} key={item.id + '-' + index}>
                        {item.name}
                      </option>)
                    })}
                  </Field>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
              </div>

              <div className="row" style={{marginTop: "1em"}}>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-sx-10">
                  <Field 
                    component="input" 
                    name="plate_number" 
                    placeholder="TARGA" 
                    type="text" 
                    className="mainField" 
                    normalize={ value => getValidPlateNumberContent(value) }
                    required />              
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
              </div>             

              <div className="row" style={{marginTop: "1em"}}>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-sx-10">
                  <Field name="VehiclePowerSupplyId" className="form-control" component="select" required>
                    <option value="">Seleziona alimentazione veicolo...</option>
                    {this.state.powerSupplies.map((item, index) => {
                      return (<option value={item.id} key={item.id + '-' + index}>
                        {item.name}
                      </option>)
                    })}
                  </Field>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
              </div>

              { status && 
                <div className="row" style={{marginTop: "1em"}}>
                  <div className="col-md-12" >  
                    {status === 'loading' && <span>Modifica in Corso</span>}
                    {status === 'error'   && <span>Errore nella modifica del veicolo</span>}
                    {status === 'success' && <span>Veicolo Modificato Correttamente</span>}
                  </div>
                </div>
              }

              <div className="row" style={{marginTop: "1em"}}>
                <div className="col-md-12" style={{ marginBottom: "1em" }}>
                { ( status === 'success' || status === 'error' ) && 
                  <button onClick={this.props.onCloseVehicleEdit} type="button" className="btn btn-primary btn-xs btn-update btn-add-card">Chiudi</button>
                }
                { status !== 'success' && status !== 'loading' && status !== 'error'  &&
                  <button onClick={this.props.onCancelClick} type="button" className="btn btn-danger btn-xs btn-update btn-add-card"><FontAwesomeIcon size="1x" icon={solidIcons.faCircleXmark} />&nbsp; Annulla</button>
                }
                { status !== 'success' && status !== 'loading' && status !== 'error'  && 
                  <button type="submit" className="btn btn-success btn-xs btn-update btn-add-card"><FontAwesomeIcon size="1x" icon={solidIcons.faCircleCheck} />&nbsp; Conferma</button>
                }
              </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    )
  }
}

export default reduxForm({
  form: 'ResellerUserDetailVehicleEditForm'
})(ResellerUserDetailVehicleEditForm)
