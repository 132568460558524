
import DatePickerFormComponent from '../../redux-form/DatePickerFormComponent'
import React, { Component } from 'react'
import { Button, Tabs, Tab, Modal, Table, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { actions as authActions } from 'redux/modules/Auth'
import { reportActions } from 'redux/modules/Report'
import { reduxForm, Field, change } from 'redux-form'
import { connect } from 'react-redux'
import history from '../../../history'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import moment from 'moment'
import "./ResellerModal.css";
import ResellerMessages from 'containers/Reseller/Messages/Messages'
import ResellerModalMessages from 'containers/Reseller/Messages/ResellerMessages'
import 'moment/locale/it';
import { formatDate } from 'libs/utils';


const mapStateToProps = (state) => {
    return {
        stateReport: state.report
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch)
    }
}

class ResellerModal extends Component {

    /**
     * --------------------------------------------------------------
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            settings: {
                messages: {
                    enable: false
                }
            },
            minHeight: 0,
            isModalOpen: this.props.isModalOpen, // Definisce lo stato iniziale per la modale,
            isModalClose: this.props.isModalClose,
            activeTab: 1,
            monthDate: moment().format('YYYY/MM/DD'), // funzionalità definizione data di inizio e fine per il download
            monthNumber: moment().month(),
            monthDateName: moment().format('MMMM'),
            errorDownload: ''
        }
        this.fetchThingsToDo = this.fetchThingsToDo.bind(this)
        this.onRedirectHome = this.onRedirectHome.bind(this)        
    }
    
    componentDidMount() {
        //console.log("BackOfficeContainer componentDidMount")
        this.fetchSettings()
        this.fetchThingsToDo()
        this.MonthNameConverter(moment().format('DD/MM/YYYY'), moment().month())
    }
    /**
     * --------------------------------------------------------------
     */
    fetchSettings() {

        var settings = this.state.settings

        SostaOnlineApiService.fetchMessagesSettings()
            .then(data => {
                settings.messages = data.data
                this.setState({ settings: settings })
            })
            .catch(error => {
                console.log("ERROR", error)
            })
    }

    /**
     * --------------------------------------------------------------
     */
    fetchThingsToDo() {
        SostaOnlineApiService.fetchThingsToDo().then(data => {
            this.props.reportActions.fetchThingsToDo(data.data)
        })
            .catch(error => {
                console.log("ERROR", error)
            })
    }

    getBadgeClass(playBadge) {
        if (playBadge)
            return "badge badge-list badgeActive"
        else
            return "badge badge-list badgeInactive"
    }

    handleTabSelect = (selectedTab) => {
        this.setState({ activeTab: selectedTab });
    }

    percentage(maxValue, nowValue, mode) {
        if (mode) {
            return ((nowValue / maxValue) * 100).toFixed(2);
        } else {
            return (((maxValue - nowValue) / nowValue) * 100).toFixed(2);
        }
    }

    formatNumber(number, mode = 2) {
        return `€ ${number.toFixed(mode).replace(/\./, ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
        //return `€ ${number.toFixed(2).replace(/\./, ',')}`;
        //return `€ ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
        //return `€ ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.').replace(".", ",")}`;
        //return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    onOpenNewMessageModal = () => {
        // Aggiungi qui la logica per mostrare la modale "ResellerMessages"
        this.setState({ showResellerMessagesModal: true });
    }

    onCloseNewMessageModal = () => {
        // Aggiungi qui la logica per mostrare la modale "ResellerMessages"
        this.setState({ showResellerMessagesModal: false });
    }

    onClickResellerDetails = () => {
        history.push('/reseller/reports');

        this.props.onCloseModal()
        this.setState({ isModalOpen: false })
    }

    /**
     * --------------------------------------------------------------
     */

    convertArrayOfObjectsToCSV(array) {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => Object.values(obj).join(','));
        return header + '\n' + rows.join('\n');
    }


    /**
     * --------------------------------------------------------------
     */
    async onGenerateCSV(DatiRivenditore) {       

        let reportData = []
        let f = 'YYYY/MM/DD'

        if (DatiRivenditore != null && DatiRivenditore.length > 0) {
            let noVehicles = false
            for (let index in DatiRivenditore) {
                let resellerName = await SostaOnlineApiService.getMarksResellersName(DatiRivenditore[index].resellerId);                
                let thisMark = DatiRivenditore[index].Marks                
                noVehicles = thisMark.ActiveVehicle == undefined || thisMark.ActiveVehicle == null || thisMark.ActiveVehicle.plate_number == null
                let targa = noVehicles ? 'N/D' : thisMark.ActiveVehicle.plate_number
                let tecnologia = noVehicles ? 'N/D' : thisMark.ActiveVehicle.VehiclePowerSupply.name
                let targhe = noVehicles ? 'N/D' : thisMark.Vehicles.map(item => item.plate_number).join(' · ')
                let societa = thisMark.User.companyName
                let piva = thisMark.User.vatNumber                

                var reportRow = {
                    'RESELLER NAME': resellerName.data,
                    'RESELLER ID': DatiRivenditore[index].resellerId,
                    'NOME COMPLETO': thisMark.User.firstName + ' ' + thisMark.User.lastName,
                    'CODICE_FISCALE': thisMark.User.fiscalCode,
                    'SOCIETA': societa ? societa : 'N/D',
                    'P.IVA': piva ? piva : 'N/D',
                    'NR_PERMESSO': thisMark.id,
                    'STATO_PERMESSO': thisMark.ceased ? 'CESSATO' : 'ATTIVO',
                    'DATA_EMISSIONE': moment(thisMark.issuedDate).format(f),
                    'DATA_INIZIO': moment(thisMark.startDate).format(f),
                    'DATA_FINE': moment(thisMark.endDate).format(f),
                    'TIPO_PERMESSO': thisMark.virtual ? 'VIRTUALE' : 'NORMALE',
                    'NOME_PERMESSO': thisMark.MarkType.name,
                    'TARGHE ASSOCIATE': targhe,
                    'VEICOLO_ATTIVO': targa + " ( " + tecnologia + " )",
                    'PERMESSO PADRE': thisMark.fatherMark ? thisMark.fatherMark : 'N/D',
                    'IMPORTO': '€ ' + DatiRivenditore[index].price // potrebbe essere necessario mostrare un importo pubblico e politico per non dover aggiungere in futuro un eventuale aggio
                };

                reportData.push(reportRow);
            }

        }
        //console.log("[components/Reseller/Modals/ResellerModal.js: onGenerateCSV] reportData:", reportData)
        if (reportData.length > 0) {

            const csvContent = this.convertArrayOfObjectsToCSV(reportData);
            return { csv: csvContent }

        } else {
            console.log("[components/Reseller/Modals/ResellerModal.js: onGenerateCSV] Error: Errore durante l'esportazione")
            return null
        }
    }

    MonthNameConverter(date, month) {        
        let monthDateName = moment(moment(date).format('DD/MM/YYYY')).format('MMMM');
        //let monthDate = moment(date).format('DD/MM/YYYY');
        let monthDate = moment(date,'DD/MM/YYYY').format('DD/MM/YYYY');        
        this.setState({ monthDateName: monthDateName, monthNumber: month, monthDate: moment(monthDate, 'DD/MM/YYYY').format('DD/MM/YYYY') });        
    }


    /**
     * --------------------------------------------------------------
     */
    async onDownloadReport(reseller, date) {       
        
        // let questaData = moment(moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')).format('YYYY/MM/DD')
        // let dataLogica = moment(moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')).format('YYYYMMDD')

        //let questaData = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')

        let dateFinal = (formatDate(date)); 
        console.log('dateFinal22: ' + dateFinal);
        let questaData = moment(dateFinal, 'YYYY/MM/DD').format('YYYY/MM/DD');
        let dataLogica = moment(dateFinal, 'YYYY/MM/DD').format('YYYY/MM/DD');        

        try {            
            // const records = await SostaOnlineApiService.getMarksResellersList(reseller.id, moment().format("YYYY/MM/DD"));            
            let resellerName = await SostaOnlineApiService.getMarksResellersName(reseller.id);
            console.log('resellerName: ' +  resellerName.data);            

            const MarksResellers = await SostaOnlineApiService.getMarksResellersList(reseller.id, resellerName.data, questaData);

            console.log('MarksResellers11: ' , MarksResellers);
            console.log('MarksResellers22: ' , reseller.id);
            console.log('MarksResellers33: ' , resellerName.data);
            
            //console.log('[components/Reseller/Modals/ResellerModal.js: onDownloadReport] MarksResellers', MarksResellers)
            if (MarksResellers && MarksResellers.data.total > 0) {
                const result = await this.onGenerateCSV(MarksResellers.data.rows)

                //console.log('[components/Reseller/Modals/ResellerModal.js: onDownloadReport] result', result)
                if (MarksResellers && result && result.csv) {

                    // Crea un blob com i dati CSV ricevuti dal server
                    const blob = new Blob([result.csv], { type: 'text/csv' });

                    // Crea una URL temporary con il BLOB
                    const url = window.URL.createObjectURL(blob);

                    // Crea un link per iniziare il download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = dataLogica + '_' + reseller.lastName + '_' + reseller.firstName + '_report.csv';
                    document.body.appendChild(a);
                    a.click();

                    window.URL.revokeObjectURL(url);
                } else {
                    //console.log('[components/Reseller/Modals/ResellerModal.js: onDownloadReport] result', result);
                    console.error('Errore nel download del report: risposta invalida del server');
                }
                this.setState({ errorDownload: '' })
            } else {
                let Errore = "Nessun permesso generato nel mese di " + this.state.monthDateName
                this.setState({ errorDownload: Errore })
                throw new Error(Errore)
            }
        } catch (error) {
            console.error('Errore nel download del report:', error);
        }

    }

    onRedirectHome() {
        history.push('/reseller/home')
    }

    optionsSelected(theMonth) {
        return (
            <select
                defaultValue={theMonth == '' || theMonth == null || theMonth == undefined ? moment().month() : theMonth}
                className="monthField"
                name="selectedMonth"
                required
                onChange={(event) => {
                    const selectedMonth = parseInt(event.target.value, 10); // Ottieni il mese selezionato come numero
                    const selectedDate = moment().month(selectedMonth).format('DD/MM/YYYY');
                    this.MonthNameConverter(selectedDate, selectedMonth);
                }}
            >
                <option>Seleziona un mese</option>
                {[...Array(12).keys()].map((month) => (
                    <option value={month}>
                        {moment().month(month).format('MMMM')}
                    </option>
                ))}
            </select>
        );
    }

    // Nel render, aggiungi il bottone e la modale
    render() {

        if (this.props) {
            const {
                user,
                stateReport,
                onNewMessagesClick,
                onLogoutClick,
                onPasswordChangeClick,
                settingsMessages,
                badgeData,
                onHandleButtonClick,
                isModalOpen
            } = this.props
            const {
                monthDate,
                monthNumber,
                monthDateName,
                errorDownload
            } = this.state

            if (user) {
                const { MarksResellers, marksBudgetValue, marksTotalValue, marksResellersTotal, buttonColor, iconType, theBudget, thePercentage, theTotal, theResidual, countMarksResellersTotal } = badgeData;
                //console.log('ResellerModal render badgeData:countMarksResellersTotal', countMarksResellersTotal);

                // todo: inserire il conteggio messaggi 'playMessagesNew' nel Tab Notifiche
                var playMessagesNew = (stateReport.newMessages && stateReport.newMessages > 0)
                const ricapitoloPlafond = '\nSu un plafond massimo di ' + theBudget;
                const ricapitoloTotale = ' per un valore totale di ' + theTotal;
                const ricapitoloPrincipale = marksResellersTotal == 0 ? "Non sono ancora stati creati permessi o abbonamenti" : marksResellersTotal > 1 ? "Sono stati creati " + marksResellersTotal + " permessi o abbonamenti" : "È stato creato un abbonamento";
                const ricapitoloResiduo = 'Rimane ' + theResidual + ' di budget';
                if (isModalOpen) {

                    // Funzione per generare le righe HTML di una tabella con i dati di MarksResellers
                    const generateRows = (records) => {
                        return records.slice(0, 10).map((record, index) => (
                            <tr key={index}>
                                <td>{record.Marks.User.firstName + ' ' + record.Marks.User.lastName}</td>
                                <td>{record.Marks.User.fiscalCode}</td>
                                <td>{record.Marks.id}</td>
                                <td>{record.Marks.MarkType.name}</td>
                                <td>{record.Marks.Vehicles.map((vehicle, idx) => (idx !== 0 ? ', ' : '') + vehicle.plate_number)}</td>
                            </tr>
                        ));
                    };

                    const optionsSelected = (theMonth) => {
                        return (
                            <select
                                defaultValue={theMonth == '' || theMonth == null || theMonth == undefined ? moment().month() : theMonth}
                                className="monthField"
                                name="selectedMonth"
                                required
                                onChange={(event) => {
                                    const selectedMonth = parseInt(event.target.value, 10); // Ottieni il mese selezionato come numero
                                    const selectedDate = moment().month(selectedMonth).format('DD/MM/YYYY');
                                    this.MonthNameConverter(selectedDate, selectedMonth);
                                }}
                            >
                                <option>Seleziona un mese</option>
                                {[...Array(12).keys()].map((month) => (
                                    <option key={month} value={month}>
                                        {moment().month(month).format('MMMM')}
                                    </option>
                                ))}
                            </select>
                        );
                    };

                    return (
                        <Modal show={true} onHide={this.props.onCloseModal} className="modale-reseller">
                            <Modal.Header closeButton className="header-modale-reseller">
                                <Modal.Title id="header-modale-reseller" className="title-header-modale-reseller">

                                    {/*
                                        Pulsante invio email (mancano ancora relative procedure)
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        onClick={(evt) => { this.onOpenNewMessageModal() }}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" className="buttons-icons-modale-reseller" icon={solidIcons.faPaperPlane} />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            Assistenza
                                        </span>
                                        {<ResellerModalMessages show={this.state.showResellerMessagesModal} onClose={this.onCloseNewMessageModal} {...this.props} />}
                                    </Button>
                                    */}
                                    {/*
                                        Pulsante di logout e cambio password
                                    */}
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        onClick={() => {
                                            onPasswordChangeClick();
                                            this.props.onCloseModal(); //Dopo di fare click su forgot PWD, si chiude la modale
                                        }}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faUser} className="buttons-icons-modale-reseller" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            Cambio Password
                                        </span>
                                    </Button>
                                    <Button
                                        className="badge-buttons-modale-reseller button-reseller"
                                        onClick={onLogoutClick}
                                    >
                                        <span className=" buttons-icons-modale-reseller icon-wrapper">
                                            <FontAwesomeIcon size="1x" icon={solidIcons.faSignOutAlt} className="buttons-icons-modale-reseller" />
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="button-reseller">
                                            Logout
                                        </span>
                                    </Button>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="modale-body-reseller">
                                <Tabs defaultActiveKey={this.state.activeTab} id="tab-modale-reseller" className="tab-modale-reseller" onSelect={this.handleTabSelect}>

                                    <Tab eventKey={1} title={<strong>Rivenditore</strong>}>
                                        <div className="container container-modale-reseller">
                                            <div className="col-md-12 col-container-modale-reseller">
                                                <div className="row row-container-modale-reseller">
                                                    {/*tabella con i dati del rivenditore*/}
                                                    <table className="table table-container-modale-reseller">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Rivenditore:</strong></td><td>{user.firstName + " " + user.lastName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Email:</strong></td><td>{user.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Telefono:</strong></td><td>{user.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Comune:</strong></td><td>{user.residence}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Indirizzo:</strong></td><td>{user.address}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Citta:</strong></td><td>{user.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>CAP:</strong></td><td>{user.cap}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>P.IVA:</strong></td><td>{user.vat}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Operatività:</strong></td><td>{ricapitoloPrincipale + (marksResellersTotal > 0 ? ricapitoloTotale : '')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Plafond/budget:</strong></td><td>{marksResellersTotal == -1 || marksResellersTotal == '∞' ? 'Su un plafond infinito &infin;' : marksResellersTotal > 0 ? ricapitoloPlafond : 'Nessun plafond specificato'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Percentuale:</strong></td><td>{thePercentage} del budget a disposizione</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Residuo:</strong></td><td>{theResidual} del budget a disposizione</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    {/*
                                    <Tab eventKey={2} title={stateReport.newMessages ? <>
                                        <strong>Notifiche</strong>&nbsp;&nbsp;<Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={stateReport.newMessages ? stateReport.newMessages : 0} color="secondary"><FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} color="action" /></Badge></>
                                        : <strong>Notifiche</strong>}  >
                                        <ResellerMessages {...this.props} />
                                    </Tab>
                                    */}
                                    <Tab eventKey={2} title={<strong>Utenti e Permessi</strong>}>
                                        <div className="container container-modale-reseller">

                                            <div className="col-md-12 col-container-modale-reseller">
                                                <div className="row row-container-modale-reseller" style={{ marginTop: "1vh", marginBottom: "2vh" }}>
                                                    {MarksResellers && MarksResellers.length == 0 && (
                                                        <div className="stiky-tab-modale-reseller">
                                                            Qui sarà presente la lista degli ultimi 10 abbonamenti creati per il mese corrente.
                                                        </div>
                                                    )}
                                                    {MarksResellers && MarksResellers.length > 0 && (
                                                        <div className="stiky-tab-modale-reseller">
                                                            Questi sono gli ultimi 10 abbonamenti creati.
                                                        </div>
                                                    )
                                                    /*
                                                        ResellerNavigatorScreen fetchBadgeData() restituisce gli ultimi 10 abbonamenti creati (ordinati sulla base dell'ID)
                                                    */}
                                                </div>
                                                <div className="row row-container-modale-reseller">
                                                    <Table className="table table-container-modale-reseller" responsive striped bordered condensed hover>
                                                        <thead>
                                                            <tr>
                                                                {/*<!--th>#</th-->*/}
                                                                <th>Nome Cognome</th>
                                                                <th>Codice Fiscale</th>
                                                                <th>Permesso</th>
                                                                <th>Categoria</th>
                                                                <th>Veicoli</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {MarksResellers && MarksResellers.length === 0 ? (
                                                                <tr>
                                                                    <td>Dati non disponibili</td>
                                                                </tr>
                                                            ) : (
                                                                generateRows(MarksResellers)
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>

                                                <div className="row row-container-modale-reseller" style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                                                    {countMarksResellersTotal > 0 && (
                                                        <div className="stiky-tab-modale-reseller">Per ottenere maggiori dettagli su abbonamenti emessi e utenti registrati premere il pulsante <strong>Dettaglio Rivenditore</strong>.</div>
                                                    )
                                                    /*
                                                        countMarksResellersTotal conta TUTTI gli abbonamenti del reseller, anche quelli dei mesi precedenti
                                                    */}
                                                </div>
                                                {countMarksResellersTotal > 0 ?
                                                    <div className="row row-container-form-modale-reseller" style={{ marginTop: '30px' }} >
                                                        <div className="stiky-tab-modale-reseller fixedSize300">
                                                            <label className="labelMonthField">
                                                                <span><strong>Ottieni Report di:</strong></span>
                                                            </label>
                                                            {(optionsSelected(monthNumber))}
                                                        </div>
                                                        <div className="stiky-tab-modale-reseller">                                                            
                                                            <Button className="badge-buttons-modale-reseller button-reseller" onClick={() => this.onDownloadReport(user, this.state.monthDate)}>
                                                                <span className="buttons-icons-modale-reseller icon-wrapper icon-tie-modale-reseller">
                                                                    <FontAwesomeIcon className="buttons-icons-modale-reseller icon-tie-modale-reseller" size="2x" icon={solidIcons.faUserTie} />
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <span className="button-reseller">
                                                                    <small>Dettaglio Rivenditore</small><br /><strong>{user.firstName + " " + user.lastName}</strong>
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row row-container-form-modale-reseller" style={{ marginTop: '30px' }} >
                                                        Dal primo abbonamento emesso in poi, qui sarà disponibile il Dettaglio del Rivenditore
                                                    </div>
                                                }

                                                {errorDownload != '' ?
                                                    <div className="row row-container-modale-reseller">
                                                        <div className="col-12 stiky-tab-modale-reseller text-danger">
                                                            {errorDownload}
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>

                            </Modal.Body>

                        </Modal >
                    )
                } else {
                    return (
                        <Modal open={false}>
                            <div className="container">
                            </div>
                        </Modal>
                    )
                }

            } else {
                return null;
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ResellerModal' })(ResellerModal))