import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import MarksPersonsPerHouseUnits from './ResellerMarksPersonsPerHouseUnits';

moment.locale('it')



class MarksPersonsPerHouseUnitsModal extends Component {

  static propTypes = {
    show:       PropTypes.bool.isRequired,
    userId:     PropTypes.number,    
    markTypeId: PropTypes.number,
    onClose:    PropTypes.func.isRequired,
  }
  
  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }

    this.close    = this.close.bind(this)    
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
     
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
        
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      userId,
      markTypeId
    } = this.props
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faList} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong> PERMESSI ATTIVI NELLO STESSO NUCLEO ABITATIVO </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => { this.close() } } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        <div className="col-md-12">
          <div className="app-modal-body">   
           { userId != null && markTypeId != null &&                                
            <MarksPersonsPerHouseUnits
              userId={userId}
              markTypeId={markTypeId}            
              />
           }
          </div>
        </div>    

       
      </Modal>
    )
  }
}

export default reduxForm({ form: 'MarksPersonsPerHouseUnitsModal'})(MarksPersonsPerHouseUnitsModal)
