import React, { Component } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import SostaOnlineApiService from 'services/SostaOnlineApiService'

export default class PaymentResult extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //       priceMark: 0
  //   }
  //   const costMark = (markId) => {
  //     console.log('markId!!!: ' + markId);
  //     SostaOnlineApiService.costMarks(markId)
  //     .then(data => {
  //     console.log('dataaaaaaaaa111222: ' , data);
  //     this.setState({ priceMark: data })
  //     })
  //     .catch(error => {
  //     console.log("ERROR: ", error)
  //     })
  //   }    
    
  //   costMark(1861)    
  // }

  render() {  

    console.log("containers/UserArea/Checkout/PaymentResult:render:props", this.props);
    /*console.log("Containers.UserArea.Checkout.PaymentResult:render:result:", this.props.result)*/
    
    const risultato = this.props.result // object
    const esito = risultato.TransactionResult // "OK" o "KO"
    const errore = risultato.ErrorDescription // null oppure "Errore di comunicazione con il gateway"
    const codiceErrore = risultato.ErrorCode // "B2S" // viene generato da noi tra l'altro, perché poi, mah?
    const importo = risultato.importo // 90.00
    const Amount = risultato.Amount //risultato.Amount // 0.90 su 90 euro???
    const ordine = risultato.codTrans // 103
    const codiceEsito = risultato.codiceEsito // "EXECUTED" // "DECLINED"
    const messaggio = risultato.messaggio // null oppure spiega l'errore
    const paymentid = risultato.paymentid // "677154204660141099" / del pagamento presente nell'ordine e nella risposta di xPayid
    const uuid = risultato.uuid // il valore univoco che avevamo generato e inviato all'inizio "71dae9b0-941f-4ab4-a611-9caa34ac1a14"
    // TODO: qui mi interrogo, se non verifichiamo il confronto di payment id e uuid per i pagamenti fraudolenti, a cosa serve portarci dietro questi valori?
    const dettaglio = (codiceErrore !== null && codiceErrore !== errore ? codiceErrore + " - " : "") + (codiceErrore !== errore ? errore : "")
    const attend = (esito !== "OK") ? <p>Attendere 15 minuti prima di tentare una nuova esecuzione del pagamento.</p> : ""
    //console.log("containers/UserArea/Checkout/PaymentResult:render:props", this.props);
    return (
      <div className="col-md-offset-2 col-md-8">
        <div className="panel panel-default panel-border">
          <div className="panel-body" style={{ margin: "2vh 2vw", padding: "2vh 2vw" }}>
            <h2 className="title">Risultato Acquisto: {esito}</h2>

            {esito === "KO" || esito === "ERRORE" || esito === null ?
              <h3 style={{ marginBottom: "0" }}>
                {esito == "KO" && codiceErrore == "B2S" ?
                  <div>
                    <p><strong>La transazione è stata interrotta dall'utente.</strong></p>
                    {attend}
                  </div>
                  : esito == "ERRORE" && codiceEsito == "DECLINED" ?
                    <div>
                      <p><strong>La transazione è stata rifiutata dall'emittente e non è andata a buon fine.</strong></p>
                      <p style={{ margin: "1.5em", color: "#eb242e" }}>
                        {dettaglio}
                      </p>
                      {attend}
                    </div>
                    :
                    <div>
                      <p><strong>La transazione non è andata a buon fine oppure è stata interrotta.</strong></p>
                      <p style={{ margin: "1.5em", color: "#eb242e" }}>
                        {dettaglio}
                      </p>
                      {attend}
                    </div>
                }
              </h3>
              : esito === "ANNULLO" ?
                <div>
                  <h3 style={{ marginBottom: "0" }}>
                    <p><strong>La transazione è stata annullata</strong></p>
                  </h3>
                  {attend}
                </div>
                : esito === "OK" ?
                  <h3 style={{ color: "#84b22f", fontWeight: "bold" }}>
                    <div>
                      <p>Transazione avvenuta con <strong>successo!</strong></p>
                    </div>
                    <div>
                      <span style={{ margin: "1.5em" }}>                                             
                        <h2 style={{ padding: 10 }}> Totale: € {Number(this.props.result.Amount).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
                      </span> 
                    </div>
                  </h3>
                  : ""
            }

            <div className="text-center" style={{ display: "flex", justifyContent: "center" }}>
              <NavLink to={"/user-area"} className="btn mainBtn" style={{ margin: "1.5em" }}>Torna al profilo</NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PaymentResult.propTypes = {
  result: PropTypes.object
}
