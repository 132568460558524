import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";//
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import ResellerEditArea from './ResellerEditArea';//

moment.locale('it')



class ResellerEditAreaModal extends Component {

  static propTypes = {
    show:             PropTypes.bool.isRequired,
    areaId:           PropTypes.number,
    onSaveArea:       PropTypes.func.isRequired,
    onDeleteArea:     PropTypes.func.isRequired,
    onCancelEditArea: PropTypes.func.isRequired,
    onClose:          PropTypes.func.isRequired,
    
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      
    }

    this.close            = this.close.bind(this)
    this.onSaveArea       = this.onSaveArea.bind(this)
    this.onDeleteArea     = this.onDeleteArea.bind(this)
    this.onCancelEditArea = this.onCancelEditArea.bind(this)
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
     
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {       
        
  }

 
  /**
   * --------------------------------------------------------------
   * @param {*} areaId 
   */
  onSaveArea(areaId) {
    if ( this.props.onSaveArea != null )
      this.props.onSaveArea(areaId)

    this.close()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onDeleteArea() {    
    if ( this.props.onDeleteArea != null )
      this.props.onDeleteArea()
    
    this.close()
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    if ( this.props.onClose != null )
      this.props.onClose()     
        
  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEditArea() {
    if ( this.props.onCancelEditArea != null )
      this.props.onCancelEditArea()
    
  }

  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,      
      areaId 
    } = this.props
      
    return (
      <Modal
        open={show}
        onClose={this.close}
        closeOnEsc={true}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        //focusTrapped={true}
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>  {areaId != null ? "MODIFICA" : "CREA" } ZONA </strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => { this.close() } } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 

        <div className="col-md-12">
          <div className="app-modal-body">                                  
            <ResellerEditArea
              areaId={areaId}
              onSaveArea={this.onSaveArea}      
              onDeleteArea={this.onDeleteArea}    
              onCancelEditArea={this.onCancelEditArea}
              />
          </div>
        </div>    

       
      </Modal>
    )
  }
}

export default reduxForm({ form: 'ResellerEditAreaModal'})(ResellerEditAreaModal)
