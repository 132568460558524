import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'

export default class CategoryNameBreacrumb extends Component {
  static propTypes = {
    id: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      category: {}
    }
  }

  async componentDidMount () {
    const {id} = this.props
    this.setState({loading: true})
    const result = await SostaOnlineApiService.fetchUserCategoryById(id)
    this.setState({loading: false, category: result.data})
  }

  render () {
    const { loading, category } = this.state

    if (loading) {
      return (
        <span> ... </span>
      )
    }

    return (
      <span>{category.name}</span>
    )
  }

}