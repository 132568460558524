import React, { Component } from 'react'
import './BackOfficeNavMenuComponent.css'
import './FrontOfficeNavMenuComponent.css'
import { DropdownButton, Navbar, MenuItem, Collapse } from 'react-bootstrap'
import { Link, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import HeaderComponent from '../components/HeaderComponent'
import InputLogo from '../assets/LogoInput60x60.png'

import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    stateReport: state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}


const UserAreaLink = ({ label, to, children }) => (
  <Route path={to} children={({ match }) => (
    <li className={match ? 'li-home-list' : 'li-list'}>
      <Link className={'clear-link'} to={to}>{children}</Link>
    </li>
  )} />
)

const UserAreaLinkMobile = ({ label, to, children, onLiClick }) => (
  <Route path={to} children={({ match }) => (
    <li onClick={() => onLiClick()} className={(match ? 'li-home-list' : 'li-list') + ' mobile-nav-link'}>
      <Link style={{ textAlign: 'left' }} className={'clear-link'} to={to}>{children}</Link>
    </li>
  )} />
)

class FrontOfficeNavMenuComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuVisible: false
    }
  }

  notifyIcon(count) {
    if (count != null && count > 0)
      return (<div className="badge badge-menu">{count}</div>)

    return null
  }

  render() {

    const {
      stateReport,
    } = this.props

    return (<div>
      <Navbar className="nav-user" style={{ marginBottom: 0 }}>
        <div className="centerPositionBetween">
          <div className="visible-sm hidden-md hidden-lg visible-xs">
            <a href="https://inputitalia.com/" target="_blank">
              <img style={{ margin: 10, width: '45px', height: '45px', marginLeft: 10, }} alt="Powered by Input s.r.l. P.IVA 01175100997" src={InputLogo} />
            </a>
          </div>

          <button type="button"
            onClick={() => this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible })}
            className="navbar-toggle collapsed navbar-btn visible-sm visible-xs"
            data-toggle="collapse"
            aria-expanded="false"
            aria-controls="navbar">
            <FontAwesomeIcon size="2x" icon={solidIcons.faBars} />
          </button>
        </div>
        <div className="visible-sm hidden-md hidden-lg visible-xs">
          <Collapse in={this.state.mobileMenuVisible}>
            <div className="nav navuserl-list-div" style={{ marginBottom: 10 }}>
              <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/marks'}>
                <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faWallet} />&nbsp;&nbsp;PERMESSI</div>
              </UserAreaLinkMobile>
              <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/vehicles'}>
                <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faCar} />&nbsp;&nbsp;VEICOLI</div>
              </UserAreaLinkMobile>
              <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/profile'}>
                <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faAddressCard} />&nbsp;&nbsp;PROFILO</div>
              </UserAreaLinkMobile>
              <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/order-history'}>
                <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faCreditCard} />&nbsp;&nbsp;ACQUISTI</div>
              </UserAreaLinkMobile>
              <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/documents'}>
                <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faFolderOpen} />&nbsp;&nbsp;DOCUMENTI</div>
              </UserAreaLinkMobile>
              {this.props.settingsMessages.enable &&
                <UserAreaLinkMobile onLiClick={() => this.setState({ mobileMenuVisible: false })} to={'/user-area/messages'}>
                  <div style={{ fontSize: 15 }} ><FontAwesomeIcon size="1x" icon={solidIcons.faEnvelope} className="col-sx-3" />&nbsp;&nbsp;CONTATTACI&nbsp;{this.notifyIcon(stateReport.newMessages)}</div>
                </UserAreaLinkMobile>
              }
              <li onClick={() => this.setState({ mobileMenuVisible: false })} className="mobile-nav-link li-list">
                <a style={{ textAlign: 'left' }} className="clear-link" onClick={this.props.onLogoutClick}>
                  <div style={{ fontSize: 15 }}><FontAwesomeIcon size="1x" icon={solidIcons.faArrowRightFromBracket} />&nbsp;&nbsp;LOGOUT</div>
                </a>
              </li>
            </div>
          </Collapse>
        </div>

        <div className="hidden-sm visible-md visible-lg hidden-xs">
          <div className="menuPosition">
            <div>
              <a href="https://inputitalia.com/" target="_blank">
                <img style={{ width: '45px', height: '45px' }} alt="Powered by Input s.r.l. P.IVA 01175100997" src={InputLogo} />
              </a>
            </div>
            <div>
              <ul className="nav navuser-list-div nav-pills">
                <UserAreaLink to={'/user-area/marks'}>PERMESSI</UserAreaLink>
                <UserAreaLink to={'/user-area/vehicles'}>VEICOLI</UserAreaLink>
                <UserAreaLink to={'/user-area/order-history'}>ACQUISTI</UserAreaLink>
                <UserAreaLink to={'/user-area/documents'}>DOCUMENTI</UserAreaLink>
                {this.props.settingsMessages.enable &&
                  <UserAreaLink to={'/user-area/messages'}>CONTATTACI&nbsp;{this.notifyIcon(stateReport.newMessages)}</UserAreaLink>
                }
              </ul>
            </div>
            <div>
              <div className="col-personalized-user-nav1" style={{ display: 'flex' }}>
                <DropdownButton
                  id="Dropdown-Logout"
                  bsStyle="default"
                  noCaret
                  className="dropdown-nav-no-caret"
                  title={
                    <div>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faChevronDown} />&nbsp;&nbsp;
                      {this.props.user.firstName.toUpperCase() + ' ' + this.props.user.lastName.toUpperCase()}
                    </div>
                  }>
                  <MenuItem style={{ marginBottom: 5, textDecoration: 'none', color: '#333' }} to={'/user-area/profile'}>
                    <FontAwesomeIcon size="1x" icon={solidIcons.faUser} />&nbsp;&nbsp;Profilo
                  </MenuItem>
                  <MenuItem onClick={this.props.onLogoutClick} >
                    <FontAwesomeIcon size="1x" icon={solidIcons.faArrowRightFromBracket} />&nbsp;&nbsp;Logout
                  </MenuItem>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <HeaderComponent />
    </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'FrontOfficeNavMenuComponent' })(FrontOfficeNavMenuComponent))